import { isString, get } from 'lodash';

export function getValidationMessage(field, validator, messageBag) {
    const fieldValidator = get(validator, field);
    const message = get(messageBag, field);

    if (!fieldValidator.$error) {
        return '';
    }
    if (isString(message)) {
        return message;
    }

    return Object.keys(message).reduce((msg, key) => {
        if (!fieldValidator[key]) {
            return get(message, key);
        }
        return msg;
    }, '');
}
