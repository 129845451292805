<template>
    <div class="integration is-hubspot">
        <be-integration-header class="integration__header" back-url="/dashboard/marketplace/apps">
            <template v-slot:heading>
                Health &amp; Safety Settings
            </template>
            <template v-slot:subheading>
                <p>
                    The Health and Safety App allows easy monitoring of spaces through configuring hazard zones to keep
                    people safe, PPE absence detection.
                </p>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field horizontal>
                            <template v-slot:label>
                                <span class="is-block has-text-left has-text-weight-bold">
                                    Integration
                                </span>
                            </template>
                            <b-switch v-model="integration.enabled" />
                        </b-field>
                        <div class="content">
                            <p>
                                This features enables the Safe Spaces dashboard to provide insights about your
                                environment.
                            </p>
                        </div>
                    </div>
                    <div class="column is-12">
                        <b-button
                            @click="save"
                            class="be-button is-wider is-primary"
                        >
                            Save
                        </b-button>
                    </div>
                    <div class="column is-12">
                        <div class="card is-rounded integration__info mt-5">
                            <div class="card-content">
                                <div class="content">
                                    <h4>Using this integration</h4>
                                    <p>
                                        To use this integration, set up a stationery camera with a hazard zone defined
                                        and configured alerts for when a person enters this zone.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';
import marketplaceAppMixin from '@/mixins/marketplaceAppMixin';
import integrationFakeSaveMixin from '@/mixins/integrationFakeSaveMixin';

export default {
    mixins: [
        noticesMixin,
        marketplaceAppMixin,
        integrationFakeSaveMixin(false),
    ],
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
