<template>
    <div class="columns is-multiline">
        <div class="column is-12">
            <b-field>
                <template #label>
                    Choose the option below that best suits this camera’s primary use:
                </template>
            </b-field>
            <b-field>
                <b-radio v-model="configureSelect1" native-value="ENTRY" class="mr-3">
                    This is a dedicated entry camera
                </b-radio>
            </b-field>
            <b-field>
                <b-radio v-model="configureSelect1" native-value="EXIT" class="mr-3">
                    This is a dedicated exit camera
                </b-radio>
            </b-field>
            <b-field>
                <b-radio v-model="configureSelect1" native-value="SHOW_MORE" class="mr-3">
                    This camera monitors vehicle entering and exiting
                </b-radio>
            </b-field>
        </div>

        <div v-if="configureOrientation" class="column is-12">
            <b-field custom-class="is-marginless">
                <template #label>
                    From this camera’s viewing angle, choose the typical vehicle orientation observed:
                </template>
            </b-field>
            <b-field>
                <b-radio v-model="configureSelect2" native-value="AUTO_ENTRY_FRONT" class="mr-3">
                    This camera observes the front of the vehicle entering and the rear of the vehicle exiting
                </b-radio>
            </b-field>
            <b-field>
                <b-radio v-model="configureSelect2" native-value="AUTO_ENTRY_REAR" class="mr-3">
                    This camera observes the front of the vehicle exiting and the rear of the vehicle entering
                </b-radio>
            </b-field>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import modelMixin from '@/mixins/modelMixin';
import manageCameraStepMixin from '@/mixins/manageCameraStepMixin';
import { CAMERA_FUNCTION_TYPES } from '@/utils/sites/constants';

export default {
    props: {
        location: {
            type: [String, Number],
            default: '' || null,
        },
    },

    mixins: [
        modelMixin,
        manageCameraStepMixin,
        validationMixin,
    ],

    validations() {
        const validations = {
            cameraFunction: { required },
        };
        return validations;
    },

    data() {
        return {
            configureFunction: true,
            configureOrientation: false,
            configureSelect1: null,
            configureSelect2: null,
            cameraFunction: null,
        };
    },

    computed: {
        functionType() {
            return this.computedValue.function_type || 0;
        },
    },

    watch: {
        functionType: {
            handler(val) {
                const keys = Object.keys(CAMERA_FUNCTION_TYPES);
                const type = keys.reduce((curr, key) => {
                    const value = CAMERA_FUNCTION_TYPES[key];
                    if (value === val) {
                        return key;
                    }
                    return curr;
                }, keys[0]);

                const isConfigureFunctionEnabled = this.isConfigureFunctionEnabled(type);
                const isConfigureOrientationEnabled = this.isConfigureOrientationEnabled(type);

                if (isConfigureFunctionEnabled) {
                    this.configureFunction = true;
                    this.configureOrientation = isConfigureOrientationEnabled;
                    this.cameraFunction = type;
                    if (isConfigureOrientationEnabled) {
                        this.configureSelect1 = 'SHOW_MORE';
                        this.configureSelect2 = type;
                    } else {
                        this.configureSelect1 = type;
                        this.configureSelect2 = null;
                        this.configureOrientation = false;
                    }
                }
            },
            immediate: true,
        },
        cameraFunction(val) {
            this.computedValue.function_type = CAMERA_FUNCTION_TYPES[val] || 0;
        },
        configureFunction(val) {
            if (!val) {
                this.configureSelect1 = null;
                this.cameraFunction = '';
            }
            this.$v.$reset();
        },

        configureSelect1(val) {
            if (val === 'SHOW_MORE') {
                this.configureOrientation = true;
                this.configureSelect2 = null;
                this.cameraFunction = '';
                return;
            }
            this.configureOrientation = false;
            this.cameraFunction = val;
        },

        configureSelect2(val) {
            if (val) {
                this.cameraFunction = val;
            }
        },
    },

    methods: {
        isConfigureFunctionEnabled(functionType) {
            return ['ENTRY', 'EXIT', 'AUTO_ENTRY_FRONT', 'AUTO_ENTRY_REAR'].includes(functionType);
        },

        isConfigureOrientationEnabled(functionType) {
            return ['AUTO_ENTRY_FRONT', 'AUTO_ENTRY_REAR'].includes(functionType);
        },
    },
};
</script>
