<template>
    <div class="mapbox-map">
        <mgl-map
            v-if="shouldLoad"
            class="mapbox-map__map"
            ref="map"
            v-bind="mapboxOptions"
            :map-style="mapStyle"
            @load="onMapLoaded"
            @moveend="onMoveEnd"
        >
            <template v-for="(marker, index) in clustersGeoJson">
                <sighting-map-cluster
                    v-if="marker.properties.cluster"
                    :key="`cluster${index}`"
                    :marker="marker"
                />
                <sighting-map-multi-marker
                    v-else-if="marker.properties.items.length"
                    :key="`marker-multi${index}`"
                    :marker="marker"
                    @click="onMarkerClick"
                    @popup-opened="onImageInteraction"
                    @confidence-click="onImageInteraction"
                />
            </template>

            <mgl-navigation-control
                :show-compass="false"
                position="bottom-right"
            />
        </mgl-map>

        <div class="mapbox-map__view-toggler">
            <div class="field has-addons">
                <div class="control">
                    <b-button
                        class="be-button is-toggle"
                        size="is-small"
                        :type="mapView !== 'satellite' ? 'is-toggle-active' : 'is-toggle-inactive'"
                        @click="mapView = 'default'"
                    >
                        <span>Street</span>
                    </b-button>
                </div>
                <div class="control">
                    <b-button
                        class="be-button is-toggle"
                        size="is-small"
                        :type="mapView === 'satellite' ? 'is-toggle-active' : 'is-toggle-inactive'"
                        @click="mapView = 'satellite'"
                    >
                        <span>Satellite</span>
                    </b-button>
                </div>
            </div>
        </div>
        <media-image-popup
            :active.sync="showMauiImagePopup"
            :image-with-detail="mauiImageWithDetail"
            image-type="dolphin"
            :display-arrows="formattedApiResults && formattedApiResults.length > 1"
            :loading="isLoadingFull"
            @prev="goto('prev')"
            @next="goto('next')"
            @close="closePopup"
        />
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import Supercluster from 'supercluster';
import { mapActions, mapGetters } from 'vuex';
import { groupBy, head, isEmpty } from 'lodash';
import { MglNavigationControl } from 'v-mapbox';
import { getMapStyle, MAPBOX_OPTIONS } from '@/utils/map';
import SightingMapCluster from '@/components/widgets/maui63/maps/SightingMapCluster';
import SightingMapMultiMarker from '@/components/widgets/maui63/maps/SightingMapMultiMarker';
import mapMixin from '@/mixins/mapMixin';
import hasMauiImagePopupMixin from '@/mixins/hasMauiImagePopupMixin';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import hasPopupMixin from '@/mixins/hasPopupMixin';

export default {
    mixins: [
        mapMixin,
        hasMauiImagePopupMixin,
        hasPopupMixin,
        widgetDataMixin,
    ],

    components: {
        SightingMapMultiMarker,
        SightingMapCluster,
        MglNavigationControl,
    },

    data() {
        return {
            radioButton: '',
            shouldLoad: false,
            clustersGeoJson: [],
            mapboxOptions: {
                ...MAPBOX_OPTIONS,
                center: [174, -36],
                zoom: 9,
                attributionControl: false,
                maxZoom: 18,
            },
        };
    },

    mounted() {
        this.shouldLoad = true;
        this.createCluster();
    },

    beforeDestroy() {
        this.clusterIndex = null;
    },

    watch: {
        resultsGeoJson: {
            handler() {
                this.$nextTick(() => {
                    this.refreshClusterIndex();
                    this.centerMapBounds();
                    this.updateClusters();
                });
            },
            deep: true,
        },
    },

    computed: {
        mapView: {
            get() {
                return this.$store.state.maui.detections.map.mapView;
            },
            set(mapView) {
                this.$store.commit('maui/detections/map/setMapView', { mapView });
            },
        },
        ...mapGetters('maui/detections/map', [
            'overallCount',
            'isLoading',
            'isSuccess',
            'formattedApiResults',
        ]),

        mapStyle() {
            return getMapStyle(this.mapView);
        },

        resultsGeoJson() {
            const grouped = groupBy(this.formattedApiResults, (row) => `${row.lat}-${row.lng}`);
            return Object.values(grouped).map((items) => {
                const first = head(items);
                const count = items.length;
                const isMulti = count > 1;

                return {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: this.getDetectionBounds(first),
                    },
                    properties: {
                        name: first.count,
                        item: first,
                        count,
                        items,
                        isMulti,
                    },
                };
            });
        },

        mapBounds() {
            return this.formattedApiResults.reduce((bnds, item) => {
                bnds.extend(this.getDetectionBounds(item));
                return bnds;
            }, new Mapbox.LngLatBounds());
        },
    },

    methods: {
        ...mapActions({
            loadData: 'maui/detections/map/loadData',
            loadDataCount: 'maui/detections/map/loadDataCount',
            loadDataRow: 'media/images/loadDataRow',
        }),

        onMapLoaded() {
            this.centerMapBounds();
            this.updateClusters();
        },

        centerMapBounds() {
            if (!isEmpty(this.mapBounds) && this.$refs.map.map) {
                this.$refs.map.map.fitBounds(this.mapBounds, {
                    padding: {
                        top: 100, bottom: 75, left: 25, right: 25,
                    },
                    duration: 0,
                });
            }
        },

        onMarkerClick(item) {
            this.openPopup(item, 0, true);
        },

        onImageInteraction(item) {
            this.loadDataRow({ id: item.image });
        },

        onMoveEnd() {
            this.updateClusters();
        },

        getDetectionBounds(item) {
            return [
                item.lng,
                item.lat,
            ];
        },

        createCluster() {
            this.clusterIndex = new Supercluster({
                radius: 40,
                maxZoom: 16,
                log: true,
            });

            this.clusterIndex.load(this.resultsGeoJson);
        },

        refreshClusterIndex() {
            if (!this.clusterIndex) {
                this.createCluster();
                return;
            }

            this.clusterIndex.load(this.resultsGeoJson);
        },

        updateClusters() {
            const { map } = this.$refs.map;
            if (!map) {
                return;
            }

            const bounds = map.getBounds();
            const zoom = map.getZoom();

            const clustersGeoJson = this.clusterIndex.getClusters(
                [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
                Math.floor(zoom),
            );

            this.clustersGeoJson = clustersGeoJson;
        },
    },
};
</script>

<style lang="scss" scoped>
@import url('https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css');

.mapbox-map {
    &__map {
        @include be-toolbar-filters-full-height;
        width: 100%;
    }
}
</style>
