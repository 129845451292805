import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import form from './form';

const state = getDefaultDataState({ hasPagination: false });

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    formattedApiResults(state) {
        return state.data ?? [];
    },

    apiUrlParams() {
        const params = buildUrlParams({});
        return params;
    },

    apiUrl() {
        return 'organisation/invites/';
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
};

const actions = {
    loadData({ state, commit, getters }, { perPage = null, page = null, refresh = false }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = getters.apiUrlParams;
        const path = getters.apiUrl;

        const dataHash = createDataHash(path, params);

        if (!refresh) {
            if (state.dataHash === dataHash) {
                return Promise.resolve(true);
            }
        }

        commit('setStatus', { status: 'loading' });
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setStatus', { status: 'success' });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        form,
    },
};
