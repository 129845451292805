import { HS_CAMERA_VIOLATIONS_TYPES } from '@/utils/sites/cameras';

const getDefaultState = () => ({
    currentFilter: HS_CAMERA_VIOLATIONS_TYPES[0].handle,
});

const state = getDefaultState();

const mutations = {

    setFilter(state, handle) {
        state.currentFilter = handle;
    },

    resetFilters(state) {
        state.currentFilter = getDefaultState().currentFilter;
    },

    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    init() {
        return Promise.resolve();
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    modules: {},
};
