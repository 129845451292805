<template>
    <be-widget class="top-plates" :is-loading="isLoading || isLoadingFull" :has-table="true">
        <data-table-options :display-dates="false" class="pt-4" />
        <b-table
            :data="formattedApiResults"
            :hoverable="true"
            :selected="lastSelectedRow"
            :class="{ 'has-cursor-loading': isRowLoading }"
        >
            <b-table-column label="Id" width="1%" v-slot="props">
                <p class="has-text-centered has-text-weight-bold">
                    <a
                        href="#"
                        class="has-text-white"
                        @click.prevent="openPopup(props.row, props.index)">{{ props.index + 1 }}</a>
                </p>
            </b-table-column>
            <b-table-column label="Image" :width="imagesPreviewEnabled ? 100 : '1%'" v-slot="props">
                <image-preview
                    v-if="imagesPreviewEnabled"
                    :row="props.row"
                    @click="openPopup($event, props.index)"
                />
                <p v-else class="has-text-centered">
                    <be-button-view-image @click="openPopup(props.row, props.index)" />
                </p>
            </b-table-column>
            <b-table-column label="Entry Time" width="180" v-slot="props">
                <span class="has-cursor-default">
                    {{ props.row.entry_time | dateTimeFormatted }}
                </span>
            </b-table-column>
            <b-table-column label="Time Spent" width="110" v-slot="props">
                <b-tag class="has-cursor-default">
                    {{ props.row.dwellTime | dwellTime }}
                </b-tag>
            </b-table-column>
            <b-table-column label="Detections" v-slot="props">
                <be-detections-list
                    v-if="props.row.detections.length"
                    :detections="props.row.detections"
                    display-orientation
                    display-model
                    wrap
                    :display-confidence-label="false"
                />
                <b-tag v-else class="has-cursor-default">None</b-tag>
            </b-table-column>
            <b-table-column label="Site" width="150" v-slot="props">
                {{ props.row.site || '-' }}
            </b-table-column>
            <b-table-column label="Camera" width="150" v-slot="props">
                {{ props.row.camera || '-' }}
            </b-table-column>
            <b-table-column label=" " width="1%" v-slot="props" header-class="has-text-right">
                <be-button-remove @click="remove(props.row)" />
            </b-table-column>

            <template slot="empty">
                <data-table-empty v-if="showZeroResults">
                    There are no results to display. Try changing your filter or search criteria.
                </data-table-empty>
            </template>
        </b-table>
        <be-pagination
            key="live-explorer-data"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :count="overallCount"
        />
        <media-image-popup
            :active.sync="showCarJamInfo"
            :image-with-detail="platesAndImageAndWithDetail"
            :display-arrows="formattedApiResults && formattedApiResults.length > 1"
            :loading="isLoadingFull"
            @prev="goto('prev')"
            @next="goto('next')"
            @close="closePopup"
        />
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';
import BeDetectionsList from '@/components/global/BeDetectionsList';
import BeButtonRemove from '@/components/global/BeButtonRemove';
import detectionDetailMixin from '@/mixins/detectionDetailMixin';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import BePagination from '@/components/global/BePagination';
import { formatDwellTime } from '@/utils/dwell';
import dataTableMixin from '@/mixins/dataTableMixin';
import formattersMixin from '@/mixins/formattersMixin';
import ImagePreview from '@/components/local/data/ImagePreview';
import DataTableOptions from '@/components/local/dataTables/DataTableOptions';
import BeButtonViewImage from '@/components/global/BeButtonViewImage';

export default {
    mixins: [detectionDetailMixin, hasPopupMixin, dataTableMixin, formattersMixin],

    components: {
        BeButtonViewImage,
        DataTableEmpty,
        BePagination,
        BeDetectionsList,
        BeButtonRemove,
        ImagePreview,
        DataTableOptions,
    },

    filters: {
        dwellTime(time, inMinutes = true) {
            return formatDwellTime({ time, inMinutes });
        },
    },

    computed: {
        ...mapGetters('liveExplorer', [
            'overallCount',
            'isLoading',
            'formattedApiResults',
        ]),

        perPage: {
            get() {
                return this.$store.state.liveExplorer.perPage;
            },
            set(perPage) {
                return this.$store.commit('liveExplorer/setPerPage', { perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.liveExplorer.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit('liveExplorer/setPage', { page });
            },
        },

        showZeroResults() {
            return !this.isLoading
                && this.overallCount === 0;
        },
    },

    methods: {
        ...mapActions('liveExplorer', ['deleteRow']),
        ...mapActions('liveExplorer/summary', ['loadSummaryData']),

        openPopup(row, index) {
            this.viewDetails(row, index);
        },

        remove(row) {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure this vehicle is no longer onsite?',
                type: 'is-danger',
                onConfirm: () => {
                    this.deleteRow(row)
                        .then(() => (
                            this.loadSummaryData({ force: true })
                        ));
                },
            });
        },
    },
};
</script>
