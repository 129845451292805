<template>
    <div class="camera-metrics">
        <table class="camera-metrics__table">
            <thead>
                <tr>
                    <th class="camera-metrics__label is-measurement">Measurement metric</th>
                    <th class="camera-metrics__label is-medium">Medium risk threshhold</th>
                    <th class="camera-metrics__label is-high">High risk threshhold</th>
                    <th class="camera-metrics__label is-alert">Alert threshhold</th>
                    <th class="camera-metrics__label is-period">Threshhold period</th>
                </tr>
            </thead>
            <tbody>
                <h-s-measurement-row
                    ref="measurement"
                    v-for="(violation, type) in violationTypes"
                    :key="`row-${type}`"
                    :measurement="violation"
                    v-model="cameraModel.violation_metrics[type]"
                />
            </tbody>
        </table>
        <div class="camera-metrics__additional mt-4">
            <b-field label="">
                <b-checkbox v-model="cameraModel.hsafety_setting.alert_on_increased_risk_level">
                    <small>Alert on Risk Level increased and sustained for more than 2 hours</small>
                </b-checkbox>
            </b-field>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import HSMeasurementRow from '@/components/local/sites/hs/partials/HSMeasurementRow';
import {
    getDefaultHSCameraState,
    getHSCameraSaveData,
    HS_CAMERA_VIOLATIONS_TYPES,
} from '@/utils/sites/cameras';
import manageHSCameraMixin from '@/mixins/hs/manageHSCameraMixin';
import noticesMixin from '@/mixins/noticesMixin';

export default {
    components: {
        HSMeasurementRow,
    },

    mixins: [
        manageHSCameraMixin,
        noticesMixin,
    ],

    data() {
        return {
            cameraModel: {},
            violationTypes: HS_CAMERA_VIOLATIONS_TYPES,
        };
    },

    watch: {
        cameraId: {
            handler() {
                this.cameraModel = getDefaultHSCameraState(this.camera);
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('sites', ['updateSiteCamera']),

        save() {
            if (this.isLoading) {
                return Promise.resolve(false);
            }

            let isValid = true;
            this.$refs.measurement.forEach((el) => {
                if (!el.validate()) {
                    isValid = false;
                }
            });

            if (!isValid) {
                return Promise.resolve(false);
            }

            this.isLoading = true;
            return this.updateSiteCamera({
                cameraId: this.cameraId,
                siteId: this.site.id,
                data: getHSCameraSaveData(this.cameraModel),
            })
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Camera enabled successfully',
                    });
                    return true;
                })
                .catch((error) => {
                    this.$emit('request-error', error.message);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.camera-metrics {
    &__table {
        vertical-align: middle;
        border-spacing: 5px;
        border-collapse: separate;
    }

    &__label {
        line-height: 1.1;
        font-size: 13px;
        text-align: center;
        padding: 5px 10px;
        vertical-align: middle;

        &.is-measurement {
            text-align: left;
            padding-left: 0;
            padding-right: 0;
            font-size: 17px;
        }

        &.is-medium {
            color: $black;
            background: $yellow;
        }

        &.is-high {
            color: $black;
            background: $red;
        }

        &.is-period,
        &.is-alert {
            color: $black;
            background: $light-blue;
        }
    }
}
</style>
