import { required } from 'vuelidate/lib/validators';

export default {
    validations() {
        const integration = {};

        if (this.integration.enabled) {
            integration.apiKey = { required };
        }

        return {
            integration,
        };
    },

    data() {
        return {
            isSaving: false,
            integration: {
                enabled: false,
                apiKey: '',
            },
            validationMessages: {
                apiKey: 'Please provide a valid API key',
            },
        };
    },

    methods: {
        save() {
            if (this.isSaving || this.isTesting) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isSaving = true;
            this.closeNotices();

            setTimeout(() => {
                this.displaySuccessNotice({
                    message: 'Integration updated successfully',
                });
                this.isSaving = false;
            }, 1000);
        },

        fieldType(field) {
            return this.$v.integration[field].$error ? 'is-danger' : '';
        },

        fieldMessage(field) {
            return this.$v.integration[field].$error && this.validationMessages[field]
                ? this.validationMessages[field]
                : '';
        },
    },
};
