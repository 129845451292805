<template>
    <div class="live-dashboard">
        <div class="app-container">
            <b-loading :active="!isLoaded" :is-full-page="false" :can-cancel="false" />
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Live Dashboard
                </template>
                <template v-slot:subheading>
                    {{ `Next auto refresh in ${formattedTime}` }}
                </template>
            </be-page-header>
            <div
                class="camera-grid"
                v-if="camerasData.length !== 0 && isLoaded"
                :style="gridStyle"
            >
                <LiveDashboardCamera
                    v-for="(camera, index) in camerasData"
                    :key="index"
                    :camera="camera"
                    :cameraStyle="cameraStyle"
                    :imageObject="camera.imageObject"
                    :timeSinceLastImage="camera.timeSinceLastImage"
                />
            </div>
            <template v-else-if="isLoaded">
                <p>Setup cameras to see images</p>
            </template>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LiveDashboardCamera from '@/components/pages/dashboard/vehicle/LiveDashboardCamera';
import { getApiUrl } from '@/utils/api';
import noticesMixin from '@/mixins/noticesMixin';
import notificationSound from '@/assets/sounds/beep.mp3';

export default {
    name: 'LiveDashboard',

    components: {
        LiveDashboardCamera,
    },

    mixins: [
        noticesMixin,
    ],

    data() {
        return {
            refreshInterval: 300,
            timerCount: 300,
            camerasData: [],
            detectionsData: [],
            isLoaded: false,
            windowWidth: window.innerWidth,
            alertInstance: null,
        };
    },

    created() {
        this.timerCount--;
    },

    watch: {
        timerCount(value) {
            if (value > 0) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            } else {
                this.timerCount = this.refreshInterval;
                this.fetchCameras();
            }
        },
    },

    computed: {
        formattedTime() {
            const minutes = Math.floor(this.timerCount / 60);
            const seconds = this.timerCount % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },

        cameraStyle() {
            const size = (this.windowWidth * 0.07);
            return {
                minWidth: `${size}px`,
                maxWidth: `${size}px`,
                minHeight: `${size * 0.5625}px`,
                maxHeight: `${size * 0.5625}px`,
            };
        },

        gridStyle() {
            return {
                gridTemplateColumns: `repeat(10, minmax(${this.cameraStyle.maxWidth}, 1fr))`,
            };
        },
    },

    mounted() {
        window.addEventListener('resize', this.updateWindowWidth);

        this.fetchDetections();

        this.alertInstance = setInterval(() => {
            this.fetchDetections();
        }, 60000);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
        clearInterval(this.alertInstance);
    },

    methods: {
        async fetchCameras() {
            try {
                let proceedQuery = true;
                let page = 1;
                const maxItemPerPage = 1000;
                const result = [];
                while (proceedQuery) {
                    /* eslint-disable no-await-in-loop */
                    const response = await axios({
                        url: getApiUrl({ path: `cameras/live/?page=${page}&page_length=${maxItemPerPage}&` }),
                    });
                    page++;

                    if (response.data.results.length < maxItemPerPage) {
                        proceedQuery = false;
                    }

                    result.push(...response.data.results);
                }
                this.camerasData = result.map((camera) => {
                    let timeSinceLastImage = null;
                    const imageObject = camera?.image || null;

                    if (imageObject) {
                        const lastSeenDate = new Date(camera.last_seen);
                        const now = new Date();
                        const diffInMilliseconds = now - lastSeenDate;
                        const diffInMinutes = Math.ceil(diffInMilliseconds / (1000 * 60));

                        timeSinceLastImage = diffInMinutes;
                    }

                    return {
                        ...camera,
                        siteName: camera.site.name,
                        imageId: imageObject?.id || null,
                        imageObject: {
                            ...imageObject?.detected_objects || {},
                            url: imageObject?.image_file || null,
                            id: imageObject?.id || null,
                        },
                        timeSinceLastImage,
                    };
                });
                this.isLoaded = true;
                this.camerasData.sort((a, b) => new Date(a.last_seen) - new Date(b.last_seen));
            } catch (error) {
                console.error('Error fetching cameras:', error);
                throw error;
            }
        },

        async fetchDetections(minutes = 1) {
            if (this.camerasData.length === 0) {
                await this.fetchCameras();
            }
            try {
                const response = await axios({
                    url: getApiUrl({ path: `alpr/live-detections/?minutes=${minutes}&/` }),
                });
                response.data.forEach((detection) => {
                    const isStolen = detection.tags.find((obj) => obj.label.toLowerCase() === 'stolen');
                    const camera = this.camerasData.find((camera) => camera.imageId === detection.image.id);

                    if (isStolen) {
                        this.alertPopup(`Stolen vehicle detected!Camera: ${camera?.name.replace('CAM:', '') || ''}, Plate: ${detection?.data?.plate || ''}`);
                    }
                });
            } catch (error) {
                console.error('Error fetching detections:', error);
                throw error;
            }
        },

        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },

        alertPopup(message = '') {
            this.displayWarningNotice({
                message,
            });
            const audio = new Audio(notificationSound);
            const resp = audio.play();

            if (resp !== undefined) {
                // to prevent autoplay policy error showing
                resp.catch((error) => {
                    console.log(error);
                });
            }
        },
    },
};
</script>
<style scoped lang="scss">
.live-dashboard {
    .camera-grid {
        display: grid;
        grid-auto-rows: auto;
        column-gap: 5px;
        row-gap: 10px;
        text-align: center;
    }
}
</style>
