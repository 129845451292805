import { mapActions, mapGetters } from 'vuex';

export default {
    // watch: {
    //     isLoading(val) {
    //         if (val) {
    //             this.$emit('loading');
    //         } else {
    //             this.$emit('ready');
    //         }
    //     },
    // },

    computed: {
        ...mapGetters('maui/detections', [
            'overallCount',
            'isLoading',
            'isSuccess',
            'formattedApiResults',
        ]),

        perPage: {
            get() {
                return this.$store.state.maui.detections.perPage;
            },
            set(perPage) {
                return this.$store.commit('maui/detections/setPerPage', { perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.maui.detections.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit('maui/detections/setPage', { page });
            },
        },

        showZeroResults() {
            return !this.isLoading
                && this.overallCount === 0;
        },
    },

    methods: {
        ...mapActions({
            loadData: 'maui/detections/loadData',
        }),
    },
};
