<template>
    <div class="account">
        <div class="app-container">
            <be-page-header :app-container="false">
                Account
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-12">
                    <div class="card is-rounded">
                        <div class="card-header">
                            <div class="card-header-title">
                                Your Profile
                            </div>
                        </div>
                        <div class="card-content">
                            <p><strong>{{ fullName }}</strong></p>
                            <p>{{ userDetails.email }}</p>
                        </div>
                        <div class="card-footer">
                            <div class="card-footer-item">
                                <a
                                    @click="showPasswordPanel = true"
                                    @keydown.enter="showPasswordPanel = true"
                                    tabindex="0"
                                >
                                    Change Password
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <password-change-dialog
            :active="showPasswordPanel"
            @close="showPasswordPanel = false"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PasswordChangeDialog from '@/components/widgets/PasswordChangeDialog';

export default {
    // TODO: better separation of concerns, break out payment & plan logic
    name: 'Account',
    components: {
        PasswordChangeDialog,
    },
    data: () => ({
        showPasswordPanel: false,
    }),
    computed: {
        ...mapGetters('user', ['userDetails', 'userStatus']),
        ...mapGetters('payment', ['cardDetails', 'cardStatus']),
        ...mapGetters('plans', ['allPlans']),
        fullName() {
            const first_name = this.userDetails.first_name || ''; // eslint-disable-line camelcase
            const last_name = this.userDetails.last_name || ''; // eslint-disable-line camelcase
            return `${first_name} ${last_name}`; // eslint-disable-line camelcase
        },
        userLoading() {
            return this.userStatus === 'loading';
        },
    },
};
</script>
