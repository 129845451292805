<template>
    <div class="observation-filters">
        <div class="observation-filters__title">Observations Filters</div>
        <div class="observation-filters__list">
            <div class="observation-filters__row is-sites">
                <div class="observation-filters__group mr-4">
                    <multiselect
                        v-if="isMultiSelect"
                        class="multiselect"
                        v-model="currentFilters.metricType"
                        placeholder="Select metrics"
                        :options="metricTypes"
                        label="label"
                        track-by="type"
                        :multiple="true"
                    />
                    <b-select
                        v-else
                        v-model="currentFilters.metricType"
                        class="observation-filters__select is-risk"
                        :class="{ 'is-error': $v.currentFilters.metricType.$error }"
                    >
                        <option :value="null">Select metrics</option>
                        <option
                            v-for="r in metricTypes"
                            :key="r.handle"
                            :value="r.handle"
                        >
                            {{ r.label }}
                        </option>
                    </b-select>
                </div>
                <div class="observation-filters__group mr-4">
                    <multiselect
                        v-if="isMultiSelect"
                        class="multiselect"
                        v-model="currentFilters.selectedSites"
                        placeholder="Select site(s)"
                        :options="sites"
                        label="shortName"
                        track-by="id"
                        :multiple="true"
                    />
                    <b-select
                        v-else
                        v-model="currentFilters.site"
                        class="observation-filters__select is-site"
                        :class="{ 'is-error': $v.currentFilters.site.$error }"
                    >
                        <option :value="null">Select site</option>
                        <option
                            v-for="site in sites"
                            :key="site.id"
                            :value="site.id"
                        >
                            {{ site.shortName }}
                        </option>
                    </b-select>
                </div>
                <!-- <div class="observation-filters__group mr-4">
                    <multiselect
                        v-if="isMultiSelect"
                        class="multiselect"
                        v-model="currentFilters.selectedCameras"
                        placeholder="Select camera(s)"
                        :options="selectedSitesCameras"
                        label="name"
                        track-by="id"
                        :multiple="true"
                    />
                    <b-select
                        v-else
                        v-model="currentFilters.camera"
                        class="observation-filters__select is-camera"
                        :class="{ 'is-error': $v.currentFilters.camera.$error }"
                    >
                        <option :value="null">Select camera</option>
                        <option
                            v-for="camera in currentSiteCameras"
                            :key="camera.id"
                            :value="camera.id"
                        >
                            {{ camera.name }}
                        </option>
                    </b-select>
                </div> -->
            </div>
            <div class="observation-filters__datetime">
                <div class="observation-filters__datetime__col is-start">
                    <b-field label="From" custom-class="date-range-label">
                        <b-datepicker
                            v-model="currentFilters.from"
                            v-bind="datepickerOptions"
                            placeholder="Click to select..."
                            icon="calendar-today"

                        />
                        <b-clockpicker
                            ref="clockpickerStart"
                            v-model="startTime"
                            placeholder="Click to select..."
                            icon="clock"

                        >
                            <b-button
                                label="Confirm"
                                type="is-primary"
                                @click="updateTimeRange()"
                            />
                        </b-clockpicker>
                    </b-field>
                </div>
                <div class="observation-filters__datetime__col is-ends">
                    <b-field label="To" custom-class="date-range-label">
                        <b-datepicker
                            v-model="currentFilters.to"
                            v-bind="datepickerOptions"
                            placeholder="Click to select..."
                            icon="calendar-today"
                        />
                        <b-clockpicker
                            ref="clockpickerEnd"
                            v-model="endTime"
                            placeholder="Click to select..."
                            icon="clock"
                        >
                            <b-button
                                label="Confirm"
                                type="is-primary"
                                @click="updateTimeRange()"
                            />
                        </b-clockpicker>
                    </b-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import dayjs from 'dayjs';
import Multiselect from 'vue-multiselect';
import { getDefaultObservationFilters } from '@/store/vehicle/observations/observationFilters';
import { getDatepickerOptions } from '@/utils/datepicker';
import { VEHICLE_RISK_METRICS } from '@/utils/sites/cameras';

export default {
    components: {
        Multiselect,
    },

    mixins: [validationMixin],

    props: {
        simple: {
            type: Boolean,
            default: false,
        },
        isMultiSelect: {
            type: Boolean,
            default: false,
        },
    },

    validations() {
        const currentFilters = {
            metricType: { required },
            from: { required },
            // grouped: { required },
            to: {
                required,
                isBefore(value, model) {
                    return !dayjs(value).isBefore(model.to);
                },
            },
        };

        // if (!this.simple) {
        //     currentFilters.grouped = { required };
        // }

        return {
            currentFilters,
        };
    },

    data() {
        return {
            filteredObjectClasses: [...this.$store.state.vehicle.objectClasses.objectClasses],
            datepickerOptions: getDatepickerOptions(),
            currentFilters: {
                ...this.$store.state.vehicle.observations.observationFilters.currentFilters,
            },
            metricTypes: VEHICLE_RISK_METRICS,
        };
    },

    computed: {
        ...mapState('vehicle/observations/observationFilters', ['metricType', 'sites', 'cameras']),
        ...mapGetters('vehicle/observations/observationFilters', ['currentSiteCameras', 'selectedSitesCameras']),

        site() {
            return this.currentFilters.site;
        },

        startTime: {
            set(val) {
                this.currentFilters.from.setHours(val.getHours());
                this.currentFilters.from.setMinutes(val.getMinutes());
                this.currentFilters.from.setSeconds(val.getSeconds());
            },
            get() {
                return this.$store.state.vehicle.observations.observationFilters.currentFilters.from;
            },
        },

        endTime: {
            set(val) {
                this.currentFilters.to.setHours(val.getHours());
                this.currentFilters.to.setMinutes(val.getMinutes());
                this.currentFilters.to.setSeconds(val.getSeconds());
            },
            get() {
                return this.$store.state.vehicle.observations.observationFilters.currentFilters.to;
            },
        },
    },

    watch: {
        currentFilters: {
            handler(val) {
                this.setCurrentFilters(val);
            },
            deep: true,
        },

        site: {
            handler(val, oldval) {
                if (val && oldval) {
                    this.$set(this.currentFilters, 'camera', null);
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapMutations('vehicle/observations/observationFilters', ['setCurrentFilters']),
        ...mapActions('vehicle/observations/observationFilters', ['getSiteCameras']),
        getFilteredObjectClasses(text) {
            this.filteredObjectClasses = this.$store.state.vehicle.objectClasses.objectClasses
                .filter((obj) => (
                    obj.label
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                ));
        },

        updateTimeRange() {
            this.currentFilters.from = new Date(this.currentFilters.from);
            this.currentFilters.to = new Date(this.currentFilters.to);
            this.$refs.clockpickerStart.close();
            this.$refs.clockpickerEnd.close();
        },

        getFilters() {
            return {
                ...this.currentFilters,
            };
        },

        resetFilters() {
            this.currentFilters = getDefaultObservationFilters();
        },

        validate() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.observation-filters {
    &__title {
        font-size: 20px;
        font-weight: $weight-normal;
    }

    &__list {
        margin-top: 20px;
    }

    &__row + &__row {
        margin-top: 10px;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        &.is-search {
            .control {
                flex: 1;
            }
        }
    }

    &__label {
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    &__group {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    &__datepicker {
        width: 170px;
    }

    &__select {
        ::v-deep select {
            min-width: 100px;
        }

        &.is-camera,
        &.is-site {
            ::v-deep select {
                width: 200px;
            }
        }
    }

    .multiselect {
        margin-top: 5px;
        min-width: 300px;

        ::v-deep .multiselect__tags {
            background-color: transparent !important;
            border-color: #736b5e;
        }
        ::v-deep .multiselect__input{
            background-color: transparent !important;
        }
    }

    &__datetime {
        &__col {
            display: flex;
            align-items: stretch;

            .field {
                margin-top: 0.5em !important;
            }
        }

        .field {
            margin: 0;
        }

        .field + .field {
            margin-left: 0.5em;
        }

        &__separator {
            width: 1.5em;
            display: flex;
            align-items: center;
            margin-top: 1.5em;
            margin-bottom: 1.5em;

            span {
                width: 100%;
                display: block;
                height: 1px;
                background: #aaa;
            }
        }
    }

    &__select.is-error ::v-deep select,
    &__input.is-error ::v-deep input,
    &__datepicker.is-error ::v-deep input {
        border-color: $red;
    }

    ::v-deep .datepicker-footer {
        background: transparent;
    }
}
</style>
