<template>
    <div class="integration is-salesforce">
        <be-integration-header class="integration__header" back-url="/dashboard/marketplace/apps">
            <template v-slot:logo>
                <img src="@/assets/integrations/logo-salesforce.png" alt="Salesforce Logo" style="max-width: 150px">
            </template>
            <template v-slot:subheading>
                <p>
                    The Salesforce App helps identify high value customers and provides useful insights that can be used
                    to easily rollout customer loyalty programmes.
                </p>
            </template>
            <template v-slot:cta>
                <div class="buttons">
                    <b-button
                        type="a"
                        href="https://salesforce.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="be-button is-wider is-primary"
                    >
                        Find out more
                    </b-button>
                    <b-button
                        type="a"
                        href="https://salesforce.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="be-button is-wider is-white"
                    >
                        Sign Up
                    </b-button>
                </div>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field horizontal>
                            <template v-slot:label>
                                <span class="is-block has-text-left has-text-weight-bold">
                                    Integration
                                </span>
                            </template>
                            <b-switch v-model="integration.enabled" />
                        </b-field>
                        <div class="content">
                            <p>
                                Enable this integration to send specific detection alerts and metadata to your
                                Salesforce API.
                            </p>
                        </div>
                    </div>
                    <div class="column is-12" v-if="integration.enabled">
                        <div class="content">
                            <p>
                                Step 1. Download RVision certificate and upload it to Salesforce via connected app settings.
                                Please, refer to
                                <a href="https://help.salesforce.com/s/articleView?id=connected_app_create_api_integration.htm&type=5&language=en_US" target="_blank" rel="nofollow noopener">
                                    the Salesforce documentation
                                </a>
                                for more information.
                            </p>
                            <b-button
                                @click="downloadCertificate"
                                class="be-button is-wider is-white"
                            >
                                Download certificate
                            </b-button>
                        </div>

                        <div class="content">
                            <p>
                                Step 2. Once you have configured a connected app in Salesforce, provide the following details:
                            </p>
                            <b-field
                                label="Username"
                                :type="fieldType('username')"
                                :message="fieldMessage('username')"
                            >
                                <b-input v-model="integration.username" />
                            </b-field>
                            <b-field
                                label="Client Id"
                                :type="fieldType('clientId')"
                                :message="fieldMessage('clientId')"
                            >
                                <b-input v-model="integration.clientId" />
                            </b-field>
                        </div>
                    </div>
                    <div class="column is-12">
                        <div class="content">
                            <b-button
                                @click="save"
                                :loading="isSaving"
                                class="be-button is-wider is-primary"
                            >
                                Save
                            </b-button>
                        </div>
                    </div>
                    <div class="column is-12" v-if="integration.enabled">
                        <div class="content">
                            <p>
                                Step 3. Test your connection
                            </p>
                            <b-button
                                @click="sendTestRequest"
                                :loading="isTestingConnection"
                                class="be-button is-wider is-primary"
                            >
                                Test connection
                            </b-button>
                        </div>
                    </div>
                    <div class="column is-12" v-if="integration.enabled">
                        <div class="card is-rounded integration__info mt-5">
                            <div class="card-content">
                                <div class="content">
                                    <h4>Using this integration</h4>
                                    <p>
                                        To use this integration, create a Webhook of type Salesforce and choose it as an action in a Vehicle detection alert
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import noticesMixin from '@/mixins/noticesMixin';
import marketplaceAppMixin from '@/mixins/marketplaceAppMixin';
import integrationOAuthMixin from '@/mixins/integrationOAuthMixin';

const appId = 'salesforce';

export default {
    mixins: [
        validationMixin,
        noticesMixin,
        marketplaceAppMixin,
        integrationOAuthMixin(appId),
    ],
    methods: {
        ...mapActions('marketplace/user', ['loadAppSettings']),
    },
    mounted() {
        this.loadAppSettings({ appId });
    },
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
