<template>
    <div class="observations-explorer">
        <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
        <template v-if="hasFiltersApplied">
            <h-s-observations-date-times />
            <h-s-observations-list class="mt-5" />
        </template>
        <template v-else>
            <div class="observations-explorer__empty content has-text-centered">
                <p>Apply filters to explore the results</p>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HSObservationsDateTimes from '@/components/local/hs/observations/HSObservationsDateTimes';
import HSObservationsList from '@/components/local/hs/observations/HSObservationsList';

export default {
    components: {
        HSObservationsDateTimes,
        HSObservationsList,
    },

    computed: {
        ...mapGetters('hs/observations/explorer', ['isLoading', 'hasFiltersApplied']),
    },
};
</script>

<style lang="scss" scoped>
.observations-explorer {
    position: relative;

    &__empty {
        opacity: .6;
    }
}
</style>
