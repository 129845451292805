<template>
    <div class="home">
        <div class="home__inner">
            <div class="home-container">
                <div class="home__logo">
                    <router-link to="/" class="home__logo__link" @click.native="showMobileNav = false">
                        <be-logo class="is-home" />
                    </router-link>
                </div>
                <div class="home__content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BeLogo from '@/components/global/BeLogo';

export default {
    components: {
        BeLogo,
    },
};
</script>

<style lang="scss">
.home {
    line-height: normal;
    font-family: $family-primary;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $grey-dark;
    color: $white;
    overflow-x: hidden;

    &__inner {
        z-index: 200;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        &__link {
            display: block;
        }
    }

    &__content {
        flex-grow: 1;
    }
}
</style>
