export default {
    methods: {
        goto(direction) {
            let newIndex = this.lastSelectedRowIndex || 0;
            if (direction === 'prev') {
                if (newIndex > 0) {
                    newIndex -= 1;
                } else {
                    newIndex = this.formattedApiResults.length - 1;
                }
            } else if (direction === 'next') {
                if (newIndex < this.formattedApiResults.length - 1) {
                    newIndex += 1;
                } else {
                    newIndex = 0;
                }
            }

            this.viewDetails(
                this.formattedApiResults[newIndex],
                newIndex,
            );
        },

        openPopup(row, index, openImmediately = false) {
            this.viewDetails(row, index, openImmediately);
        },
    },
};
