<template>
    <div class="data-map-explorer">
        <div class="data-map-explorer__inner">
            <overview-filters
                ref="filters"
                class="data-map-explorer__filters"
                :filter-confidence="true"
                :all-time-enabled="true"
                :is-loading="isLoading"
            />
            <div class="app-container is-map">
                <be-page-header :app-container="false" :is-abs="true" class="data-map-explorer__header">
                    <template v-slot:default>
                        Map Explorer
                    </template>
                    <template v-slot:subheading>
                        <be-detections-heading>
                            <span v-if="allTime" class="has-text-primary">
                                All Time
                            </span>
                        </be-detections-heading>
                    </template>
                </be-page-header>
                <sightings-map
                    :can-load-data="true"
                />
                <sightings-map-info @export="exportData" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getApiUrl } from '@/utils/api';
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import OverviewFilters from '@/components/global/OverviewFilters';
import SightingsMap from '@/components/widgets/maui63/maps/SightingsMap';
import SightingsMapInfo from '@/components/widgets/maui63/maps/SightingsMapInfo';

export default {
    name: 'DataMapExplorer',

    components: {
        SightingsMapInfo,
        BeDetectionsHeading,
        OverviewFilters,
        SightingsMap,
    },

    mounted() {
        this.populateFilters();
        this.loadDataCount();
    },

    computed: {
        ...mapGetters('maui/detections/map', ['isLoading']),
        ...mapState('dataFilters', ['allTime']),
    },

    methods: {
        ...mapActions('maui/detections', ['loadDataCount']),
        ...mapActions('maui/detections/map', ['exportData']),

        populateFilters() {
            if (!this.$route.query.tag) {
                return Promise.resolve(true);
            }

            const promises = this.$route.query.tag.split(',')
                .map((tagSlug) => this.$http(getApiUrl({ path: `tags/${tagSlug}` }))
                    .then(({ data }) => data));

            return Promise.all(promises).then((tags) => {
                this.$store.commit('dataFilters/setSelectedTags', { tags });
            });
        },
    },

    beforeDestroy() {
        // revert the overview filters to the date time values if all time was selected
        if (this.allTime) {
            this.$refs.filters.selectTimePeriod('7');
        }

        // let's also reset the data in the storage and clean up some memory
        this.$store.commit('maui/detections/map/resetState');
    },
};
</script>

<style lang="scss" scoped>
.data-map-explorer {
    &__inner {
        position: relative;
        overflow: hidden;
        padding-top: $overview-filters-height;
    }

    &__header {
        pointer-events: none;
    }

    &__filters {
        @include z-index(map-list, 2);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
}
</style>
