<template>
    <div class="integration is-hubspot">
        <be-integration-header class="integration__header" back-url="/dashboard/marketplace/apps">
            <template v-slot:heading>
                Stolen Vehicles
            </template>
            <template v-slot:subheading>
                <p>The New Zealand police maintain a current list of all reported stolen vehicles in New Zealand. This
                    list is useful to setup alerts against to know when a vehicle of interest has been seen by one of
                    your image sources.</p>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field horizontal>
                            <template v-slot:label>
                                <span class="is-block has-text-left has-text-weight-bold">
                                    Integration
                                </span>
                            </template>
                            <b-switch v-model="integration.enabled" />
                        </b-field>
                        <div class="content">
                            <p>
                                This feature enables a dynamic alert list of the current New Zealand stolen cars
                                database from the New Zealand Police
                            </p>
                        </div>
                    </div>
                    <div class="column is-12">
                        <b-button
                            @click="save"
                            :loading="isLoading"
                            class="be-button is-wider is-primary"
                        >
                            Save
                        </b-button>
                    </div>
                    <div class="column is-12">
                        <div class="card is-rounded integration__info mt-5">
                            <div class="card-content">
                                <div class="content">
                                    <h4>Using this integration</h4>
                                    <p>To use this list, setup an R/VISION alert and select “All Stolen Vehicles in New
                                        Zealand” as the filter list.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import noticesMixin from '@/mixins/noticesMixin';
import marketplaceAppMixin from '@/mixins/marketplaceAppMixin';
import integrationFakeSaveMixin from '@/mixins/integrationFakeSaveMixin';

export default {

    mixins: [
        noticesMixin,
        marketplaceAppMixin,
        integrationFakeSaveMixin(false),
    ],

    computed: {
        ...mapState('stolenPlates', ['stolenPlates']),
        ...mapGetters('stolenPlates', ['isLoading']),
    },

    data() {
        return {
            integration: {
                enabled: false,
            },
        };
    },

    methods: {
        ...mapActions('stolenPlates', ['update', 'load']),
        ...mapActions('alerts/lists', ['getAllLists']),
        ...mapActions('marketplace/user', ['toggleApp']),

        save() {
            if (this.isLoading) {
                return;
            }

            this.closeNotices();
            this.update({ stolenPlates: this.integration.enabled })
                .then(() => {
                    this.getAllLists({ force: true });
                    this.toggleApp({
                        appId: this.app,
                        force: this.integration.enabled,
                    });
                    this.displaySuccessNotice({
                        message: 'Integration updated successfully',
                    });
                });
        },
    },

    created() {
        this.integration.enabled = this.stolenPlates;
    },
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
