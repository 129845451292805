import { isArray, isEmpty, isString } from 'lodash';
import { convertApiStringArr } from '@/utils/str';
import { transformImageDetectionsObject } from '@/utils/transformers/detections';

export function liveExplorerTransformerRow(entry) {
    // temporary
    let dwellTime;
    if (isString(entry.dwell) && entry.dwell.indexOf('.') > -1) {
        [dwellTime] = entry.dwell.split('.');
    } else {
        dwellTime = entry.dwell;
    }

    const { image } = entry;

    return {
        ...entry,
        dwellTime: Number(dwellTime),
        detections: transformImageDetectionsObject({ image }),
        platesList: convertApiStringArr(entry.plate),
        confidences: convertApiStringArr(entry.confidence),
    };
}

export function liveExplorerTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => liveExplorerTransformerRow(item));
}
