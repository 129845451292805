<template>
    <b-modal
        :active="active"
        has-modal-card
        trap-focus
        scroll="keep"
        :width="600"
        :can-cancel="false"
        :destroy-on-hide="true"
    >
        <div class="modal-card is-narrower">
            <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Update list <span class="has-text-primary">{{ list.description }}</span>
                </p>
                <button type="button" class="modal-card-close" @click.prevent="close()">
                    <b-icon icon="close" />
                </button>
            </header>
            <div class="modal-card-body">
                <b-message v-if="serverError" type="is-danger" @click.native="serverError = ''">
                    <span class="has-text-dark">
                        {{ serverError }}
                    </span>
                </b-message>
                <b-field
                    :type="$v.file.$error ? 'is-danger' : ''"
                    :message="$v.file.$error ? validationMessages.file : ''"
                >
                    <b-tag
                        v-if="file"
                        size="is-large"
                        closable
                        @close="file = null"
                    >
                        {{ file.name }}
                    </b-tag>
                    <b-upload
                        v-else
                        drag-drop
                        expanded
                        v-model="file"
                        :class="$v.file.$error ? 'has-error' : ''"
                    >
                        <section class="section py-5">
                            <div class="content has-text-centered">
                                <p>
                                    <b-icon icon="cloud-upload" size="is-large" />
                                </p>
                                <p>Drop your file here or click to upload</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </div>
            <div class="modal-card-foot">
                <b-button type="is-primary" class="be-button is-wide" @click="upload()">
                    Upload
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import noticesMixin from '@/mixins/noticesMixin';

const fileValidation = (value) => {
    if (!value) {
        return false;
    }
    return true;
};

export default {
    mixins: [
        validationMixin,
        noticesMixin,
    ],

    props: {
        list: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },

    validations: {
        file: {
            fileValidation,
        },
    },

    data() {
        return {
            file: null,
            isLoading: false,
            serverError: '',
            validationMessages: {
                file: 'CSV file is required',
            },
        };
    },

    methods: {
        ...mapActions('alerts/lists', ['updateList']),

        upload() {
            if (this.isLoading) {
                return;
            }

            this.serverError = '';

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            this.updateList({
                file: this.file,
                listId: this.list.id,
            })
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'List updated successfully',
                    });
                    this.close();
                })
                .catch((err) => {
                    this.serverError = err.message;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        close() {
            this.isLoading = false;
            this.serverError = '';
            this.file = null;
            this.$v.$reset();
            this.$emit('close');
        },
    },

    mounted() {
        this.$root.$emit('intercom:hide');
        this.$once('hook:beforeDestroy', () => {
            this.$root.$emit('intercom:show');
        });
    },
};
</script>

<style lang="scss" scoped>
.modal-card {
    position: relative;

    ::v-deep .upload.has-error {
        .upload-draggable {
            border-color: $danger;
        }
    }
}
</style>
