const getDefaultState = () => ({
    sitesIndexView: 'map',
    overviewFiltersVisible: false,
    dataExplorerImagesPreviewEnabled: false,
    dataExplorerFullDatesEnabled: false,
    liveDataExplorerAutoReload: true,
    navOpened: true,
});

const state = getDefaultState();

const getters = {
    isSitesIndexMapView: (state) => state.sitesIndexView === 'map',
    isSitesIndexListView: (state) => state.sitesIndexView === 'list',
};

const mutations = {
    setSitesIndexView(state, view) {
        state.sitesIndexView = view;
    },

    toggleOverviewFilters(state, force = null) {
        state.overviewFiltersVisible = force === null ? !state.overviewFiltersVisible : force;
    },

    toggleDataExplorerImagesPreviewEnabled(state, force = null) {
        state.dataExplorerImagesPreviewEnabled = force === null ? !state.dataExplorerImagesPreviewEnabled : force;
    },

    toggleDataExplorerFullDatesEnabled(state, force = null) {
        state.dataExplorerFullDatesEnabled = force === null ? !state.dataExplorerFullDatesEnabled : force;
    },

    toggleLiveDataExplorerAutoReload(state, force = null) {
        state.liveDataExplorerAutoReload = force === null ? !state.liveDataExplorerAutoReload : force;
    },

    setNavOpened(state, payload) {
        state.navOpened = payload.navOpened;
    },
    toggleNav(state, force = null) {
        state.navOpened = force === null ? !state.navOpened : force;
    },

    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
