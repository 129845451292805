var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-image",class:{ 'is-zoomed': _vm.zoomed }},[_c('b-loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),(_vm.url)?[(_vm.isError)?[_c('div',{staticClass:"media-image__error"},[_c('div',{staticClass:"content"},[_c('p',[_vm._v(_vm._s(_vm.message))])])])]:[_c('div',{staticClass:"media-image__inner",on:{"dblclick":function($event){$event.preventDefault();return _vm.onZoom.apply(null, arguments)}}},[_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],ref:"container",staticClass:"media-image__container",class:{ 'is-zoomed': _vm.zoomed }},[_c('div',{ref:"scene",staticClass:"media-image__scene",class:{ 'is-zoomed': _vm.zoomed },style:(_vm.sceneStyles),on:{"mousemove":_vm.onMouseMove}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTooltipShown),expression:"isTooltipShown"}],staticClass:"tooltip"},[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")]),_c('img',{ref:"image",staticClass:"media-image__image",style:({
                                opacity: _vm.isReady ? 1 : 0,
                            }),attrs:{"src":_vm.url,"alt":"Image"},on:{"load":_vm.onImageLoad,"error":_vm.onImageError}}),(_vm.isHS)?_c('media-image-h-s-canvas',{staticClass:"media-image__canvas",style:({
                                width: ((this.imageSize.width) + "px"),
                                height: ((this.imageSize.height) + "px"),
                            }),attrs:{"is-visible":_vm.isReady,"width":_vm.imageSize.width,"height":_vm.imageSize.height,"detections":_vm.detections,"camera":_vm.camera,"display-roi":_vm.displayRoi,"displayHeatmap":false,"heatmapImage":null,"scale":_vm.scale,"showTooltip":_vm.showTooltip,"imageConfig":_vm.imageConfig}}):_c('media-image-canvas',{staticClass:"media-image__canvas",style:({
                                width: ((this.imageSize.width) + "px"),
                                height: ((this.imageSize.height) + "px"),
                            }),attrs:{"is-visible":_vm.isReady,"width":_vm.imageSize.width,"height":_vm.imageSize.height,"detections":_vm.detections,"image-type":_vm.imageType,"row-type":_vm.rowType,"scale":_vm.scale}})],1)]),_c('div',{staticClass:"controls"},[_c('b-button',{staticClass:"controls__control is-light",attrs:{"icon-right":_vm.zoomed ? 'minus' : 'plus'},on:{"click":function($event){return _vm.toggleZoom()}}})],1)])]]:[_c('div',{staticClass:"media-image__error"},[_c('div',{staticClass:"content"},[_c('p',[_vm._v(_vm._s(_vm.message))])])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }