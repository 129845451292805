import exportHelpers from '@/utils/export-helpers';
import { PAGINATION_PER_PAGE } from '../../utils/constants';

const getDefaultState = () => ({
    isLoading: false,
    data: {},
    title: 'RVision',
    header: 'CONFIDENCE,RVISION_URL,SITE_ID,CAMERA_ID,IMAGE_ID,JSON_DATA\n',
    currentView: null,
    perPage: PAGINATION_PER_PAGE[0],
    prefix: '',
    totalPages: 1,
    totalItemCount: 0,
});

const state = getDefaultState();

const getters = {
    getLoadingStatus: (state) => state.isLoading,
    getData: (state) => state.data[state.currentView],
    getHeader: (state) => state.header,
    getTitle: (state) => state.title,
    getCurrentView: (state) => state.currentView,
    getCurrentPage: (state) => state.currentPage,
    getPerPage: (state) => state.perPage,
    getTotalPages: (state) => state.totalPages,
    getTotalItemCount: (state) => state.totalItemCount,
};

const mutations = {
    setLoadingStatus(state, { status }) {
        state.isLoading = status;
    },
    setData(state, { data }) {
        // to solve vue is not detecting changes in getters
        const temp = { ...state.data };
        temp[state.currentView] = data;
        state.data = { ...temp };
    },
    setHeader(state, { data }) {
        state.header = data;
    },
    setTitle(state, { data }) {
        state.title = data;
    },
    setCurrentView(state, { data }) {
        state.currentView = data;
    },
    setPerPage(state, { data }) {
        state.perPage = data;
    },
    setTotalPages(state, { data }) {
        state.totalPages = data;
    },
    setTotalItemCount(state, { data }) {
        state.totalItemCount = data;
    },
};

const actions = {
    setCSVData({ commit, rootState }, { data, prefix }) {
        let formattedData;
        let header = '';
        let title = '';

        switch (prefix) {
            case 'hs':
                header = 'CONFIDENCE,RVISION_URL,SITE_ID,CAMERA_ID,IMAGE_ID,JSON_DATA\n';
                title = 'Health & Safety Detections';
                formattedData = exportHelpers.formatHSData(data, { rootState });
                break;
            case 'hs-image':
                header = 'CONFIDENCE,RVISION_URL,SITE_ID,CAMERA_ID,IMAGE_ID,JSON_DATA\n';
                title = 'Health & Safety Detections';
                formattedData = exportHelpers.formatHSImageData(data, { rootState });
                break;
            case 'vehicle':
                header = 'TYPE,PLATE,CONFIDENCE,RVISION_URL,SITE_ID,CAMERA_ID,IMAGE_ID,DATE,TIME,SITE_NAME,CAMERA_NAME,DIRECTION\n';
                title = 'Vehicle Detections';
                formattedData = exportHelpers.formatVehicleData(data, { rootState });
                break;
            default:
                formattedData = data;
                break;
        }

        commit('setData', { data: formattedData });
        commit('setHeader', { data: header });
        commit('setTitle', { data: title });
    },
    setCurrentView({ commit }, { data }) {
        commit('setCurrentView', { data });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
