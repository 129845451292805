<template>
    <be-widget class="detailed-usage" :has-table="true" :is-loading="isLoading || isLoadingFull">
        <template v-slot:heading>
            Detailed list view
        </template>
        <template v-slot:headingAfter>
            <images-detections-toggler v-model="currentView" :values="togglerValues" />
        </template>
        <template v-slot:content>
            <detections-table
                v-if="isDetectionsView"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
            />
            <unique-detections-table
                v-else-if="isUniqueDetectionsView"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
            />
            <all-images-table
                v-else-if="isImagesView"
                :can-load-data="canLoadData"
                @loading="isLoading = true"
                @ready="isLoading = false"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ImagesDetectionsToggler from '../filters/ImagesDetectionsToggler';
import UniqueDetectionsTable from './UniqueDetectionsTable';
import DetectionsTable from './DetectionsTable';
import AllImagesTable from './AllImagesTable';

export default {
    name: 'DataExplorerTableWidget',

    props: {
        canLoadData: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        DetectionsTable,
        ImagesDetectionsToggler,
        UniqueDetectionsTable,
        AllImagesTable,
    },

    data() {
        return {
            isLoading: false,
            currentView: 'detections',
            togglerValues: [
                {
                    handle: 'detections',
                    label: 'All plate detections',
                },
                {
                    handle: 'unique',
                    label: 'Unique plate detections',
                },
                {
                    handle: 'images',
                    label: 'All images',
                },
            ],
        };
    },

    watch: {
        currentView: {
            handler(val) {
                this.setCurrentView({ data: `vehicle-${val}` });
                this.$emit('view-changed', val);
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            isLoadingFull: 'media/images/isLoading',
        }),
        isDetectionsView() {
            return this.currentView === 'detections';
        },
        isUniqueDetectionsView() {
            return this.currentView === 'unique';
        },
        isImagesView() {
            return this.currentView === 'images';
        },
    },

    methods: {
        ...mapActions({
            setCurrentView: 'dataExport/setCurrentView',
        }),
    },
};
</script>
