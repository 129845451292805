import axios from 'axios';
import { buildUrlParams, createDataHash, getStatusGetters } from '@/utils/store-helpers';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { errorHandler } from '@/utils/errors';
import { getApiUrl } from '@/utils/api';

const getDefaultState = () => ({
    status: '',
    data: {
        total_today: null,
        current_occupancy: null,
        current_occupancy_overdue: null,
    },
    dataHash: null,
});

const state = getDefaultState();

const getters = {
    ...getStatusGetters(),
    summaryData: (state) => state.data,
};

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setData(state, { data }) {
        state.data = data;
    },
    setDataHash(state, { dataHash }) {
        state.dataHash = dataHash;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
};

const actions = {
    loadSummaryData({ commit, state, rootGetters }, { force = false }) {
        const filterParams = rootGetters['liveExplorer/filterParams'];

        const params = buildUrlParams({
            additional: filterParams.additional,
        });
        const path = 'explorer/live/overview';

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash && !force) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: STATUS_LOADING });
        return axios({ url: getApiUrl({ path }), params })
            .then((response) => {
                commit('setData', { data: response.data });
                commit('setStatus', { status: STATUS_SUCCESS });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
            })
            .finally(() => {
                commit('setStatus', { status: STATUS_SUCCESS });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
