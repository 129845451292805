import Vue from 'vue';
import dayjs from 'dayjs';
import axios from 'axios';
import { find, get, truncate } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { CAMERA_APP_TYPES, SITE_TYPES } from '@/utils/sites/constants';
import { HS_CAMERA_VIOLATIONS_TYPES } from '@/utils/sites/cameras';

export const getDefaultObservationFilters = () => ({
    risk: null,
    site: null,
    camera: null,
    selectedSites: [],
    selectedCameras: [],
    from: dayjs().subtract(7, 'day').toDate(),
    to: dayjs().toDate(),
});

const getDefaultState = () => ({
    currentFilters: getDefaultObservationFilters(),
    riskTypes: HS_CAMERA_VIOLATIONS_TYPES,
    sites: [],
    cameras: [],
});

const state = getDefaultState();
const getters = {
    currentSite(state) {
        if (!state.currentFilters.site) {
            return null;
        }
        return find(state.sites, { id: state.currentFilters.site });
    },
    currentSiteCameras(getters) {
        if (!getters.currentSite) {
            return [];
        }
        return getters.currentSite.cameras;
    },
    selectedSites(state) {
        if (!state.currentFilters.selectedSites) {
            return [];
        }
        return state.currentFilters.selectedSites;
    },
    selectedSitesCameras(state) {
        if (!state.currentFilters.selectedSites) {
            return [];
        }
        return state.currentFilters.selectedSites
            .flatMap((sites) => sites.cameras);
    },
    getDateRange(state) {
        return {
            from: state.currentFilters.from ? dayjs(state.currentFilters.from) : null,
            to: state.currentFilters.to ? dayjs(state.currentFilters.to) : null,
        };
    },
};
const mutations = {
    setCurrentFilters(state, filters = {}) {
        state.currentFilters = {
            ...state.currentFilters,
            ...filters,
        };
    },
    setCurrentFilter(state, { filter, value }) {
        Vue.set(state.currentFilters, filter, value);
    },
    resetFilters() {
        state.currentFilters = getDefaultObservationFilters();
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setSites(state, { sites }) {
        state.sites = sites;
    },
    setSelectedSites(state, { selectedSites }) {
        state.selectedSites = selectedSites;
    },
    setSelectedCameras(state, { selectedCameras }) {
        state.selectedCameras = selectedCameras;
    },
};
const actions = {
    init({ dispatch }) {
        return dispatch('getSites');
    },
    getSites({ state, commit }) {
        if (state.sites.length) {
            return Promise.resolve(true);
        }
        return axios({ url: getApiUrl({ path: 'sites/summary' }) })
            .then((response) => {
                const results = get(response, 'data') || [];
                const sites = results
                    .filter((result) => result.site_type === SITE_TYPES.HS && result.cameras.length)
                    .map((result) => ({
                        name: result.name,
                        shortName: truncate(result.name, { length: 20 }),
                        id: result.id,
                        cameras: result.cameras
                            .filter((camera) => camera.app_type === CAMERA_APP_TYPES.HS)
                            .map((camera) => ({
                                ...camera,
                                shortName: truncate(camera.name, { length: 20 }),
                            })),
                    }));

                commit('setSites', { sites });
            })
            .catch((err) => {
                errorHandler(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
