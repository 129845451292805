<template>
    <transition
        :enter-active-class="transition.enter"
        :leave-active-class="transition.leave">
        <div
            v-show="isActive"
            class="be-notice"
            :class="[type, position]"
            role="alertdialog">
            <template v-if="icon">
                <div class="be-notice__icon">
                    <b-icon :icon="icon" />
                </div>
            </template>
            <div class="be-notice__text" v-html="message" />
            <div
                class="be-notice__action"
            >
                <button
                    type="button"
                    class="button"
                    :class="[type]"
                    @click="action"
                >
                    <b-icon icon="close-thick" size="is-small" />
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import NoticeMixin from 'buefy/src/utils/NoticeMixin';

export default {
    name: 'BeNotice',
    mixins: [NoticeMixin],
    props: {
        icon: {
            type: String,
            default: null,
        },
        onAction: {
            type: Function,
            default: () => {
            },
        },
        indefinite: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newDuration: this.duration || 2000,
        };
    },
    methods: {
        /**
         * Click listener.
         * Call action prop before closing (from Mixin).
         */
        action() {
            this.onAction();
            this.close();
        },
    },
};
</script>
