<template>
    <div class="be-integration-header">
        <div class="be-integration-header__inner app-container">
            <div class="be-integration-header__main">
                <template v-if="backUrl">
                    <span class="be-integration-header__back">
                        <b-button
                            tag="router-link"
                            type="is-light"
                            :to="backUrl"
                            class="be-button is-back"
                            icon-left="chevron-left"
                        />
                    </span>
                </template>
                <template v-if="!!$slots.back">
                    <span class="be-integration-header__back">
                        <slot name="back" />
                    </span>
                </template>
                <div class="be-integration-header__content">
                    <template v-if="!!$slots.logo">
                        <div class="be-integration-header__logo">
                            <slot name="logo" />
                        </div>
                    </template>
                    <template v-if="!!$slots.heading">
                        <component
                            :is="headingTag"
                            class="be-integration-header__heading"
                            :class="`is-${headingTag}`"
                        >
                            <slot name="heading" />
                        </component>
                    </template>
                </div>
            </div>
            <div class="be-integration-header__side">
                <template v-if="!!$slots.cta">
                    <slot name="cta" />
                </template>
            </div>
            <template v-if="!!$slots.subheading">
                <div class="be-integration-header__sub">
                    <p class="be-integration-header__subheading">
                        <slot name="subheading" />
                    </p>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
export default {
    name: 'BePageHeader',

    props: {
        isAbs: {
            type: Boolean,
            default: false,
        },
        headingTag: {
            type: String,
            default: 'h2',
        },
        backUrl: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.be-integration-header {
    background: $black-ter;
    margin-bottom: 2rem;

    &__inner {
        @include tablet {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__main {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__back {
        margin-right: 1rem;
    }

    &__sub {
        flex: 0 0 100%;
    }

    &__side {
        @include mobile {
            margin-top: 1em;
        }
        @include tablet {
            flex: 0 0 200px;
            text-align: right;
            margin-left: 20px;
            flex: 0 0 250px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__logo {
        width: 250px;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__heading {
        @include page-heading;
        margin-bottom: .5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__subheading {
        margin-top: 2em;
        max-width: 650px;
    }
}
</style>
