<template>
    <div class="be-pagination">
        <div class="be-pagination__inner">
            <div class="be-pagination__left">
                <div class="field is-grouped">
                    <b-field label="Per page">
                        <b-select v-model="computedPerPage">
                            <option
                                v-for="page in perPageValues"
                                :key="`option${page}`"
                                :value="page"
                            >
                                {{ page }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field :label="pageLabel">
                        <b-select
                            v-if="perPageSelect"
                            :key="`select${currentPage}/${perPage}`"
                            v-model.lazy.number="computedCurrentPage"
                        >
                            <option
                                v-for="countItem in totalArr"
                                :key="`option${countItem}`"
                                :value="countItem + 1"
                            >
                                {{ countItem + 1 }}
                            </option>
                        </b-select>
                        <b-input
                            v-else
                            :key="`input${currentPage}/${perPage}`"
                            v-model.lazy.number="computedCurrentPage"
                            type="number"
                            min="1"
                        />
                    </b-field>
                </div>
            </div>
            <div class="be-pagination__right">
                <div class="buttons">
                    <b-button
                        v-if="hasPrevPage"
                        icon-left="chevron-left"
                        @click="computedCurrentPage -= 1"
                    >
                        Prev
                    </b-button>
                    <b-button
                        v-if="hasNextPage"
                        icon-right="chevron-right"
                        @click="computedCurrentPage += 1"
                    >
                        Next
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DEFAULT_PAGINATION_PER_PAGE, PAGINATION_PER_PAGE } from '@/utils/constants';

export default {
    props: {
        perPage: {
            type: Number,
            default: DEFAULT_PAGINATION_PER_PAGE,
        },
        perPageSelect: {
            type: Boolean,
            default: true,
        },
        perPageValues: {
            type: Array,
            default: () => [...PAGINATION_PER_PAGE],
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        count: {
            type: Number,
            default: 0,
        },
        nextPage: {
            type: Number,
            default: null,
        },
        prevPage: {
            type: Number,
            default: null,
        },
    },

    watch: {
        currentPage(value) {
            this.newCurrentPage = value;
        },
        perPage(value) {
            this.newPerPage = value;
        },
    },

    data() {
        return {
            newCurrentPage: this.currentPage,
            newPerPage: this.perPage,
        };
    },

    computed: {
        totalArr() {
            return Array.from(Array(this.totalPages).keys());
        },

        pageLabel() {
            let label = 'Page';

            if (this.computedCurrentPage && this.totalPages) {
                label += ` (${this.computedCurrentPage} of ${this.totalPages})`;
            }

            return label;
        },

        totalPages() {
            if (this.count) {
                const totalPages = Math.ceil(this.count / this.perPage);
                this.$store.commit('dataExport/setTotalPages', { data: totalPages });
                return totalPages;
            }

            return null;
        },

        hasPrevPage() {
            return this.currentPage > 1;
        },

        hasNextPage() {
            if (this.totalPages) {
                return this.currentPage < this.totalPages;
            }

            if (this.nextPage) {
                return true;
            }

            return false;
        },

        computedPerPage: {
            get() {
                return this.newPerPage;
            },
            set(value) {
                this.newPerPage = value;
                this.computedCurrentPage = 1;
                this.$emit('update:perPage', value);
            },
        },

        computedCurrentPage: {
            get() {
                return this.newCurrentPage;
            },
            set(value) {
                this.newCurrentPage = value;
                this.$emit('update:currentPage', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.be-pagination {
    margin-top: 20px;

    &__inner {
        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__left {
        .label {
            font-size: 14px;
        }

        .field {
            margin-bottom: 0;
        }
    }
}
</style>
