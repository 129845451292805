<template>
    <be-widget class="chart" :is-loading="isLoading">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:headingAfter>
            <images-detections-toggler v-model="currentView" />
        </template>
        <template v-slot:content>
            <div class="chart">
                <div v-if="!isImagesView" class="chart__controls">
                    <b-checkbox
                        class="be-checkbox"
                        v-model="isShowForecast"
                    >
                        Show forecast
                    </b-checkbox>
                </div>
                <bar-chart
                    class="chart__wrapper"
                    :chart-data="chartData"
                    :options="options"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BarChart from '@/utils/chartjs/BarChart';
import ImagesDetectionsToggler from '@/components/widgets/filters/ImagesDetectionsToggler';
import { DATE_FORMAT } from '@/utils/formatters/datetime';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import { COLORS } from '@/utils/constants';
import { FORECAST_DAYS } from '@/store/explorer/detections/unique/dailysummary';

const getChartDataDefaults = () => ({
    labels: [],
    datasets: [
        {
            label: '',
            backgroundColor: COLORS.purple,
            data: [],
        },
    ],
});

export default {
    components: {
        ImagesDetectionsToggler,
        BarChart,
    },

    mixins: [widgetDataMixin],

    data() {
        return {
            currentView: 'images',
            chartData: getChartDataDefaults(),
            isShowForecast: true,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                },
            },
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('explorer/detections/unique/dailysummary', [
            'isLoading',
            'formattedApiResults',
            'formattedForecastsUniquePlates',
        ]),

        widgetTitle() {
            return this.currentView === 'images'
                ? 'Images received over time'
                : 'Unique events over time';
        },

        isImagesView() {
            return this.currentView === 'images';
        },

        displayDates() {
            const startDate = this.$date(this.getDateRange.start);
            let endDate = this.$date(this.getDateRange.end);

            if (!this.isImagesView && this.isShowForecast) {
                endDate = endDate.add(FORECAST_DAYS, 'day');
            }

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 0; i < diff; i++) {
                dateArray.push(startDate.add(i + 1, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },
    },

    watch: {
        currentView: 'updateChartData',

        isShowForecast: 'updateChartData',

        formattedApiResults: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },

    methods: {
        ...mapActions('explorer/detections/unique/dailysummary', ['loadData']),

        canPopulateChart() {
            return this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        getLabelsArray() {
            const startDate = this.$date(this.getDateRange.start);
            const endDate = this.$date(this.getDateRange.end);

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 1; i <= diff; i++) {
                dateArray.push(startDate.add(i, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },

        getApiResultsData({ field = 'images' }) {
            if (!this.formattedApiResults) {
                return [];
            }

            const data = this.displayDates.map((date) => {
                if (this.formattedApiResults[date]) {
                    return this.formattedApiResults[date][field];
                }
                if (!this.isImagesView && this.isShowForecast && this.formattedForecastsUniquePlates[date]) {
                    return this.formattedForecastsUniquePlates[date];
                }
                return null;
            });

            return data;
        },

        getApiResultsBg() {
            if (!this.formattedApiResults) {
                return [];
            }

            return this.displayDates.map((date) => {
                if (this.formattedApiResults[date]) {
                    return this.isImagesView ? '#AE83FB' : '#FFFA9C';
                }
                if (this.formattedForecastsUniquePlates[date]) {
                    return COLORS.transparentYellow;
                }
                return '#ffffff';
            });
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            const label = this.isImagesView ? 'Images' : 'Detections';
            const backgroundColor = this.getApiResultsBg();
            const field = this.isImagesView ? 'images' : 'unique_plates';
            const data = this.getApiResultsData({ field });

            const datasets = [{
                label,
                backgroundColor,
                data,
            }];

            this.chartData = {
                labels: this.displayDates,
                datasets,
            };
        },

        resetChartData() {
            this.chartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
        margin-top: auto;
    }
}
</style>
