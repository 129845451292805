import layout from './layout';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    resetState({ dispatch }) {
        return dispatch('layout/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        layout,
    },
};
