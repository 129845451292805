import Vue from 'vue';
import Vuex from 'vuex';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './plugins/sentry';
import './plugins/global';
import './plugins/analytics';
import './plugins/http';
import './plugins/defaults';
import './plugins/http-mock';
import './plugins/intercom';
import './plugins/bulma';
import './plugins/dayjs';
import './plugins/vuescroll';
import './plugins/be-notice';
import './plugins/event-bus';
import './plugins/vue-element-query';
import './plugins/filters';
import './plugins/konva';
import './plugins/numeric';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueCompositionAPI);

new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted() {
        // this.$intercom.boot({
        //     user_id: this.userId,
        //     name: this.name,
        //     email: this.email,
        // });

        window.Dummi.identify({
            user: {
                title: this.name,
                email: this.email,
                externalId: this.userId,
            },
        });

        // this.$on('intercom:show', () => {
        //     const el = document.querySelector('.intercom-lightweight-app');
        //     if (el) {
        //         el.style.position = 'static';
        //         el.style['z-index'] = null;
        //     }
        // });
        // this.$on('intercom:hide', () => {
        //     const el = document.querySelector('.intercom-lightweight-app');
        //     if (el) {
        //         el.style['z-index'] = -1;
        //         el.style.position = 'relative';
        //     }
        // });
    },
    watch: {
        $route() {
            // this.$intercom.update();
            // window.Intercom('update');

        },
    },
})
    .$mount('#app');
