<template>
    <div class="live-dashboard-camera">
        <div class="camera-item">
            <b-loading v-if="hasImage" :active="!isLoaded" :is-full-page="false" :can-cancel="false" />
            <div class="camera-image-container">
                <div class="camera-image" :style="cameraStyle">
                    <img
                        v-if="hasImage"
                        :src="imageObject.url"
                        alt="Live Dashboard Camera Image"
                        @load="isLoaded = true"
                        v-show="isLoaded"
                    />
                    <h3 class="camera-no-img" v-else>NO IMAGES</h3>
                    <div
                        class="camera-overlay"
                        v-show="isLoaded || !hasImage"
                        :style="{ backgroundColor: overlayColor, opacity: 0.5 }"
                    />
                </div>
            </div>

            <h1 class="camera-name"> {{ camera.siteName.replace('SITE:', '') }} : {{ camera.name.replace('CAM:', '') }}</h1>
            <p v-if="hasImage" class="time-since-last-image" :style="{ color: timeSinceLastImageColor }">
                {{ this.formattedTimeSinceLastImage }}
            </p>
        </div>
    </div>
</template>
<script>
import { COLORS } from '@/utils/constants';

export default {
    name: 'LiveDashboardCamera',
    props: {
        camera: {
            type: Object,
            default: null,
        },

        cameraStyle: {
            type: Object,
            default: () => ({}),
        },

        imageObject: {
            type: Object,
            default: null,
        },

        timeSinceLastImage: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            isLoaded: true,
        };
    },

    computed: {
        hasImage() {
            return this.imageObject && this.imageObject.url;
        },

        overlayColor() {
            if (this.timeSinceLastImage === null || !this.hasImage) {
                return COLORS.greyDark;
            }
            if (this.timeSinceLastImage <= 10) {
                return COLORS.green;
            } if (this.timeSinceLastImage > 10 && this.timeSinceLastImage <= 60) {
                return COLORS.yellow;
            }
            return COLORS.red;
        },

        timeSinceLastImageColor() {
            return COLORS.greyLight;
        },

        formattedTimeSinceLastImage() {
            const minutes = this.timeSinceLastImage;
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            let ds = 's';
            let ms = 's';
            let hs = 's';

            if (days === 1) {
                ds = '';
            }
            if (hours === 1) {
                hs = '';
            }
            if (minutes === 1) {
                ms = '';
            }

            if (days === 0) {
                if (hours === 0) {
                    return `${minutes} minute${ms} ago`;
                }
                return `${hours} hour${hs} ${minutes % 60} minute${ms} ago`;
            }
            return `${days} day${ds} ${hours % 24} hour${hs} ago`;
        },
    },
};
</script>
<style scoped lang="scss">
.live-dashboard-camera {

    .camera-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .camera-item {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;

        .camera-image {
            position: relative;
            width: 100%;
            height: auto;
            min-width: inherit;
            max-width: inherit;
            min-height: inherit;
            max-height: inherit;

            img {
                min-width: inherit;
                max-width: inherit;
                min-height: inherit;
                max-height: inherit;
            }

            .camera-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            .camera-no-img {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                z-index: 2;
                font-size: 1vw;
                min-width: inherit;
                max-width: inherit;
                min-height: inherit;
                max-height: inherit;
            }
        }

        .camera-name,
        .time-since-last-image {
            position: relative;
            font-size: 0.7vw;
            width: 100%;
            z-index: 3;
        }

        .camera-name {
            margin-top: 5px;
        }
    }
}
</style>
