import Vue from 'vue';
import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { cancelSource } from '@/utils/api-tokens';

const getDefaultDataState = () => ({
    data: {},
    status: '',
});

const state = getDefaultDataState({ hasPagination: true });

const mutations = {
    addVideo(state, { id, video }) {
        Vue.set(state.data, id, video);
    },
    setData(state, { data }) {
        state.data = data;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultDataState());
    },
};

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
};

const actions = {
    loadDataRow({ state, commit, rootGetters }, { id, isPreload = false }) {
        if (state.data[id]) {
            return Promise.resolve(state.data[id]);
        }

        const path = `alpr/videos/${id}`;

        if (!isPreload) {
            commit('setStatus', { status: STATUS_LOADING });
        }

        return axios({
            url: getApiUrl({ path }),
            cancelToken: cancelSource.token,
        })
            .then((response) => {
                if (!isPreload) {
                    commit('setStatus', { status: STATUS_SUCCESS });
                }
                commit('addVideo', { id, video: response.data });
                return response.data;
            })
            .catch((err) => {
                if (axios.isCancel(err) || !rootGetters['auth/isLoggedIn']) {
                    return;
                }
                commit('addVideo', { id, video: { error: true } });
                if (!isPreload) {
                    commit('setStatus', { status: STATUS_ERROR });
                }
                errorHandler(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
