<template>
    <manage-popup :active="active" @close="maybeClose" class="roi-popup">
        <template v-slot:title>
            Edit Camera ROI
            <button type="button" class="roi-popup__help-icon" @click.prevent="showHelp()">
                <b-icon icon="help-circle-outline" custom-size="mdi-18px" />
            </button>
        </template>
        <template v-slot:body>
            <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
            <be-request-error-message v-if="requestErrorMessage" @close="requestErrorMessage = null">
                {{ requestErrorMessage }}
            </be-request-error-message>
            <div class="roi-popup__tabs" role="tablist">
                <template v-if="isLoaded">
                    <nav class="tabs">
                        <ul>
                            <li role="presentation" :class="{ 'is-active': activeTab === 0 }">
                                <a href="#" role="tab" tabindex="-1" @click.prevent="changeTab(0)">
                                    <span>Image</span>
                                </a>
                            </li>
                            <li role="presentation" :class="{ 'is-active': activeTab === 1 }">
                                <a href="#" role="tab" tabindex="-1" @click.prevent="changeTab(1)">
                                    <span>Manual</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div v-if="activeTab === 0" class="tab-content roi-popup__tab">
                        <roi-camera-image
                            v-if="hasImage"
                            ref="image"
                            :camera="camera"
                            :image="imageObject"
                            :rois="rois"
                        />
                        <template v-else>
                            <div class="roi-popup__message">
                                <div class="content">
                                    Preview is not available because camera has not received any images yet.
                                    Please contact the RVision team for further details.
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-if="activeTab === 1" class="tab-content roi-popup__tab">
                        <roi-camera-manual
                            ref="manual"
                            :camera="camera"
                            :rois="rois"
                        />
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:footer>
            <div class="buttons">
                <b-button class="be-button is-outline" @click="close">Cancel</b-button>
                <b-button
                    class="be-button is-wider is-primary"
                    @click.prevent="save"
                    :loading="isLoading"
                >
                    Save Camera ROI
                </b-button>
            </div>
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import axios from 'axios';
import noticesMixin from '@/mixins/noticesMixin';
import manageCameraMixin from '@/mixins/manageCameraMixin';
import RoiCameraManual from '@/components/local/sites/partials/RoiCameraManual';
import RoiCameraImage from '@/components/local/sites/partials/RoiCameraImage';
import { getApiUrl } from '@/utils/api';

export default {
    components: { RoiCameraImage, RoiCameraManual },
    mixins: [
        manageCameraMixin,
        noticesMixin,
    ],

    mounted() {
        this.isLoading = true;
        this.loadData()
            .then(() => {
                this.isLoading = false;
            });
    },

    data() {
        return {
            requestErrorMessage: null,
            rois: [],
            activeTab: 0,
            isLoading: false,
            isLoaded: false,
            imageObject: null,
            displayType: 'manual',
        };
    },

    computed: {
        hasImage() {
            return this.imageObject !== null;
        },

        activeRef() {
            return this.$refs[this.activeTab ? 'manual' : 'image'];
        },
    },

    methods: {
        ...mapActions('media/images', ['loadDataRow']),

        changeTab(index) {
            if (this.activeTab === index) {
                return;
            }

            if (this.activeRef && this.activeRef.isDirty()) {
                this.$buefy.dialog.confirm({
                    message: 'Current tab unsaved data will be lost. ',
                    type: 'is-danger',
                    onConfirm: () => {
                        this.activeTab = index;
                    },
                });
                return;
            }
            this.activeTab = index;
        },

        loadImage() {
            this.imageObject = null;
            if (!this.camera.last_image_id) {
                return Promise.resolve(false);
            }
            return this.loadDataRow({
                id: this.camera.last_image_id,
            }).then((data) => {
                if (!isEmpty(data.detected_objects) && data.image_file) {
                    this.imageObject = {
                        ...data.detected_objects,
                        url: data.image_file,
                        id: data.id,
                    };
                }
            });
        },

        loadRois() {
            return axios({
                method: 'GET',
                url: getApiUrl({ path: `cameras/${this.camera.id}/rois` }),
            })
                .then((result) => {
                    this.rois = result.data;
                });
        },

        save() {
            if (this.isLoading || !this.activeRef) {
                return;
            }
            // invalid data
            if (!this.activeRef.validate()) {
                return;
            }

            this.requestErrorMessage = null;
            this.isLoading = true;
            Promise.all(
                this.activeRef.getSaveData()
                    .map((roi) => this.updateRoi(roi)),
            )
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Camera ROIs updated successfully',
                    });
                    this.close();
                    // return this.loadData();
                })
                .catch((error) => {
                    this.requestErrorMessage = error.message;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        loadData() {
            this.isLoaded = false;
            return Promise.all([
                this.loadRois(),
                this.loadImage(),
            ]).then(() => {
                this.isLoaded = true;
            });
        },

        updateRoi(roi) {
            const { deleted, id, data } = roi;
            let url;
            let method;
            const path = `cameras/${this.camera.id}/rois`;

            if (deleted) {
                url = getApiUrl({ path: `${path}/${id}` });
                method = 'DELETE';
            } else if (id) {
                url = getApiUrl({ path: `${path}/${id}` });
                method = 'PUT';
            } else {
                url = getApiUrl({ path });
                method = 'POST';
            }

            return axios({ method, url, data });
        },

        showHelp() {
            const title = 'Edit Camera ROI Help';
            const message = `
                <div class="content">
                <ul>
                <li>Move the corners of the polygon to form the desired region of interest.</li>
                <li>Double click along an edge to add an additional node.</li>
                <li>Double click on a node to delete it</li>
                <li>Self intersecting polygons are not allowed as a region of interest.
                Make sure none of the nodes intersect an edge.</li>
                <li>Right click anywhere to deactivate the current polygon before drawing a new one.
                 To activate a polygon, click on any edge of the polygon.</li>
                <li>To delete a polygon, make sure it's active (green) and press delete/backspace.</li>
                </ul>
                </div>
            `;

            this.$buefy.dialog.alert({
                title,
                message,
                type: 'is-info',
                ariaRole: 'alertdialog',
                ariaModal: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.roi-popup {
    ::v-deep .modal-card-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__help-icon {
        @include button-reset;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        margin-left: 5px;
        color: $primary;
        @include transition-button;

        &:hover {
            opacity: .7;
        }
    }

    &__tabs {
        min-height: 300px;
    }

    &__tab {
        padding-left: 0;
        padding-right: 0;
    }

    &__message {
        height: 235px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $grey-light;
        font-size: .9em;
        cursor: default;
    }

}
</style>
