<template>
    <div class="columns-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <b-field
                    label="Name"
                    :type="getFieldType('name')"
                    :message="getFieldMessage('name')"
                >
                    <b-input v-model.trim="siteModel.name" />
                </b-field>
            </div>
            <div class="column is-6">
                <b-field
                    label="Enter address"
                    :type="getFieldType('address')"
                    :message="getFieldMessage('address')"
                >
                    <be-address-autocomplete
                        v-model.trim="siteModel.address"
                        :field-props="{
                            type: getFieldType('address'),
                            class: 'mb-0',
                        }"
                        @selected="addressSelected"
                    />
                </b-field>
            </div>
            <div class="column is-3">
                <b-field
                    label="Latitude"
                    :type="getFieldType('lat')"
                    :message="getFieldMessage('lat')"
                >
                    <b-input v-model.trim="siteModel.lat" />
                </b-field>
            </div>
            <div class="column is-3">
                <b-field
                    label="Longitude"
                    :type="getFieldType('lng')"
                    :message="getFieldMessage('lng')"
                >
                    <b-input v-model.trim="siteModel.lng" />
                </b-field>
            </div>
            <div class="column is-12">
                <div class="field">
                    <div class="label">Tags</div>
                    <be-tags
                        prefix="SITE"
                        dropdown-position="top"
                        v-model="siteTags"
                        :is-loading="isTagsLoading"
                    />
                </div>
            </div>
            <div class="column is-12">
                <b-field
                    label="Number of bays"
                    :type="getFieldType('number_of_bays')"
                    :message="getFieldMessage('number_of_bays')"
                >
                    <b-input v-model.trim="siteModel.number_of_bays" />
                </b-field>
            </div>
            <div class="column is-12">
                <b-field
                    label="Occupancy count"
                    :type="getFieldType('occupancy_count')"
                    :message="getFieldMessage('occupancy_count')"
                >
                    <b-input type="number" v-model.number.trim="siteModel.occupancy_count" />
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import manageSiteMixin from '@/mixins/manageSiteMixin';

export default {
    mixins: [validationMixin, manageSiteMixin],
};
</script>
