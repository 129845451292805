<template>
    <be-widget class="top-plates" :is-loading="isLoading || isLoadingFull" :has-table="true">
        <template v-slot:heading>
            Most frequently seen plates
        </template>
        <template v-slot:content>
            <b-table
                :data="formattedApiResults"
                :hoverable="true"
                :selected="lastSelectedRow"
                :class="{ 'has-cursor-loading': isRowLoading }"
            >
                <b-table-column field="last_seen" label="Last Seen" v-slot="props">
                    {{ props.row.last_seen | dateTimeHuman }}
                </b-table-column>
                <b-table-column field="licence_plate" label="Detection" v-slot="props">
                    <be-detections-list
                        :clickable="false"
                        :wrap="true"
                        :detections="props.row.detections"
                    />
                </b-table-column>
                <b-table-column field="times_seen" label="Times Seen" v-slot="props">
                    <b-tag class="has-cursor-default">
                        {{ props.row.times_seen }}
                    </b-tag>
                </b-table-column>
                <b-table-column field="last_image" label="Last Image" header-class="has-text-right" v-slot="props">
                    <p class="has-text-right has-text-weight-bold">
                        <a href="#" @click.prevent="viewDetails(props.row)">View</a>
                    </p>
                </b-table-column>
            </b-table>
            <media-image-popup
                :active="showCarJamInfo"
                :image-with-detail="platesAndImageAndWithDetail"
                :display-arrows="formattedApiResults && formattedApiResults.length > 1"
                :loading="isLoadingFull"
                @prev="goto('prev')"
                @next="goto('next')"
                @close="closePopup"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import detectionDetailMixin from '@/mixins/detectionDetailMixin';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import BeDetectionsList from '@/components/global/BeDetectionsList';
import formattersMixin from '@/mixins/formattersMixin';

export default {
    components: {
        BeDetectionsList,
    },

    props: {
        amount: {
            type: Number,
            default: 10,
        },
    },

    mixins: [detectionDetailMixin, hasPopupMixin, widgetDataMixin, formattersMixin],

    computed: {
        ...mapGetters('explorer/plates/mostread', ['formattedApiResults', 'isLoading']),
    },

    methods: {
        ...mapActions('explorer/plates/mostread', ['loadData']),
    },
};
</script>
<style lang="scss" scoped>
.top-plates {
    overflow: hidden;
}
</style>
