import axios from 'axios';
import { getApiUrl } from '@/utils/api';

const getDefaultState = () => ({
    all: {},
    status: '',
});

const state = getDefaultState();

export default {
    namespaced: true,
    state,
    mutations: {
        plansRequest(state) {
            state.status = 'loading';
        },
        plansSuccess(state, plans) {
            state.status = 'success';
            if (plans) {
                state.all = plans;
            }
        },
        plansError(state) {
            state.status = 'error';
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        getAllPlans({ commit }) {
            return new Promise((resolve, reject) => {
                commit('plansRequest');
                axios({ url: getApiUrl({ path: 'billing/viewAllPlans' }) })
                    .then((response) => {
                        const plans = response.data.data;
                        commit('plansSuccess', plans.reverse());
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('plansError');
                        reject(error);
                    });
            });
        },
        setCanStoreImages({}, { canStoreImages }) { // eslint-disable-line no-empty-pattern
            return axios({
                url: getApiUrl({ path: 'billing/storeImages' }),
                method: 'POST',
                data: { opt_in: canStoreImages },
            });
        },
        resetState({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        allPlans: (state) => state.all,
    },
};
