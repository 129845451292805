<template>
    <div class="columns is-multiline">
        <div class="column is-4">
            <be-widget class="live-explorer-overview" icon="car" :is-loading="isLoading">
                <template v-slot:heading>
                    Total unique vehicles today:
                </template>
                <template v-slot:content>
                    <p class="label-widget-number">
                        {{ summaryData.total_today || '-' }}
                    </p>
                </template>
            </be-widget>
        </div>
        <div class="column is-4">
            <be-widget class="live-explorer-overview" icon="car-info" :is-loading="isLoading">
                <template v-slot:heading>
                    Current ocupancy:
                </template>
                <template v-slot:content>
                    <p class="label-widget-number">
                        {{ summaryData.current_occupancy || '-' }}
                    </p>
                </template>
            </be-widget>
        </div>
        <div class="column is-4">
            <be-widget class="live-explorer-overview" icon="alert" :is-loading="isLoading">
                <template v-slot:heading>
                    Current occupancy over dwell time:
                </template>
                <template v-slot:content>
                    <p class="label-widget-number">
                        {{ summaryData.current_occupancy_overdue || '-' }}
                    </p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'LiveExplorerOverview',

    computed: {
        ...mapGetters('liveExplorer/summary', ['summaryData', 'isLoading']),
    },
};
</script>
