import alerts from '@/store/alerts/alerts';
import lists from '@/store/alerts/lists';
import messageHistory from '@/store/alerts/messageHistory';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    resetState({ commit }) {
        commit('alerts/resetState');
        commit('lists/resetState');
        commit('messageHistory/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        alerts,
        lists,
        messageHistory,
    },
};
