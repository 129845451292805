import Vue from 'vue';

import BeWiget from '@/components/global/BeWidget';
import BePageHeader from '@/components/global/BePageHeader';
import BeDiffLabel from '@/components/global/BeDiffLabel';
import BeIntegrationHeader from '@/components/global/BeIntegrationHeader';

Vue.component('be-widget', BeWiget);
Vue.component('be-page-header', BePageHeader);
Vue.component('be-integration-header', BeIntegrationHeader);
Vue.component('be-diff-label', BeDiffLabel);
