<template>
    <div class="be-info">
        <div v-if="label" class="be-info__label label-table">{{ label }}</div>
        <template v-if="!!$slots.default">
            <div class="be-info__content mt-1">
                <slot />
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
    },
};
</script>
