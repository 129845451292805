import dayjs from 'dayjs';

export const getDatepickerOptions = () => ({
    datetimeFormatter: (time) => dayjs(time).format('DD MMM, YYYY HH:mm'),
    datepicker: {},
    timepicker: {},
    horizontalTimePicker: false,
    icon: 'calendar-today',
    trapFocus: true,
    mobileNative: false,
});
