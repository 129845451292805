import Vue from 'vue';
import axios from 'axios';
import { get } from 'lodash';
import {
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { liveExplorerTransformer } from '@/utils/transformers/live-explorer';
import summary from '@/store/liveExplorer/summary';
import { formatDwellTime } from '@/utils/dwell';

const hasPagination = true;

const getDefaultState = () => ({
    ...getDefaultDataState({ hasPagination }),
    filter: {
        plate: '',
        dwellTime: '',
        site: '',
    },
});

const state = getDefaultState();

const getters = {
    ...getDefaultDataGetters({ hasPagination }),

    imageIds(state, getters) {
        return getters.formattedApiResults
            .map((row) => get(row, 'image.id'))
            .filter((r) => r);
    },

    formattedApiResults(state, getters) {
        return liveExplorerTransformer(getters.apiResults);
    },

    filterParams(state) {
        const params = {
            page: state.currentPage,
            perPage: state.perPage,
            additional: {},
        };

        if (state.filter.dwellTime) {
            params.additional.dwell = formatDwellTime({ time: state.filter.dwellTime, inMinutes: true });
        }
        if (state.filter.site) {
            params.additional.site = state.filter.site;
        }
        if (state.filter.plate) {
            params.additional.plate = state.filter.plate;
        }

        return params;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination }),

    deleteRow(state, { id }) {
        const results = state.data.results.filter((row) => row.id !== id);
        Vue.set(state.data, 'results', results);
    },

    setFilter(state, payload) {
        Object.assign(state.filter, payload);
    },

    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    resetState({ commit }) {
        commit('resetState');
        commit('summary/resetState');
        return true;
    },

    getData({ commit, state, getters, dispatch }, {
        perPage = null,
        page = null,
        force = false,
        loadSummary = true,
    }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = buildUrlParams(getters.filterParams);
        const path = 'explorer/live/dwell_time';

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash && !force) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: STATUS_LOADING });

        const promises = [
            axios({ url: getApiUrl({ path }), params })
                .then((response) => {
                    commit('setStatus', { status: STATUS_SUCCESS });
                    commit('setData', { data: response.data });
                    commit('setDataHash', { dataHash });
                })
                .then(() => dispatch('media/images/preload', { ids: getters.imageIds }, { root: true }))
                .catch((err) => {
                    errorHandler(err);
                    commit('setStatus', { status: STATUS_ERROR });
                }),
        ];

        if (loadSummary) {
            promises.push(dispatch('summary/loadSummaryData', { force }));
        }

        return Promise.all(promises);
    },

    deleteRow({ commit }, { id }) {
        commit('setStatus', { status: STATUS_LOADING });

        const path = `explorer/live/dwell_time/delete/${id}`;

        return axios({
            url: getApiUrl({ path }),
            method: 'DELETE',
        })
            .then(() => {
                commit('deleteRow', { id });
                commit('setStatus', { status: STATUS_SUCCESS });
                return true;
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        summary,
    },
};
