<template>
    <be-info v-if="confidence" :label="label">
        <div class="label-widget-number">
            {{ confidenceLabel }}
        </div>
    </be-info>
</template>

<script>
import { getConfidenceType } from '@/utils/confidence';
import { formatPercentage } from '@/utils/formatters/numbers';
import BeInfo from '@/components/global/BeInfo';

export default {
    components: {
        BeInfo,
    },

    props: {
        confidence: {
            type: Number,
            default: 0,
        },
        label: {
            type: String,
            default: 'Average Confidence',
        },
    },

    computed: {
        confidenceType() {
            return getConfidenceType({
                confidence: this.confidence,
            });
        },

        confidenceLabel() {
            return formatPercentage({
                number: this.confidence,
            });
        },
    },
};
</script>
