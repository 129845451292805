<template>
    <b-modal
        :active="active"
        has-modal-card
        trap-focus
        scroll="keep"
        :width="600"
        :can-cancel="false"
        :destroy-on-hide="true"
    >
        <div class="modal-card is-narrower">
            <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Upload new list
                </p>
                <button type="button" class="modal-card-close" @click.prevent="close()">
                    <b-icon icon="close" />
                </button>
            </header>
            <div class="modal-card-body">
                <b-message v-if="serverError" type="is-danger" @click.native="serverError = ''">
                    <span class="has-text-dark">
                        {{ serverError }}
                    </span>
                </b-message>
                <b-field
                    label="List name"
                    :type="$v.description.$error ? 'is-danger' : ''"
                    :message="getErrorMessage('description')"
                >
                    <b-input v-model.trim="description" />
                </b-field>

                <b-field
                    :type="$v.file.$error ? 'is-danger' : ''"
                    :message="getErrorMessage('file')"
                >
                    <b-tag
                        v-if="file"
                        size="is-large"
                        closable
                        @close="file = null"
                    >
                        {{ file.name }}
                    </b-tag>
                    <b-upload
                        v-else
                        drag-drop
                        expanded
                        v-model="file"
                        :class="$v.file.$error ? 'has-error' : ''"
                    >
                        <section class="section py-5">
                            <div class="content has-text-centered">
                                <p>
                                    <b-icon icon="cloud-upload" size="is-large" />
                                </p>
                                <p>Drop your file here or click to upload</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </div>
            <div class="modal-card-foot">
                <b-button type="is-primary" class="be-button is-wide" @click="upload()">
                    Upload
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { get, isEmpty, isString } from 'lodash';

const fileValidation = (value) => {
    if (!value) {
        return false;
    }
    return true;
};

function uniqValidation(value) {
    if (isEmpty(this.lists)) {
        return true;
    }
    return this.lists.reduce((exists, list) => (
        exists
            ? list.description !== value
            : exists
    ), true);
}

export default {
    mixins: [
        validationMixin,
    ],

    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },

    validations: {
        description: {
            required,
            uniqValidation,
        },
        file: {
            fileValidation,
        },
    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    this.reset();
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            isUploaded: false,
            file: null,
            description: '',
            isLoading: false,
            serverError: '',
            validationMessages: {
                description: {
                    required: 'List name is required',
                    uniqValidation: 'Another alert list with this name already exists. Please modify the name and upload again.',
                },
                file: 'CSV file is required',
            },
        };
    },

    computed: {
        ...mapState('alerts/lists', ['lists']),
    },

    methods: {
        ...mapActions('alerts/lists', ['uploadList', 'getAllLists']),

        getErrorMessage(field) {
            if (!this.$v[field].$error) {
                return '';
            }

            const messages = this.validationMessages[field];

            if (isString(messages)) {
                return messages;
            }

            const keys = Object.keys(messages);

            let message = '';
            keys.forEach((key) => {
                const value = get(this.$v, `${field}.${key}`, true);
                if (value === false) {
                    message = messages[key];
                }
            });

            return message;
        },

        upload() {
            if (this.isLoading) {
                return;
            }

            this.serverError = '';

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            this.uploadList({
                file: this.file,
                description: this.description,
            })
                .then(() => {
                    this.isUploaded = true;
                    this.close();
                    this.getAllLists({ force: true });
                })
                .catch((err) => {
                    this.serverError = err.message;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        reset() {
            this.isUploaded = true;
            this.file = null;
            this.description = '';
            this.serverError = '';
        },

        close() {
            this.$v.$reset();
            this.$emit('close');
        },
    },

    mounted() {
        this.$root.$emit('intercom:hide');
        this.$once('hook:beforeDestroy', () => {
            this.$root.$emit('intercom:show');
        });
    },
};
</script>

<style lang="scss" scoped>
.modal-card {
    position: relative;

    ::v-deep .upload.has-error {
        .upload-draggable {
            border-color: $danger;
        }
    }
}
</style>
