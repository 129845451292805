import dayjs from 'dayjs';

const getDefaultState = () => {
    const period = 7;
    const endDate = dayjs().add(1, 'minute');
    const startDate = endDate.startOf('day').subtract(period - 1, 'day');

    const states = {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        period,
        selectedTags: [],
        confidenceLevel: 'all',
        selectedPlates: [],
        selectedDetectionTypes: [],
        allTime: false,

        timePeriods: [
            {
                period: 'today',
                label: 'Today',
            },
            {
                period: '7',
                label: 'Last 7 days',
            },
            {
                period: '30',
                label: 'Last 30 days',
            },
            {
                period: '90',
                label: 'Last 90 days',
            },
        ],

        confidenceLevels: [
            {
                level: 'all',
                label: 'All',
            },
            {
                level: 'high',
                label: 'High',
            },
            {
                level: 'medium',
                label: 'Medium',
            },
            {
                level: 'low',
                label: 'Low',
            },
        ],
    };

    return {
        ...states,
        filters: {
            ...states,
        },
    };
};

const state = getDefaultState();

const getters = {
    getDateRange(state) {
        return {
            start: state.startDate,
            end: state.endDate,
            period: state.period,
        };
    },
    getPeriodLabel(state) {
        if (!state.period) {
            return '';
        }
        const currPeriod = `${state.period}`;
        return state.timePeriods.reduce((curr, period) => (period.period === currPeriod ? period.label : curr), '');
    },
    getSelectedTagsSlugs(state) {
        return state.selectedTags.map((tag) => tag.slug);
    },
    getFilterSelectedTagsSlugs(state) {
        return state.filters.selectedTags.map((tag) => tag.slug);
    },
    getSelectedDetectionTypesSlugs(state) {
        return state.selectedDetectionTypes.map((type) => type.slug);
    },
    getSelectedPlates(state) {
        return state.selectedPlates;
    },
    getConfidenceLevel(state) {
        return state.confidenceLevel;
    },
    getSelectedTagsAsParam(state) {
        return state.selectedTags.map((tag) => tag.slug).join(',');
    },
    getFilterValues(state) {
        return {
            startDate: state.filters.startDate,
            endDate: state.filters.endDate,
            period: state.filters.period,
            selectedTags: state.filters.selectedTags.map((tag) => tag.slug),
            selectedDetectionTypes: state.filters.selectedDetectionTypes.map((type) => type.slug),
            selectedPlates: state.filters.selectedPlates,
            confidenceLevel: state.filters.confidenceLevel,
            allTime: state.filters.allTime,
        };
    },
    getFilterHash(state, getters) {
        const filterValues = getters.getFilterValues;
        const tags = `t:${filterValues.selectedTags.join(',')}`;
        const types = `t:${filterValues.selectedDetectionTypes.join(',')}`;
        const plates = `p:${filterValues.selectedPlates.join(',')}`;
        const dates = `d:${dayjs(filterValues.startDate).unix()},${dayjs(filterValues.endDate).unix()}}`;
        const confidence = `c:${filterValues.confidenceLevel}`;

        return `${dates}|${tags}|${types}|${plates}|${confidence}`;
    },
};

const mutations = {
    setStartDate(state, { startDate }) {
        state.startDate = startDate;
    },
    setEndDate(state, { endDate }) {
        state.endDate = endDate;
    },
    setRange(state, { startDate, endDate, period = null }) {
        state.startDate = startDate;
        state.endDate = endDate;
        state.period = period;
        state.allTime = false;
    },
    setPeriod(state, { period }) {
        state.period = period;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setSelectedTags(state, { tags }) {
        state.selectedTags = tags || [];
    },
    setSelectedDetectionTypes(state, { types }) {
        state.selectedDetectionTypes = types || [];
    },
    setConfidenceLevel(state, { confidenceLevel }) {
        state.confidenceLevel = confidenceLevel;
    },
    setSelectedPlates(state, { plates }) {
        state.selectedPlates = plates || [];
    },
    setAllTime(state, { allTime }) {
        state.allTime = allTime;
    },
    applyFilters(state) {
        state.filters = { ...state };
    },
};
const actions = {
    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
