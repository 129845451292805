<template>
    <div class="apps">
        <div class="apps__columns columns is-multiline is-mobile">
            <div
                v-for="(app, index) in rvisionApps"
                :key="`app${index}`"
                class="apps__column column is-6-mobile is-4-tablet is-3-desktop is-2-widescreen"
            >
                <marketplace-apps-item
                    :key="`rvision-${index}-${app.slug}`"
                    :item="app"
                    class="apps__item"
                />
            </div>
        </div>

        <template v-if="availableApps.length">
            <header class="apps__header">
                <h2 class="apps__header__title title is-4">
                    Great Apps for R/VISION
                </h2>
            </header>
            <div class="apps__columns columns is-multiline is-mobile">
                <div
                    v-for="(app, index) in availableApps"
                    :key="`app${index}`"
                    class="apps__column column is-6-mobile is-4-tablet is-3-desktop is-2-widescreen"
                >
                    <marketplace-apps-item
                        :key="`available-${index}-${app.slug}`"
                        :item="app"
                        class="apps__item"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MarketplaceAppsItem from '@/components/local/marketplace/MarketplaceAppsItem';

export default {
    components: {
        MarketplaceAppsItem,
    },

    computed: {
        ...mapState('marketplace', ['rvisionApps', 'availableApps']),
    },

    methods: {
        ...mapActions('marketplace/user', ['loadAllAppSettings']),
    },

    mounted() {
        this.loadAllAppSettings({});
    },
};
</script>

<style lang="scss" scoped>
.apps {
    &__header {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    &__column {
        display: flex;
        justify-content: stretch;
    }
    &__item {
        flex: 1;
    }
}
</style>
