import plates from './plates/index';
import detections from './detections/index';
import images from './images';
import speed from './speed';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    resetState({ commit }) {
        commit('detections/resetState');
        commit('detections/unique/resetState');
        commit('detections/unique/dailysummary/resetState');
        commit('detections/unique/summary/resetState');

        commit('plates/sitegraph/resetState');
        commit('plates/mostread/resetState');

        commit('images/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        plates,
        detections,
        images,
        speed,
    },
};
