/* eslint-disable */
// XAML Icons

// icon: hard-hat
export const HS_VIOLATION_HAT = 'M2.7842 18.5038H21.1442C22.0082 18.5038 22.7042 17.8078 22.7042 16.9438V16.1278C22.7042 15.2638 22.0082 14.5678 21.1442 14.5678H20.9762C20.5442 11.3518 18.3362 8.66379 15.1442 7.53579C15.2402 7.03179 15.1442 6.52779 14.8562 6.09579C14.5202 5.59179 13.9442 5.27979 13.3202 5.27979H10.5842C9.9602 5.27979 9.40819 5.59179 9.04819 6.09579C8.76019 6.52779 8.66419 7.03179 8.76019 7.53579C5.59219 8.66379 3.3602 11.3518 2.9282 14.5678H2.7602C1.8962 14.5678 1.2002 15.2638 1.2002 16.1278V16.9438C1.2482 17.8078 1.9442 18.5038 2.7842 18.5038ZM9.84019 6.64779C10.0082 6.40779 10.2722 6.26379 10.5842 6.26379H13.3682C13.6802 6.26379 13.9442 6.40779 14.1122 6.64779C14.2802 6.88779 14.3042 7.19979 14.2082 7.48779L12.8162 11.0398C12.6482 11.4478 12.2882 11.6158 11.9762 11.6158C11.6642 11.6158 11.3042 11.4718 11.1362 11.0398L9.7442 7.48779C9.6482 7.19979 9.67219 6.91179 9.84019 6.64779ZM2.2082 16.1278C2.2082 15.7918 2.4722 15.5278 2.8082 15.5278H3.3602H3.3842H6.86419C7.12819 15.5278 7.34419 15.3118 7.34419 15.0478C7.34419 14.7838 7.12819 14.5678 6.86419 14.5678H3.91219C4.34419 11.8318 6.3602 9.43179 9.0962 8.44779L10.2482 11.3758C10.5362 12.0958 11.2082 12.5518 11.9762 12.5518C12.7442 12.5518 13.4162 12.0958 13.7042 11.3758L14.8562 8.44779C17.6162 9.43179 19.6082 11.8078 20.0402 14.5918H17.0882C16.8242 14.5918 16.6082 14.8078 16.6082 15.0718C16.6082 15.3358 16.8242 15.5518 17.0882 15.5518H20.5682H20.5922H21.1442C21.4802 15.5518 21.7442 15.8158 21.7442 16.1518V16.9438C21.7442 17.2798 21.4802 17.5438 21.1442 17.5438H2.7842C2.4482 17.5438 2.1842 17.2798 2.1842 16.9438V16.1278H2.2082Z';

// icon:
export const HS_VIOLATION_VEST = 'M20.0636 4.08012C20.3276 4.08012 20.5436 3.86412 20.5436 3.60012C20.5436 3.33612 20.3276 3.12012 20.0636 3.12012H14.9036C14.6396 3.12012 14.4236 3.33612 14.4236 3.60012V5.92812C14.4236 7.27212 13.3196 8.52012 12.0716 8.52012C10.8476 8.52012 9.71961 7.29612 9.71961 5.92812V3.60012C9.71961 3.33612 9.50361 3.12012 9.23961 3.12012H4.07961C3.81561 3.12012 3.59961 3.33612 3.59961 3.60012C3.59961 3.86412 3.81561 4.08012 4.07961 4.08012C4.41561 4.08012 5.06361 5.08812 5.06361 6.96012C5.06361 8.83212 4.41561 9.84012 4.07961 9.84012C3.81561 9.84012 3.59961 10.0561 3.59961 10.3201V12.0241V13.8241V16.3441V18.1441V20.4241C3.59961 20.6881 3.81561 20.9041 4.07961 20.9041H12.0716H20.0636C20.3276 20.9041 20.5436 20.6881 20.5436 20.4241V18.1441V16.3441V13.8241V12.0241V10.3201C20.5436 10.0561 20.3276 9.84012 20.0636 9.84012C19.7276 9.84012 19.0796 8.83212 19.0796 6.96012C19.0796 5.08812 19.7276 4.08012 20.0636 4.08012ZM6.02361 6.96012C6.02361 5.78412 5.78361 4.77612 5.39961 4.08012H8.78361V5.92812C8.78361 7.63212 10.0556 9.14412 11.6156 9.43212V11.5441H4.55961V10.7041C5.42361 10.2961 6.02361 8.83212 6.02361 6.96012ZM19.5836 15.8881H12.5516V14.3281H19.5836V15.8881ZM19.5836 17.6881H12.5516V16.8481H19.5836V17.6881ZM4.55961 14.3041H11.5916V15.8641H4.55961V14.3041ZM4.55961 16.8481H11.5916V17.6881H4.55961V16.8481ZM19.5836 13.3441H12.5516V12.5041H19.5836V13.3441ZM11.5916 13.3441H4.55961V12.5041H11.5916V13.3441ZM4.55961 18.6481H11.5916V19.9681H4.55961V18.6481ZM12.5516 19.9681V18.6481H19.5836V19.9681H12.5516ZM19.5836 10.7041V11.5441H12.5516V9.43212C14.1116 9.16812 15.3836 7.65612 15.3836 5.92812V4.08012H18.7676C18.3836 4.77612 18.1436 5.78412 18.1436 6.96012C18.1196 8.83212 18.7196 10.2961 19.5836 10.7041Z';

export const HS_VIOLATION_SUCCESS = 'M4 7V21H18V23H4C2.9 23 2 22.1 2 21V7H4M12.8 15.35L9.5 12.05L10.9 10.65L12.8 12.55L17.1 8.25L18.5 9.65L12.8 15.35M20 3C21.1 3 22 3.9 22 5V17C22 18.1 21.1 19 20 19H8C6.9 19 6 18.1 6 17V5C6 3.9 6.9 3 8 3H11.18C11.6 1.84 12.7 1 14 1C15.3 1 16.4 1.84 16.82 3H20M14 3C13.45 3 13 3.45 13 4C13 4.55 13.45 5 14 5C14.55 5 15 4.55 15 4C15 3.45 14.55 3 14 3M10 7V5H8V17H20V5H18V7H10Z';

export const HS_VIOLATION_ICONS = {
    hat: HS_VIOLATION_HAT,
    vis: HS_VIOLATION_VEST,
};
