import Vue from 'vue';
import axios from 'axios';
import { find, findIndex } from 'lodash';
import { API_VERSION, getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { buildUrlParams } from '@/utils/store-helpers';
import { DEFAULT_PAGINATION_PER_PAGE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';

const getDefaultState = () => ({
    // index alerts information
    alerts: [],

    alertsCount: 0,

    // full alerts information
    alertsFull: [],

    status: '',
    currentPage: 1,
    perPage: DEFAULT_PAGINATION_PER_PAGE,
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
};

const mutations = {
    setData(state, { data }) {
        state.data = data;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setPage(state, { page }) {
        state.currentPage = page;
    },
    setPerPage(state, { perPage }) {
        state.perPage = perPage;
    },

    alertsSuccess(state, { data }) {
        state.status = STATUS_SUCCESS;
        if (data.results) {
            state.alerts = data.results;
        }
        if (data.count) {
            state.alertsCount = data.count;
        }
    },

    alertSuccess(state, { alertId, alert }) {
        state.status = STATUS_SUCCESS;
        const existingIndex = findIndex(state.alertsFull, { id: alertId });
        if (existingIndex > -1) {
            Vue.set(state.alertsFull, existingIndex, alert);
        } else {
            state.alertsFull = [...state.alertsFull, alert];
        }
    },

    alertCreatedSuccess(state, { data }) {
        state.status = STATUS_SUCCESS;
        state.alerts = [...state.alerts, data];
    },

    alertUpdatedSuccess(state, { alertId, data }) {
        state.status = STATUS_SUCCESS;
        ['alerts', 'alertsFull'].forEach((stateKey) => {
            const existingAlertIndex = findIndex(state[stateKey], { id: alertId });
            if (existingAlertIndex > -1) {
                Vue.set(state[stateKey], existingAlertIndex, data);
            }
        });
    },

    alertRemovedSuccess(state, { alertId }) {
        state.status = STATUS_SUCCESS;
        ['alerts', 'alertsFull'].forEach((stateKey) => {
            const existingAlertIndex = findIndex(state[stateKey], { id: alertId });
            if (existingAlertIndex > -1) {
                Vue.delete(state[stateKey], existingAlertIndex);
            }
        });
    },
};

const actions = {
    getAlerts({ commit, state }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = buildUrlParams({
            perPage: state.perPage,
            page: state.currentPage,
        });

        const path = 'alert';

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path, apiVersion: API_VERSION.v2 }),
            params,
        })
            .then((response) => {
                commit('alertsSuccess', { data: response.data });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    getUser({ commit }, { alertId, force = false }) {
        if (!force) {
            const existing = find(state.alertsFull, { id: alertId });
            if (existing) {
                return Promise.resolve(true);
            }
        }

        commit('setStatus', { status: STATUS_LOADING });
        return axios({ url: getApiUrl({ path: `alert/${alertId}`, apiVersion: API_VERSION.v2 }) })
            .then((response) => {
                commit('alertSuccess', { alertId, alert: response.data });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
                // throw another error to display a message on the frontend
                throw new Error('Alert not found');
            });
    },

    updateUser({ commit }, { alertId, data }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            method: 'PUT',
            url: getApiUrl({ path: `alert/${alertId}`, apiVersion: API_VERSION.v2 }),
            data,
        })
            .then((response) => {
                commit('alertUpdatedSuccess', {
                    alertId,
                    data: response.data,
                });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
                throw new Error('Cannot update alert');
            });
    },

    createUser({ commit }, { data }) {
        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            method: 'POST',
            url: getApiUrl({ path: 'alert', apiVersion: API_VERSION.v2 }),
            data,
        })
            .then((response) => {
                commit('alertCreatedSuccess', { data: response.data });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
                throw new Error('Cannot create alert');
            });
    },

    removeUser({ commit }, { alertId }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            method: 'DELETE',
            url: getApiUrl({ path: `alert/${alertId}`, apiVersion: API_VERSION.v2 }),
        })
            .then(() => {
                commit('alertRemovedSuccess', { alertId });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
                throw new Error('Cannot remove alert');
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
