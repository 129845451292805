<template>
    <be-info class="last-detection" v-if="camera && imageId" :label="detectionLabel">
        <div v-if="isError" class="last-detection__error">Image error</div>
        <div v-else-if="isLoading" class="last-detection__loading" />
        <figure v-else-if="image" class="mt-1">
            <img class="is-block" :src="image" alt="">
        </figure>
    </be-info>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { get } from 'lodash';
import BeInfo from '@/components/global/BeInfo';
import { dateHumanFormatter } from '@/utils/formatters/datetime';

export default {
    props: {
        camera: {
            type: Object,
            default: null,
        },
        displayLabel: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        BeInfo,
    },

    data() {
        return {
            isLoading: true,
        };
    },

    watch: {
        imageUrl: {
            handler(val) {
                if (val) {
                    this.isLoading = false;
                }
            },
            immediate: true,
        },
    },

    mounted() {
        if (this.imageId) {
            this.loadDataRow({
                id: this.imageId,
            }).then(() => {
                this.isLoading = false;
            });
        }
    },

    computed: {
        ...mapState('media/images', ['data']),

        isError() {
            return get(this.data, `${this.imageId}.error`, false);
        },

        image() {
            return get(this.data, `${this.imageId}.image_file`);
        },

        imageId() {
            return get(this.camera, 'last_image_id', null);
        },

        lastDetection() {
            return get(this.camera, 'last_seen', null);
        },

        detectionLabel() {
            return this.displayLabel
                ? `Last detection: ${dateHumanFormatter(this.lastDetection)}`
                : '';
        },
    },

    methods: {
        ...mapActions('media/images', ['loadDataRow']),
    },
};
</script>

<style lang="scss" scoped>
.last-detection {
    &__loading {
        display: flex;
        height: 40px;
        position: relative;

        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            animation: spinAround .5s infinite linear;
            border: 2px solid $accent;
            border-radius: 9999px;
            border-right-color: transparent;
            border-top-color: transparent;
            content: "";
            display: block;
            height: 1em;
            position: relative;
            width: 1em;
        }
    }
}
</style>
