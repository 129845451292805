import axios from 'axios';
import {
    buildStartEndDateAsUtcUrl, buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';

const state = getDefaultDataState({ hasPagination: false });

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    speedSummaryData(state) {
        return state.data || {
            average_vehicle_speed: null,
        };
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
};

const actions = {
    loadData({
        state, commit, rootState, rootGetters,
    }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
        });

        const path = buildStartEndDateAsUtcUrl({
            path: 'explorer/speed/summary',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setStatus', { status: 'success' });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
