<template>
    <b-modal
        :active="active"
        has-modal-card
        trap-focus
        scroll="keep"
        :width="widthPx"
        :can-cancel="false"
        :destroy-on-hide="true"
    >
        <div class="modal-card" :class="`is-${width}`">
            <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
            <header v-if="!hideHeader" class="modal-card-head">
                <p class="modal-card-title">
                    <slot name="title" />
                </p>
                <button type="button" class="modal-card-close" @click.prevent="$emit('close')">
                    <b-icon icon="close" />
                </button>
            </header>
            <div class="modal-card-body">
                <slot name="body" />
            </div>
            <footer v-if="!hideFooter" class="modal-card-foot">
                <template v-if="!!$slots.footer">
                    <slot name="footer" />
                </template>
                <template v-else>
                    <b-button class="button be-button is-wider is-primary" @click.prevent="$emit('save')">
                        Save
                    </b-button>
                </template>
            </footer>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: 'narrower',
        },
        toggleIntercom: {
            type: Boolean,
            default: true,
        },
    },

    mounted() {
        if (this.toggleIntercom) {
            this.$root.$emit('intercom:hide');
            this.$once('hook:beforeDestroy', () => {
                this.$root.$emit('intercom:show');
            });
        }
    },

    computed: {
        widthPx() {
            switch (this.width) {
                case 'wide':
                    return 820;
                case 'full':
                    return '';
                case 'wider':
                    return 980;
                case 'narrow':
                    return 420;
                case 'narrower':
                    return 600;
                default:
                    return 420;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-card {
    position: relative;
}
</style>
