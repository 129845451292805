export function normalizeMinMaxNumber(value = 0, min = -Infinity, max = Infinity) {
    return Math.min(Math.max(value, min), max);
}

export function binarySearchClosestSmallerNumber(list, number) {
    let low = 0;
    let high = list.length - 1;
    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        if (list[mid] === number || (list[mid] < number && (list[mid + 1] > number || mid === list.length - 1))) {
            return list[mid];
        }
        if (list[mid] < number) {
            low = mid + 1;
        } else {
            high = mid - 1;
        }
    }
    return null;
}
