import axios from 'axios';
import dayjs from 'dayjs';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateUrl, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { dateFormatter } from '@/utils/formatters/datetime';

export const FORECAST_DAYS = 7;

const getDefaultState = () => ({
    ...getDefaultDataState({ hasPagination: false }),
    plate: null,
});

const state = getDefaultState();

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    apiResults(state) {
        return state.data;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
    setPlate(state, { plate }) {
        state.plate = plate;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    loadData({
        commit, rootState, state,
    }, { plate = null }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        if (plate) {
            commit('setPlate', { plate });
        }

        const path = buildStartEndDateUrl({
            path: `explorer/plates/${state.plate}/sitegraph`,
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        const siteGraph = axios({ url: getApiUrl({ path }) });

        const now = dayjs();
        const from = dateFormatter(now.add(1, 'day'));
        const to = dateFormatter(now.add(FORECAST_DAYS + 1, 'day'));
        const forecastPath = `forecast/forecastBy/plate/${state.plate}/predictionFrom/${from}/to/${to}`;

        const forecast = axios({ url: getApiUrl({ path: forecastPath }) });

        commit('setStatus', { status: 'loading' });

        return Promise.all([siteGraph, forecast])
            .then((response) => {
                commit('setStatus', { status: 'success' });
                const actualData = response[0].data;
                const forecastData = response[1].data.items;
                commit('setData', { data: { actualData, forecastData } });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
