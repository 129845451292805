<template>
    <div :class="rootClasses">
        <b-loading
            v-if="isLoading"
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
        />
        <div :class="innerClasses">
            <template v-if="icon">
                <div class="be-widget__icon" :class="iconType">
                    <b-icon :icon="icon" />
                </div>
            </template>
            <div v-if="hasHeading" :class="headingClasses">
                <div :class="headingTitleClasses">
                    <slot name="heading" />
                </div>
                <template v-if="!!$slots.headingAfter">
                    <div class="be-widget__heading__after">
                        <slot name="headingAfter" />
                    </div>
                </template>
            </div>
            <div class="be-widget__content" :class="contentClasses">
                <slot />
                <slot name="content" />
            </div>
            <div v-if="!!$slots.footer" class="be-widget__footer" :class="footerClasses">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BeWidget',

    /* eslint-disable vue/no-potential-component-option-typo */
    eq: {
        breakpoints: {
            small: { maxWidth: 469 },
            medium: { minWidth: 470, maxWidth: 649 },
            large: { minWidth: 650 },
        },
    },
    /* eslint-enable vue/no-potential-component-option-typo */

    props: {
        transparent: {
            type: Boolean,
            default: false,
        },
        hasPadding: {
            type: Boolean,
            default: true,
        },
        hasTable: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        titleSize: {
            type: String,
            validator: (val) => ['', 'large'].includes(val),
            default: '',
        },
        footerClasses: {
            type: Array,
            default: () => [],
        },
        contentClasses: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            default: '',
        },
        iconType: {
            type: String,
            default: 'is-success',
        },
    },

    computed: {
        hasHeading() {
            return !!this.$slots.heading || !!this.$slots.headingAfter;
        },

        rootClasses() {
            const classes = ['be-widget'];
            if (!this.transparent) {
                classes.push('card');
            }
            if (this.hasTable) {
                classes.push('has-table');
            }
            if (!this.hasHeading) {
                classes.push('has-no-head');
            }
            if (this.icon) {
                classes.push('has-icon');
            }
            return classes;
        },

        innerClasses() {
            const classes = ['be-widget__inner'];
            if (!this.transparent) {
                classes.push('card-content');
            }
            if (!this.hasPadding || this.transparent) {
                classes.push('is-paddingless');
            }

            return classes;
        },

        headingClasses() {
            const classes = ['be-widget__heading'];
            if (this.$eq.medium || this.$eq.large) {
                classes.push('is-row');
            } else {
                classes.push('is-col');
            }
            return classes;
        },

        headingTitleClasses() {
            const classes = ['be-widget__heading__title'];
            if (this.titleSize === 'large') {
                classes.push('title is-5');
            } else {
                classes.push('heading-title');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped>
.be-widget {
    flex: 1;
    border-radius: $radius;
    display: flex;
    position: relative;
    overflow: visible;

    ::v-deep .heading-title {
        font-size: 14px;
        letter-spacing: 0.01em;
        font-weight: $weight-normal;
        color: $text;
    }

    &__inner {
        flex: 1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &.has-icon & {
        &__heading,
        &__content,
        &__footer {
            padding-left: 56px;
        }
    }

    &__icon {
        position: absolute;
        left: 16px;
        top: 16px;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-rounded;

        &.is-success {
            background: $success;
            color: $success-invert;
        }

        &.is-warning {
            background: $warning;
            color: $warning-invert;
        }

        &.is-danger {
            background: $danger;
            color: $danger-invert;
        }

        &.is-info {
            background: $info;
            color: $info-invert;
        }
    }

    &.is-paddingless &__icon {
        left: 0;
        top: 0;
    }

    &__heading {
        flex: 0 0 auto;
        margin-bottom: 15px;

        &__after {
            font-size: 14px;
        }

        &.is-row {
            display: flex;
            align-items: center;
        }

        &.is-row & {
            &__title {
                flex: 1;
            }

            &__after {
                margin-left: 1rem;
                flex: 0 0 auto;
            }
        }

        &.is-col & {
            &__after {
                margin-top: 5px;
            }
        }
    }

    &__content {
        flex: 1;
        position: relative;
        &.is-align-bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
    &.has-table {
        ::v-deep .b-table {
            margin-left: -16px;
            margin-right: -16px;
        }
    }
    &.has-table.has-no-head & {
        &__inner {
            padding-top: 0;
        }
    }

    &__footer {
        margin-top: 1.5rem;
        font-size: 14px;
        font-weight: $weight-bold;
        color: $text;
        letter-spacing: 0.01em;

        a {
            text-decoration: underline;
        }
    }
}
</style>
