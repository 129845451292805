import { get, find, sumBy, chain, range } from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import { STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import detections from '@/store/hs/observations/explorer/detections';
import { DATETIME_FORMATTED_FORMAT, datetimeApiFormatter } from '@/utils/formatters/datetime';
import { errorHandler } from '@/utils/errors';
import { getApiUrl } from '@/utils/api';
import { HS_CAMERA_VIOLATIONS_TYPES } from '@/utils/sites/cameras';

const getDefaultState = () => ({
    status: '',
    filtersApplied: {},
    data: null,
    selectedDate: null,
    selectedTime: null,
});

const summaryDateTimeTransformer = (data) => {
    const allTimes = range(0, 24).map((time) => `${`0${time}`.slice(-2)}`);

    const grouped = chain(data)
        .groupBy((row) => dayjs(row.violation_hour).format('YYYY-MM-DD'))
        .value();

    const dates = Object.keys(grouped).map((date) => {
        // const dateTimes = chain(grouped[date])
        //     .groupBy((row) => dayjs(row.violation_hour).format('HH'))
        //     .reduce((result, value, key) => {
        //         // eslint-disable-next-line no-param-reassign
        //         result[key] = sumBy(value, 'violation_count');
        //         return result;
        //     }, {})
        //     .value();

        const times = allTimes.map((time) => {
            const hour = parseInt(time.slice(0, 2), 10);
            const ampm = hour < 12 ? 'AM' : 'PM';
            const twelveHour = hour % 12 || 12;

            return {
                time,
                displayTime: `${`0${twelveHour}`.slice(-2)} ${ampm}`,
                count: 0,
            };
        });
        return { date, times };
    });

    return dates;
};

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,

    filtersSummary(state, getters, rootState, rootGetters) {
        if (!getters.hasFiltersApplied) {
            return '';
        }
        const { observation } = state.filtersApplied;

        const riskFilter = find(HS_CAMERA_VIOLATIONS_TYPES, { handle: observation.riskType });
        let str = `Searching for ${riskFilter.label}`;

        const site = find(rootState.hs.observations.observationFilters.sites, { id: observation.site });
        if (site) {
            str += ` on site "${site.name}"`;
        }

        const camera = find(rootGetters['hs/observations/observationFilters/currentSiteCameras'], {
            id: observation.camera,
        });
        if (camera) {
            str += ` and camera "${camera.name}"`;
        }

        str += ` between ${dayjs(observation.from).format(DATETIME_FORMATTED_FORMAT)}`;
        str += `  and ${dayjs(observation.to).format(DATETIME_FORMATTED_FORMAT)}`;

        return str;
    },

    hasFiltersApplied(state) {
        return !!(state.filtersApplied && Object.values(state.filtersApplied).length);
    },

    dates(state) {
        return state.data && state.data.dates ? state.data.dates : null;
    },

    formattedDates(state, getters) {
        if (!getters.dates) {
            return null;
        }
        return getters.dates.map((date) => ({
            ...date,
            count: date.times ? sumBy(date.times, 'count') : 0,
        }));
    },

    formattedTimes(state, getters) {
        if (!getters.formattedDates) {
            return null;
        }
        const date = find(getters.formattedDates, {
            date: state.selectedDate,
        }) || null;

        return date && date.times ? date.times : null;
    },

    detectionsRequestParameters(state) {
        return `${state.selectedDate}T${state.selectedTime}:00:00`;
    },

    summaryRequestParameters(state) {
        const from = datetimeApiFormatter(get(state.filtersApplied, 'observation.from'));
        const to = datetimeApiFormatter(get(state.filtersApplied, 'observation.to'));
        const keyword = get(state.filtersApplied, 'observation.keyword', null);
        const objectClasses = get(state.filtersApplied, 'observation.objectClasses', []);
        const seen = get(state.filtersApplied, 'observation.seen', null);

        const params = {
            risk_filter_type: get(state.filtersApplied, 'observation.riskType').map((x) => x.handle),
            observation_filter_site: get(state.filtersApplied, 'observation.selectedSites')[0].id,
            observation_filter_camera: get(state.filtersApplied, 'observation.selectedCameras').map((camera) => camera.id),
            observation_filter_from: from,
            observation_filter_to: to,
            observation_filter_group: get(state.filtersApplied, 'observation.grouped', 5),
        };

        if (keyword) {
            params.observation_filter_keyword = keyword;
        }
        if (objectClasses.length) {
            params.observation_filter_object_class = objectClasses;
        }
        if (seen !== null) {
            params.observation_filter_seen = seen;
        }

        return params;
    },
};

const mutations = {
    setData(state, payload) {
        state.data = payload.data;
    },
    setSelectedDate(state, payload) {
        state.selectedDate = payload.date;
    },
    setSelectedTime(state, payload) {
        state.selectedTime = payload.time;
    },
    resetData(state) {
        state.selectedDate = null;
        state.selectedTime = null;
        state.data = null;
    },
    setStatus(state, payload) {
        state.status = payload.status;
    },
    applyFilters(state, payload) {
        state.filtersApplied = {
            ...payload,
        };
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};
const actions = {
    filter({ state, commit, getters }, payload) {
        if (state.status === STATUS_LOADING) {
            return Promise.resolve();
        }

        commit('detections/resetState');
        commit('resetData');
        commit('setStatus', { status: STATUS_LOADING });
        commit('applyFilters', payload.filters || {});

        return axios({
            url: getApiUrl({ path: 'hsafety/observations/summary' }),
            params: getters.summaryRequestParameters,
        })
            .then((response) => {
                const dates = summaryDateTimeTransformer(response.data);
                commit('setData', { data: { dates } });
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });

        // const dates = ['2022-07-01', '2022-07-02', '2022-07-03', '2022-07-04', '2022-07-05'];
        // const times = range(0, 24);
        //
        // const getTimes = () => (
        //     times.map((time) => ({
        //         time: `${(`0${time}`).slice(-2)}`, count: random(2),
        //     }))
        // );
        //
        // const datesObject = dates.map((date) => ({
        //     date,
        //     times: getTimes(),
        // }));
        // return new Promise((resolve) => {
        //     setTimeout(() => {
        //         commit('setData', { data: { dates: datesObject } });
        //         commit('setStatus', { status: STATUS_SUCCESS });
        //         resolve();
        //     }, 1000);
        // });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        detections,
    },
};
