export default {
    props: {
        value: [Array, Object, Number, Boolean, String],
    },

    data() {
        return {
            newValue: this.value,
        };
    },

    computed: {
        computedValue: {
            get() {
                return this.newValue;
            },
            set(value) {
                this.newValue = value;
                this.$emit('input', value);
            },
        },
    },

    watch: {
        value(value) {
            this.newValue = value;
        },
    },
};
