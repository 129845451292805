<template>
    <v-layer :data-id="id" ref="layer">
        <v-group v-bind="groupBindings" :key="groupKey" @dragmove="handleGroupDragMove" @dragend="handleGroupDragEnd">
            <v-line
                :key="flattenedPoints.join(':')"
                v-bind="lineBindings"
                @dblclick="handleAddPoint"
                ref="line"
            />
            <v-rect
                v-for="(rectBinding, index) in rectBindings"
                :key="`point-${rectBindings.length}-${index}-${rectsRefreshKey}`"
                v-bind="rectBinding"
                @dragstart="fixCurrentPoints"
                @dragend="handleDragEndPoint"
                @dragmove="handleDragMovePoint"
                @mouseover="handleMouseOverStartPoint(index, $event)"
                @mouseout="handleMouseOutStartPoint(index, $event)"
                @focusin="handleFocusInStartPoint(index, $event)"
                @focusout="handleFocusOutStartPoint(index, $event)"
                @dblclick="handleRemovePoint(index, $event)"
            />
            <v-label v-if="label" ref="label" :config="labelPosition">
                <v-tag :config="labelTagConfig" />
                <v-text :config="labelTextConfig" />
            </v-label>
        </v-group>
    </v-layer>
</template>

<script>
import chunk from 'lodash/chunk';
import { Group } from 'konva/lib/Group';

import { COLORS } from '@/utils/constants';
import { intersectLines } from '@/utils/intersect';
import { normalizeMinMaxNumber } from '@/utils/number';

import noticesMixin from '@/mixins/noticesMixin';

const RECT_SIZE = 9;
const RECT_SIZE_HALF = RECT_SIZE / 2;
const MAX_NODES = 40;
const MIN_NODES = 3;
const LAYER_LABEL_PADDING = 7;
const ERROR_DURATION_MS = 5000;

export default {
    mixins: [noticesMixin],

    props: {
        id: {
            type: String,
            required: true,
        },
        roiPoints: {
            type: Array,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        scale: {
            type: Number,
            required: true,
        },
        mousePosition: {
            type: Array,
            required: true,
        },
        bounds: {
            type: Object,
            required: true,
        },
        size: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            points: this.roiPoints,
            tempPoints: [],
            isMouseOverStartPoint: false,
            groupKey: 'group',
            isFinished: this.roiPoints.length >= MIN_NODES,
            labelPosition: {
                x: 0,
                y: 0,
            },
            labelSize: {
                width: 0,
                height: 0,
            },
            rectsRefreshKey: 0,
        };
    },

    computed: {
        normalizedMousePosition() {
            return this.normalizePoints(this.mousePosition);
        },
        normalizedPoints() {
            const normalizedPoints = this.normalizePoints(this.points.flat());
            const normalizedPointsChunks = chunk(normalizedPoints, 2);
            return normalizedPointsChunks;
        },

        flattenedPoints() {
            return this.normalizedPoints
                .concat(this.isFinished ? [] : this.normalizedMousePosition)
                .reduce((a, b) => a.concat(b), []);
        },

        canAddNodes() {
            return this.points.length < MAX_NODES;
        },

        // x
        xPoints() {
            return this.points.map(([x]) => x);
        },
        minXPoint() {
            return Math.min(...this.xPoints);
        },
        minXPointShift() {
            return (this.minXPoint - RECT_SIZE_HALF) * -1;
        },
        maxXInPoints() {
            return Math.max(...this.xPoints);
        },
        maxXPointShift() {
            return this.size.width - this.maxXInPoints - RECT_SIZE_HALF;
        },
        // y
        yPoints() {
            return this.points.map(([, y]) => y);
        },
        minYPoint() {
            return Math.min(...this.yPoints);
        },
        minYPointShift() {
            return (this.minYPoint - RECT_SIZE_HALF) * -1;
        },
        maxYInPoints() {
            return Math.max(...this.yPoints);
        },
        maxYPointShift() {
            return this.size.height - this.maxYInPoints - RECT_SIZE_HALF;
        },

        strokeColor() {
            if (!this.active) {
                return COLORS.greyLight;
            }

            return this.isIntersectLines ? COLORS.red : COLORS.green;
        },

        lineBindings() {
            return {
                draggable: false,
                points: this.flattenedPoints,
                closed: this.isFinished,
                stroke: this.strokeColor,
                strokeWidth: 4, // this.active ? 4 : 2,
                hitStrokeWidth: 8,
                lineJoin: 'bevel',
                fillEnabled: false,
            };
        },

        groupBindings() {
            return {
                draggable: this.active && this.isFinished,
            };
        },

        rectBindings() {
            return this.normalizedPoints.map(([x, y], index) => ({
                draggable: this.active,
                x,
                y,
                offsetX: RECT_SIZE_HALF,
                offsetY: RECT_SIZE_HALF,
                width: RECT_SIZE,
                height: RECT_SIZE,
                fill: this.active ? COLORS.green : COLORS.greyLight,
                hitStrokeWidth: index === 0 ? RECT_SIZE * 2 : 'auto',
            }));
        },

        isIntersectLines() {
            return intersectLines(this.flattenedPoints, { closed: this.isFinished });
        },

        labelTagConfig() {
            return {
                fill: `${COLORS.white}BB`,
            };
        },

        labelTextConfig() {
            if (!this.isFinished) {
                return {};
            }
            return {
                text: this.label,
                fontFamily: 'replica',
                fontSize: 12,
                padding: 2,
                fill: COLORS.black,
            };
        },
    },

    watch: {
        isFinished: {
            handler: 'emitChange',
            immediate: true,
        },
        points: {
            handler() {
                this.emitChange();

                this.updateLabelPosition();
                this.updateLabelSize();
            },
            deep: true,
        },
        label: {
            handler() {
                this.$nextTick().then(() => {
                    this.updateLabelSize();
                    this.updateLabelPosition();
                });
            },
            immediate: true,
        },
    },

    mounted() {
        this.$root.$on('roi:camera:esc', this.onEscape);
        this.$once('hook:beforeDestroy', () => {
            this.$root.$off('roi:camera:esc', this.onEscape);
        });
    },

    methods: {
        getGroupKey() {
            return `group-${this.flattenedPoints}`;
        },

        onEscape() {
            if (!this.active) {
                return;
            }
            if (this.isFinished || !this.points.length) {
                return;
            }
            this.$delete(this.points, this.points.length - 1);
        },

        handleClick() {
            if (!this.active || this.isFinished) {
                return;
            }
            if (this.isMouseOverStartPoint && this.points.length >= MIN_NODES) {
                this.isFinished = true;
                return;
            }
            if (this.isIntersectLines) {
                this.displayErrorIntersect();
                return;
            }
            if (this.points.length === MAX_NODES) {
                this.displayErrorPoints();
                return;
            }

            this.points = [
                ...this.points,
                this.normalizedMousePosition,
            ];
        },

        handleMouseOverStartPoint(index, event) {
            if (!this.active) {
                return;
            }
            if (index || this.isFinished || this.points.length < 3) {
                return;
            }
            event.target.scale({ x: 2, y: 2 });
            this.isMouseOverStartPoint = true;
        },

        handleMouseOutStartPoint(index, event) {
            if (!this.active) {
                return;
            }
            if (index) {
                return;
            }
            event.target.scale({ x: 1, y: 1 });
            this.isMouseOverStartPoint = false;
        },

        handleFocusInStartPoint(index, event) {
            this.handleMouseOverStartPoint(index, event);
        },

        handleFocusOutStartPoint(index, event) {
            this.handleMouseOutStartPoint(index, event);
        },

        handleDragEndPoint() {
            if (this.isIntersectLines) {
                this.resetToFixedPoints();
                this.refreshRects();
                this.displayErrorSelfIntersect();
            }
            this.clearFixedPoints();
        },

        handleDragMovePoint(event) {
            if (!this.active) {
                return;
            }

            const normalizedPos = this.normalizePoints([event.target.attrs.x, event.target.attrs.y]);
            event.target.x(normalizedPos[0]);
            event.target.y(normalizedPos[1]);

            const index = event.target.index - 1;

            this.points = [...this.points.slice(0, index), normalizedPos, ...this.points.slice(index + 1)];
        },

        handleGroupDragMove(event) {
            // we only care about the group
            if (event.target instanceof Group) {
                if (event.target.attrs.x <= this.minXPointShift) {
                    event.target.x(this.minXPointShift);
                }
                if (event.target.attrs.x >= this.maxXPointShift) {
                    event.target.x(this.maxXPointShift);
                }
                if (event.target.attrs.y <= this.minYPointShift) {
                    event.target.y(this.minYPointShift);
                }
                if (event.target.attrs.y >= this.maxYPointShift) {
                    event.target.y(this.maxYPointShift);
                }
            }
        },

        handleGroupDragEnd(event) {
            // we only care about the group
            if (!(event.target instanceof Group)) {
                return;
            }
            const { x, y } = event.target.attrs;
            this.points = this.points.map((p) => ([
                p[0] + x,
                p[1] + y,
            ]));
            // we can't have a computed key because it will screw up
            // the points drag event as the key will change and redraw
            // will happen
            this.groupKey = this.getGroupKey();
        },

        // is BC inline with AC or visa-versa
        inLine(start, end, point) {
            // ensure we do not divide by 0
            const dx = (point[0] - start[0]) / (end[0] - start[0] + 0.005);
            const dy = (point[1] - start[1]) / (end[1] - start[1] + 0.005);

            // Check on or within x and y bounds
            const betweenX = dx > 0 && dx <= 1;
            const betweenY = dy > 0 && dy <= 1;
            const diff = Math.abs(dx - dy) < 1;

            return diff && betweenX && betweenY;
        },

        handleAddPoint() {
            if (!this.active) {
                return;
            }
            // we can't add more points if the shape is not finished
            if (!this.isFinished) {
                return;
            }

            if (!this.canAddNodes) {
                this.displayErrorPoints();
                return;
            }

            this.fixCurrentPoints();

            let newPointBeforeIndex = null;
            this.points.forEach((point, index) => {
                const nextIndex = this.points[index + 1] ? index + 1 : 0;
                const nextPoint = this.points[nextIndex];
                if (newPointBeforeIndex === null && this.inLine(point, nextPoint, this.normalizedMousePosition)) {
                    newPointBeforeIndex = index;
                }
            });

            if (newPointBeforeIndex !== null) {
                const points = [...this.points];
                points.splice(newPointBeforeIndex + 1, 0, this.normalizedMousePosition);
                this.points = points;
            }

            if (this.isIntersectLines) {
                this.resetToFixedPoints();
                this.displayErrorSelfIntersect();
            }

            this.clearFixedPoints();
        },

        handleRemovePoint(index) {
            if (!this.active) {
                return;
            }
            if (!this.isFinished || this.points.length < 4) {
                return;
            }
            const points = [...this.points];
            points.splice(index, 1);
            this.points = points;
        },

        normalizePoints(points = []) {
            const chunks = chunk(points, 2);
            const maxX = this.size.width - RECT_SIZE_HALF;
            const maxY = this.size.height - RECT_SIZE_HALF;
            const normalize = ([x, y]) => (
                [normalizeMinMaxNumber(x, RECT_SIZE_HALF, maxX), normalizeMinMaxNumber(y, RECT_SIZE_HALF, maxY)]
            );
            const normalizedPoints = chunks.map(normalize).flat();
            return normalizedPoints;
        },

        updateLabelPosition() {
            const x = normalizeMinMaxNumber(
                this.flattenedPoints[0],
                LAYER_LABEL_PADDING,
                this.size.width - this.labelSize.width,
            );
            const y = normalizeMinMaxNumber(
                this.flattenedPoints[1],
                LAYER_LABEL_PADDING,
                this.size.height - this.labelSize.height,
            );
            this.labelPosition.x = x;
            this.labelPosition.y = y;
        },
        updateLabelSize() {
            if (!this.$refs.label) {
                return;
            }
            const { width, height } = this.$refs.label.getNode().getClientRect();
            this.labelSize.width = width;
            this.labelSize.height = height;
        },

        emitChange() {
            this.$emit('change', { points: this.points, id: this.id, isFinished: this.isFinished });
        },

        isValid() {
            return this.isFinished
                && !this.isIntersectLines;
        },

        handleLineMouseEnter(event) {
            const stage = event.target.getStage();
            if (!this.active) {
                stage.container().style.cursor = 'pointer';
            } else {
                stage.container().style.cursor = 'move';
            }
        },

        handleLineMouseLeave(event) {
            const stage = event.target.getStage();
            stage.container().style.cursor = 'default';
        },

        handleRectMouseEnter(event) {
            const stage = event.target.getStage();
            stage.container().style.cursor = 'pointer';
        },
        handleRectMouseLeave(event) {
            const stage = event.target.getStage();
            stage.container().style.cursor = 'default';
        },

        refreshRects() {
            this.rectsRefreshKey += 1;
        },

        fixCurrentPoints() {
            this.tempPoints = this.points;
        },
        resetToFixedPoints() {
            this.points = this.tempPoints;
            this.clearFixedPoints();
        },
        clearFixedPoints() {
            this.tempPoints = [];
        },

        displayErrorSelfIntersect() {
            this.displayErrorNotice({
                message: 'Self-intersecting polygons are not allowed as a region of interest.',
                duration: ERROR_DURATION_MS,
            });
        },
        displayErrorIntersect() {
            this.displayErrorNotice({
                message: 'A region of interest needs to be in the shape of a polygon.',
                duration: ERROR_DURATION_MS,
            });
        },
        displayErrorPoints() {
            this.displayErrorNotice({
                message: 'You have reached the maximum number of points allowed for this region of interest.',
                duration: ERROR_DURATION_MS,
            });
        },
    },
};
</script>
