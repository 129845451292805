<template>
    <manage-popup
        :active="active"
        class="alert-popup"
        :toggle-intercom="false"
        @close="$emit('close')"
    >
        <template #title>
            Send Notification
        </template>
        <template #body>
            <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
            <div class="columns is-multiline">
                <div class="column is-4">
                    <b-field label="Send SMS">
                        <b-switch v-model="notificationSms" />
                    </b-field>
                </div>
                <div class="column is-8">
                    <b-field
                        v-show="notificationSms"
                        label="Send SMS To"
                        expanded
                        :type="$v.toSms.$error ? 'is-danger' : ''"
                        :message="$v.toSms.$error ? validationMessages.toSms : ''"
                    >
                        <b-select v-model="toSms" expanded>
                            <option disabled value="">Select Contact</option>
                            <option value="safety">Site Contact</option>
                            <option value="stms">STMS</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Send Email">
                        <b-switch v-model="notificationEmail" />
                    </b-field>
                </div>
                <div class="column is-8">
                    <b-field
                        v-show="notificationEmail"
                        label="Send Email To"
                        expanded
                        :type="$v.toEmail.$error ? 'is-danger' : ''"
                        :message="$v.toEmail.$error ? validationMessages.toEmail : ''"
                    >
                        <b-select v-model="toEmail" expanded>
                            <option disabled value="">Select Contact</option>
                            <option value="safety">Site Contact</option>
                            <option value="stms">STMS</option>
                        </b-select>
                    </b-field>
                </div>
                <div v-show="notificationEmail" class="column is-4">
                    <div class="alert-popup__cc-label">
                        <span class="mr-3">Send CC</span>
                        <b-tooltip v-if="domain" type="is-dark" position="is-right">
                            <template v-slot:default>
                                <b-icon icon="help-circle-outline" class="has-text-grey-lighter" />
                            </template>
                            <template v-slot:content>
                                Emails can only be sent to<br><u>{{ domain }}</u> domain email addresses.
                            </template>
                        </b-tooltip>
                    </div>
                </div>
                <div v-show="notificationEmail" class="column is-8">
                    <b-field label="" expanded>
                        <b-taginput v-model="toEmailCC" rounded :before-adding="beforeAddEmail" />
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field
                        class="alert-popup__message"
                        expanded
                        :type="$v.message.$error ? 'is-danger' : ''"
                        :message="$v.message.$error ? validationMessages.message : ''"
                    >
                        <template #label>
                            Message Body
                            <button
                                v-if="!isLoading && message !== getDefaultMessage()"
                                type="button"
                                class="alert-popup__message-reset"
                                @click="setDefaultMessage()"
                            >
                                (Reset message)
                            </button>
                        </template>
                        <b-input v-model="message" type="textarea" expanded :maxlength="messageCharacterLimit" />
                    </b-field>
                </div>
            </div>
        </template>
        <template #footer>
            <b-button class="button be-button is-wider is-primary" :loading="isLoading" @click.prevent="send()">
                Send
            </b-button>
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { email, maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import { get } from 'lodash';
import { validationMixin } from 'vuelidate';
import ManagePopup from '@/components/local/sites/ManagePopup';
import noticesMixin from '@/mixins/noticesMixin';
import observationNotificationMixin from '@/mixins/hs/observationNotificationMixin';
import { formatPhone } from '@/utils/phones';

export default {
    mixins: [noticesMixin, observationNotificationMixin, validationMixin],

    components: {
        ManagePopup,
    },

    validations() {
        return {
            toSms: {
                required: requiredIf((nestedModel) => nestedModel.notificationSms),
            },
            toEmail: {
                required: requiredIf((nestedModel) => nestedModel.notificationEmail),
            },
            message: {
                required,
                maxLength: maxLength(this.messageCharacterLimit),
            },
        };
    },

    data() {
        return {
            toSms: '',
            toEmail: '',
            toEmailCC: [],
            notificationSms: true,
            notificationEmail: false,
        };
    },

    computed: {
        safetyEmails() {
            return get(this.site, 'safety_manager.email', []);
        },
        safetyPhones() {
            return get(this.site, 'safety_manager.phone', []);
        },
        stmsEmails() {
            return get(this.site, 'stms.email', []);
        },
        stmsPhones() {
            return get(this.site, 'stms.phone', []);
        },
    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    this.loadCamera()
                        .then(() => this.loadSite())
                        .then(() => this.setDefaultMessage());
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('hs/observations/explorer/detections', ['detectionSendNotification']),

        beforeAddEmail(tag) {
            if (!email(tag)) {
                return false;
            }
            if (!this.domain) {
                return true;
            }

            return tag.includes(`@${this.domain}`);
        },

        send() {
            if (this.isLoading) {
                return;
            }

            const phones = this.toSms
                ? (this.toSms === 'safety' ? this.safetyPhones : this.stmsPhones)
                    .map(formatPhone)
                : [];

            // eslint-disable-next-line no-nested-ternary
            const emails = (this.toEmail
                ? (this.toEmail === 'safety' ? this.safetyEmails : this.stmsEmails)
                : []).concat(this.toEmailCC);

            const payload = {
                id: this.detection.id,
                type: 'notification',
                data: {
                    phones,
                    emails,
                    message: this.message,
                },
            };

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            this.detectionSendNotification(payload)
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Notification successfully sent',
                    });
                    this.$emit('close');
                })
                .catch((err) => {
                    this.displayErrorNotice({
                        message: err.message || 'Cannot send notification. Please reload the page and try again',
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.alert-popup {
    &__cc-label {
        padding-top: 7px;
        display: flex;
        align-items: center;
    }

    &__message {
        position: relative;

        ::v-deep textarea {
            resize: none;
        }

        ::v-deep .counter {
            color: $grey-lighter;

            &.is-invisible {
                visibility: visible !important;
            }
        }

        &-reset {
            @include button-reset;
            text-decoration: underline;
            font-size: .75em;
            color: $grey-lighter;
            display: inline-flex;
            margin-left: 5px;
        }
    }
}
</style>
