import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { errorHandler } from '@/utils/errors';

const getDefaultState = () => ({
    objectClasses: [],
    status: '',
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
};

const mutations = {
    setObjectClasses(state, { objectClasses }) {
        state.objectClasses = Object.keys(objectClasses)
            .map((handle) => ({
                handle,
                label: objectClasses[handle],
            }));
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    loadObjectClasses({ commit }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            url: getApiUrl({ path: 'hsafety/explorer/object_classes' }),
        })
            .then((response) => {
                commit('setObjectClasses', { objectClasses: response.data });
                commit('setStatus', { status: STATUS_SUCCESS });
                return true;
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
