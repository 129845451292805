import images from './images';
import videos from './videos';

const state = {
    preloadDetectionImages: true,
};
const getters = {};
const mutations = {
    setPreloadDetectionImages(state, preload) {
        state.preloadDetectionImages = preload || false;
    },
};
const actions = {
    resetState({ commit }) {
        commit('images/resetState');
        commit('videos/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        images,
        videos,
    },
};
