import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { APP_MODE } from '@/utils/constants';

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN || '';

if (SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: SENTRY_DSN,
        environment: APP_MODE,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
}
