import axios from 'axios';
import { downloadFileFromApiResponse, getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { buildUrlParams } from '@/utils/store-helpers';
import { DEFAULT_PAGINATION_PER_PAGE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';

const getDefaultState = () => ({
    lists: [],
    allLists: [],

    listsCount: 0,

    status: '',
    currentPage: 1,
    perPage: DEFAULT_PAGINATION_PER_PAGE,
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === 'loading',
    isSuccess: (state) => state.status === 'success',
};

const mutations = {
    setData(state, { data }) {
        state.data = data;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setPage(state, { page }) {
        state.currentPage = page;
    },
    setPerPage(state, { perPage }) {
        state.perPage = perPage;
    },

    listsSuccess(state, { data }) {
        state.status = STATUS_SUCCESS;
        if (data.results) {
            state.lists = data.results;
        }
        if (data.count) {
            state.listsCount = data.count;
        }
    },
    allListsSuccess(state, { data }) {
        state.status = STATUS_SUCCESS;
        state.allLists = data || [];
    },

};

const actions = {
    getLists({ commit, state }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = buildUrlParams({
            perPage: state.perPage,
            page: state.currentPage,
        });

        const path = 'alert_list';

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('listsSuccess', { data: response.data });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    getAllLists({ commit, state }, { force = null }) {
        if (force === null && state.allLists.length) {
            return Promise.resolve(true);
        }
        const path = 'alert_list/unpaginated';

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
        })
            .then((response) => {
                commit('allListsSuccess', { data: response.data });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    getList({ commit }, { listId }) {
        const path = `alert_list/${listId}`;

        return axios({
            url: getApiUrl({ path }),
        })
            .then(() => {
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    uploadList({ commit, dispatch }, { file, description }) {
        const path = 'alert_list';

        const formData = new FormData();
        formData.append('description', description);
        formData.append('csv', file);

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
        })
            .then(() => {
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .then(() => dispatch('getLists', {}))
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                throw new Error(err.message);
            });
    },

    updateList({ commit, dispatch }, { listId, file, description }) {
        const path = `alert_list/${listId}`;

        const formData = new FormData();
        if (file) {
            formData.append('csv', file);
        }
        if (description) {
            formData.append('description', description);
        }

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'PATCH',
        })
            .then(() => {
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .then(() => dispatch('getLists', {}))
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                throw new Error(err.message);
            });
    },

    deleteList({ commit, dispatch }, { listId }) {
        const path = `alert_list/${listId}`;

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
            method: 'DELETE',
        })
            .then(() => {
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .then(() => dispatch('getLists', {}))
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                throw new Error(err.message);
            });
    },

    downloadList({ commit }, { listId, filename }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            url: getApiUrl({ path: `alert_list/download/${listId}` }),
            responseType: 'blob',
        })
            .then((response) => {
                commit('setStatus', { status: STATUS_SUCCESS });
                downloadFileFromApiResponse({ response, filename });
            }).catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
                throw new Error('Cannot download list');
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
