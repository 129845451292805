<template>
    <div class="card is-rounded site-item">
        <b-loading :is-full-page="false" :can-cancel="false" :active="isSiteLoading" />
        <div class="site-item__inner">
            <div class="site-item__content">
                <header>
                    <h4 class="title-widget mb-1">
                        {{ item.name | truncate(30) }}
                    </h4>
                    <div class="label-sub-text" v-text="item.address" />
                    <be-camera-status class="mt-3" v-if="status" :status="status" />
                </header>
                <div class="site-item__more" v-if="showMore">
                    <hr>
                    <camera-last-detection :camera="lastDetectionCamera" class="mb-3" :display-label="true" />
                    <be-avg-confidence :confidence="confidence" class="mb-3" />
                    <be-info label="Tags" class="my-3">
                        <b-taglist class="has-cursor-default">
                            <b-tag
                                v-for="(tag, index) in site.tags"
                                :key="`tag${index}`"
                            >
                                {{ tag.label }}
                            </b-tag>
                        </b-taglist>
                    </be-info>

                    <be-info>
                        <b-taglist attached class="has-cursor-default">
                            <b-tag>
                                Cameras
                            </b-tag>
                            <b-tag
                                type="is-primary"
                            >
                                {{ cameras.length }}
                            </b-tag>
                        </b-taglist>
                    </be-info>
                </div>
            </div>
            <div class="site-item__footer">
                <hr>
                <b-button
                    tag="router-link"
                    :to="`/dashboard/sites/${item.id}`"
                    class="be-button is-secondary"
                    :expanded="true"
                    :type="showMore ? 'is-primary' : 'is-secondary'"
                >
                    View site
                </b-button>
            </div>
            <div class="site-item__toggle mt-3 has-text-centered">
                <a href="#" @click.prevent="toggleMore" class="be-toggle-link">
                    <span v-text="showMore ? 'Hide more' : 'Show more'" />
                    <b-icon :icon="showMore ? 'chevron-up' : 'chevron-down'" custom-size="mdi-18px" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import siteCardMixin from '@/mixins/siteCardMixin';
import BeCameraStatus from '@/components/global/BeCameraStatus';
import BeAvgConfidence from '@/components/global/BeAvgConfidence';
import BeInfo from '@/components/global/BeInfo';
import CameraLastDetection from './partials/CameraLastDetection';

export default {
    components: {
        CameraLastDetection,
        BeInfo,
        BeAvgConfidence,
        BeCameraStatus,
    },

    /* eslint-disable vue/no-potential-component-option-typo */
    eq: {
        breakpoints: {
            small: { maxWidth: 249 },
            large: { minWidth: 250 },
        },
    },
    /* eslint-enable vue/no-potential-component-option-typo */

    mixins: [siteCardMixin],

    mounted() {
        this.loadSite();
    },
};
</script>

<style lang="scss" scoped>
.site-item {
    min-height: 140px;
    display: flex;
    align-items: stretch;
    position: relative;

    &__inner {
        padding: 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex: 1;
    }
}
</style>
