<template>
    <div class="be-camera-status has-cursor-default">
        <b-tag
            rounded
            :class="['label-status px-3', `is-status-${status}`]"
        >
            {{ statusLabel }}
        </b-tag>
    </div>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },

    computed: {
        statusLabel() {
            if (this.status === 'pending') {
                return 'Pending Setup';
            }
            if (this.status === 'warning') {
                return 'Warning';
            }
            if (this.status === 'online') {
                return 'Online';
            }

            return '';
        },
    },
};
</script>
