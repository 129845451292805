import { isEmpty } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import BeRequestErrorMessage from '@/components/global/BeRequestErrorMessage';
import ManagePopup from '@/components/local/sites/ManagePopup';
import { CAMERA_VALIDATION_MESSAGES, getDefaultCameraState } from '@/utils/sites/cameras';
import { CAMERA_COUNTRIES, CAMERA_LOCATIONS } from '@/utils/sites/constants';

export default {
    components: {
        BeRequestErrorMessage,
        ManagePopup,
    },

    validations: {
        cameraModel: {
            name: {
                required,
            },
            event_frequency: {
                required,
            },
            country: {
                required,
            },
        },
    },

    props: {
        active: {
            type: Boolean,
            required: true,
        },
        camera: {
            type: Object,
            default: null,
        },
        site: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isUpdating: false,
            step: 1,
            cameraModel: getDefaultCameraState(this.camera),
            requestErrorMessage: null,
            validationMessages: CAMERA_VALIDATION_MESSAGES,
            countries: CAMERA_COUNTRIES,
            locations: CAMERA_LOCATIONS,
            closeConfirmationMessage: 'Are you sure you want to exit without saving?',
        };
    },

    computed: {
        isEdit() {
            return this.camera && this.camera.id;
        },
        isNew() {
            return !this.isEdit;
        },
        isMobile() {
            return this.$bus.isMobile;
        },
        isEmpty() {
            return isEmpty(this.cameraModel);
        },
    },

    methods: {
        maybeClose() {
            if (this.isLoading) {
                return;
            }

            if (this.isEmpty) {
                this.close();
                return;
            }

            this.$buefy.dialog.confirm({
                message: this.closeConfirmationMessage,
                onConfirm: () => this.close(),
            });
        },

        close() {
            this.requestErrorMessage = null;
            this.step = 1;
            if (this.$v) {
                this.$v.$reset();
            }
            this.$emit('close');
            this.$nextTick(() => {
                this.cameraModel = getDefaultCameraState();
            });
        },
    },
};
