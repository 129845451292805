<template>
    <div
        class="card is-rounded observation-group-item"
        @click="$emit('view', group)"
        @keydown.enter="$emit('view', group)"
        tabindex="0"
    >
        <div class="observation-group-item__inner">
            <figure class="observation-group-item__img mb-3">
                <template v-if="video">
                    <img v-if="video.poster_image" :src="video.poster_image" alt="">
                    <video v-else width="360">
                        <source :src="`${video.display_video_file}#t=0.1`" type="video/mp4" />
                    </video>
                </template>
            </figure>
            <footer class="observation-group-item__footer">
                <div class="observation-group-item__footer-time">
                    <span>{{ date }}</span>
                </div>
                <div class="observation-group-item__footer-count">
                    <span>{{ count ? count : 'no' }} related clip{{ count === 1 ? '' : 's' }}</span>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { get, head } from 'lodash';
import { mapActions } from 'vuex';
import { DATETIME_FORMATTED_FORMAT } from '@/utils/formatters/datetime';

export default {
    props: {
        group: {
            type: Object,
            required: true,
        },
    },

    computed: {
        image() {
            return null;
        },
        videoId() {
            return get(this.first, 'video');
        },
        video() {
            return this.videoId
                ? (this.$store.state.media.videos.data[this.videoId] || null)
                : null;
        },

        first() {
            return head(this.group.cluster) || null;
        },

        count() {
            return this.group.cluster && this.group.cluster.length
                ? this.group.cluster.length
                : 0;
        },

        date() {
            return this.$date(
                get(this.first, 'video_time_created'),
            ).format(DATETIME_FORMATTED_FORMAT);
        },
        // from() {
        //     return this.$date(
        //         get(this.group, 'from'),
        //     ).format(TIME_FORMAT);
        // },
        // to() {
        //     return this.$date(
        //         get(this.group, 'to'),
        //     ).format(TIME_FORMAT);
        // },
    },

    mounted() {
        this.loadDataRow({ id: this.videoId, isPreload: true });
    },

    methods: {
        ...mapActions('media/videos', ['loadDataRow']),
    },
};
</script>

<style lang="scss" scoped>
.observation-group-item {
    cursor: pointer;

    &__inner {
        padding: 1rem;
    }

    &__img {
        display: block;
        height: 0;
        padding-top: 66%;
        position: relative;
        background: $dark;

        img,
        video {
            @include overlay;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &__footer {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 32px;
        align-items: center;
        font-size: 13px;

        &-time {
            text-align: left;
        }

        &-count {
            text-align: right;
            font-weight: $weight-bold;
        }
    }
}
</style>
