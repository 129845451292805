<template>
    <be-widget class="chart">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:content>
            <div class="chart">
                <line-chart
                    class="chart__wrapper"
                    :chart-data="lineChartData"
                    :options="barChartOptions"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import { VEHICLE_RISK_METRICS } from '@/utils/sites/cameras';
import {
    DATETIME_FORMAT_SEC,
    DATE_SIMPLE_FORMATTED_FORMAT,
    DATETIME_SIMPLE_FORMATTED_FORMAT,
} from '@/utils/formatters/datetime';
import LineChart from '@/utils/chartjs/LineChart';
import { COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({ labels: [], datasets: [] });

export default {
    components: {
        LineChart,
    },

    data() {
        return {
            lineChartData: getChartDataDefaults(),
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        boxWidth: 10,
                        padding: 10,
                        usePointStyle: true,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Risk distribution for period',
                            },
                            ticks: {
                                callback: (value) => `${value}`,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                suggestedMin: 0,
                                stepSize: 10,
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Number of events',
                            },
                        },
                    ],
                },
            },
        };
    },

    computed: {
        ...mapGetters('vehicle/observations/observationFilters', ['getDateRange']),
        ...mapGetters('vehicle/observations/summary', [
            'isLoading',
            'getData',
            'getAppliedFilters',
        ]),

        violationTypes() {
            return VEHICLE_RISK_METRICS.filter(
                (type) => this.getAppliedFilters.observation.metricType.map((x) => x.handle).includes(type.handle),
            ).map((type) => ({
                ...type,
                color: this.getViolationTypeColor(type),
            }));
        },

        widgetTitle() {
            return 'Overall parking metrics for period';
        },

        apiResults() {
            return this.violationTypes.map((type) => this.generateViolationResults(type));
        },

        displayDates() {
            const startDate = this.$date(this.getDateRange.from);
            const endDate = this.$date(this.getDateRange.to);

            const datetimeArray = [
                {
                    datetime: startDate.format(DATETIME_FORMAT_SEC),
                    label: startDate.format(DATETIME_SIMPLE_FORMATTED_FORMAT),
                },
            ];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            const diffHour = Math.floor(endDate.diff(startDate, 'hour', true));
            if (diff < 2) {
                for (let i = 0; i < diffHour; i++) {
                    const datetime = startDate.add(i + 1, 'hour');
                    datetimeArray.push({
                        datetime: datetime.format(DATETIME_FORMAT_SEC),
                        label: datetime.format(DATETIME_SIMPLE_FORMATTED_FORMAT),
                    });
                }
            } else {
                for (let i = 0; i < diff; i++) {
                    const datetime = startDate.add(i + 1, 'day');
                    datetimeArray.push({
                        datetime: datetime.format(DATETIME_FORMAT_SEC),
                        label: datetime.format(DATE_SIMPLE_FORMATTED_FORMAT),
                    });
                }
            }
            return datetimeArray;
        },
    },

    watch: {
        getData: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },

    methods: {
        getViolationTypeColor({ type }) {
            switch (type) {
                case 0:
                    return COLORS.red;
                case 1:
                    return COLORS.blue;
                case 2:
                    return COLORS.yellow;
                case 3:
                    return COLORS.green;
                case 4:
                    return COLORS.pink;
                case 5:
                    return COLORS.lightblue;
                case 6:
                    return COLORS.purple;
                case 7:
                    return COLORS.white;
                default:
                    return COLORS.greyLight;
            }
        },

        generateViolationResults(type) {
            const data = JSON.parse(JSON.stringify(this.getData));
            let violationData = data?.find((x) => x.metricType === type.handle).data;

            const results = {
                label: type.label,
                borderColor: type.color,
                backgroundColor: type.color,
                fill: false,
                tension: 0.4,
                cubicInterpolationMode: 'monotone',
                data:
                    this.displayDates?.map((e) => {
                        let count = 0;
                        if (violationData === undefined) {
                            return [];
                        }

                        violationData = violationData?.filter((row) => {
                            if (!row.hour) {
                                const [rowDate, rowTimeWithOffset] = row.violation_hour.split('T');
                                const rowTime = rowTimeWithOffset.split('+')[0];
                                if (
                                    `${rowDate} ${rowTime}` === e.datetime
                                ) {
                                    count += row.violation_count;
                                    return false;
                                }
                                return true;
                            }
                            const [rowDate, rowTimeWithOffset] = row.hour.split('T');
                            const rowTime = rowTimeWithOffset.split('+')[0];
                            if (
                                `${rowDate} ${rowTime}` === e.datetime
                            ) {
                                count += row.count;
                                return false;
                            }
                            return true;
                        }) || [];

                        if (type.handle === 'PeakDwellTime' || type.handle === 'AverageDwellTime') {
                            count = Math.round((count / 60) * 10) / 10;
                        }

                        return count;
                    }),
            };
            return results;
        },

        canPopulateChart() {
            return (
                this.apiResults
                && this.getDateRange.from
                && this.getDateRange.to
            );
        },

        getChartData() {
            if (!this.apiResults) {
                return {};
            }

            return {
                labels: this.displayDates.map((d) => d.label),
                datasets: this.apiResults,
            };
        },

        updateChartData() {
            // to overcome the problem of non-reactive deep updates
            this.resetChartData();
            if (!this.canPopulateChart()) {
                return;
            }
            this.$nextTick(() => {
                this.lineChartData = this.getChartData();
            });
        },

        resetChartData() {
            this.lineChartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
        margin-top: auto;
    }
}
</style>
