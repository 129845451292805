<template>
    <be-widget class="list-table-widget" :has-padding="true" :is-loading="isLoading" :has-table="true">
        <template v-slot:content>
            <b-table
                :data="tableData"
                :hoverable="true"
                class="list-table-widget__table is-seamless"
                :row-class="(row) => row.built_in ? 'is-builtin' : ''"
            >
                <b-table-column label="Description" v-slot="props" cell-class="has-cursor-default">
                    <template v-if="props.row.built_in">
                        <i>
                            {{ props.row.description }}
                            (Built-in)
                        </i>
                    </template>
                    <template v-else>
                        {{ props.row.description }}
                    </template>
                </b-table-column>
                <b-table-column label="Updated on" width="150" v-slot="props">
                    <span class="has-cursor-default">
                        {{ props.row.date_updated | dateTimeHuman }}
                    </span>
                </b-table-column>
                <b-table-column label=" " width="180" v-slot="props">
                    <div class="buttons" v-if="!props.row.built_in">
                        <button type="button" class="button" @click="editList = props.row">
                            <b-icon icon="cloud-upload" custom-size="mdi-18px" type="is-primary" />
                        </button>
                        <button type="button" class="button" @click="download(props.row)">
                            <b-icon icon="cloud-download" custom-size="mdi-18px" type="is-success" />
                        </button>
                        <button type="button" class="button" @click="remove(props.row)">
                            <b-icon icon="close-circle" custom-size="mdi-18px" type="is-danger" />
                        </button>
                    </div>
                </b-table-column>
            </b-table>
            <be-pagination
                key="alerts"
                :current-page.sync="currentPage"
                :per-page.sync="perPage"
                :count="listsCount"
            />
        </template>
        <list-re-upload-popup
            :active="!!editList"
            :list="editList || {}"
            @close="editList = null"
        />
    </be-widget>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BePagination from '@/components/global/BePagination';
import ListReUploadPopup from '@/components/local/alerts/ListReUploadPopup';
import formattersMixin from '@/mixins/formattersMixin';

export default {
    components: {
        ListReUploadPopup,
        BePagination,
    },

    mixins: [formattersMixin],

    data() {
        return {
            editList: null,
        };
    },

    computed: {
        ...mapState('alerts/lists', ['lists', 'listsCount']),
        ...mapGetters('alerts/lists', ['isLoading']),

        tableData() {
            return this.lists || [];
        },

        perPage: {
            get() {
                return this.$store.state.alerts.lists.perPage;
            },
            set(perPage) {
                return this.setPerPage({ perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.alerts.lists.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.setPage({ page });
            },
        },
    },

    watch: {
        currentPage() {
            this.getLists({});
        },
        perPage() {
            this.getLists({});
        },
    },

    methods: {
        ...mapActions('alerts/lists', ['getLists', 'deleteList', 'downloadList']),
        ...mapMutations('alerts/lists', ['setPerPage', 'setPage']),

        remove(list) {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this list?',
                type: 'is-danger',
                onConfirm: () => {
                    this.deleteList({ listId: list.id });
                },
            });
        },

        download(list) {
            this.downloadList({
                listId: list.id,
                filename: `${list.description}.csv`,
            });
        },
    },

    mounted() {
        this.getLists({});
    },
};
</script>

<style lang="scss" scoped>
.list-table-widget {
    &__table {
        ::v-deep .is-builtin {
            background: lighten($grey-dark, 3);
            color: $white;
        }
    }
}
</style>
