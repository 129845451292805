<template>
    <div class="be-page-header" :class="{ 'is-abs': isAbs, 'app-container': hasAppContainer }">
        <div class="be-page-header__inner">
            <div class="be-page-header__main">
                <component :is="titleTag" class="be-page-header__heading">
                    <template v-if="!!$slots.back">
                        <span class="be-page-header__heading__back">
                            <slot name="back" />
                        </span>
                    </template>
                    <span class="be-page-header__heading__text" :class="`is-${titleTag}`">
                        <slot />
                    </span>
                    <template v-if="!!$slots.headingCta">
                        <span class="be-page-header__heading__cta">
                            <slot name="headingCta" />
                        </span>
                    </template>
                </component>
                <template v-if="!!$slots.subheading">
                    <p class="be-page-header__subheading">
                        <slot name="subheading" />
                    </p>
                </template>
            </div>
            <template v-if="!!$slots.cta">
                <div class="be-page-header__aside">
                    <slot name="cta" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BePageHeader',

    props: {
        titleTag: {
            type: String,
            default: 'h2',
        },
        isAbs: {
            type: Boolean,
            default: false,
        },
        appContainer: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        hasAppContainer() {
            return this.appContainer || this.isAbs;
        },
    },
};
</script>

<style lang="scss" scoped>
.be-page-header {
    margin-bottom: 2rem;

    &.is-abs {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        @include z-index(header);
    }

    &__inner {
        display: flex;
        align-items: center;
    }

    &__main {
        flex: 1;
    }

    &__aside {
        flex: 0 0 auto;
        margin-left: 20px;
    }

    &__heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__back {
            margin-right: 1rem;
        }

        &__text {
            @include page-heading;
        }

        &__cta {
            margin-left: 1rem;
        }
    }

    &__subheading {
        color: $text-accent;
        letter-spacing: 0.01em;
        font-size: 14px;
    }
}
</style>
