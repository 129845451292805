<template>
    <div class="eye-alerts">
        <div class="app-container">
            <be-page-header class="mb-5" :app-container="false">
                <template v-slot>
                    Alerts
                </template>
                <template v-slot:subheading>
                    Manage and add alerts
                </template>
                <template v-slot:headingCta>
                    <b-button
                        tag="router-link"
                        to="alerts/new"
                        type="is-primary"
                        class="be-button is-small"
                        rounded
                        icon-left="plus"
                    >
                        Add Alert
                    </b-button>
                </template>
            </be-page-header>
            <enterprise-banner v-if="$store.getters['user/displayEnterpriseBanner']" />
            <div class="columns is-multiline">
                <div class="column is-12">
                    <alerts-table-widget />
                </div>
            </div>
            <be-page-header class="mb-5 mt-6" :app-container="false">
                <template v-slot>
                    Filter Lists
                </template>
                <template v-slot:subheading>
                    Manage and add custom lists to use for alerting
                </template>
                <template v-slot:headingCta>
                    <b-button
                        type="is-primary"
                        class="be-button is-small"
                        rounded
                        icon-left="plus"
                        @click="toggleUploadListPopup"
                    >
                        Upload List
                    </b-button>
                </template>
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-12">
                    <lists-table-widget />
                </div>
            </div>

            <div v-if="isUserAdmin" class="mt-6">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <message-history />
                    </div>
                </div>
            </div>

            <list-upload-popup
                :active="uploadListActive"
                @close="toggleUploadListPopup"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageHistory from '@/components/widgets/MessageHistory';
import AlertsTableWidget from '@/components/local/alerts/AlertsTableWidget';
import ListsTableWidget from '@/components/local/alerts/ListsTableWidget';
import ListUploadPopup from '@/components/local/alerts/ListUploadPopup';
import EnterpriseBanner from '@/components/local/informational/EnterpriseBanner';

export default {
    components: {
        EnterpriseBanner,
        ListsTableWidget,
        ListUploadPopup,
        AlertsTableWidget,
        MessageHistory,
    },

    data() {
        return {
            uploadListActive: false,
        };
    },
    computed: {
        ...mapGetters('user', ['userDetails']),

        isUserAdmin() {
            return this.userDetails && this.userDetails.role === 'ADMIN';
        },
    },

    methods: {
        toggleUploadListPopup() {
            this.uploadListActive = !this.uploadListActive;
        },
    },
};
</script>
