<template>
    <div class="fields-single">
        <div class="app-container" v-if="form && !isError">
            <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
            <be-page-header :app-container="false">
                <template v-slot:default>
                    {{ pageHeading }}
                </template>
                <template v-slot:back>
                    <b-button
                        tag="router-link"
                        type="is-light"
                        to="/dashboard/user-management"
                        class="be-button is-back"
                        icon-left="chevron-left"
                    />
                </template>
            </be-page-header>
            <div class="fields-single__form">
                <div class="columns is-multiline">
                    <div class="column is-6">
                        <b-field
                            label="First Name"
                            :type="fieldType('firstName')"
                            :message="fieldMessage('firstName')"
                        >
                            <b-input v-model="form.firstName" :disabled="true" />
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field
                            label="Last Name"
                            :type="fieldType('lastName')"
                            :message="fieldMessage('lastName')"
                        >
                            <b-input v-model="form.lastName" :disabled="true" />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field
                            label="Email"
                            :type="fieldType('email')"
                            :message="fieldMessage('email')"
                        >
                            <b-input v-model="form.email" :disabled="true" />
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-field
                            label="Sites"
                            :type="fieldType('sites')"
                            :message="fieldMessage('sites')"
                        >
                            <multiselect
                                class="multiselect"
                                v-model="form.sites"
                                :options="sites"
                                label="name"
                                placeholder="Select Sites"
                                track-by="id"
                                :multiple="true"
                            />
                        </b-field>

                    </div>
                    <div class="column is-12">
                        <b-field
                            label="Role"
                            :type="fieldType('role')"
                            :message="fieldMessage('role')"
                        >
                            <b-select v-model="form.role" expanded>
                                <option
                                    v-for="action in roleList"
                                    :key="`action${action.value}`"
                                    :value="action.value"
                                >
                                    {{ action.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                <hr />
                <div class="columns fields-single__actions">
                    <div class="column is-6">
                        <b-button
                            class="be-button is-primary"
                            expanded
                            @click="save"
                            :loading="isSaving"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
                <template v-if="!isNew">
                    <hr />
                    <div class="columns">
                        <div class="column is-12 has-text-right">
                            <a href="#" class="has-text-danger" @click.prevent="remove">
                                <small>Remove User</small>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-else-if="isError" class="app-container">
            <div class="py-5 content has-text-grey-lighter has-text-centered">
                <p>Invalid Form</p>
                <p>
                    <b-button
                        tag="router-link"
                        to="/dashboard/user-management"
                        class="be-button is-outline is-wider"
                    >
                        Back
                    </b-button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import noticesMixin from '@/mixins/noticesMixin';
import { getApiUrl } from '@/utils/api';

const ROLES = [
    {
        value: 'ADMIN',
        label: 'Admin',
    },
    {
        value: 'SITE_VIEWER',
        label: 'Site Viewer',
    },
    {
        value: 'VIEWER',
        label: 'Viewer',
    },
];

const getDefaultValues = () => ({
    firstName: '',
    lastName: '',
    role: null,
    sites: [],
});

export default {
    components: {
        Multiselect,
    },

    mixins: [
        validationMixin,
        noticesMixin,
    ],

    validations() {
        const form = {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            role: { required },
            sites: {},
        };

        return { form };
    },

    data() {
        return {
            form: getDefaultValues(),
            siteList: [],
            roleList: ROLES,
            isReady: false,
            isSaving: false,
            isRemoving: false,
            isError: false,

            validationMessages: {
                action: 'Please select an form action',
                firstName: 'Please enter first name',
                lastName: 'Please enter last name',
                role: 'Please select a role',
                email: 'Please enter a (valid) email',
            },
            infoMessages: {
            },
        };
    },

    computed: {

        sites() {
            return this.siteList.map((site) => ({
                name: site.name,
                id: site.id,
            }));
        },

        isLoading() {
            return !this.isReady || this.isSaving || this.isRemoving || this.isError;
        },

        canAction() {
            return !this.isSaving && !this.isRemoving;
        },

        formId() {
            return this.$route.params.guid;
        },

        isNew() {
            return this.$route.params.guid === 'create';
        },

        pageHeading() {
            return this.isNew ? 'Create New User' : 'Edit User';
        },

        apiUrl() {
            return getApiUrl({ path: `organisation/members${this.isNew ? '' : `/${this.formId}`}` });
        },

    },

    mounted() {
        const promises = [this.getSites()];

        if (!this.isNew) {
            promises.push(this.getModelData()
                .then((result) => {
                    this.form = result;
                })
                .catch(() => {
                    this.isError = true;
                }));
        }

        Promise.all(promises)
            .then(() => {
                this.isReady = true;
            });
    },

    methods: {
        async getSites() {
            const response = await this.$http(getApiUrl({ path: 'sites/?page=1&page_length=1000&' }));
            this.siteList = response?.data?.results || [];
        },

        async getModelData() {
            const data = (await this.$http(this.apiUrl))?.data || {};

            const result = {
                firstName: data?.first_name || '',
                lastName: data?.last_name || '',
                email: data?.email || '',
                role: data?.role || '',
                sites: data?.sites || [],
            };

            return result;
        },

        getSaveData() {
            const data = {
                first_name: this.form.firstName,
                last_name: this.form.lastName,
                email: this.form.email,
                role: this.form.role,
                sites: this.form.sites.map((site) => site.id),
            };

            return data;
        },

        save() {
            if (!this.canAction) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isSaving = true;
            this.closeNotices();

            const data = this.getSaveData();

            const promise = this.isNew
                ? this.createUser(data)
                : this.updateUser(data);

            promise.then(() => {
                this.displaySuccessNotice({
                    message: this.isNew
                        ? 'User added successfully'
                        : 'User updated successfully',
                    closeOthers: true,
                });
                this.$router.push('/dashboard/user-management');
            }).catch(() => {
                this.displayErrorNotice({
                    message: this.isNew
                        ? 'Cannot save form'
                        : 'Cannot update form',
                });
            }).finally(() => {
                this.isSaving = false;
            });
        },

        createUser(payload) {
            return this.$http.post(this.apiUrl, payload);
        },

        updateUser(payload) {
            return this.$http.put(this.apiUrl, payload);
        },

        deleteUser() {
            return this.$http.delete(this.apiUrl);
        },

        remove() {
            if (!this.canAction) {
                return;
            }

            this.closeNotices();
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this user?',
                type: 'is-danger',
                trapFocus: true,
                onConfirm: () => {
                    this.isRemoving = true;
                    this.removeUser()
                        .then(() => {
                            this.displaySuccessNotice({
                                message: 'User removed successfully',
                            });
                            this.$emit('close');
                            this.$router.push('/dashboard/user-management');
                        })
                        .finally(() => {
                            this.isRemoving = false;
                        });
                },
            });
        },

        fieldType(field) {
            return this.$v.form[field].$error ? 'is-danger' : '';
        },

        fieldMessage(field) {
            if (this.$v.form[field].$error && this.validationMessages[field]) {
                return this.validationMessages[field];
            }

            return this.infoMessages[field]
                ? this.infoMessages[field]
                : '';
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.fields-single {
    .app-container {
        position: relative;
    }

    &__form {
        width: 100%;
        max-width: 500px;
    }

    &__actions {
        align-items: center;
        justify-content: end;

        ::v-deep .b-checkbox {
            display: flex;
        }
    }

    ::v-deep .field {
        &.form-list.is-grouped {
            @include mobile {
                display: block;
                .field {
                    margin-right: 0;
                    margin-bottom: .5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            @include tablet {
                .field.has-checkbox {
                    width: 190px;
                    flex: 0 0 190px;
                }
            }
        }

        &.has-checkbox {
            display: flex;
            align-items: center;

            .checkbox {
                margin-right: 0;
            }
        }
    }

    ::v-deep .tooltip-trigger {
        line-height: 1;

        > .icon {
            margin-top: 8px;
        }
    }
}

.multiselect {
    margin-top: 5px;
    min-width: 300px;
    ::v-deep .multiselect__tags {
        background-color: #37404C !important;
        border-color: #37404C;
    }
    ::v-deep .multiselect__input{
        background-color: #37404C  !important;
    }
}
</style>
