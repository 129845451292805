<template>
    <div class="hs-explorer">
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Health & Safety Observations Explorer
                </template>
            </be-page-header>
            <h-s-observations-filters
                simple
                :summary="filtersSummary"
                :is-loading="isLoading"
                :is-multi-select="true"
                @filter="onFilter"
            />
            <div class="hs-explorer__content">
                <h-s-observations-explorer />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import HSObservationsFilters from '@/components/local/hs/observations/HSObservationsFilters';
import HSObservationsExplorer from '@/components/local/hs/observations/HSObservationsExplorer';

export default {
    components: {
        HSObservationsExplorer,
        HSObservationsFilters,
    },

    mounted() {
        this.resetState();
        // this.resetFilters();
    },

    computed: {
        ...mapGetters('hs/observations/explorer', ['isLoading', 'filtersSummary']),
    },

    methods: {
        ...mapActions('hs/observations/explorer', ['filter']),
        ...mapActions('hs/observations', ['resetFilters']),
        ...mapMutations('hs/observations/explorer', ['resetState']),

        onFilter(payload) {
            this.filter(payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.hs-explorer {
    &__content {
        margin-top: 1.5rem;
    }
}
</style>
