import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { platesTransformer } from '@/utils/transformers/plates';

const state = getDefaultDataState({ hasPagination: false });

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),
    formattedApiResults: (state) => platesTransformer(state.data),
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
};

const actions = {
    loadData({ commit, rootState, rootGetters, state }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const path = buildStartEndDateUrl({
            path: 'explorer/plates/mostread',
            startDate,
            endDate,
        });

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        return axios({ url: getApiUrl({ path }), params })
            .then((response) => {
                commit('setStatus', { status: 'success' });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
