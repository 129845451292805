import { mapActions, mapGetters } from 'vuex';
import { find } from 'lodash';
import EnterpriseBanner from '@/components/local/informational/EnterpriseBanner';

export default {
    components: {
        EnterpriseBanner,
    },

    props: {
        app: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters('marketplace', ['allApps']),

        heading() {
            const app = find(this.allApps, { slug: this.app });

            if (!app) {
                return 'App Settings';
            }

            return `${app.label} Settings`;
        },
    },

    methods: {
        ...mapActions('marketplace/user', ['toggleApp']),
    },
};
