/* eslint-disable no-nested-ternary, no-restricted-properties */

const truncateN = (n) => (n > 0 ? Math.floor(n) : Math.ceil(n));

export function getDms(dd, longOrLat, format = true) {
    const hemisphere = /^[WE]|(?:lng)/i.test(longOrLat)
        ? dd < 0
            ? 'W'
            : 'E'
        : dd < 0
            ? 'S'
            : 'N';

    const absDD = Math.abs(dd);
    const degrees = truncateN(absDD);
    const minutes = truncateN((absDD - degrees) * 60);
    const seconds = ((absDD - degrees - minutes / 60) * 60 ** 2).toFixed(2);

    const dms = {
        degrees, minutes, seconds, hemisphere,
    };

    if (!format) {
        return dms;
    }

    let str = `${dms.degrees}°`;
    if (dms.minutes) {
        str += ` ${dms.minutes}'`;
    }
    if (dms.seconds && dms.seconds !== '0.00') {
        str += ` ${dms.seconds}"`;
    }
    str += ` ${dms.hemisphere}`;

    return str;
}

export const MAPBOX_DEFAULT_DARK = 'mapbox://styles/rushdigital/ckctsqxn21yzi1ipdt6n94r7f';
export const MAPBOX_DEFAULT_SATELLITE = 'mapbox://styles/mapbox/satellite-v9';
export const MAPBOX_DEFAULT_STYLE = MAPBOX_DEFAULT_DARK;

export const MAPBOX_OPTIONS = {
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    mapStyle: MAPBOX_DEFAULT_STYLE,
};

export function getMapStyle(mapViewStyle) {
    return mapViewStyle === 'satellite'
        ? MAPBOX_DEFAULT_SATELLITE
        : MAPBOX_DEFAULT_DARK;
}
