<template>
    <div class="prediction-confirmation">
        <b-button
            @click="onClick('yes')"
            class="be-button is-success is-smaller has-text-weight-bold"
        >
            Yes
        </b-button>
        <b-button
            @click="onClick('no')"
            class="be-button is-danger is-smaller has-text-weight-bold"
        >
            No
        </b-button>
        <b-button
            @click="onClick('maybe')"
            class="be-button is-warning is-smaller has-text-weight-bold"
        >
            Maybe
        </b-button>
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';

export default {
    mixins: [noticesMixin],

    methods: {
        onClick(type) {
            this.displaySuccessNotice({
                message: 'Thank you for helping improve our accuracy!',
                position: 'is-top-right',
            });
            this.$emit('click', type);
        },
    },
};
</script>

<style lang="scss" scoped>
.prediction-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;

    .button + .button {
        margin-left: .75rem;
    }
}
</style>
