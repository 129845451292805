<template>
    <div class="roi-image">
        <template v-if="isError">
            <div class="roi-image__error">
                <div class="content">
                    <p>{{ message }}</p>
                </div>
            </div>
        </template>
        <template v-else>
            <div ref="container" class="roi-image__container">
                <div ref="scene" class="roi-image__scene">
                    <img
                        ref="image"
                        :src="url"
                        class="roi-image__image"
                        @load="onImageLoad"
                        @error="onImageError"
                        :style="{ opacity: isReady ? 1 : 0 }"
                        alt=""
                    >
                    <roi-camera-image-canvas
                        ref="canvas"
                        v-if="scale && isReady"
                        :canvas-style="sceneStyles"
                        :rendered-size="renderedSize"
                        :scale="scale"
                        :rois="rois"
                        class="roi-image__canvas"
                    />
                    <div v-else :style="sceneStyles" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { get } from 'lodash';
import { STATUS_ERROR, STATUS_PENDING, STATUS_READY } from '@/utils/constants';
import RoiCameraImageCanvas from '@/components/local/sites/partials/RoiCameraImageCanvas';

export default {
    components: { RoiCameraImageCanvas },
    props: {
        camera: {
            type: Object,
            required: true,
        },
        image: {
            type: Object,
            required: true,
        },
        rois: {
            type: Array,
            required: true,
        },
    },

    data() {
        const width = get(this.image, 'img_width', 0);
        const height = get(this.image, 'img_height', 0);

        const realImageSize = {
            width,
            height,
            ratio: width && width ? width / height : 1,
        };

        return {
            imageStatus: STATUS_PENDING,
            message: 'No image provided',
            containerSize: null,
            realImageSize,
            imageSize: {
                width: 0,
                height: 0,
            },
        };
    },

    computed: {
        scale() {
            return this.renderedSize.width
                ? this.realImageSize.width / this.renderedSize.width
                : 0;
        },

        url() {
            return get(this.image, 'url');
        },

        isReady() {
            return this.imageStatus === STATUS_READY;
        },

        isError() {
            return this.imageStatus === STATUS_ERROR;
        },

        renderedSize() {
            if (!this.containerSize) {
                return {
                    width: 0,
                    height: 0,
                };
            }
            const ratio = this.realImageSize.width / this.containerSize.width;
            const height = parseInt(this.realImageSize.height / ratio, 10);
            return {
                width: this.containerSize.width,
                height,
            };
        },

        sceneStyles() {
            return {
                width: `${this.renderedSize.width}px`,
                height: `${this.renderedSize.height}px`,
            };
        },
    },

    mounted() {
        this.$bus.$on('resize', this.resize);
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.resize);
        });
        this.resize();
    },

    methods: {
        isDirty() {
            return false;
        },

        onImageLoad() {
            this.imageStatus = STATUS_READY;
        },

        onImageError() {
            this.imageStatus = STATUS_ERROR;
            const match = this.url.match(/Expires=(\d+)/);
            if (match) {
                if (this.$date().unix() > Number(match[1])) {
                    this.message = 'The link to this image has expired after 60 minutes of inactivity. Please refresh your browser window to reload the image.';
                    return;
                }
            }
            this.message = 'This image has been deleted as your data retention policy is set to 30 days. To review or modify this policy please contact us.';
        },

        resize() {
            this.$nextTick(() => {
                if (this.$refs.container) {
                    const width = this.$refs.container.offsetWidth;
                    const height = this.$refs.container.offsetHeight;
                    this.containerSize = {
                        width,
                        height,
                        ratio: width / height,
                    };
                }
                if (this.$refs.image) {
                    this.imageSize = {
                        width: this.$refs.image.clientWidth,
                        height: this.$refs.image.clientHeight,
                    };
                }
            });
        },

        validate() {
            return this.$refs.canvas.validate();
        },

        getSaveData() {
            return this.$refs.canvas.getSaveData();
        },
    },
};
</script>

<style lang="scss" scoped>
.roi-image {
    position: relative;

    *:focus {
        outline: none;
    }

    &__container {
        position: relative;
        flex: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__scene {
        position: relative;
        width: 100%;
    }

    &__canvas {
        position: relative;
        z-index: 100;
    }

    &__image {
        position: absolute;
        top: 0;

        display: block;
        width: 100%;
        height: auto;
    }
}
</style>
