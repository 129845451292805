<template>
    <div class="integration is-hubspot">
        <be-integration-header class="integration__header" back-url="/dashboard/marketplace/apps">
            <template v-slot:heading>
                {{ heading }}
            </template>
            <template v-slot:subheading>
                <p>Integration coming soon</p>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field horizontal>
                            <template v-slot:label>
                                <span class="is-block has-text-left has-text-weight-bold">
                                    Integration
                                </span>
                            </template>
                            <b-switch v-model="integration.enabled" />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-button
                            @click="save"
                            :loading="isSaving"
                            class="be-button is-wider is-primary"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';
import marketplaceAppMixin from '@/mixins/marketplaceAppMixin';
import integrationFakeSaveMixin from '@/mixins/integrationFakeSaveMixin';

export default {
    mixins: [
        noticesMixin,
        marketplaceAppMixin,
        integrationFakeSaveMixin(false),
    ],
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
