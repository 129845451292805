<template>
    <be-widget class="frequent-plates" :is-loading="isLoading" :transparent="true">
        <template v-slot:heading>
            Search for a plate within this period
        </template>
        <template v-slot:content>
            <b-field class="frequent-plates__search">
                <b-input
                    placeholder="Search plates"
                    class="frequent-plates__search__input"
                    v-model="searchInput"
                    @keydown.native.enter="search()"
                />
                <p class="control">
                    <b-button
                        class="be-button is-wider"
                        type="is-primary"
                        @click="search()"
                    >
                        Search
                    </b-button>
                </p>
            </b-field>
            <div v-if="selectedPlates && selectedPlates.length" class="frequent-plates__clear">
                <a href="#" @click.prevent="clear()">Clear plate</a>
            </div>
            <p class="label-smaller">or click on a plate tag below to see all its detections over the selected
                period</p>
        </template>
    </be-widget>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            searchInput: '',
        };
    },

    computed: {
        selectedPlates: {
            set(plates) {
                this.$store.commit('dataFilters/setSelectedPlates', { plates });
                // reset plate search over time
                this.$store.commit('explorer/plates/sitegraph/resetState');
            },
            get() {
                return this.$store.state.dataFilters.selectedPlates;
            },
        },
    },

    watch: {
        selectedPlates() {
            this.$emit('update');
        },
    },

    mounted() {
        this.clear();
    },

    methods: {
        search(emit = true) {
            if (this.searchInput) {
                this.selectedPlates = [this.searchInput.toUpperCase()];
                this.$store.commit('dataFilters/applyFilters');
            } else {
                this.selectedPlates = [];
            }
            if (emit) {
                this.$emit('update');
            }
        },
        clear(emit = true) {
            this.searchInput = '';
            this.search(emit);
        },
    },
};
</script>

<style lang="scss" scoped>
.frequent-plates {
    &__search {
        width: 100%;
        margin-bottom: 5px;

        &__input {
            flex: 1;
        }
    }

    &__clear {
        font-size: 14px;
        display: flex;
        justify-content: flex-end;

        a {
            @include transition-button;
            width: 116px;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            color: $primary;

            &:hover {
                color: darken($primary, 10);
            }
        }
    }
}
</style>
