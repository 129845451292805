<template>
    <div class="alerts-single">
        <div class="app-container" v-if="alert && !isError">
            <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
            <be-page-header :app-container="false">
                <template v-slot:default>
                    {{ pageHeading }}
                </template>
                <template v-slot:back>
                    <b-button
                        tag="router-link"
                        type="is-light"
                        to="/dashboard/alerts"
                        class="be-button is-back"
                        icon-left="chevron-left"
                    />
                </template>
            </be-page-header>
            <div class="alerts-single__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field
                            label="Name"
                            :type="fieldType('description')"
                            :message="fieldMessage('description')"
                        >
                            <b-input v-model="alert.description" />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field
                            label="Action"
                            :type="fieldType('action')"
                            :message="fieldMessage('action')"
                        >
                            <b-select v-model="alert.action" expanded>
                                <option
                                    v-for="action in alertActions"
                                    :key="`action${action.value}`"
                                    :value="action.value"
                                >
                                    {{ action.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-field
                            label="Site"
                            :type="fieldType('site')"
                            :message="fieldMessage('site')"
                        >
                            <b-select v-model="alert.site" expanded>
                                <option
                                    v-for="site in siteList"
                                    :key="`site${site.id}`"
                                    :value="site.id"
                                >
                                    {{ site.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-field
                            v-if="alert.action === 'email'"
                            label="Recipients"
                            :type="fieldType('email')"
                            :message="fieldMessage('email')"
                        >
                            <b-taginput
                                v-model="alert.email"
                                :before-adding="beforeRecepientEmailAdding"
                                placeholder="Recipient list separated by commas"
                            />
                        </b-field>
                        <b-field
                            v-if="alert.action === 'sms'"
                            label="Recipients"
                            :type="fieldType('msg_number')"
                            :message="fieldMessage('msg_number')"
                        >
                            <b-taginput
                                v-model="alert.msg_number"
                                :before-adding="beforeRecepientPhoneAdding"
                                placeholder="Recipient list separated by commas"
                            />
                        </b-field>
                        <b-field
                            v-if="alert.action === 'webhook'"
                            label="Recipients"
                            :type="fieldType('webhook')"
                            :message="fieldMessage('webhook')"
                        >
                            <b-select v-model="alert.webhook" expanded>
                                <option v-if="!alert.webhook" disabled>Select Webhook</option>
                                <option
                                    v-for="webhook in webhooks"
                                    :key="`webhook${webhook.id}`"
                                    :value="webhook.id"
                                >
                                    {{ webhook.name || webhook.url }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-field
                            class="alert-list"
                            grouped
                            label="List to filter alerts against"
                            :type="fieldType('alert_list')"
                            :message="fieldMessage('alert_list')"
                        >
                            <b-field expanded :type="fieldType('alert_list')">
                                <b-select v-model="alert.alert_list" expanded>
                                    <option v-if="!alert.alert_list" disabled>Select List</option>
                                    <option
                                        v-for="list in allLists"
                                        :key="`list${list.id}`"
                                        :value="list.id"
                                    >
                                        {{ list.description }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field expanded class="has-checkbox">
                                <b-checkbox v-model="alert.is_whitelist">
                                    Alert if not in this list
                                </b-checkbox>
                            </b-field>
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-field
                            label="Alert detection threshold to trigger alert"
                            :type="thresholdFieldType"
                            :message="thresholdFieldMessage"
                        >
                            <be-slide-bar
                                class="pt-5"
                                v-model="alert.confidence_threshold"
                                v-bind="slidebarProps"
                            />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field
                            label="Dwell Time (Vehicles Only)"
                            :type="fieldType('dwell_time')"
                            :message="fieldMessage('dwell_time')"
                            grouped
                        >
                            <be-dwell-time v-model="alert.dwell_time" :disabled="!hasDwellTime" />
                            <div class="control">
                                <b-tooltip multilined :position="$bus.isMobile ? 'is-left' : 'is-top'" type="is-dark">
                                    <template v-slot:default>
                                        <b-icon icon="help-circle-outline" class="has-text-grey-lighter" />
                                    </template>
                                    <template v-slot:content>
                                        <p>Ensure you have an compatible Entry camera and Exit camera configured at each
                                            relevant site. This option is enabled once you select ‘Any Vehicles’ as the
                                            filter list.</p>
                                    </template>
                                </b-tooltip>
                            </div>
                        </b-field>
                    </div>
                </div>
                <hr />
                <div class="columns alerts-single__actions">
                    <div class="column is-6">
                        <b-field>
                            <b-checkbox
                                v-model="alert.enabled"
                            >
                                Enable Alert
                            </b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-button
                            class="be-button is-primary"
                            expanded
                            @click="save"
                            :loading="isSaving"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
                <template v-if="!isNew">
                    <hr />
                    <div class="columns">
                        <div class="column is-12 has-text-right">
                            <a href="#" class="has-text-danger" @click.prevent="remove">
                                <small>Remove Alert</small>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-else-if="isError" class="app-container">
            <div class="py-5 content has-text-grey-lighter has-text-centered">
                <p>Invalid alert</p>
                <p>
                    <b-button
                        tag="router-link"
                        to="/dashboard/alerts"
                        class="be-button is-outline is-wider"
                    >
                        Back
                    </b-button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { find, isString } from 'lodash';
import { validationMixin } from 'vuelidate';
import { between, email, numeric, required, requiredIf } from 'vuelidate/lib/validators';
import noticesMixin from '@/mixins/noticesMixin';
import BeSlideBar from '@/components/global/BeSlideBar';
import { COLORS } from '@/utils/constants';
import { phoneNumber } from '@/utils/validators';
import BeDwellTime from '@/components/global/BeDwellTime';
import { getApiUrl } from '@/utils/api';

const ALERT_ACTIONS = [
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'sms',
        label: 'SMS',
    },
    {
        value: 'webhook',
        label: 'Webhook',
    },
];

const SMS_MSG_CONFIRM = 'Usage charges for SMS alerts will apply (11 cents per SMS and the first 30 are free on a standard plan).';

const getDefaultAlertData = () => ({
    description: '',
    site: null,
    action: ALERT_ACTIONS[0].value,
    email: [],
    msg_number: [],
    webhook: '',
    alert_list: '',
    is_whitelist: false,
    enabled: false,
    confidence_threshold: 70,
    dwell_time: 0,
});

export default {
    components: {
        BeDwellTime,
        BeSlideBar,
    },

    mixins: [
        validationMixin,
        noticesMixin,
    ],

    validations() {
        const alert = {
            description: { required },
            site: {},
            action: { required },
            email: {
                required: requiredIf((model) => model.action === 'email'),
            },
            msg_number: {
                required: requiredIf((model) => model.action === 'sms'),
            },
            webhook: {
                required: requiredIf((model) => model.action === 'webhook'),
            },
            dwell_time: { numeric },
            alert_list: { required },
            is_whitelist: { required },
            confidence_threshold: {
                required,
                numeric,
                between: between(0, 100),
            },
        };

        return { alert };
    },

    watch: {
        hasDwellTime(val) {
            if (!val) {
                this.alert.dwell_time = 0;
            }
        },
        alertAction(val, oldVal) {
            if (this.isReady && val === 'sms') {
                this.$buefy.dialog.confirm({
                    message: SMS_MSG_CONFIRM,
                    type: 'is-info',
                    cancelText: 'Cancel',
                    confirmText: 'Accept',
                    onCancel: () => {
                        this.$nextTick(() => {
                            this.alert.action = oldVal;
                        });
                    },
                });
            }
        },
    },

    data() {
        return {
            isReady: false,
            isSaving: false,
            isRemoving: false,
            isError: false,
            alertActions: ALERT_ACTIONS,
            alert: getDefaultAlertData(),
            validationMessages: {
                description: 'Please enter the alert name',
                action: 'Please select an alert action',
                alert_list: 'Please select a list',
                confidence_threshold: 'Please select a valid threshold',
                email: 'Please add at least one email',
                msg_number: 'Please add at least one phone number',
                webhook: 'Please add an alert webhook',
                dwell_time: 'Please add a valid dwell time: days:hrs:mins',
            },
            infoMessages: {
                dwell_time: 'days:hrs:mins',
            },
            recommendedThreshold: 70,
            siteList: [],
        };
    },

    computed: {
        ...mapState('alerts/alerts', ['alertsFull']),
        ...mapState('webhooks', ['webhooks']),
        ...mapState('alerts/lists', ['allLists']),
        ...mapGetters({
            isWebhooksLoading: 'webhooks/isLoading',
            isAlertsLoading: 'alerts/alerts/isLoading',
            isListsLoading: 'alerts/alerts/isLoading',
        }),

        isLoading() {
            return this.isAlertsLoading || this.isListsLoading || this.isWebhooksLoading;
        },

        canAction() {
            return !this.isSaving && !this.isRemoving;
        },

        alertId() {
            return this.$route.params.guid;
        },

        isNew() {
            return this.$route.params.guid === 'new';
        },

        pageHeading() {
            return this.isNew ? 'Add a New Alert' : 'Edit Alert';
        },

        thresholdFieldType() {
            if (this.$v.alert.confidence_threshold.$error) {
                return 'is-danger';
            }
            if (this.alert.confidence_threshold < this.recommendedThreshold) {
                return 'is-warning';
            }
            return '';
        },

        thresholdFieldMessage() {
            if (this.$v.alert.confidence_threshold.$error) {
                return this.validationMessages.confidence_threshold;
            }

            if (this.alert.confidence_threshold < this.recommendedThreshold) {
                return `We recommend a confidence threshold of ${this.recommendedThreshold} or above.`;
            }

            return '';
        },

        slidebarProps() {
            let tooltipBg = COLORS.red;
            if (this.alert.confidence_threshold >= this.recommendedThreshold) {
                tooltipBg = COLORS.yellow;
            }
            if (this.alert.confidence_threshold >= 90) {
                tooltipBg = COLORS.green;
            }

            return {
                labelStyles: {
                    color: COLORS.black,
                    backgroundColor: COLORS.black,
                },
                processStyle: {
                    backgroundColor: tooltipBg,
                },
                tooltipStyles: {
                    backgroundColor: tooltipBg,
                    borderColor: tooltipBg,
                    color: COLORS.black,
                },
            };
        },

        alertList() {
            return this.alert.alert_list;
        },

        alertAction() {
            return this.alert.action;
        },

        hasDwellTime() {
            if (!this.alert.alert_list) {
                return false;
            }

            const list = find(this.allLists, { id: this.alert.alert_list });
            if (!list || !list.built_in) {
                return false;
            }

            return list.description.toLowerCase().includes('any vehicl');
        },
    },

    mounted() {
        const promises = [];

        if (!this.isNew) {
            promises.push(this.getAlert({ alertId: this.alertId })
                .then(() => {
                    this.alert = this.getModelData();
                })
                .catch(() => {
                    this.isError = true;
                }));
        }

        promises.push(this.getWebhooks({}));
        promises.push(this.getAllLists({}));
        promises.push(this.getSites());

        Promise.all(promises)
            .then(() => {
                this.isReady = true;
            });
    },

    methods: {
        ...mapActions('alerts/alerts', ['getAlert', 'updateAlert', 'createAlert', 'removeAlert']),
        ...mapActions('alerts/lists', ['getAllLists']),
        ...mapActions('webhooks', ['getWebhooks']),

        async getSites() {
            const response = await this.$http(getApiUrl({ path: 'sites/?page=1&page_length=1000&' }));
            this.siteList.push({ id: null, name: 'All' }, ...response?.data?.results ?? []);
        },

        getModelData() {
            const alert = find(this.alertsFull, { id: this.alertId });

            const alertData = {
                ...this.alert,
                ...alert,
            };

            if (alertData.email) {
                alertData.email = isString(alertData.email)
                    ? [alertData.email]
                    : alertData.email;
                alertData.action = 'email';
            }

            if (alertData.msg_number) {
                alertData.msg_number = isString(alertData.msg_number)
                    ? [alertData.msg_number]
                    : alertData.msg_number;
                alertData.action = 'sms';
            }

            if (alertData.webhook) {
                alertData.action = 'webhook';
            }

            return alertData;
        },

        beforeRecepientEmailAdding(tag) {
            return email(tag);
        },

        beforeRecepientPhoneAdding(tag) {
            return phoneNumber(tag);
        },

        getSaveData() {
            const data = {
                description: this.alert.description,
                site: this.alert.site,
                alert_list: this.alert.alert_list,
                is_whitelist: this.alert.is_whitelist,
                enabled: this.alert.enabled,
                confidence_threshold: this.alert.confidence_threshold,
            };

            if (this.hasDwellTime) {
                data.dwell_time = this.alert.dwell_time;
            }

            switch (this.alert.action) {
                case 'email':
                    // eslint-disable-next-line
                    data.email = this.alert.email;
                    break;
                case 'sms':
                    // eslint-disable-next-line
                    data.msg_number = this.alert.msg_number;
                    break;
                case 'webhook':
                default:
                    // eslint-disable-next-line
                    data.webhook = this.alert.webhook;
                    break;
            }

            return data;
        },

        save() {
            if (!this.canAction) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isSaving = true;
            this.closeNotices();

            const data = this.getSaveData();
            const promise = this.isNew
                ? this.createAlert({ data })
                : this.updateAlert({ alertId: this.alertId, data });

            promise.then(() => {
                this.displaySuccessNotice({
                    message: this.isNew
                        ? 'Alert added successfully'
                        : 'Alert updated successfully',
                    closeOthers: true,
                });
                this.$router.push('/dashboard/alerts');
            }).catch(() => {
                this.displayErrorNotice({
                    message: this.isNew
                        ? 'Cannot save alert'
                        : 'Cannot update alert',
                });
            }).finally(() => {
                this.isSaving = false;
            });
        },

        remove() {
            if (!this.canAction) {
                return;
            }

            this.closeNotices();
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this alert?',
                type: 'is-danger',
                trapFocus: true,
                onConfirm: () => {
                    this.isRemoving = true;
                    this.removeAlert({
                        alertId: this.alertId,
                    })
                        .then(() => {
                            this.displaySuccessNotice({
                                message: 'Alert removed successfully',
                            });
                            this.$emit('close');
                            this.$router.push('/dashboard/alerts');
                        })
                        .finally(() => {
                            this.isRemoving = false;
                        });
                },
            });
        },

        fieldType(field) {
            return this.$v.alert[field].$error ? 'is-danger' : '';
        },

        fieldMessage(field) {
            if (this.$v.alert[field].$error && this.validationMessages[field]) {
                return this.validationMessages[field];
            }

            return this.infoMessages[field]
                ? this.infoMessages[field]
                : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.alerts-single {
    .app-container {
        position: relative;
    }

    &__form {
        width: 100%;
        max-width: 500px;
    }

    &__actions {
        align-items: center;

        ::v-deep .b-checkbox {
            display: flex;
        }
    }

    ::v-deep .field {
        &.alert-list.is-grouped {
            @include mobile {
                display: block;
                .field {
                    margin-right: 0;
                    margin-bottom: .5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            @include tablet {
                .field.has-checkbox {
                    width: 190px;
                    flex: 0 0 190px;
                }
            }
        }

        &.has-checkbox {
            display: flex;
            align-items: center;

            .checkbox {
                margin-right: 0;
            }
        }
    }

    ::v-deep .tooltip-trigger {
        line-height: 1;

        > .icon {
            margin-top: 8px;
        }
    }
}
</style>
