<template>
    <div class="field">
        <div class="control" @click.stop.prevent="copyImage">
            <div class="copy">
                <div class="copy__cta">
                    {{ copyLabel }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';

export default {
    mixins: [noticesMixin],

    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'Copy Image',
        },
        labelCopied: {
            type: String,
            default: 'Image Copied!',
        },
    },

    data() {
        return {
            copyLabel: this.label,
        };
    },

    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },

    methods: {
        updateLabel() {
            this.copyLabel = this.labelCopied;
            this.timeout = setTimeout(() => {
                this.copyLabel = this.label;
            }, 2000);
        },

        async copyImage() {
            const imageURL = this.imageSrc;
            const blob = await this.imageToBlob(imageURL);
            const item = new ClipboardItem({ [blob.type]: blob });
            navigator.clipboard.write([item]).then(() => {
                this.displaySuccessNotice({ message: 'Image copied to clipboard' });
                this.updateLabel();
            }).catch((err) => {
                this.displayErrorNotice({ message: err.message });
            });
        },

        async imageToBlob(imageURL) {
            const img = new Image();
            const c = document.createElement('canvas');
            const ctx = c.getContext('2d');
            img.crossOrigin = 'Anonymous';
            img.src = imageURL;
            return new Promise((resolve) => {
                img.onload = function () {
                    c.width = this.naturalWidth;
                    c.height = this.naturalHeight;
                    ctx.drawImage(this, 0, 0);
                    c.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/png', 0.75);
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.copy {
    display: flex;
    align-items: stretch;
    cursor: pointer;

    &__cta {
        transition: all $speed $easing;
        padding: .5rem 1rem;
        border: 1px solid $border;
        border-radius: $radius;
        background: $grey-darker;
        @include mobile {
            padding: .5rem .75rem;
        }
    }

    &.is-expanded & {
        &__text {
            max-width: none;
        }
    }

    &__cta {
        margin-left: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $primary;
        font-weight: $weight-bold;
        flex: 0 0 auto;
        @include mobile {
            flex: 0 0 auto;
        }
    }

    &:hover & {
        &__cta {
            border-color: darken($border, 10);
        }
        &__cta {
            color: darken($primary, 10);
        }
    }
}
</style>
