<template>
    <div class="card is-rounded camera-item">
        <div class="camera-item__inner">
            <header class="camera-item__header">
                <div class="camera-item__header__level">
                    <h4 class="camera-item__header__title title-widget mb-1 mr-1">
                        {{ camera.name | truncate(30) }}
                    </h4>
                    <button type="button" class="camera-item__header__edit button-link is-primary mt-1" @click="toggleEditPopup()">
                        Edit
                    </button>
                </div>
                <div class="label-sub-text" :class="lastDetectionWarning ? 'has-text-danger' : ''">
                    <span>{{ lastDetectionText }}</span>
                    <b-icon
                        v-if="lastDetectionWarning"
                        icon="alert-outline"
                        size="is-small"
                    />
                </div>
                <be-camera-status class="mt-2" v-if="status" :status="status" />
            </header>

            <div class="camera-item__more" v-if="hasShowMore && showMore">
                <hr>
                <be-avg-confidence :confidence="camera.average_confidence || 0" />

                <be-info v-if="camera.tags" label="Tags" class="mt-3">
                    <b-taglist>
                        <b-tag
                            v-for="(tag, index) in camera.tags"
                            :key="`tag${index}`"
                        >
                            {{ tag.label }}
                        </b-tag>
                    </b-taglist>
                </be-info>
                <be-info v-if="camera.face_redaction" label="Anonymise faces" class="mt-3">
                    <b-tag class="has-cursor-default">
                        YES
                    </b-tag>
                </be-info>
                <camera-last-detection class="mt-3" :camera="camera" />
            </div>

            <div class="camera-item__footer">
                <hr>
                <b-button
                    tag="router-link"
                    :to="detectionsLink"
                    class="be-button"
                    expanded
                    :type="showMore ? 'is-primary' : 'is-secondary'"
                >
                    View detections
                </b-button>
            </div>
            <div class="camera-item__toggle mt-3 has-text-centered" v-if="hasShowMore">
                <a href="#" @click.prevent="toggleMore" class="be-toggle-link">
                    <span v-text="showMore ? 'Hide more' : 'Show more'" />
                    <b-icon :icon="showMore ? 'chevron-up' : 'chevron-down'" custom-size="mdi-18px" />
                </a>
            </div>
        </div>
        <manage-h-s-camera-popup
            v-if="editActive"
            :key="camera.id"
            :camera-id="camera.id"
            :site="site"
            :active="editActive"
            @close="toggleEditPopup"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import BeAvgConfidence from '@/components/global/BeAvgConfidence';
import BeCameraStatus from '@/components/global/BeCameraStatus';
import BeInfo from '@/components/global/BeInfo';
import { getDetectionsByTagUrl } from '@/utils/detections';
import { datetimeHumanFormatter } from '@/utils/formatters/datetime';
import CameraLastDetection from '@/components/local/sites/partials/CameraLastDetection';
import ManageHSCameraPopup from '@/components/local/sites/hs/ManageHSCameraPopup';

export default {
    components: {
        ManageHSCameraPopup,
        CameraLastDetection,
        BeInfo,
        BeCameraStatus,
        BeAvgConfidence,
    },

    /* eslint-disable vue/no-potential-component-option-typo */
    eq: {
        breakpoints: {
            small: { maxWidth: 249 },
            large: { minWidth: 250 },
        },
    },
    /* eslint-enable vue/no-potential-component-option-typo */

    props: {
        site: {
            type: Object,
            required: true,
        },
        camera: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            editActive: false,
            showMore: false,
        };
    },

    computed: {
        ...mapState('sites', ['frequencyList']),

        hasShowMore() {
            return this.camera.tags || this.camera.last_image_id;
        },

        lastDetection() {
            return this.camera.last_seen
                ? this.$date(this.camera.last_seen)
                : null;
        },

        lastDetectionWarning() {
            return this.status === 'warning';
        },

        lastDetectionText() {
            return this.lastDetection
                ? `Last detection ${datetimeHumanFormatter(this.lastDetection)}`
                : 'No detections yet';
        },

        status() {
            if (!this.lastDetection) {
                return 'pending';
            }

            if (this.lastDetection.add(1, 'day').isAfter(this.$date())) {
                return 'online';
            }

            return 'warning';
        },

        detectionsLink() {
            return getDetectionsByTagUrl({
                path: '/dashboard/health-and-safety/explorer',
                tags: this.camera.tags,
            });
        },
    },

    watch: {
        editActive: {
            handler(val) {
                if (val) {
                    this.$emit('edit', this.camera);
                }
            },
        },
    },

    methods: {
        toggleMore() {
            this.showMore = !this.showMore;
        },
        toggleEditPopup() {
            this.editActive = !this.editActive;
        },
    },
};
</script>

<style lang="scss" scoped>
.camera-item {
    flex: 1;
    min-height: 140px;

    &__inner {
        padding: 1rem;
    }

    &__header {
        position: relative;

        &__title {
            flex: 1;
        }

        &__level {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__edit {
            flex: 0 0 auto;
        }
    }

    &__confidence {
        flex: 0 0 auto;
        margin-left: 10px;
    }
}
</style>
