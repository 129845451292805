<template>
    <div>
        <v-stage ref="stage" :config="configKonva" class="media-image-canvas">
            <v-layer v-if="displayRoi">
                <bounding-box-roi
                    v-for="(roi, index) in rois"
                    :key="`roi-${index}`"
                    :scale="scale"
                    :roi="roi"
                    :showTooltip="showTooltip"
                />
            </v-layer>
            <v-layer>
                <bounding-box-group
                    v-for="(result, index) in resultsGeneric"
                    :key="`result-${index}`"
                    :result="result"
                    :scale="scale"
                    :translate="translate"
                    :imageConfig="imageConfig"
                />
            </v-layer>
            <v-layer>
                <v-image
                    v-if="displayHeatmap && heatmapImage"
                    ref="heatmap"
                    :config="heatmapConfig"
                />
            </v-layer>
        </v-stage>
    </div>
</template>

<script>
import { get, isString } from 'lodash';
import axios from 'axios';
import { mapMutations } from 'vuex';
import BoundingBoxGroup from '@/components/local/data/detections/BoundingBoxGroup';
import BoundingBoxRoi from '@/components/local/data/detections/BoundingBoxRoi';
import { getApiUrl } from '@/utils/api';

export default {
    components: {
        BoundingBoxGroup,
        BoundingBoxRoi,
    },
    props: {
        detections: {
            type: Object,
            required: true,
        },
        camera: {
            type: [String, Object],
            default: null,
        },
        displayRoi: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number,
            default: 1,
        },
        height: {
            type: Number,
            default: 1,
        },
        isVisible: {
            type: Boolean,
            default: true,
        },
        scale: {
            type: Number,
            default: 1,
        },
        translate: {
            type: Array,
            default: () => [0, 0],
        },
        showTooltip: {
            type: Function,
            default: () => {},
        },
        displayHeatmap: {
            type: Boolean,
            default: false,
        },
        heatmapImage: {
            type: HTMLImageElement,
            required: false,
            default: null,
        },
        imageConfig: {
            type: Object,
            required: false,
            default: () => {},
        },
    },

    data() {
        return {
            rois: [],
        };
    },

    computed: {
        configKonva() {
            return {
                width: this.width,
                height: this.height,
            };
        },

        resultsGeneric() {
            return get(this.detections, 'results_generic', []);
        },

        cameraId() {
            if (!this.camera || isString(this.camera)) {
                return this.camera;
            }
            return get(this.camera, 'id');
        },
        heatmapConfig() {
            return {
                image: this.heatmapImage,
                width: this.width,
                height: this.height,
                opacity: 0.5,
            };
        },
    },

    watch: {
        cameraId: {
            handler(val) {
                if (val) {
                    this.loadRois();
                    this.loadCameraData();
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapMutations('canvas', ['setCameraData', 'setZoneRois']),
        loadRois() {
            if (!this.cameraId) {
                return;
            }
            axios({
                method: 'GET',
                url: getApiUrl({ path: `cameras/${this.cameraId}/rois` }),
            }).then((result) => {
                const data = get(result, 'data', []);
                this.rois = data.sort((a, b) => (a.roi_type < b.roi_type ? -1 : 1));
                this.setZoneRois({ zoneRois: this.rois });
            });
        },
        loadCameraData() {
            if (!this.cameraId) {
                return;
            }
            axios({
                method: 'GET',
                url: getApiUrl({ path: `cameras/lookup/${this.cameraId}` }),
            }).then((result) => {
                const data = get(result, 'data', []);
                this.setCameraData({ cameraData: data });
            });
        },
    },
};
</script>
