<template>
    <div class="totals columns is-multiline">
        <div class="totals__col column is-one-third-tablet is-one-fifth-widescreen">
            <be-widget icon="check">
                <template v-slot:heading>Average Confidence</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ averageConfidence }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-one-third-tablet  is-one-fifth-widescreen">
            <be-widget icon="glasses">
                <template v-slot:heading>Total unique events</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalPlatesDetected }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-one-third-tablet is-one-fifth-widescreen">
            <be-widget icon="video-outline">
                <template v-slot:heading>Total unique plates</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalUniquePlates }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-one-third-tablet is-one-fifth-widescreen">
            <be-widget icon="video-outline">
                <template v-slot:heading>Total images received</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalImagesReceived }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-one-third-tablet is-one-fifth-widescreen">
            <be-widget icon="speedometer">
                <template v-slot:heading>Average speed of vehicles</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ averageVehicleSpeed }} km/h</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatNumber, formatPercentage } from '@/utils/formatters/numbers';
import widgetDataMixin from '@/mixins/widgetDataMixin';

export default {
    mixins: [widgetDataMixin],

    computed: {
        ...mapGetters('dataFilters', ['getFilterHash']),
        ...mapGetters('explorer/detections/unique/summary', ['summaryData', 'isLoading']),
        ...mapGetters('explorer/speed/summary', ['speedSummaryData', 'isLoading']),

        averageConfidence() {
            return this.summaryData.average_confidence
                ? formatPercentage({ number: this.summaryData.average_confidence, decimal: false })
                : '-';
        },

        totalPlatesDetected() {
            return this.summaryData.total_unique_plates
                ? formatNumber({ number: this.summaryData.total_unique_plates })
                : '-';
        },

        totalImagesReceived() {
            return this.summaryData.total_images
                ? formatNumber({ number: this.summaryData.total_images })
                : '-';
        },

        totalUniquePlates() {
            return this.summaryData.total_unique_true_license_plate
                ? formatNumber({ number: this.summaryData.total_unique_true_license_plate })
                : '-';
        },
        averageVehicleSpeed() {
            return this.speedSummaryData.average_vehicle_speed
                ? formatNumber({ number: Math.round(this.speedSummaryData.average_vehicle_speed) })
                : '-';
        },
    },

    methods: {
        ...mapActions('explorer/detections/unique/summary', ['loadData']),
        ...mapActions('explorer/speed/summary', { loadSpeedSummaryData: 'loadData' }),
    },

    watch: {
        getFilterHash() {
            this.$emit('update');
            if (this.canLoadData) {
                this.loadData({});
                this.loadSpeedSummaryData({});
            }
        },
    },

    mounted() {
        if (this.canLoadData) {
            this.loadData({});
            this.loadSpeedSummaryData({});
        }
    },
};
</script>

<style lang="scss" scoped>
.totals {
    justify-content: stretch;
    &__col {
        display: flex;
        justify-content: stretch;
    }
}
</style>
