<template>
    <b-field v-bind="fieldProps">
        <b-autocomplete
            :data="data"
            :value="computedValue"
            @typing="getAsyncData"
            @select="onSelect"
            :loading="isFetching"
            field="place_name"
            placeholder="Start typing the address..."
        >
            <template slot-scope="props">
                <div>
                    <span class="has-text-weight-bold">{{ props.option.suggestionTitle }}</span>
                    <br>
                    <small>{{ props.option.suggestionDescr }}</small>
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
import GeocoderService from '@mapbox/mapbox-sdk/services/geocoding';
import MapboxClient from '@mapbox/mapbox-sdk';
import { debounce } from 'lodash';

export default {
    props: {
        fieldProps: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            data: [],
            isFetching: false,
            search: '',
            selected: null,
            newValue: this.value,
        };
    },

    computed: {
        computedValue: {
            get() {
                return this.newValue;
            },
            set(value) {
                this.newValue = value;
                this.$emit('input', value);
            },
        },
    },

    mounted() {
        this.geocoderService = GeocoderService(
            MapboxClient({
                accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
                origin: 'https://api.mapbox.com',
            }),
        );
    },

    methods: {
        onSelect(option) {
            if (!option) {
                this.computedValue = '';
                this.$emit('selected', { lng: null, lat: null });
                return;
            }

            this.computedValue = option.place_name;
            this.$emit('selected', { lng: option.center[0], lat: option.center[1] });
        },

        getAsyncData: debounce(function (search) {
            const query = search ? search.trim() : '';
            if (!query) {
                return;
            }

            const request = this.geocoderService.forwardGeocode({
                types: ['address'],
                countries: ['nz', 'au'],
                limit: 5,
                query,
            }).send();

            this.isFetching = true;
            this.data = [];
            request
                .then((response) => {
                    let features = [];
                    if (response && response.statusCode == '200') { // eslint-disable-line
                        ({ features } = response.body);
                    }
                    this.data = features.map((feature) => {
                        const placeName = feature.place_name.split(',');
                        return {
                            ...feature,
                            suggestionTitle: placeName[0],
                            suggestionDescr: placeName.splice(1, placeName.length).join(',').trim(),
                        };
                    });
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.isFetching = false;
                });
        }, 500),
    },
};
</script>
