<template>
    <div>
        <media-image-popup
            :active.sync="showCarJamInfo"
            :image-with-detail="mediaData"
            @close="close"
        />
    </div>
</template>

<script>
import detectionDetailMixin from '@/mixins/detectionDetailMixin';

export default {
    name: 'HSMedia',

    data() {
        return {
            isLoading: false,
            mediaData: {},
        };
    },

    mixins: [detectionDetailMixin],

    computed: {
        mediaId() {
            return this.$route.params.id;
        },
    },

    mounted() {
        this.viewDetailByMediaId(this.mediaId);
    },

    methods: {
        close() {
            if (this.$route.path.includes('vehicle-explorer')) {
                this.$router.push('/dashboard/vehicle-explorer');
            } else if (this.$route.path.includes('health-and-safety/explorer')) {
                this.$router.push('/dashboard/health-and-safety/explorer');
            } else {
                // Default route
                this.$router.push('/');
            }
        },
    },

};
</script>
