import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '../router';

Vue.use(VueAnalytics, {
    id: 'UA-146793666-1',
    router,
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href,
            };
        },
    },
    checkDuplicatedScript: true,
    debug: {
        sendHitTask: process.env.NODE_ENV === 'production',
    },
});
