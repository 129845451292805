import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { ExportToCsv } from 'export-to-csv';
import * as dayjs from 'dayjs';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { mauiMapDetectionsCsvTransformer, mauiMapDetectionsTransformer } from '@/utils/transformers/maui63/detections';
import { cancelSource } from '@/utils/api-tokens';

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    mapView: 'default',
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),
    formattedApiResults: (state) => mauiMapDetectionsTransformer(state.data),
    csvApiResults: (state) => mauiMapDetectionsCsvTransformer(state.data),
    canExportCsv: (state) => !isEmpty(state.data),
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
    setMapView(state, { mapView }) {
        state.mapView = mapView;
    },
};

const actions = {
    loadData({ state, commit, rootState, rootGetters }, { allTime = false }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const urlParams = {
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
        };

        const params = buildUrlParams(urlParams);

        const path = allTime
            ? 'maui/dolphin_sightings/map'
            : buildStartEndDateUrl({
                path: 'maui/dolphin_sightings/map',
                startDate,
                endDate,
            });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
            cancelToken: cancelSource.token,
            params,
        })
            .then((response) => {
                commit('setStatus', { status: STATUS_SUCCESS });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    console.error('Request canceled', err.message);
                    return;
                }
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    loadDataCount() {
        const path = 'maui/dolphin_sightings/count';

        return axios({
            url: getApiUrl({ path }),
        })
            .then((response) => (
                get(response, 'data.count', 0)
            ));
    },

    exportData({ getters }) {
        if (!getters.canExportCsv) {
            return Promise.resolve(true);
        }

        const csvExporter = new ExportToCsv({
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: `maui63-data-export-${dayjs().format('YYYYMMDD-HHmmss')}`,
        });

        csvExporter.generateCsv(getters.csvApiResults);

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
