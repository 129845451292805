<template>
    <div class="be-diff-label" :class="rootClasses">
        <div class="be-diff-label__diff">
            <b-icon :icon="`menu-${direction}`" />
            <span v-if="number">{{number }}</span>
        </div>
        <div class="be-diff-label__label">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        direction: {
            type: String,
            default: 'up',
            validate: (val) => ['up', 'down'].includes(val),
        },
        number: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
    },

    computed: {
        rootClasses() {
            return [`is-${this.direction}`];
        },
    },
};
</script>

<style lang="scss" scoped>
    .be-diff-label {
        display: inline-flex;
        font-size: 12px;
        font-weight: $weight-bold;
        color: $grey;
        line-height: 1;
        align-items: center;

        &__diff {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }

        &.is-up &__diff {
            color: $success;
        }

        &.is-down &__diff {
            color: $danger;
        }
    }
</style>
