<template>
    <be-widget class="alert-table-widget" :has-padding="true" :is-loading="isLoading" :has-table="true">
        <template v-slot:content>
            <b-table
                :data="tableData"
                :hoverable="true"
                class="is-seamless"
            >
                <b-table-column label="Description" v-slot="props">

                    <div class="alert-table-widget__description">
                        <b-icon
                            class="alert-table-widget__description__icon"
                            :icon="getRowIcon(props.row)"
                            custom-size="mdi-18px"
                        />
                        <p class="alert-table-widget__description__text">{{ props.row.description }}</p>
                    </div>
                </b-table-column>
                <b-table-column label="Recipients" v-slot="props">
                    <alert-recepients-list :alert="props.row" />
                </b-table-column>
                <b-table-column label="List" v-slot="props">
                    <alert-list :alert="props.row" />
                </b-table-column>
                <b-table-column label="Updated on" width="150" v-slot="props">
                    <span class="has-cursor-default">
                        {{ props.row.date_updated | dateTimeHuman }}
                    </span>
                </b-table-column>
                <b-table-column label="Enabled" width="50" v-slot="props">
                    <span class="has-cursor-default">
                        <b-checkbox :value="props.row.enabled" @input="toggleAlert(props.row)" />
                    </span>
                </b-table-column>
                <b-table-column label=" " width="50" header-class="has-text-right" v-slot="props">
                    <p class="has-text-right has-text-weight-bold">
                        <router-link :to="`/dashboard/alerts/${props.row.id}`">
                            Edit
                        </router-link>
                    </p>
                </b-table-column>
            </b-table>
            <be-pagination
                key="alerts"
                :current-page.sync="currentPage"
                :per-page.sync="perPage"
                :count="alertsCount"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BePagination from '@/components/global/BePagination';
import AlertRecepientsList from '@/components/local/alerts/AlertRecepientsList';
import AlertList from '@/components/local/alerts/AlertList';
import formattersMixin from '@/mixins/formattersMixin';

export default {
    components: {
        AlertList,
        AlertRecepientsList,
        BePagination,
    },

    mixins: [formattersMixin],

    computed: {
        ...mapState('alerts/alerts', ['alerts', 'alertsCount']),
        ...mapGetters({
            isWebhooksLoading: 'webhooks/isLoading',
            isAlertsLoading: 'alerts/alerts/isLoading',
            isListsLoading: 'alerts/alerts/isLoading',
        }),

        isLoading() {
            return this.isAlertsLoading || this.isListsLoading || this.isWebhooksLoading;
        },

        tableData() {
            return this.alerts || [];
        },

        perPage: {
            get() {
                return this.$store.state.alerts.alerts.perPage;
            },
            set(perPage) {
                return this.setPerPage({ perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.alerts.alerts.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.setPage({ page });
            },
        },
    },

    watch: {
        currentPage() {
            this.getAlerts({});
        },
        perPage() {
            this.getAlerts({});
        },
    },

    methods: {
        ...mapActions('alerts/alerts', ['getAlerts', 'updateAlert']),
        ...mapActions('alerts/lists', ['getAllLists']),
        ...mapActions('webhooks', ['getWebhooks']),
        ...mapMutations('alerts/alerts', ['setPerPage', 'setPage']),

        toggleAlert(row) {
            this.updateAlert({
                alertId: row.id,
                data: {
                    ...row,
                    enabled: !row.enabled,
                },
            });
        },

        getRowIcon(row) {
            if (row.email) {
                return 'email-outline';
            }
            if (row.msg_number) {
                return 'message-outline';
            }
            if (row.webhook) {
                return 'webhook';
            }
            return '';
        },
    },

    mounted() {
        this.getAlerts({});
        this.getWebhooks({});
        this.getAllLists({});
    },
};
</script>

<style scoped lang="scss">
.alert-table-widget {
    &__description {
        display: flex;
        align-items: center;
        cursor: default;

        &__icon {
            margin-right: 5px;
        }

        &__text {
            font-weight: $weight-bold;
        }
    }
}
</style>
