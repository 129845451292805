<template>
    <div class="rois">
        <div class="rois__tabs" role="tablist">
            <template v-if="isLoaded">
                <nav class="tabs">
                    <ul>
                        <li role="presentation" :class="{ 'is-active': activeTab === 0 }">
                            <a href="#" role="tab" tabindex="-1" @click.prevent="changeTab(0)">
                                <span>Visual ROIs</span>
                            </a>
                        </li>
                        <li role="presentation" :class="{ 'is-active': activeTab === 1 }">
                            <a href="#" role="tab" tabindex="-1" @click.prevent="changeTab(1)">
                                <span>Manual ROIs</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div v-if="activeTab === 0" class="tab-content rois__tab">
                    <roi-h-s-camera-image
                        v-if="hasImage"
                        ref="image"
                        :camera="camera"
                        :image="imageObject"
                        :rois="rois"
                    />
                    <template v-else-if="isLoaded">
                        <div class="rois__message">
                            <div class="content">
                                Preview is not available because camera has not received any images yet.
                                Please contact the RVision team for further details.
                            </div>
                        </div>
                    </template>
                </div>
                <div v-if="activeTab === 1" class="tab-content rois__tab">
                    <roi-h-s-camera-manual
                        ref="manual"
                        :camera="camera"
                        :rois="rois"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isEmpty, get } from 'lodash';
import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import noticesMixin from '@/mixins/noticesMixin';
import manageHSCameraMixin from '@/mixins/hs/manageHSCameraMixin';
import RoiHSCameraImage from '@/components/local/sites/hs/partials/RoiHSCameraImage';
import RoiHSCameraManual from '@/components/local/sites/hs/partials/RoiHSCameraManual';

export default {
    components: {
        RoiHSCameraImage,
        RoiHSCameraManual,
    },

    mixins: [
        noticesMixin,
        manageHSCameraMixin,
    ],

    props: {
        cameraId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            rois: [],
            activeTab: 0,
            isLoaded: false,
            imageObject: null,
            displayType: 'manual',
        };
    },

    computed: {
        hasImage() {
            return this.imageObject !== null;
        },

        activeRef() {
            return this.$refs[this.activeTab ? 'manual' : 'image'];
        },
    },

    mounted() {
        this.isLoading = true;
        this.loadData()
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        ...mapActions('media/images', ['loadDataRow']),

        changeTab(index) {
            if (this.activeTab === index) {
                return;
            }

            if (!this.activeRef.validate()) {
                return;
            }

            // update rois from the previous tab
            this.rois = this.activeRef.getRoiData();
            this.activeTab = index;
        },

        loadImage() {
            this.imageObject = null;

            return axios({
                url: getApiUrl({ path: `cameras/${this.camera.id}` }),
            })
                .then((response) => get(response, 'data.last_image_id'))
                .then((lastImageId) => {
                    if (!lastImageId) {
                        return false;
                    }
                    return this.loadDataRow({ id: lastImageId });
                })
                .then((data) => {
                    if (data && !isEmpty(data.detected_objects) && data.image_file) {
                        this.imageObject = {
                            ...data.detected_objects,
                            url: data.image_file,
                            id: data.id,
                        };
                    }
                });
        },

        loadRois() {
            return this.$http({
                method: 'GET',
                url: getApiUrl({ path: `cameras/${this.camera.id}/rois` }),
            })
                .then((result) => {
                    this.rois = result.data;
                });
        },

        loadData() {
            this.isLoaded = false;
            return Promise.all([
                this.loadRois(),
                this.loadImage(),
            ]).then(() => {
                this.isLoaded = true;
            });
        },

        save() {
            if (this.isLoading) {
                return Promise.resolve(false);
            }
            if (!this.activeRef) {
                return Promise.resolve(true);
            }
            if (!this.activeRef.validate()) {
                return Promise.resolve(false);
            }

            this.requestErrorMessage = null;
            this.isLoading = true;
            return Promise.all(
                this.activeRef.getSaveData()
                    .map((roi) => this.updateRoi(roi)),
            )
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Camera ROIs updated successfully',
                    });
                    return true;
                })
                .catch((error) => {
                    this.$emit('request-error', error.message);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        updateRoi(roi) {
            const { deleted, id, data } = roi;
            let url;
            let method;
            const path = `cameras/${this.camera.id}/rois`;

            if (deleted) {
                url = getApiUrl({ path: `${path}/${id}` });
                method = 'DELETE';
            } else if (id) {
                url = getApiUrl({ path: `${path}/${id}` });
                method = 'PUT';
            } else {
                url = getApiUrl({ path });
                method = 'POST';
            }

            return this.$http({ method, url, data });
        },
    },
};
</script>

<style lang="scss" scoped>
.rois {
    &__help-icon {
        @include button-reset;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        margin-left: 5px;
        color: $primary;
        @include transition-button;

        &:hover {
            opacity: .7;
        }
    }

    &__tabs {
        min-height: 300px;
    }

    &__tab {
        padding-left: 0;
        padding-right: 0;
    }

    &__message {
        height: 235px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $grey-light;
        font-size: .9em;
        cursor: default;
        text-align: center;
        pointer-events: none;
        user-select: none;
        @include tablet {
            padding-left: 20%;
            padding-right: 20%;
        }
    }

}
</style>
