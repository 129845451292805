import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { cancelSource } from '@/utils/api-tokens';
import { errorHandler } from '@/utils/errors';

const getDefaultState = () => ({
    tags: [],
    status: '',
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
};

const mutations = {
    setTags(state, { tags }) {
        const sortedTags = [...tags].map((tag) => {
            const prefix = tag.label.toUpperCase().split(':')[0];
            if (prefix === 'CAM') {
                return { ...tag, type: 'CAM' };
            } if (prefix === 'SITE') {
                return { ...tag, type: 'SITE' };
            }
            return { ...tag, type: null };
        });
        sortedTags.sort((labelA, labelB) => {
            const prefixA = labelA.label.toUpperCase().split(':')[0];
            const prefixB = labelB.label.toUpperCase().split(':')[0];

            if (prefixA === 'CAM' && prefixB !== 'CAM') {
                return -1;
            } if (prefixA !== 'CAM' && prefixB === 'CAM') {
                return 1;
            } if (prefixA === 'SITE' && prefixB !== 'SITE') {
                return -1;
            } if (prefixA !== 'SITE' && prefixB === 'SITE') {
                return 1;
            }
            return labelA.label.localeCompare(labelB.label);
        });

        state.tags = sortedTags;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    loadTags({ commit, rootGetters }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            url: getApiUrl({ path: 'tags' }),
            cancelToken: cancelSource.token,
        })
            .then((response) => {
                commit('setTags', { tags: response.data });
                commit('setStatus', { status: STATUS_SUCCESS });
                return true;
            })
            .catch((err) => {
                if (axios.isCancel(err) || !rootGetters['auth/isLoggedIn']) {
                    return;
                }
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
