import axios from 'axios';
import { API_VERSION, getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import {
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';

const hasPagination = true;

const getDefaultState = () => ({
    ...getDefaultDataState({ hasPagination }),
});

const state = getDefaultState();

const getters = {
    ...getDefaultDataGetters({ hasPagination }),

    formattedApiResults(state, getters) {
        return getters.apiResults;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination }),

    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    getHistory({ state, commit }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = buildUrlParams({
            page: state.currentPage,
            perPage: state.perPage,
        });

        const path = 'alert/history';

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path, apiVersion: API_VERSION.v2 }),
            params,
        })
            .then((response) => {
                commit('setStatus', { status: STATUS_SUCCESS });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
