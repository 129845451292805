import { get, omit } from 'lodash';
import { CAMERA_VALIDATION_MESSAGES } from '@/utils/sites/cameras';
import { CAMERA_COUNTRIES, CAMERA_LOCATIONS, SITE_CAMERA_NAME_MAXLENGTH } from '@/utils/sites/constants';
import { getValidationMessage } from '@/utils/validations/validations';

export default {
    data() {
        return {
            validationMessages: {
                ...CAMERA_VALIDATION_MESSAGES,
                name: {
                    required: CAMERA_VALIDATION_MESSAGES.name,
                    isUnique: 'You already have a camera with the same name. Please choose another name for this camera.',
                    maxLength: `Site name must be less than ${SITE_CAMERA_NAME_MAXLENGTH}
                    characters in length. Please choose a shorter name.`,
                },
                cameraFunction: 'Please select the camera function',
            },
            countries: CAMERA_COUNTRIES,
            locations: omit(CAMERA_LOCATIONS, ['none']),
        };
    },

    methods: {
        fieldHasError(field, parent = 'computedValue') {
            if (!this.$v) {
                return false;
            }

            const getter = [
                parent,
                field,
                '$error',
            ].filter((g) => g);

            return get(this.$v, getter.join('.'), false);
        },

        getFieldType(field, parent = 'computedValue') {
            if (this.fieldHasError(field, parent)) {
                return 'is-danger';
            }

            return '';
        },

        getFieldMessage(field, parent = 'computedValue') {
            if (!this.fieldHasError(field, parent)) {
                return '';
            }
            return getValidationMessage(field, this.$v[parent], this.validationMessages);
        },
    },
};
