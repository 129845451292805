<template>
    <div class="webhooks-single">
        <div class="app-container" v-if="webhook && !isError">
            <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
            <div class="level">
                <div class="level-left" />
            </div>
            <be-page-header :app-container="false">
                <template v-slot:default>
                    {{ pageHeading }}
                </template>
                <template v-slot:back>
                    <b-button
                        tag="router-link"
                        type="is-light"
                        to="/dashboard/webhooks"
                        class="be-button is-back"
                        icon-left="chevron-left"
                    />
                </template>
            </be-page-header>
            <div class="webhooks-single__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field
                            label="Name"
                            :type="fieldType('name')"
                            :message="fieldMessage('name')"
                        >
                            <b-input v-model="webhook.name" />
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-field label="Type">
                            <b-select v-model="webhook.webhook_type" expanded>
                                <option value="manual">Manual</option>
                                <option :disabled="isAppDisabled('salesforce')" value="salesforce">Salesforce</option>
                            </b-select>
                        </b-field>
                    </div>

                    <div class="manual-setup" v-if="webhook.webhook_type === 'manual'">
                        <div class="column is-12">
                            <b-field
                                label="Url"
                                :type="fieldType('url')"
                                :message="fieldMessage('url')"
                            >
                                <b-input type="url" v-model="webhook.url" />
                            </b-field>
                        </div>

                        <div class="column is-12">
                            <b-field
                                label="Method"
                                :type="fieldType('method')"
                                :message="fieldMessage('method')"
                            >
                                <b-select v-model="webhook.method">
                                    <option value="post">POST</option>
                                    <option value="get">GET</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column is-12">
                            <header-field
                                :type="fieldType('headers')"
                                :message="fieldMessage('headers')"
                                v-model="webhook.headers"
                            />
                        </div>
                        <div class="column is-12">
                            <b-field>
                                <b-checkbox v-model="webhook.include_byte_image_payload">
                                    Include BYTE Image Payload
                                </b-checkbox>
                            </b-field>
                        </div>
                    </div>
                    <!--                    <div class="column is-12">-->
                    <!--                        <b-field>-->
                    <!--                            <b-checkbox v-model="webhook.pattern_filter_enabled">-->
                    <!--                                Pattern filter result-->
                    <!--                            </b-checkbox>-->
                    <!--                        </b-field>-->
                    <!--                        <b-field-->
                    <!--                            v-if="webhook.pattern_filter_enabled"-->
                    <!--                            :type="fieldType('pattern_filter_expression')"-->
                    <!--                            :message="fieldMessage('pattern_filter_expression')"-->
                    <!--                        >-->
                    <!--                            <b-input-->
                    <!--                                v-model="webhook.pattern_filter_expression"-->
                    <!--                                placeholder="RegEx pattern expression"-->
                    <!--                            />-->
                    <!--                        </b-field>-->
                    <!--                    </div>-->
                    <!--                    <div class="column is-12">-->
                    <!--                        <b-field>-->
                    <!--                            <b-checkbox v-model="webhook.pattern_filter_site_camera">-->
                    <!--                                Pattern filter site ID and camera ID-->
                    <!--                            </b-checkbox>-->
                    <!--                        </b-field>-->
                    <!--                        <b-field-->
                    <!--                            v-if="webhook.pattern_filter_site_camera"-->
                    <!--                            :type="fieldType('pattern_filter_site_camera_expression')"-->
                    <!--                            :message="fieldMessage('pattern_filter_site_camera_expression')"-->
                    <!--                        >-->
                    <!--                            <b-input-->
                    <!--                                v-model="webhook.pattern_filter_site_camera_expression"-->
                    <!--                                placeholder="RegEx pattern expression"-->
                    <!--                            />-->
                    <!--                        </b-field>-->
                    <!--                    </div>-->
                    <div class="column is-12">
                        <b-field>
                            <b-checkbox v-model="webhook.only_call_if_plate">
                                Only call when result contains a PLATE recognition
                            </b-checkbox>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-multiline" style="padding: 10px; margin: 20px 0 40px 0px; background:#00000044">
                    <div class="column is-12">
                        <b-field
                            label="Test Image ID"
                        >
                            <b-input v-model="webhook.test_image_id" placeholder="Leave blank to use last submitted image" />
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-button
                            class="be-button is-secondary"
                            expanded
                            @click="test"
                            :loading="isTesting"
                            :disabled="isSaving"
                        >
                            Run Test
                        </b-button>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        <b-button
                            class="be-button is-primary"
                            expanded
                            @click="save"
                            :loading="isSaving"
                            :disabled="isTesting"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
                <template v-if="!isNew">
                    <hr />
                    <div class="columns">
                        <div class="column is-12 has-text-right">
                            <a href="#" class="has-text-danger" @click.prevent="remove">
                                <small>Remove Webhook</small>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-else-if="isError" class="app-container">
            <div class="py-5 content has-text-grey-lighter has-text-centered">
                <p>Invalid webhook</p>
                <p>
                    <b-button
                        tag="router-link"
                        to="/dashboard/webhooks"
                        class="be-button is-outline is-wider"
                    >
                        Back
                    </b-button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { find } from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, url } from 'vuelidate/lib/validators';
import noticesMixin from '@/mixins/noticesMixin';
import HeaderField from '@/components/local/webhooks/HeaderField';
import { getApiUrl } from '@/utils/api';

const getDefaultWebhookData = () => ({
    name: '',
    webhook_type: 'manual',
    url: '',
    method: 'post',
    headers: [],
    only_call_if_plate: false,
    include_byte_image_payload: false,
    test_image_id: '',
});

export default {
    components: {
        HeaderField,
    },

    mixins: [
        validationMixin,
        noticesMixin,
    ],

    validations() {
        let webhook = {
            name: { required },
            headers: {
                $each: {
                    header: { required },
                    value: { required },
                },
            },
        };

        if (this.webhook.webhook_type === 'manual') {
            webhook = {
                ...webhook,
                url: { required, url },
                method: { required },
            };
        }

        return { webhook };
    },

    data() {
        return {
            isTesting: false,
            isSaving: false,
            isRemoving: false,
            isError: false,

            webhook: getDefaultWebhookData(),

            validationMessages: {
                name: 'Please enter webhook name',
                method: 'Please select the delivery method',
                url: 'Please provide a valid URL',
                headers: 'Please fill all the headers values',
            },
        };
    },

    computed: {
        ...mapState('webhooks', ['webhooksFull']),
        ...mapGetters('webhooks', ['isLoading']),
        ...mapGetters('marketplace/user', ['allEnabledApps']),

        canAction() {
            return !this.isSaving && !this.isTesting && !this.isRemoving;
        },

        webhookId() {
            return this.$route.params.guid;
        },

        isNew() {
            return this.$route.params.guid === 'new';
        },

        pageHeading() {
            return this.isNew ? 'Add a New Webhook' : 'Edit Webhook';
        },
    },

    mounted() {
        this.loadAllAppSettings({});
        if (!this.isNew) {
            this.getWebhook({ webhookId: this.webhookId })
                .then(() => {
                    this.webhook = this.getModelData();
                })
                .catch(() => {
                    this.isError = true;
                });
        }
    },

    methods: {
        ...mapActions('webhooks', ['getWebhook', 'updateWebhook', 'createWebhook', 'removeWebhook']),
        ...mapActions('marketplace/user', ['loadAllAppSettings']),

        test() {
            if (!this.canAction) {
                return;
            }

            this.closeNotices();

            this.$v.$touch();
            if (this.$v.$invalid) {
                this.displayErrorNotice({
                    message: 'Please fix the form errors',
                    closeOthers: true,
                });
                return;
            }

            this.isTesting = true;

            const testData = this.getTestData();
            let testUri = 'webhooks/test';
            if (testData.test_image_id !== '') {
                testUri = `webhooks/test/image/${testData.test_image_id}/`;
            }

            this.$http({
                method: 'POST',
                url: getApiUrl({ path: testUri }),
                data: testData,
            }).then(() => {
                this.displaySuccessNotice({
                    message: 'Test successful',
                    closeOthers: true,
                });
            }).catch(() => {
                this.displayErrorNotice({
                    message: 'Test failed',
                    closeOthers: true,
                });
            }).finally(() => {
                this.isTesting = false;
            });
        },

        getModelData() {
            const webhook = find(this.webhooksFull, { id: this.webhookId });

            let headers = [];
            if (webhook.headers) {
                headers = Object.keys(webhook.headers).map((header) => ({
                    header,
                    value: webhook.headers[header],
                }));
            }

            return {
                ...this.webhook,
                ...webhook,
                headers,
                method: webhook.method ? webhook.method.toLowerCase() : this.webhook.method,
            };
        },

        getSaveData() {
            return {
                ...this.webhook,
                headers: this.webhook.headers.reduce((obj, header) => {
                    obj[header.header] = header.value; // eslint-disable-line no-param-reassign
                    return obj;
                }, {}),
            };
        },

        getTestData() {
            return {
                name: this.webhook.name,
                url: this.webhook.url,
                method: this.webhook.method,
                only_call_if_plate: this.webhook.only_call_if_plate,
                wait_for_result: this.webhook.wait_for_result,
                include_byte_image_payload: this.webhook.include_byte_image_payload,
                test_image_id: this.webhook.test_image_id,
                headers: this.webhook.headers.reduce((obj, header) => {
                    obj[header.header] = header.value; // eslint-disable-line no-param-reassign
                    return obj;
                }, {}),
                webhook_type: this.webhook.webhook_type,
            };
        },

        save() {
            if (!this.canAction) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isSaving = true;
            this.closeNotices();

            const data = this.getSaveData();
            const promise = this.isNew
                ? this.createWebhook({ data })
                : this.updateWebhook({ webhookId: this.webhookId, data });

            promise.then(() => {
                this.displaySuccessNotice({
                    message: this.isNew
                        ? 'Webhook added successfully'
                        : 'Webhook updated successfully',
                    closeOthers: true,
                });
                this.$router.push('/dashboard/webhooks');
            }).catch(() => {
                this.displayErrorNotice({
                    message: this.isNew
                        ? 'Cannot save webhook'
                        : 'Cannot update webhook',
                });
            }).finally(() => {
                this.isSaving = false;
            });
        },

        remove() {
            if (!this.canAction) {
                return;
            }

            this.closeNotices();

            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this webhook?',
                type: 'is-danger',
                onConfirm: () => {
                    this.isRemoving = true;
                    this.removeWebhook({
                        webhookId: this.webhookId,
                    })
                        .then(() => {
                            this.displaySuccessNotice({
                                message: 'Webhook removed successfully',
                            });
                            this.$emit('close');
                            this.$router.push('/dashboard/webhooks');
                        })
                        .finally(() => {
                            this.isRemoving = false;
                        });
                },
            });
        },

        fieldType(field) {
            return this.$v.webhook[field].$error ? 'is-danger' : '';
        },

        fieldMessage(field) {
            return this.$v.webhook[field].$error && this.validationMessages[field]
                ? this.validationMessages[field]
                : '';
        },
        isAppDisabled(appId) {
            return this.allEnabledApps.indexOf(appId) < 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.webhooks-single {
    .app-container {
        position: relative;
    }

    &__form {
        width: 100%;
        max-width: 500px;
    }
}
</style>
