<template>
    <be-widget class="chart" :is-loading="isLoading">
        <template v-slot:heading>
            Minimum and Average Distances from Forklift
        </template>
        <template v-slot:content>
            <div class="chart">
                <scatter-chart
                    class="chart__wrapper"
                    :chart-data="scatterChartData"
                    :options="scatterChartOptions"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ScatterChart from '@/utils/chartjs/ScatterChart';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import { CHART_COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({ labels: [], datasets: [] });

export default {
    components: {
        ScatterChart,
    },

    mixins: [widgetDataMixin],

    data() {
        return {
            currentView: 'scatter',
            scatterChartData: getChartDataDefaults(),
            scatterChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: false,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            tooltipFormat: 'YYYY-MM-DD hA',
                            stepSize: 1,
                            unit: 'day',
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Time',
                        },
                        bounds: 'ticks',
                    }],
                    yAxes: [{
                        ticks: {
                            stepSize: 1,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Distance',
                        },
                    }],
                },
            },
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('hs/proximity', [
            'isLoading',
            'apiResults',
        ]),
    },

    watch: {
        apiResults: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },

    methods: {
        ...mapActions('hs/proximity', ['loadData']),

        canPopulateChart() {
            return this.apiResults && this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        getScatterData() {
            if (!this.apiResults) {
                return {};
            }

            const pointBackgroundColors = [];
            const data = [];

            for (let i = 0; i < this.apiResults.dataAvg.length; i += 1) {
                pointBackgroundColors.push(CHART_COLORS.yellow);
                data.push({
                    x: this.apiResults.dataAvg[i].time,
                    y: this.apiResults.dataAvg[i].value,
                });
            }

            for (let i = 0; i < this.apiResults.dataMin.length; i += 1) {
                pointBackgroundColors.push(CHART_COLORS.red);
                data.push({
                    x: this.apiResults.dataMin[i].time,
                    y: this.apiResults.dataMin[i].value,
                });
            }

            return {
                datasets: [{
                    label: 'Distance',
                    data,
                    pointBackgroundColor: pointBackgroundColors,
                    borderWidth: 0,
                }],
            };
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            // to overcome the problem of non-reactive deep updates
            this.scatterChartData = getChartDataDefaults();
            this.$nextTick(() => {
                this.scatterChartData = this.getScatterData();
            });
        },

        resetChartData() {
            this.scatterChartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
      position: relative;
      height: 500px;
      width: 100%;
      margin-top: auto;
  }
}
</style>
