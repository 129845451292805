<template>
    <b-checkbox
        class="be-checkbox"
        :class="{ 'is-large': size === 'large' }"
        v-model="canStoreImages"
    >
        {{ label }}
    </b-checkbox>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { errorHandler } from '@/utils/errors';

export default {
    props: {
        label: {
            type: String,
            default: 'Contribute images to improve accuracy',
        },
        size: {
            type: String,
            default: '',
            validator: (val) => ['', 'large'].includes(val),
        },
    },

    data() {
        return {
            canStoreImages: true,
        };
    },

    computed: {
        ...mapGetters('user', ['userDetails']),
    },

    watch: {
        userDetails: {
            handler(val) {
                if (val && val.plan) {
                    this.canStoreImages = !!val.is_image_stored;
                }
            },
            immediate: true,
            deep: true,
        },

        canStoreImages(value) {
            this.setCanStoreImages({ canStoreImages: value })
                .catch((err) => errorHandler('Could not update store images flag', err));
        },
    },

    methods: {
        ...mapActions('plans', ['setCanStoreImages']),
    },
};
</script>
