<template>
    <div
        v-if="scale"
        class="roi-canvas"
        @contextmenu.prevent="() => null"
        tabindex="0"
        @keydown.esc="onEscape"
        @keydown.delete="onDelete"
    >
        <div :style="canvasStyle" class="roi-canvas__inner">
            <v-stage
                ref="stage"
                @click="handleClick"
                :config="configKonva"
                @mousemove="handleMouseMove"
            >
                <roi-camera-image-layer
                    ref="layer"
                    v-for="roi in sortedRoisPoints"
                    :key="`roi-${roi.id}-${roi.active ? 1 : 0}`"
                    :id="roi.id"
                    :active="roi.active"
                    :roi-points="roi.points"
                    :size="renderedSize"
                    :mouse-position="currentMousePosition"
                    :bounds="bounds"
                    :scale="realScale"
                    :label="label[roi.id]"
                    @change="handleRoiChange"
                />
            </v-stage>
        </div>
        <div class="roi-canvas__info mt-2">
            Regions of interest can contain a maximum of 40 points.
        </div>
        <div class="mt-4">
            <template v-if="finishedRoisPoints.length">
                <b-field v-for="(roi, index) in finishedRoisPoints" :key="roi.id">
                    <b-input
                        v-model="label[roi.id]"
                        placeholder="ROI Label"
                        @focus="isFocusedInput = true"
                        @blur="isFocusedInput = false"
                        expanded
                    />
                    <div class="control">
                        <b-button icon-left="close" @click="removeRoiPoint(index, activeRoiIndex === index)" />
                    </div>
                </b-field>
            </template>

            <template v-else>
                <div class="error">
                    Add at least one ROI
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { get, isEmpty, isEqual, omit, orderBy, uniqueId, uniqWith } from 'lodash';
import RoiCameraImageLayer from '@/components/local/sites/partials/RoiCameraImageLayer';

export default {
    components: { RoiCameraImageLayer },
    props: {
        scale: {
            type: Number,
            required: true,
        },
        renderedSize: {
            type: Object,
            required: true,
        },
        rois: {
            type: Array,
            required: true,
        },
        canvasStyle: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            activeRoiIndex: null,
            currentMousePosition: [0, 0],
            isFocusedInput: false,
            label: {},
            roisPoints: [],
            deletedRoisPoints: [],
        };
    },

    computed: {
        bounds() {
            return {
                x: [0, this.renderedSize.width],
                y: [0, this.renderedSize.height],
            };
        },
        configKonva() {
            return {
                width: this.renderedSize.width,
                height: this.renderedSize.height,
            };
        },

        realScale() {
            return this.scale || 1;
        },

        finishedRoisPoints() {
            return this.roisPoints.filter((p) => p.isFinished);
        },

        sortedRoisPoints() {
            return orderBy(this.roisPoints, ['active']);
        },
    },

    mounted() {
        // wait till it renders first
        this.$nextTick(() => {
            // generate a full ROI if no objects present
            if (isEmpty(this.rois)) {
                this.generateDefaultRoiPoints();
                return;
            }
            const { roisPoints, label } = this.convertRoisPoints(this.rois);
            this.label = label;
            this.roisPoints = roisPoints;
        });
    },

    watch: {
        finishedRoisPoints: {
            handler(val) {
                val.forEach((roi) => this.maybeGenerateLabel(roi.id, val.length));
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        generateId() {
            return uniqueId('layer-');
        },
        maybeGenerateLabel(roiId, len = 1) {
            if (isEmpty(this.label[roiId])) {
                this.label[roiId] = len === 1 ? 'Default' : `ROI #${len}`;
            }
        },
        hasActiveIndex() {
            return this.activeRoiIndex !== null;
        },
        getActiveLayer() {
            return this.hasActiveIndex()
                ? this.$refs.layer[this.activeRoiIndex]
                : null;
        },
        convertRoisPoints(rawObjects) {
            const label = {};
            const roisPoints = [];

            rawObjects.forEach((roiObject) => {
                const rois = get(roiObject, 'roi', []);
                const tags = get(roiObject, 'tags', []);

                rois.forEach((rowPoints, index) => {
                    const points = uniqWith(this.convertPointsToScale(rowPoints), isEqual);
                    const id = this.generateId();

                    roisPoints.push({
                        id,
                        points,
                        active: false,
                        roiId: roiObject.id,
                        roiType: roiObject.roi_type || 0,
                    });

                    if (tags[index]) {
                        label[id] = tags[index].label;
                    }
                });

                return { label, roisPoints };
            });

            return { label, roisPoints };
        },

        generateDefaultRoiPoints() {
            const id = this.generateId();
            this.maybeGenerateLabel(id);
            // const { roisPoints, label } = this.convertRoisPoints(this.rois);
            // this.label = label;
            // this.roisPoints = roisPoints;

            this.roisPoints = [{
                id,
                points: [
                    [0, 0],
                    [this.renderedSize.width, 0],
                    [this.renderedSize.width, this.renderedSize.height],
                    [0, this.renderedSize.height],
                ],
                active: false,
                roiType: 0,
            }];
        },

        convertPointsToScale(points) {
            return points.map((roi) => ([
                roi[0] / this.realScale,
                roi[1] / this.realScale,
            ]));
        },

        convertPointsToOriginal(points) {
            return points.map((roi) => ([
                parseInt(roi[0] * this.realScale, 10),
                parseInt(roi[1] * this.realScale, 10),
            ]));
        },

        getMousePosition(stage) {
            return [stage.getPointerPosition().x, stage.getPointerPosition().y];
        },

        handleMouseMove(event) {
            this.currentMousePosition = this.getMousePosition(
                event.target.getStage(),
            );
        },

        handleClick(event) {
            if (event.evt.button > 0) {
                // we don't care about any of the other clicks
                this.handleRightClick(event);
                return;
            }
            if (this.hasActiveIndex()) {
                this.getActiveLayer().handleClick(event);
                return;
            }

            const layer = event.target.getLayer();
            if (layer) {
                this.activateRoiPoint(layer.attrs['data-id']);
                return;
            }

            this.addRoiPoint();
        },

        handleRightClick() {
            if (this.hasActiveIndex() && !this.getActiveLayer().isFinished) {
                this.removeRoiPoint(this.activeRoiIndex);
            }
            this.activateRoiPoint(null);
        },

        onDelete() {
            if (!this.hasActiveIndex() || this.roisPoints.length < 2) {
                return;
            }
            this.removeRoiPoint(this.activeRoiIndex, true);
        },

        removeRoiPoint(index, deactivate = false) {
            // save the deleted point
            if (this.roisPoints[index] && this.roisPoints[index].roiId) {
                this.deletedRoisPoints.push(this.roisPoints[index].roiId);
            }
            this.roisPoints = this.roisPoints.filter((point, idx) => (
                idx !== index
            ));

            // remove the label
            this.label = omit(this.label, [this.activeRoiIndex]);

            if (deactivate) {
                this.activateRoiPoint(null);
            }
        },

        addRoiPoint() {
            this.roisPoints.push({
                id: this.generateId(),
                points: [this.currentMousePosition],
                active: true,
            });
            this.activeRoiIndex = this.roisPoints.length - 1;
        },

        activateRoiPoint(id) {
            let index = null;
            const points = [];
            this.roisPoints.forEach((point, idx) => {
                const active = point.id === id;
                points.push({ ...point, active });
                if (active) {
                    index = idx;
                }
            });
            this.activeRoiIndex = index;
            this.roisPoints = points;
        },

        updateRoiPoints(id, points, isFinished) {
            const len = points.length;
            this.roisPoints = this.roisPoints.map((roiPoints) => {
                if (roiPoints.id !== id) {
                    return roiPoints;
                }
                if (!len) {
                    return null;
                }
                return {
                    ...roiPoints,
                    points,
                    isFinished,
                };
            }).filter((r) => r);

            if (!len) {
                this.activateRoiPoint(null);
            }
        },

        onContext(event) {
            event.preventDefault();
        },

        onEscape() {
            this.$root.$emit('roi:camera:esc');
        },

        handleRoiChange({ points, id, isFinished }) {
            this.updateRoiPoints(id, points, isFinished);
        },

        validate() {
            return !!this.sortedRoisPoints.length && this.$refs.layer.reduce((curr, row) => (
                curr && row.isValid()
            ), true);
        },

        getSaveData() {
            // deletedRoisPoints
            const saveData = [];

            this.roisPoints.forEach((roi) => {
                saveData.push({
                    id: roi.roiId || null,
                    deleted: false,
                    data: {
                        roi: [this.convertPointsToOriginal(roi.points)],
                        tags: this.label[roi.id] ? [{ label: this.label[roi.id] }] : [],
                        roi_type: roi.roiType || 0,
                    },
                });
            });

            this.deletedRoisPoints.forEach((id) => {
                saveData.push({ id, deleted: true });
            });

            return saveData;
        },
    },
};
</script>

<style lang="scss" scoped>
.roi-canvas {
    &,
    &__inner {
        &:focus {
            border: 0;
        }
    }

    &__info {
        opacity: .8;
        font-size: .8em;
        pointer-events: none;
    }
}

.error {
    color: $red;
}
</style>
