import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import {
    getDefaultDataState,
    buildStartEndDateUrl,
} from '@/utils/store-helpers';
import {
    STATUS_LOADING,
    STATUS_SUCCESS,
} from '@/utils/constants';

const state = getDefaultDataState({ data: {}, status: STATUS_LOADING });

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,

    getData(state) {
        return state.data;
    },

    apiResults(state) {
        return state.data;
    },
};

const mutations = {
    setData(state, payload) {
        state.data = payload;
    },
    setStatus(state, payload) {
        state.status = payload.status;
    },
    resetState(state) {
        Object.assign(state, getDefaultDataState());
    },
};

const actions = {
    async loadData({ commit, rootState }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;
        commit('setStatus', { status: STATUS_LOADING });
        const path = buildStartEndDateUrl({
            path: 'hsafety/proximity',
            startDate,
            endDate,
        });

        const responseAvg = await axios(getApiUrl({ path }), {
            params: {
                op: 'avg',
            },
        });

        const responseMin = await axios(getApiUrl({ path }), {
            params: {
                op: 'min',
            },
        });

        const resultAvg = responseAvg.data;
        const resultMin = responseMin.data;

        commit('setData', { dataAvg: resultAvg, dataMin: resultMin });
        commit('setStatus', { status: STATUS_SUCCESS });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
