<template>
    <div class="dashboard" :class="navOpened ? 'has-nav-opened' : 'has-nav-closed'">
        <div v-if="isLoggedIn && !isLoading" class="dashboard__container">
            <be-nav class="dashboard__sidebar" />
            <div class="dashboard__app">
                <div class="dashboard__app__toolbar app-container pt-0 pb-0">
                    <be-app-toolbar />
                </div>
                <div class="dashboard__app__content" :class="contentClasses">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BeNav from '@/components/global/BeNav';
import BeAppToolbar from '@/components/global/BeAppToolbar';

export default {
    name: 'Dashboard',
    components: {
        BeAppToolbar,
        BeNav,
    },

    data() {
        return {
            isLoading: true,
        };
    },

    created() {
        Promise.all([
            this.$store.dispatch('user/getUserDetails'),
            this.$store.dispatch('plans/getAllPlans'),
            this.$store.dispatch('marketplace/init'),
            this.$store.dispatch('roi/loadRoiTypes'),
            this.$store.dispatch('hs/objectClasses/loadObjectClasses'),
        ])
            .finally(() => {
                this.isLoading = false;
            });
    },

    watch: {
        navOpened: {
            handler(val) {
                if (val) {
                    this.$root.$emit('intercom:hide');
                } else {
                    this.$root.$emit('intercom:show');
                }
            },
        },
    },

    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
        ...mapGetters('dashboard/layout', ['isSitesIndexMapView']),
        ...mapState('dashboard/layout', ['navOpened']),

        isSitesMapView() {
            return this.isSitesIndexMapView
                && ['sites', 'sightings'].indexOf(this.$route.name.toLowerCase()) > -1;
        },

        isMapExplorer() {
            return this.$route.name.toLowerCase() === 'map explorer';
        },

        contentClasses() {
            const classes = [];
            if (this.isSitesMapView || this.isMapExplorer) {
                classes.push('is-paddingless', 'is-fixed');
            }
            return classes;
        },
    },
};
</script>

<style lang="scss">
@import "../../styles/dashboard";
</style>
