import sightings from '@/store/maui63/sightings';
import detections from '@/store/maui63/detections/index';

export default {
    namespaced: true,
    modules: {
        sightings,
        detections,
    },
};
