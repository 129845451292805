<template>
    <div class="filter-search">
        <div class="filter-search__inner">
            <b-field class="filter-search__search">
                <be-tags
                    v-if="type === 'tags'"
                    type="search"
                    v-model="computedValue"
                />
            </b-field>
            <div class="filter-search__extra" v-if="computedValue.length">
                <div class="filter-search__extra__control field">
                    <button type="button" class="be-button-link is-grey-lighter" @click="clearAll">Clear</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BeTags from '@/components/global/BeTags';
import modelMixin from '@/mixins/modelMixin';

export default {
    components: {
        BeTags,
    },

    mixins: [modelMixin],

    props: {
        type: {
            type: String,
            default: 'tags',
        },
        value: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        clearAll() {
            this.computedValue = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-search {
    &__inner {
        @include tablet {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    &__search {
        margin-bottom: .5em;
        @include mobile {
            flex: 1;
            max-width: 450px;
        }
        @include tablet {
            flex: 0 0 450px;
            margin-right: 2em;
        }

        &__input {
            flex: 1;
        }
    }

    &__extra {
        //flex: 0 0 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;

        &__control {
            margin: 10px 0;
        }

        &__control + &__control {
            margin-left: 1em;
        }
    }
}
</style>
