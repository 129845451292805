<template>
    <div class="summary">
        <overview-filters
            :filter-confidence="true"
            :is-loading="isLoading"
        />
        <div class="app-container">
            <be-page-header class="mb-3" :app-container="false">
                <template v-slot:default>
                    Vehicle Overview
                </template>
                <template v-slot:subheading>
                    <be-detections-heading />
                </template>
            </be-page-header>
            <div class="summary__cols columns is-multiline">
                <div class="column is-12">
                    <div class="summary__contribute">
                        <be-contribute-images />
                    </div>
                </div>
                <div class="column is-12">
                    <system-totals :can-load-data="true" />
                </div>
                <div class="summary__half column is-6">
                    <detailed-usage-chart :can-load-data="true" />
                </div>
                <div class="summary__half column is-6">
                    <top-plates :can-load-data="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import BeContributeImages from '@/components/global/BeContributeImages';
import DetailedUsageChart from '@/components/widgets/charts/DetailedUsageChart';
import OverviewFilters from '@/components/global/OverviewFilters';
import SystemTotals from '@/components/widgets/SystemTotals';
import TopPlates from '@/components/widgets/TopPlates';
import BePageHeader from '@/components/global/BePageHeader';

export default {
    name: 'Summary',

    components: {
        BeDetectionsHeading,
        BeContributeImages,
        TopPlates,
        BePageHeader,
        DetailedUsageChart,
        OverviewFilters,
        SystemTotals,
    },

    computed: {
        ...mapGetters({
            isLoadingSummary: 'explorer/detections/unique/summary/isLoading',
            isLoadingDetections: 'explorer/detections/isLoading',
            isLoadingDailySummary: 'explorer/detections/unique/dailysummary/isLoading',
        }),

        isLoading() {
            return this.isLoadingSummary
                || this.isLoadingDetections
                || this.isLoadingDailySummary;
        },
    },
};
</script>

<style lang="scss" scoped>
.summary {
    &__contribute {
        display: flex;
        justify-content: flex-end;
    }

    &__half {
        display: flex;
        justify-content: stretch;
    }
}
</style>
