import axios from 'axios';
import { get } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { DEFAULT_PAGINATION_PER_PAGE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { mauiDetectionsTransformer } from '@/utils/transformers/maui63/detections';
import { cancelSource } from '@/utils/api-tokens';
import map from './map';

const state = {
    ...getDefaultDataState({ hasPagination: true }),
    isPaginated: true,
    totalDataCount: 0,
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: true }),
    hasResults: (state) => get(state, 'data.results', []).length > 0,
    formattedApiResults(state, getters) {
        return mauiDetectionsTransformer(getters.apiResults);
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: true }),
    setIsPaginated(state, { isPaginated }) {
        state.isPaginated = isPaginated;
    },
    setTotalDataCount(state, { totalDataCount }) {
        state.totalDataCount = totalDataCount;
    },

};

const actions = {
    loadData({ state, commit, rootState, rootGetters }, { perPage = null, page = null, paginated = true }) {
        commit('setIsPaginated', { isPaginated: paginated });
        if (paginated) {
            if (page) {
                commit('setPage', { page });
            }
            if (perPage) {
                commit('setPerPage', { perPage });
            }
        } else {
            commit('setPage', { page: 1 });
            commit('setPerPage', { page: DEFAULT_PAGINATION_PER_PAGE });
        }

        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const urlParams = {
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
        };

        if (paginated) {
            urlParams.perPage = state.perPage;
            urlParams.page = state.currentPage;
            urlParams.additional = {
                paginate: 1,
            };
        } else {
            urlParams.additional = {
                paginate: false,
            };
        }

        const params = buildUrlParams(urlParams);

        const path = buildStartEndDateUrl({
            path: 'maui/dolphin_sightings',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: STATUS_LOADING });
        return axios({
            url: getApiUrl({ path }),
            cancelToken: cancelSource.token,
            params,
        })
            .then((response) => {
                commit('setStatus', { status: STATUS_SUCCESS });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    commit('setStatus', { status: STATUS_SUCCESS });
                    console.error('Request canceled', err.message);
                    return;
                }
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    loadDataCount({ commit, state }) {
        if (state.totalDataCount) {
            return Promise.resolve(state.totalDataCount);
        }

        const path = 'maui/dolphin_sightings/count';
        return axios({
            url: getApiUrl({ path }),
        })
            .then((response) => {
                const totalDataCount = get(response, 'data.count', 0);
                commit('setTotalDataCount', { totalDataCount });
                return totalDataCount;
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        map,
    },
};
