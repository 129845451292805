<template>
    <be-widget class="chart" :is-loading="isLoading" :content-classes="['is-align-bottom']">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:headingAfter>
            <detections-toggler
                v-if="!displaySingleChart"
                v-model="currentView"
                :values="viewValues"
            />
        </template>
        <template v-slot:content>
            <div class="chart__toggler">
                <b-checkbox
                    class="be-checkbox"
                    v-model="displayViolationTypes"
                >
                    Display Detection Types
                </b-checkbox>
            </div>
            <div class="chart__chart">
                <bar-chart
                    class="chart__wrapper"
                    :chart-data="chartData"
                    :options="options"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, isArray } from 'lodash';
import BarChart from '@/utils/chartjs/BarChart';
import { DATE_FORMAT } from '@/utils/formatters/datetime';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import { CHART_COLORS, COLORS } from '@/utils/constants';
import DetectionsToggler from '@/components/widgets/hs/filters/DetectionsToggler';

const getChartDataDefaults = () => ({
    labels: [],
    datasets: [
        {
            label: '',
            backgroundColor: COLORS.purple,
            data: [],
        },
    ],
});

export default {
    components: {
        DetectionsToggler,
        BarChart,
    },

    props: {
        displaySingleChart: {
            type: String,
            default: null,
        },
    },

    mixins: [widgetDataMixin],

    data() {
        return {
            displayViolationTypes: false,
            currentView: this.displaySingleChart ? this.displaySingleChart : 'zones',
            chartData: getChartDataDefaults(),
            viewValues: [
                {
                    handle: 'zones',
                    label: 'Safety zone violations',
                },
                {
                    handle: 'ppe',
                    label: 'PPE violations',
                },
            ],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            suggestedMin: 0,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                },
            },
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('hs/dailysummary', ['isLoading', 'formattedApiResults']),

        widgetTitle() {
            return this.currentView === 'ppe'
                ? 'PPE detections over time'
                : 'Safety zone detections over time';
        },

        isPPEView() {
            return this.currentView === 'ppe';
        },

        dataField() {
            return this.isPPEView ? 'ppe' : 'zones';
        },

        displayDates() {
            const startDate = this.$date(this.getDateRange.start);
            const endDate = this.$date(this.getDateRange.end);

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 0; i < diff; i++) {
                dateArray.push(startDate.add(i + 1, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },

        displayLabels() {
            const labels = [];

            Object.values(this.formattedApiResults).forEach((apiRow) => {
                if (!isArray(apiRow[this.dataField])) {
                    return;
                }
                apiRow[this.dataField].forEach((row) => {
                    const label = `${row.label}`.trim();
                    if (labels.indexOf(label) === -1) {
                        labels.push(label);
                    }
                });
            });

            return labels;
        },

        displayDatasets() {
            const values = Object.values(CHART_COLORS);
            return this.displayLabels.map((label, index) => ({
                label,
                backgroundColor: values[index] || CHART_COLORS.grey,
            }));
        },
    },

    watch: {
        currentView() {
            this.updateChartData();
        },

        displayViolationTypes() {
            this.updateChartData();
        },

        formattedApiResults: {
            handler() {
                this.updateChartData();
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        ...mapActions('hs/dailysummary', ['loadData']),

        canPopulateChart() {
            return this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        getLabelsArray() {
            const startDate = this.$date(this.getDateRange.start);
            const endDate = this.$date(this.getDateRange.end);

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 1; i <= diff; i++) {
                dateArray.push(startDate.add(i, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },

        getApiResultsDatasets() {
            if (!this.formattedApiResults) {
                return [];
            }

            if (!this.displayViolationTypes) {
                return this.getApiResultsTotalsDataset();
            }

            const datasets = this.displayDatasets.map((dd) => {
                const dataset = {
                    ...dd,
                    data: [],
                };

                this.displayDates.forEach((date) => {
                    const rows = get(this.formattedApiResults, `${date}.${this.dataField}`, []);
                    if (!rows.length) {
                        dataset.data.push(0);
                    }
                    rows.forEach((row) => {
                        if (dd.label === row.label) {
                            dataset.data.push(row.total);
                        }
                    });
                });

                return dataset;
            });

            return datasets;
        },

        getApiResultsTotalsDataset() {
            if (!this.formattedApiResults) {
                return [];
            }

            const data = this.displayDates.map((date) => {
                const rows = get(this.formattedApiResults, `${date}.${this.dataField}`, []);
                return rows.reduce((sum, row) => (sum + row.total), 0);
            });

            let backgroundColor = CHART_COLORS.red;
            if (this.displaySingleChart === 'ppe') {
                backgroundColor = CHART_COLORS.purple;
            }

            const dataset = {
                label: 'Total',
                backgroundColor,
                data,
            };

            return [dataset];
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            this.options.legend.display = this.displayViolationTypes;

            this.chartData = {
                labels: this.displayDates,
                datasets: this.getApiResultsDatasets(),
            };
        },

        resetChartData() {
            this.chartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    &__toggler {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
    }
}
</style>
