<template>
    <div class="sites-map-list" :class="{ 'is-opened': isOpened }">
        <div class="sites-map-list__cta">
            <button type="button" @click="toggle()">
                <b-icon :icon="isOpened ? 'chevron-right' : 'chevron-left'" />
            </button>
        </div>
        <div class="sites-map-list__inner" ref="scroll" v-if="isOpened">
            <be-page-header :app-container="false" title-tag="h3" class="mb-4">
                <template v-slot>
                    Sites
                </template>
                <template v-slot:headingCta>
                    <b-button
                        type="is-primary"
                        rounded
                        class="be-button is-small"
                        @click="$emit('add-site')"
                        icon-left="plus"
                    >
                        Add Site
                    </b-button>
                </template>
            </be-page-header>
            <div class="sites-map-list__columns">
                <div
                    v-for="(site, index) in sites"
                    :key="`site${index}`"
                    class="sites-map-list__column"
                >
                    <site-item
                        :key="site.id"
                        :item="site"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import SiteItem from '@/components/local/sites/SiteItem';

export default {
    components: {
        SiteItem,
    },

    data() {
        return {
            isOpened: false,
        };
    },

    beforeDestroy() {
        this.$root.$emit('intercom:show');
        clearAllBodyScrollLocks();
    },

    watch: {
        isOpened: {
            handler(val) {
                this.$nextTick(() => {
                    if (val) {
                        this.$root.$emit('intercom:hide');
                        disableBodyScroll(this.$refs.scroll);
                    } else {
                        this.$root.$emit('intercom:show');
                        clearAllBodyScrollLocks();
                    }
                });
            },
        },
    },

    computed: {
        ...mapState('sites', ['sites']),
    },

    methods: {
        toggle(force = null) {
            this.isOpened = force === null ? !this.isOpened : force;
        },
    },
};
</script>

<style lang="scss">
.sites-map-list {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    @include z-index(map-list);

    &__cta {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        @include z-index(above);

        button {
            @include button-reset;
            width: 35px;
            height: 80px;
            background: $grey-darker;
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
            color: $white;
            @include transition-button;

            @include mobile {
                width: 30px;
                height: 60px;
            }

            &:hover {
                color: $grey-light;
            }

            &,
            &:active,
            &:focus,
            &:focus:not(:active) {
                outline: none;
                box-shadow: -1px 1px 0px rgba($white, 0.1);
                border-color: transparent;
            }
        }
    }

    &__inner {
        height: 100%;
        width: 360px;
        background: $grey-darker;
        box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);
        padding: 16px;
        overflow-y: scroll;
        overflow-x: hidden;
        @include mobile {
            width: 280px;
        }
    }

    &__columns {
        width: 100%;
    }

    &__column + &__column {
        margin-top: 16px;
    }
}
</style>
