import { CHART_COLORS } from '@/utils/constants';

export function getDatasetColorByIndex({ index }) {
    const colorsIndexes = Object.keys(CHART_COLORS);

    const lookupIndex = index % colorsIndexes.length;
    if (colorsIndexes[lookupIndex]) {
        return CHART_COLORS[colorsIndexes[lookupIndex]];
    }

    return CHART_COLORS.blue;
}
