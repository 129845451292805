export const BREAKPOINTS = {
    widescreen: 1280,
    desktop: 1024,
    tablet: 769,
    mobileLegacy: 400,
};

export const STATUS_LOADING = 'loading';

export const STATUS_PENDING = 'pending';

export const STATUS_SUCCESS = 'success';

export const STATUS_READY = 'success';

export const STATUS_ERROR = 'error';

export const PAGINATION_PER_PAGE = [10, 20, 30, 40, 50, 100];

export const DEFAULT_PAGINATION_PER_PAGE = PAGINATION_PER_PAGE[0];

export const APP_MODE = process.env.VUE_APP_MODE;

export const APP_IN_PRODUCTION = APP_MODE === 'production';

export const COLORS = {
    blue: '#07C4FF',
    yellow: '#FFFA9C',
    green: '#37F4BE',
    red: '#FD9D90',
    purple: '#AE83FB',
    pink: '#FB82EF',
    grey: '#59637C',
    lightblue: '#59D7FF',
    white: '#FFFFFF',
    greyLight: '#A6ACBE',
    greyDark: '#37404C',
    black: '#0E1118',
    transparentYellow: 'rgba(255, 250, 156, 0.3)',
    highlight: '#FF00FF',
};

export const CHART_COLORS = {
    blue: COLORS.blue,
    yellow: COLORS.yellow,
    green: COLORS.green,
    red: COLORS.red,
    purple: COLORS.purple,
    pink: COLORS.pink,
    grey: COLORS.grey,
    lightblue: COLORS.lightblue,
};

export const ACCOUNT_TYPE = {
    bigeyes: 0,
    maui63: 1,
};

export const LIVE_EXPLORER_RELOAD_INTERVAL = 60 * 1000; // 60sec

export const MAX_INTERPOLATION_INTERVAL = 3 * 1000; // 3 sec

export const BOX_FADE_OUT_TIME = 3 * 1000; // 3 sec
