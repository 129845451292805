<template>
    <manage-popup
        v-if="active"
        class="manage-popup view-group is-full"
        :active="active"
        hide-footer
        width="full"
        @close="close"
    >
        <template v-slot:title>
            <template v-if="media">
                Observation Cluster
                (<small>{{ media.length }} related clips on {{ date }}</small>)
            </template>
        </template>
        <template v-slot:body>
            <be-request-error-message v-if="requestErrorMessage" @close="requestErrorMessage = null">
                {{ requestErrorMessage }}
            </be-request-error-message>
            <div class="view-group__actions mb-3 pb-3">
                <div class="view-group__action-group">
                    <b-button
                        class="be-button is-smaller is-success"
                        :class="{
                            'is-success': !seen,
                            'is-warning': seen,
                        }"
                        :icon-left="seen ? 'eye-off' : 'eye'"
                        :loading="seenIsLoading"
                        @click.prevent="markSeen()"
                    >
                        {{ seen ? 'Mark Unseen' : 'Mark Seen' }}
                    </b-button>
                    <b-button
                        icon-left="text"
                        class="be-button is-smaller is-success"
                        @click.prevent="editNotesPopupActive = true"
                    >
                        Edit Notes
                    </b-button>
                </div>
                <div class="view-group__confirmation">
                    Is this observation correct?
                    <be-prediction-confirmation
                        @click="handlePredictionConfirmation"
                    />
                </div>
                <div class="view-group__action-group">
                    <b-button
                        icon-left="email"
                        class="be-button is-smaller is-secondary"
                        @click.prevent="notificationPopupActive = true"
                    >
                        Send Message
                    </b-button>
                    <b-button
                        icon-left="alert"
                        class="be-button is-smaller is-danger"
                        @click.prevent="alertPopupActive = true"
                    >
                        Alert SMS
                    </b-button>
                </div>
            </div>

            <div class="view-group__media">
                <media-video v-if="currentMedia" v-bind="mediaVideoBindings" />
            </div>

            <div class="view-group__footer mt-3">
                <be-detections-list
                    v-if="detectionsListFormatted"
                    :detections="detectionsListFormatted"
                    :time="null"
                    :extra="0"
                    wrap
                    display-orientation
                    display-model
                    :display-confidence-label="false"
                />
            </div>

            <h-s-observation-alert-popup
                v-if="alertPopupActive"
                :active="alertPopupActive"
                :detection="currentMedia"
                :video="currentVideo.display_video_file"
                @close="alertPopupActive = false"
            />
            <h-s-observation-notification-popup
                v-if="notificationPopupActive"
                :active="notificationPopupActive"
                :detection="currentMedia"
                :video="currentVideo.display_video_file"
                @close="notificationPopupActive = false"
            />
            <h-s-observation-edit-note-popup
                v-if="editNotesPopupActive"
                :active="editNotesPopupActive"
                :detection="currentMedia"
                @close="editNotesPopupActive = false"
            />
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { find, get, head, kebabCase } from 'lodash';
import BeRequestErrorMessage from '@/components/global/BeRequestErrorMessage';
import noticesMixin from '@/mixins/noticesMixin';
import ManagePopup from '@/components/local/sites/ManagePopup';
import HSObservationAlertPopup from '@/components/local/hs/observations/partials/HSObservationAlertPopup';
import HSObservationEditNotePopup from '@/components/local/hs/observations/partials/HSObservationEditNotePopup';
import HSObservationNotificationPopup from '@/components/local/hs/observations/partials/HSObservationNotificationPopup';
import BeDetectionsList from '@/components/global/BeDetectionsList';
import { DATETIME_FORMATTED_FORMAT, TIME_FORMAT } from '@/utils/formatters/datetime';
import MediaVideo from '@/components/local/data/MediaVideo';
import BePredictionConfirmation from '@/components/global/BePredictionConfirmation';

export default {
    components: {
        MediaVideo,
        BeDetectionsList,
        HSObservationNotificationPopup,
        HSObservationEditNotePopup,
        BePredictionConfirmation,
        HSObservationAlertPopup,
        BeRequestErrorMessage,
        ManagePopup,
    },

    mixins: [
        noticesMixin,
    ],

    props: {
        group: {
            type: Object,
            default: null,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            currentMediaId: null,
            alertPopupActive: false,
            notificationPopupActive: false,
            editNotePopupActive: false,
            editNotesPopupActive: false,
            seenIsLoading: false,
            requestErrorMessage: '',
        };
    },

    computed: {
        currentVideo() {
            return this.currentMedia
                ? (this.$store.state.media.videos.data[this.currentMedia.video] || null)
                : null;
        },
        date() {
            return this.$date(
                get(this.first, 'video_time_created'),
            ).format(DATETIME_FORMATTED_FORMAT);
        },
        time() {
            return this.$date(
                get(this.first, 'video_time_created'),
            ).format(TIME_FORMAT);
        },
        seen() {
            return this.currentMedia && this.currentMedia.seen;
        },
        media() {
            return get(this.group, 'cluster', null);
        },

        first() {
            return head(this.group.cluster) || null;
        },

        currentMedia() {
            return this.currentMediaId && this.media
                ? find(this.media, { id: this.currentMediaId })
                : null;
        },

        detectionsListFormatted() {
            return this.currentMedia && this.currentMedia.detection_data
                ? this.currentMedia.detection_data.map((item) => {
                    const detection = kebabCase(item.object_class);
                    return {
                        detection,
                        type: detection,
                        confidence: item.conf_average,
                        tags: item.tags || [],
                    };
                }) : [];
        },

        mediaVideoBindings() {
            const dataTracks = get(this.currentVideo, 'detection_data_tracks');
            const dataTracksKeys = Object.keys(dataTracks);
            let highestKey = dataTracksKeys.length;
            if (dataTracksKeys.length === 0) {
                highestKey = 0;
            }
            const trackDataList = [];
            for (let i = 0; i < highestKey; i++) {
                trackDataList.push(dataTracks[`${i}`]);
            }

            return {
                url: get(this.currentVideo, 'display_video_file'),
                poster: get(this.currentVideo, 'poster_image'),
                camera: get(this.currentVideo, 'camera'),
                tracks: trackDataList,
                detections: get(this.currentVideo, 'detection_data'),
                displayRoi: true,
            };
        },
    },

    watch: {
        active: {
            handler(val) {
                this.currentMediaId = val && this.media
                    ? this.media[0].id
                    : null;
            },
            immediate: true,
        },
    },

    mounted() {
        this.media.forEach((media) => {
            this.loadDataRow({ id: media.video, isPreload: true });
        });
    },

    methods: {
        ...mapActions('media/videos', ['loadDataRow']),
        ...mapActions('hs/observations/explorer/detections', ['detectionUpdate']),

        close() {
            this.requestErrorMessage = null;
            this.$emit('close');
        },

        markSeen() {
            if (this.seenIsLoading) {
                return;
            }

            const payload = {
                id: this.currentMediaId,
                seen: !this.media.seen,
            };

            this.seenIsLoading = true;
            this.detectionUpdate(payload)
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Marked as seen',
                    });
                })
                .catch((err) => {
                    this.displayErrorNotice({
                        message: err.message || 'Something went wrong',
                    });
                })
                .finally(() => {
                    this.seenIsLoading = false;
                });
        },

        handlePredictionConfirmation() {
            // handle yes/no/maybe
        },
    },
};
</script>

<style lang="scss" scoped>
.view-group {
    @include tablet {
        ::v-deep > .animation-content {
            width: 90%;
            height: 90%;
        }

        ::v-deep .modal-card-body {
            display: flex;
            flex-direction: column;
        }

        ::v-deep .modal-card {
            height: 100%;
        }
    }

    &__cluster {
        flex: 0 0 auto;
        display: flex;
        align-items: stretch;
        overflow-x: scroll;
        @include hide-scrollbar;

        &-item + &-item {
            margin-left: 1rem;
        }

        &-cta {
            @include button-reset;
            width: 120px;
            height: auto;
            display: block;
            color: $white;
            border: 2px solid $grey;
            padding: 5px;
            overflow: hidden;
            border-radius: $radius;

            &.is-active {
                border-color: $primary;
            }
        }

        &-fig {
            display: block;
            height: 0;
            padding-top: 66%;
            position: relative;
            background: $dark;

            img,
            video {
                @include overlay;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }

        p {
            padding-top: 5px;
            font-size: 13px;
            line-height: 1;
        }
    }

    &__media {
        flex: 1;
        //height: 450px;
        background: $grey-darker;
    }

    &__actions {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__action-group {
        margin: -5px;

        .be-button {
            margin: 5px;
        }
    }

    &__confirmation {
        justify-content: top;
        text-align: center;
    }
}
</style>
