<template>
    <div class="data-explorer">
        <!-- <overview-filters
            :filter-confidence="true"
            :is-loading="isLoading"
        /> -->
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    User Invites
                </template>
                <template v-slot:headingCta>
                    <b-button
                        type="is-primary"
                        rounded
                        class="be-button is-small"
                        @click="redirectToPage"
                        icon-left="plus"
                    >
                        Create Invite
                    </b-button>
                </template>
                <!-- <template v-slot:subheading>
                    <be-detections-heading />
                </template> -->
            </be-page-header>
            <div class="columns is-multiline">
                <!-- <div class="column is-12">
                    <system-totals :can-load-data="true" />
                </div> -->
                <div class="column is-12">
                    <!-- <violations-explorer-table-widget
                        ref="tableWidget"
                        :can-load-data="true"
                    /> -->
                    <user-invites-table
                        ref="tableWidget"
                        :can-load-data="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserInvitesTable from '@/components/widgets/dataTables/UserInvitesTable';

export default {
    components: {
        UserInvitesTable,
    },

    computed: {
        ...mapGetters({
            isLoadingDetections: 'hs/explorer/detections/isLoading',
            isLoadingPPEDetections: 'hs/explorer/detections/ppe/isLoading',
            isLoadingZonesDetections: 'hs/explorer/detections/zones/isLoading',
            isLoadingSummary: 'hs/summary/isLoading',
        }),

        isLoading() {
            return this.isLoadingDetections
                || this.isLoadingPPEDetections
                || this.isLoadingZonesDetections
                || this.isLoadingSummary;
        },
    },

    mounted() {
        // this.populateFilters();
    },

    methods: {
        redirectToPage() {
            this.$router.push('/dashboard/user-invites/create');
        },
    },
};
</script>
