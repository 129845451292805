import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { buildStartEndDateUrl, createDataHash } from '@/utils/store-helpers';

const getDefaultState = () => ({
    sightings: [],
    status: '',
    dataHash: null,
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
    isError: (state) => state.status === STATUS_ERROR,
};

const mutations = {
    setStatus(state, status) {
        state.status = status;
    },

    setSightings(state, { results }) {
        state.sightings = results;
    },

    setDataHash(state, { dataHash }) {
        state.dataHash = dataHash;
    },

    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    getSightings({ commit, state }, {
        startDate = null,
        endDate = null,
        lat = null,
        lng = null,
        radius = null,
    }) {
        const params = {};
        if (lat) {
            params.lat = lat;
        }
        if (lng) {
            params.lng = lng;
        }
        if (radius) {
            params.radius = radius;
        }

        const path = buildStartEndDateUrl({
            path: 'maui/dolphin_sightings',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', STATUS_LOADING);
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setSightings', response.data);
                commit('setStatus', { status: STATUS_SUCCESS });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                commit('sitesStatus', STATUS_ERROR);
                errorHandler(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
