<template>
    <b-modal
        v-if="active"
        :active="active"
        has-modal-card
        trap-focus
        :can-cancel="false"
        @close="closePassPanel"
    >
        <div class="modal-card is-narrow">
            <b-loading :active="userLoading" :can-cancel="false" :is-full-page="false" />
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Change Password
                </p>
                <button type="button" class="modal-card-close" @click.prevent="closePassPanel()">
                    <b-icon icon="close" />
                </button>
            </header>
            <section class="modal-card-body">
                <template v-if="passChangeComplete">
                    Your password has been updated.
                </template>
                <template v-else>
                    <b-field
                        label="Password"
                        :type="passErrors.password1 ? 'is-danger' : ''"
                        :message="passErrors.password1"
                    >
                        <b-input
                            @keyup="resetValidation"
                            v-model="password1"
                            type="password"
                            required
                            password-reveal
                        />
                    </b-field>
                    <b-field
                        label="Confirm Password"
                        :type="passErrors.password2 ? 'is-danger' : ''"
                        :message="passErrors.password2">
                        <b-input
                            @keyup="resetValidation"
                            v-model="password2"
                            type="password"
                            required
                            password-reveal
                        />
                    </b-field>
                </template>
            </section>
            <footer class="modal-card-foot">
                <template v-if="passChangeComplete">
                    <b-button class="be-button is-primary" @click="closePassPanel">
                        Done
                    </b-button>
                </template>
                <template v-else>
                    <b-button class="be-button is-outline" @click="closePassPanel">
                        Cancel
                    </b-button>
                    <b-button
                        class="be-button is-primary"
                        @click="updatePassword"
                        :loading="userLoading"
                    >
                        Update Password
                    </b-button>
                </template>
            </footer>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '@/utils/errors';

export default {
    name: 'PasswordChangeDialog',
    data() {
        return {
            password1: '',
            password2: '',
            passErrors: {},
            passChangeComplete: false,
        };
    },
    props: [
        'active',
    ],
    computed: {
        ...mapGetters('user', ['userStatus']),
        userLoading() {
            return this.userStatus === 'loading';
        },
    },
    methods: {
        updatePassword() {
            if (!this.validatePasswordForm()) {
                return false;
            }

            this.$store.dispatch('user/changePassword', {
                new_password1: this.password1,
                new_password2: this.password2,
            })
                .then(() => {
                    this.passChangeComplete = true;
                })
                .catch((err) => {
                    errorHandler('there was an error fetching your card data.', err);
                });
            return true;
        },
        validatePasswordForm() {
            let valid = true;
            const passRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
            this.passErrors = {};

            if (!this.password1) {
                this.passErrors.password1 = 'Please enter a password';
                valid = false;
            }
            if (!this.password2) {
                this.passErrors.password2 = 'Please confirm your password';
                valid = false;
            }

            if (!passRegex.test(this.password1)) {
                this.passErrors.password1 = 'Password must be: 1 number, 1 lowercase, 1 uppercase, and at least 8 characters';
                valid = false;
            }

            if (this.password1 !== this.password2) {
                this.passErrors.password2 = 'Your passwords do not match';
                valid = false;
            }

            return valid;
        },
        resetValidation() {
            if (this.passErrors) this.passErrors = {};
        },
        closePassPanel() {
            this.showPasswordPanel = false;
            this.passChangeComplete = false;
            this.password1 = '';
            this.password2 = '';
            this.resetValidation();
            this.$emit('close');
        },
    },
};
</script>
