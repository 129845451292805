import Vue from 'vue';
import axios from 'axios';
import store from '../store/store';

const { token } = store.state.auth;

if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

Vue.prototype.$http = axios;
