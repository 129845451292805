import { isArray, isEmpty } from 'lodash';
import { transformImageDetectionsObject } from '@/utils/transformers/detections';

export function platesTransformerRow(row) {
    return {
        ...row,
        image_file: row.image_url,
        last_seen: row.last_detected,
        detections: transformImageDetectionsObject({ image: row }),
        platesList: [row.licence_plate],
    };
}

export function platesTransformer(rawData) {
    if (isArray(rawData) && !isEmpty(rawData)) {
        return rawData.map((row) => platesTransformerRow(row));
    }

    return [];
}
