<template>
    <div class="columns is-multiline">
        <div class="column is-4">
            <be-widget icon="check">
                <template v-slot:heading>Average Confidence</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ averageConfidence }}</p>
                </template>
            </be-widget>
        </div>
        <div class="column is-4">
            <be-widget icon="glasses">
                <template v-slot:heading>Total detections</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalDetections }}</p>
                </template>
            </be-widget>
        </div>
        <div class="column is-4">
            <be-widget icon="video-outline">
                <template v-slot:heading>Total images received</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalImagesReceived }}</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatNumber, formatPercentage } from '@/utils/formatters/numbers';
import widgetDataMixin from '@/mixins/widgetDataMixin';

export default {
    mixins: [widgetDataMixin],

    computed: {
        ...mapGetters('explorer/detections/unique/summary', ['summaryData', 'isLoading']),

        averageConfidence() {
            return this.summaryData.average_confidence
                ? formatPercentage({ number: this.summaryData.average_confidence, decimal: false })
                : '-';
        },

        totalDetections() {
            return this.summaryData.total_unique_plates
                ? formatNumber({ number: this.summaryData.total_unique_plates })
                : '-';
        },

        totalImagesReceived() {
            return this.summaryData.total_images
                ? formatNumber({ number: this.summaryData.total_images })
                : '-';
        },
    },

    methods: {
        ...mapActions('explorer/detections/unique/summary', ['loadData']),
    },
};
</script>
