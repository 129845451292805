import chunk from 'lodash/chunk';

const COORDS_IN_POINT = 2;

/**
 * Finds intersection point between two lines
 *  @param {number} x1 - point x coordinate
 *  @param {number} y1 - point y coordinate
 *  @param {number} x2 - point x coordinate
 *  @param {number} y2 - point y coordinate
 *  @param {number} x3 - point x coordinate
 *  @param {number} y3 - point y coordinate
 *  @param {number} x4 - point x coordinate
 *  @param {number} y4 - point y coordinate
 * @returns {{x:Number,y:Number}} X and Y coordinates of intersection point.
 * @example
 * intersect(0,0,100,100,100,0,0,100)
 * // returns {x:50,y:50}
 *
 */
export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
    }

    // If the endpoints intersect
    if ((x1 === x3 && y1 === y3) || (x1 === x4 && y1 === y4) || (x2 === x3 && y2 === y3) || (x2 === x4 && y2 === y4)) {
        return false;
    }

    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    // Lines are parallel
    if (denominator === 0) {
        return false;
    }

    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    // Return a object with the x and y coordinates of the intersection
    const x = x1 + ua * (x2 - x1);
    const y = y1 + ua * (y2 - y1);

    return { x, y };
}

export function intersectLines(coords = [], { closed = true } = {}) {
    const points = chunk(coords, COORDS_IN_POINT);
    const normalizePointIndex = (index) => index % points.length;
    const isLastPoint = (index) => index === points.length - 1;
    const lines = points.map((point, i) => {
        const is = closed || !isLastPoint(i);
        return is ? [...point, ...points[normalizePointIndex(i + 1)]] : null;
    }).filter(Boolean);
    return lines.some((currentLine) => lines.some((line) => intersect(...currentLine, ...line)));
}
