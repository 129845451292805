<template>
    <div class="app-container">
        <be-page-header :app-container="false">
            <template v-slot:default>
                Live Explorer
            </template>
            <template v-slot:cta>
                <b-checkbox
                    class="be-checkbox"
                    v-model="autoReload"
                    :disabled="isRefreshing"
                >
                    Auto reload
                </b-checkbox>
            </template>
        </be-page-header>
        <enterprise-banner v-if="$store.getters['user/displayEnterpriseBanner']" />
        <div class="columns is-multiline">
            <div class="column is-12">
                <live-explorer-filters ref="filters" @search="onFilterSearch" />
            </div>
            <div class="column is-12">
                <live-explorer-overview />
            </div>
            <div class="column is-12">
                <live-explorer-data-table />
            </div>
        </div>
    </div>
</template>
<script>
import { LIVE_EXPLORER_RELOAD_INTERVAL } from '@/utils/constants';
import LiveExplorerFilters from '@/components/local/live-explorer/LiveExplorerFilters';
import LiveExplorerOverview from '@/components/local/live-explorer/LiveExplorerOverview';
import LiveExplorerDataTable from '@/components/local/live-explorer/LiveExplorerDataTable';
import EnterpriseBanner from '@/components/local/informational/EnterpriseBanner';

export default {
    components: {
        EnterpriseBanner,
        LiveExplorerDataTable,
        LiveExplorerOverview,
        LiveExplorerFilters,
    },

    data() {
        return {
            isRefreshing: false,
        };
    },

    watch: {
        autoReload: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => this.startAutoreload());
                    return;
                }
                this.$nextTick(() => this.stopAutoreload());
            },
            immediate: true,
        },
    },

    computed: {
        autoReload: {
            get() {
                return this.$store.state.dashboard.layout.liveDataExplorerAutoReload;
            },
            set(value) {
                this.$store.commit('dashboard/layout/toggleLiveDataExplorerAutoReload', value);
            },
        },
    },

    methods: {
        startAutoreload() {
            if (!this.$refs.filters) {
                this.stopAutoreload();
                return;
            }

            this.refresh();
        },

        refresh() {
            this.isRefreshing = true;
            this.$refs.filters.doSearch(true, false)
                .finally(() => {
                    this.isRefreshing = false;
                    this.resetInterval();
                });
        },

        resetInterval() {
            this.reloadInterval = setTimeout(() => this.refresh(), LIVE_EXPLORER_RELOAD_INTERVAL);
        },

        stopAutoreload() {
            if (this.reloadInterval) {
                clearTimeout(this.reloadInterval);
            }
        },

        onFilterSearch() {
            this.stopAutoreload();
            this.resetInterval();
        },
    },

    beforeDestroy() {
        this.stopAutoreload();
    },
};
</script>
