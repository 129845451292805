import { isFinite } from 'lodash';

export function isError(err) {
    return err
        && err.stack
        && err.message
        && typeof err.stack === 'string'
        && typeof err.message === 'string';
}

export function isErrorWithProblemDetails(err) {
    return err?.response?.headers['content-type'] === 'application/problem+json'
        && typeof err?.response?.data?.title === 'string';
}

export function isNumeric(num) {
    return typeof num === 'number' && isFinite(num);
}
