import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { errorHandler } from '@/utils/errors';

const getDefaultState = () => ({
    roiTypes: [],
    status: '',
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
};

const mutations = {
    setRoiTypes(state, { roiTypes }) {
        state.roiTypes = Object.keys(roiTypes).map((type) => ({
            type: Number(type),
            label: roiTypes[type],
        }));

        state.roiTypes[3].label = 'Live Lane';
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    loadRoiTypes({ commit }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            url: getApiUrl({ path: 'cameras/roi_types' }),
        })
            .then((response) => {
                commit('setRoiTypes', { roiTypes: response.data });
                commit('setStatus', { status: STATUS_SUCCESS });
                return true;
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: STATUS_ERROR });
            });
    },

    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
