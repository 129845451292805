<template>
    <mgl-marker
        :coordinates="coordinates"
        :draggable="false"
    >
        <div slot="marker" @dblclick="$emit('clusterzoom', marker)">
            <div class="map-marker">
                <div class="map-marker__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
                        <g fill="none" fill-rule="evenodd">
                            <circle cx="19.5" cy="19.5" r="19.5" fill="#07C3FF" />
                            <path fill="#FFF" d="M19.2796,32.2796 C26.4593546,32.2796 32.2796,26.4593546 32.2796,19.2796 C32.2796,12.0998454 26.4593546,6.2796 19.2796,6.2796 C12.0998454,6.2796 6.2796,12.0998454 6.2796,19.2796 C6.2796,26.4593546 12.0998454,32.2796 19.2796,32.2796 Z" />
                        </g>
                    </svg>
                </div>
                <div class="map-marker__label" v-if="label">
                    {{ label }}
                </div>
            </div>
        </div>
    </mgl-marker>
</template>

<script>
import { MglMarker } from 'v-mapbox';

export default {
    components: {
        MglMarker,
    },

    props: {
        marker: {
            type: Object,
            required: true,
        },
    },

    computed: {
        coordinates() {
            return this.marker.geometry.coordinates;
        },

        label() {
            return this.marker.properties.point_count_abbreviated;
        },
    },
};
</script>

<style lang="scss" scoped>
.map-marker {
    display: block;
    position: relative;

    &__icon {
        display: block;
        width: 44px;
        height: 44px;

        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__label {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 1;
        color: $grey;
        font-size: 14px;
        font-weight: $weight-bold;
    }
}
</style>
