import { formatCurrency, formatNumber } from '@/utils/formatters/numbers';
import {
    dateHumanFormatter,
    datetimeFormattedFormatter,
    datetimeFormatter,
    datetimeHumanFormatter,
} from '@/utils/formatters/datetime';

export default {
    filters: {
        number(number) {
            return formatNumber({ number });
        },
        currency(number) {
            return formatCurrency({ number });
        },
        dateTime(datetime, seconds = false) {
            return datetimeFormatter(datetime, seconds);
        },
        dateTimeFormatted(datetime, seconds = false) {
            return datetimeFormattedFormatter(datetime, seconds);
        },
        dateTimeHuman(datetime) {
            return datetimeHumanFormatter(datetime);
        },
        dateHuman(date) {
            return dateHumanFormatter(date);
        },
    },
};
