<template>
    <b-taglist class="has-cursor-default">
        <b-tag
            v-for="(recepient, index) in recepientsArr"
            :key="`recepient${index}`"
        >
            {{ recepient }}
        </b-tag>
    </b-taglist>
</template>

<script>
import { find, isArray, truncate } from 'lodash';
import { mapState } from 'vuex';

export default {
    props: {
        alert: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState('webhooks', ['webhooks']),

        webhookDescription() {
            if (this.alert.webhook) {
                const webhook = this.getWebhookById(this.alert.webhook);
                if (!webhook) {
                    return '';
                }
                return webhook.name || truncate(webhook.url, { length: 24 });
            }

            return null;
        },

        recepients() {
            if (this.alert.email) {
                return this.alert.email;
            }
            if (this.alert.msg_number) {
                return this.alert.msg_number;
            }
            if (this.alert.webhook) {
                return this.webhookDescription;
            }

            return null;
        },

        recepientsArr() {
            return isArray(this.recepients) ? this.recepients : [this.recepients];
        },
    },

    methods: {
        getWebhookById(webhookId) {
            return find(this.webhooks, { id: webhookId });
        },
    },
};
</script>
