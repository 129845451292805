<template>
    <be-widget :is-loading="isLoading" :has-table="true">
        <template v-slot:content>
            <h3 class="title is-5 has-text-weight-normal mb-0 py-4">Message History</h3>
            <b-table
                :data="formattedApiResults"
                :hoverable="true"
                default-sort="date"
            >
                <b-table-column field="date" label="Date" width="150" v-slot="props">
                    {{ props.row.date_sent | dateTimeHuman }}
                </b-table-column>
                <b-table-column field="to" label="To" width="120" v-slot="props">
                    <b-tooltip
                        type="is-dark"
                        :position="props.index ? 'is-top' : 'is-bottom'"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape', 'boolean']"
                        class="message-history__tooltip"
                    >
                        <template v-slot:content>
                            <div class="message-history__tooltip__to">
                                {{ props.row.to }}
                            </div>
                        </template>
                        <b-tag
                            v-for="(word, index) in formatTo(props.row).split(',').map(w => w.replace(/[\[\]']/g, ''))"
                            :key="index"
                            class="has-cursor-pointer"
                        >
                            {{ word }}
                        </b-tag>
                    </b-tooltip>
                </b-table-column>
                <b-table-column field="status" label="Status" width="100" v-slot="props">
                    <b-tag class="has-cursor-default" :type="getStatusType(props.row)">
                        {{ props.row.status }}
                    </b-tag>
                </b-table-column>
                <b-table-column field="body" label="Body" v-slot="props">
                    <template v-if="isJson(props.row)">
                        <be-jsonview :json="props.row.body" />
                    </template>
                    <template v-else>
                        <div v-html="props.row.body" />
                    </template>
                </b-table-column>
                <template v-slot:empty>
                    <data-table-empty v-if="showZeroResults">
                        No history available
                    </data-table-empty>
                </template>
            </b-table>
            <be-pagination
                key="message-history"
                :current-page.sync="currentPage"
                :per-page.sync="perPage"
                :count="overallCount"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { truncate } from 'lodash';
import formattersMixin from '@/mixins/formattersMixin';
import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';
import BePagination from '@/components/global/BePagination';
import BeJsonview from '@/components/global/BeJsonview';

export default {
    mixins: [formattersMixin],

    components: {
        BeJsonview,
        DataTableEmpty,
        BePagination,
    },

    computed: {
        ...mapGetters('alerts/messageHistory', [
            'overallCount',
            'isLoading',
            'formattedApiResults',
        ]),

        perPage: {
            get() {
                return this.$store.state.alerts.messageHistory.perPage;
            },
            set(perPage) {
                return this.$store.commit('alerts/messageHistory/setPerPage', { perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.alerts.messageHistory.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit('alerts/messageHistory/setPage', { page });
            },
        },

        showZeroResults() {
            return !this.isLoading
                && this.overallCount === 0;
        },
    },

    watch: {
        currentPage() {
            this.getHistory({});
        },
        perPage() {
            this.getHistory({});
        },
    },

    mounted() {
        this.getHistory({});
    },

    methods: {
        ...mapActions('alerts/messageHistory', ['getHistory']),

        formatTo({ webhook, to }) {
            if (webhook) {
                return truncate(to, { length: 24 });
            }
            return to;
        },

        getStatusType({ status }) {
            if (status === 'delivered') {
                return 'is-success';
            }
            if (status === 'not delivered') {
                return 'is-warning';
            }
            return '';
        },

        isJson({ body }) {
            try {
                JSON.parse(body);
            } catch (e) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.message-history {
    &__tooltip {
        &__to {
            width: 200px;
            word-break: break-all;
            white-space: normal;
            display: block;
        }
    }
}
</style>
