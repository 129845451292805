import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { getVuexStorage, VUEX_STORAGE_KEY } from '@/utils/vuex';
import auth from '@/store/auth';
import user from '@/store/user';
import payment from '@/store/payment';
import plans from '@/store/plans';
import summary from '@/store/summary';
import sites from '@/store/sites';
import nav from '@/store/nav';
import webhooks from '@/store/webhooks';
import alerts from '@/store/alerts/index';
import dataFilters from '@/store/dataFilters';
import explorer from '@/store/explorer/index';
import dashboard from '@/store/dashboard/index';
import liveExplorer from '@/store/liveExplorer';
import stolenPlates from '@/store/stolenPlates';
import media from '@/store/media';
import tags from '@/store/tags';
import hs from '@/store/hs';
import marketplace from '@/store/marketplace';
// maui imports
import maui from '@/store/maui63/maui';
import roi from '@/store/roi';
import canvas from '@/store/canvas';
import users from '@/store/users';
import dataExport from '@/store/dataExport';
import vehicle from '@/store/vehicle';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: VUEX_STORAGE_KEY,
    storage: getVuexStorage(),
    reducer: (state) => ({
        user: state.user,
        auth: state.auth,
        plans: state.plans,
        dashboard: state.dashboard,
        stolenPlates: state.stolenPlates,
        marketplace: {
            user: state.marketplace.user,
        },
    }),
    filter: (mutation) => mutation.type === 'auth_success'
        || mutation.type === 'auth/logout'
        || mutation.type === 'user_success'
        || mutation.type === 'plans/plansSuccess'
        || mutation.type === 'dashboard/layout/setSitesIndexView'
        || mutation.type === 'dashboard/layout/toggleOverviewFilters'
        || mutation.type === 'dashboard/layout/toggleDataExplorerImagesPreviewEnabled'
        || mutation.type === 'dashboard/layout/toggleLiveDataExplorerAutoReload'
        || mutation.type === 'dashboard/layout/toggleNav'
        || mutation.type === 'stolenPlates/setStolenPlates'
        || mutation.type === 'marketplace/user/toggleApp',
});

export default new Vuex.Store({
    modules: {
        auth,
        user,
        payment,
        plans,
        summary,
        dataFilters,
        alerts,
        nav,
        sites,
        webhooks,
        explorer,
        dashboard,
        liveExplorer,
        stolenPlates,
        media,
        tags,
        marketplace,
        // h&s,
        hs,
        roi,
        // maui only
        maui,
        canvas,
        users,
        dataExport,
        vehicle,
    },
    plugins: [vuexPersist.plugin],
});
