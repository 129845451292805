import { get } from 'lodash';
import { getApiUrl } from '@/utils/api';

export default {
    props: {
        detection: {
            type: Object,
            default: null,
        },
        video: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            messageCharacterLimit: 200,
            message: '',
            camera: null,
            site: null,
            isLoading: false,
            validationMessages: {
                toSms: 'Please select a contact',
                toEmail: 'Please select a contact',
                message: 'Please enter a valid message',
            },
        };
    },

    computed: {
        domain() {
            const email = get(this.site, 'safety_manager.email.0', null);
            if (email) {
                return email.split('@')[1];
            }
            return null;
        },
    },

    methods: {
        getDefaultMessage() {
            if (!this.site || !this.camera) {
                return ' ';
            }
            const abs = new URL(window.location.origin).href;
            return `Observation at ${this.site.name}:${this.camera.name}.`
                + `Please view the observation footage at ${abs}video/${this.detection.video}`;
        },

        setDefaultMessage() {
            this.message = this.getDefaultMessage();
        },

        loadCamera() {
            if (this.camera) {
                return Promise.resolve(true);
            }
            this.isLoading = true;
            return this.$http({ url: getApiUrl({ path: `cameras/${this.detection.camera}` }) })
                .then((response) => {
                    this.camera = response.data || null;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        loadSite() {
            if (this.site) {
                return Promise.resolve(true);
            }
            this.isLoading = true;
            return this.$http({ url: getApiUrl({ path: `sites/${this.camera.site}` }) })
                .then((response) => {
                    this.site = response.data || null;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
