import Vue from 'vue';
import axios from 'axios';
import { find, findIndex } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { buildUrlParams } from '@/utils/store-helpers';
import { DEFAULT_PAGINATION_PER_PAGE } from '@/utils/constants';

const getDefaultState = () => ({
    // index webhooks information
    webhooks: [],

    webhooksCount: 0,

    // full webhooks information
    webhooksFull: [],

    status: '',
    currentPage: 1,
    perPage: DEFAULT_PAGINATION_PER_PAGE,
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === 'loading',
    isSuccess: (state) => state.status === 'success',
};

const mutations = {
    setData(state, { data }) {
        state.data = data;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setPage(state, { page }) {
        state.currentPage = page;
    },
    setPerPage(state, { perPage }) {
        state.perPage = perPage;
    },

    webhooksSuccess(state, { data }) {
        state.status = 'success';
        if (data.results) {
            state.webhooks = data.results;
        }
        if (data.count) {
            state.webhooksCount = data.count;
        }
    },

    webhookSuccess(state, { webhookId, webhook }) {
        state.status = 'success';
        const existingIndex = findIndex(state.webhooksFull, { id: webhookId });
        if (existingIndex > -1) {
            Vue.set(state.webhooksFull, existingIndex, webhook);
        } else {
            state.webhooksFull = [...state.webhooksFull, webhook];
        }
    },

    webhookCreatedSuccess(state, { data }) {
        state.status = 'success';
        state.webhooks = [
            ...state.webhooks,
            data,
        ];
    },

    webhookUpdatedSuccess(state, { webhookId, data }) {
        state.status = 'success';
        ['webhooks', 'webhooksFull'].forEach((stateKey) => {
            const existingWebhookIndex = findIndex(state[stateKey], { id: webhookId });
            if (existingWebhookIndex > -1) {
                Vue.set(state[stateKey], existingWebhookIndex, data);
            }
        });
    },

    webhookRemovedSuccess(state, { webhookId }) {
        state.status = 'success';
        ['webhooks', 'webhooksFull'].forEach((stateKey) => {
            const existingWebhookIndex = findIndex(state[stateKey], { id: webhookId });
            if (existingWebhookIndex > -1) {
                Vue.delete(state[stateKey], existingWebhookIndex);
            }
        });
    },
};

const actions = {
    getWebhooks({ commit, state }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const params = buildUrlParams({
            perPage: state.perPage,
            page: state.currentPage,
        });

        const path = 'webhooks';

        commit('setStatus', { status: 'loading' });
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('webhooksSuccess', { data: response.data });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },

    getWebhook({ commit }, { webhookId, force = false }) {
        if (!force) {
            const existing = find(state.webhooksFull, { id: webhookId });
            if (existing) {
                return Promise.resolve(true);
            }
        }

        commit('setStatus', { status: 'loading' });
        return axios({ url: getApiUrl({ path: `webhooks/${webhookId}` }) })
            .then((response) => {
                commit('webhookSuccess', { webhookId, webhook: response.data });
            })
            .catch((err) => {
                commit('setStatus', { status: 'error' });
                errorHandler(err);
                // throw another error to display a message on the frontend
                throw new Error('Webhook not found');
            });
    },

    updateWebhook({ commit }, { webhookId, data }) {
        commit('setStatus', { status: 'loading' });

        return axios({
            method: 'PUT',
            url: getApiUrl({ path: `webhooks/${webhookId}` }),
            data,
        })
            .then((response) => {
                commit('webhookUpdatedSuccess', { webhookId, data: response.data });
            }).catch((err) => {
                commit('setStatus', { status: 'error' });
                errorHandler(err);
                throw new Error('Cannot update webhook');
            });
    },

    createWebhook({ commit }, { data }) {
        commit('setStatus', { status: 'loading' });
        return axios({
            method: 'POST',
            url: getApiUrl({ path: 'webhooks' }),
            data,
        })
            .then((response) => {
                commit('webhookCreatedSuccess', { data: response.data });
            }).catch((err) => {
                commit('setStatus', { status: 'error' });
                errorHandler(err);
                throw new Error('Cannot create webhook');
            });
    },

    removeWebhook({ commit }, { webhookId }) {
        commit('setStatus', { status: 'loading' });

        return axios({
            method: 'DELETE',
            url: getApiUrl({ path: `webhooks/${webhookId}` }),
        })
            .then(() => {
                commit('webhookRemovedSuccess', { webhookId });
            }).catch((err) => {
                commit('setStatus', { status: 'error' });
                errorHandler(err);
                throw new Error('Cannot remove webhook');
            });
    },
    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
