<template>
    <div v-if="imageId" class="image-preview">
        <span v-if="isError" class="image-preview__error">Image error</span>
        <span v-else-if="isLoading" class="image-preview__loading" />
        <template v-else>
            <b-tooltip
                v-if="tooltip"
                type="is-dark"
                position="is-right"
            >
                <template v-slot:content>
                    <img
                        :src="imageUrl"
                        class="image-preview__preview"
                        alt="Image preview"
                    >
                </template>
                <img
                    :src="imageUrl"
                    loading="lazy"
                    class="image-preview__image"
                    @click="$emit('click', row)"
                    @keydown.enter="$emit('click', row)"
                    tabindex="0"
                    role="button"
                    alt="Image preview"
                >
            </b-tooltip>
            <img
                v-else
                :src="imageUrl"
                loading="lazy"
                class="image-preview__image"
                @click="$emit('click', row)"
                @keydown.enter="$emit('click', row)"
                tabindex="0"
                role="button"
                alt="Image preview"
            >
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { get, isString } from 'lodash';

export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
        tooltip: {
            type: Boolean,
            default: true,
        },
        rowAsImage: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: true,
        };
    },

    watch: {
        imageUrl: {
            handler(val) {
                if (val) {
                    this.isLoading = false;
                }
            },
            immediate: true,
        },
    },

    computed: {
        ...mapState('media/images', ['data']),

        isError() {
            return get(this.data, `${this.imageId}.error`, false);
        },

        imageId() {
            if (this.rowAsImage) {
                return this.row.id;
            }
            return isString(this.row.image)
                ? this.row.image
                : this.row.image.id;
        },

        imageUrl() {
            return get(this.data, `${this.imageId}.image_file`, null);
        },
    },
};
</script>

<style lang="scss" scoped>
.image-preview {
    &__image {
        cursor: pointer;
        display: block;
    }

    &__preview {
        display: block;
        width: 250px;
        height: auto;
        max-width: none;
        max-height: none;
    }

    ::v-deep .tooltip-content {
        padding: .5em;
    }

    &__loading {
        display: inline-flex;
        width: 60px;
        height: 40px;
        position: relative;

        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            animation: spinAround .5s infinite linear;
            border: 2px solid $accent;
            border-radius: 9999px;
            border-right-color: transparent;
            border-top-color: transparent;
            content: "";
            display: block;
            height: 1em;
            position: relative;
            width: 1em;
        }
    }

    &__error {
        opacity: .5;
        font-size: 10px;
        cursor: default;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($accent, .4);
    }
}
</style>
