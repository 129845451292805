<template>
    <div class="live-explorer-filters">
        <div class="card live-explorer-filters__card is-rounded">
            <div class="live-explorer-filters__content card-content">
                <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
                <div class="live-explorer-filters__filters">
                    <div class="columns is-multiline">
                        <div class="column">
                            <b-field label="Search Plates">
                                <b-input v-model.trim="plate" />
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-field label="Dwell Time">
                                <be-dwell-time v-model="dwellTime" :has-confirm="true" expanded />
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-field label="Site">
                                <b-select v-model="site" expanded>
                                    <option value="">Any Site</option>
                                    <option
                                        v-for="site in sites"
                                        :key="`site${site.id}`"
                                        :value="site.id"
                                    >
                                        {{ site.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column is-narrow">
                            <div class="field live-explorer-filters__filters__search">
                                <span class="label" style="opacity: 0">-</span>
                                <div class="control is-expanded">
                                    <b-button @click.prevent="doSearch()" class="be-button is-wider" type="is-primary">
                                        Search
                                    </b-button>
                                    <div v-if="hasFilters" class="live-explorer-filters__filters__clear">
                                        <button type="button" class="button-link is-smaller is-grey-lighter" @click.prevent="clear()">
                                            Clear filters
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { find } from 'lodash';
import BeDwellTime from '@/components/global/BeDwellTime';
import noticesMixin from '@/mixins/noticesMixin';

export default {
    name: 'LiveExplorerFilters',

    mixins: [noticesMixin],

    components: {
        BeDwellTime,
    },

    data() {
        return {
            isSearching: false,
            site: this.$store.state.liveExplorer.filter.site,
            dwellTime: this.$store.state.liveExplorer.filter.dwellTime,
            plate: this.$store.state.liveExplorer.filter.plate,
        };
    },

    watch: {
        perPage() {
            this.getData({});
        },

        currentPage() {
            this.getData({});
        },

        site(val) {
            if (!this.checkIfParkingSite(val)) {
                this.$nextTick(() => {
                    if (this.hasFilters) {
                        this.doSearch();
                    }
                });
            }
        },
    },

    computed: {
        ...mapState('sites', ['sites']),
        ...mapState('liveExplorer', ['filter', 'currentPage', 'perPage']),
        ...mapGetters({
            isSitesLoading: 'sites/isLoading',
            isExplorerLoading: 'liveExplorer/isLoading',
        }),

        isLoading() {
            return this.isExplorerLoading || this.isSitesLoading || this.isSearching;
        },

        hasFilters() {
            return this.filter.plate || this.filter.dwellTime || this.filter.site;
        },
    },

    methods: {
        ...mapActions('sites', ['getSites']),
        ...mapActions('liveExplorer', ['getData']),
        ...mapMutations('liveExplorer', ['setFilter']),

        updateFiltersNow() {
            this.setFilter({
                site: this.site,
                dwellTime: this.dwellTime,
                plate: this.plate,
            });
        },

        checkIfParkingSite(siteId) {
            const site = find(this.sites, { id: siteId });
            if (!site) {
                return false;
            }
            if (site.is_parking_site) {
                return true;
            }

            this.$buefy.dialog.alert({
                message: 'No data to display as none of the cameras have been configured as Entry, Exit or Auto.',
                type: 'is-danger',
                ariaRole: 'alertdialog',
                ariaModal: true,
            });

            this.$nextTick(() => {
                this.site = '';
            });

            return false;
        },

        doSearch(force = false, triggerEvent = true) {
            if (this.isLoading) {
                return Promise.resolve(false);
            }

            this.updateFiltersNow();

            this.isSearching = true;
            if (triggerEvent) {
                this.$emit('search');
            }
            return this.getData({ page: 1, force })
                .then(() => true)
                .finally(() => {
                    this.isSearching = false;
                    if (triggerEvent) {
                        this.$emit('searched');
                    }
                });
        },

        clear() {
            this.site = '';
            this.dwellTime = 0;
            this.plate = '';
            this.$nextTick(() => this.doSearch());
        },
    },

    mounted() {
        this.getSites({ force: true });
        this.getData({});
    },
};
</script>

<style lang="scss" scoped>
.live-explorer-filters {
    &__card {
        overflow: visible;
        background: $grey-dark;
    }

    &__filters {
        max-width: $desktop;

        &__search {
            position: relative;
        }

        &__clear {
            position: absolute;
            top: 100%;
            margin-top: 2px;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 13px;

            a {
                @include transition-button;
                color: $primary;

                &:hover {
                    color: darken($primary, 10);
                }
            }
        }
    }

    .button-label {
        opacity: 0;
    }
}
</style>
