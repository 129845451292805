import axios from 'axios';

const cancelToken = axios.CancelToken;

export let cancelSource = cancelToken.source(); // eslint-disable-line

export const refreshToken = (() => {
    cancelSource = cancelToken.source();
});

export const cancelAllRequests = (({ refresh = false }) => {
    cancelSource.cancel();
    if (refresh) {
        refreshToken();
    }
});
