<template>
    <div class="mapbox-map sites-map">
        <mgl-map
            v-if="shouldLoad"
            class="mapbox-map__map sites-map__map"
            ref="map"
            v-bind="mapboxOptions"
            :map-style="mapStyle"
            @load="onMapLoaded"
        >
            <template v-for="(marker, index) in clustersGeoJson">
                <site-map-marker
                    v-if="marker.properties.site"
                    :key="`marker${index}`"
                    :item="marker.properties.site"
                    :marker="marker"
                />
                <site-map-cluster
                    v-if="marker.properties.cluster"
                    :key="`marker${index}`"
                    :marker="marker"
                />
            </template>

            <mgl-navigation-control
                :show-compass="false"
                position="bottom-right"
            />
        </mgl-map>

        <div class="mapbox-map__view-toggler">
            <div class="field has-addons">
                <div class="control">
                    <b-button
                        class="be-button is-toggle"
                        size="is-small"
                        :type="mapView !== 'satellite' ? 'is-toggle-active' : 'is-toggle-inactive'"
                        @click="mapView = 'default'"
                    >
                        <span>Street</span>
                    </b-button>
                </div>
                <div class="control">
                    <b-button
                        class="be-button is-toggle"
                        size="is-small"
                        :type="mapView === 'satellite' ? 'is-toggle-active' : 'is-toggle-inactive'"
                        @click="mapView = 'satellite'"
                    >
                        <span>Satellite</span>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglNavigationControl } from 'v-mapbox';
import Supercluster from 'supercluster';
import { mapState } from 'vuex';
import { getMapStyle, MAPBOX_OPTIONS } from '@/utils/map';
import mapMixin from '@/mixins/mapMixin';
import SiteMapCluster from '@/components/local/sites/SiteMapCluster';
import SiteMapMarker from './SiteMapMarker';

export default {
    mixins: [mapMixin],

    components: {
        SiteMapCluster,
        SiteMapMarker,
        MglNavigationControl,
    },

    data() {
        return {
            shouldLoad: false,
            clustersGeoJson: [],
            mapboxOptions: {
                ...MAPBOX_OPTIONS,
                center: [174, -36],
                zoom: 9,
                attributionControl: false,
                maxZoom: 18,
            },
        };
    },

    mounted() {
        this.shouldLoad = true;
        this.createCluster();
    },

    beforeDestroy() {
        this.clusterIndex = null;
    },

    watch: {
        sitesGeoJson: {
            handler() {
                this.refreshClusterIndex();
                this.updateClusters();
            },
            deep: true,
        },
    },

    computed: {
        ...mapState('sites', ['sites']),
        ...mapState('dashboard/layout', ['navOpened']),

        mapView: {
            get() {
                return this.$store.state.sites.mapView;
            },
            set(mapView) {
                this.$store.commit('sites/setMapView', { mapView });
            },
        },

        mapStyle() {
            return getMapStyle(this.mapView);
        },

        sitesGeoJson() {
            return this.sites.map((site) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: this.getSiteBounds(site),
                },
                properties: {
                    name: site.name,
                    site,
                },
            }));
        },

        mapBounds() {
            if (!this.sites.length) {
                return null;
            }
            return this.sites.reduce((bnds, site) => {
                bnds.extend(this.getSiteBounds(site));
                return bnds;
            }, new Mapbox.LngLatBounds());
        },
    },

    methods: {
        onMapLoaded({ map }) {
            if (this.mapBounds) {
                map.fitBounds(this.mapBounds, {
                    padding: {
                        top: 150, bottom: 100, left: 50, right: 50,
                    },
                    duration: 0,
                });
            }
            this.updateClusters();
            map.on('moveend', this.updateClusters);
        },

        getSiteBounds(site) {
            return [
                site.lng,
                site.lat,
            ];
        },

        createCluster() {
            this.clusterIndex = new Supercluster({
                radius: 40,
                maxZoom: 16,
                log: true,
            });

            this.clusterIndex.load(this.sitesGeoJson);
        },

        refreshClusterIndex() {
            if (!this.clusterIndex) {
                this.createCluster();
                return;
            }

            this.clusterIndex.load(this.sitesGeoJson);
        },

        updateClusters() {
            const { map } = this.$refs.map;

            const bounds = map.getBounds();
            const zoom = map.getZoom();

            const clustersGeoJson = this.clusterIndex.getClusters(
                [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
                Math.floor(zoom),
            );

            this.clustersGeoJson = clustersGeoJson;
        },
    },
};
</script>

<style lang="scss">
@import url('https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css');

.sites-map {
    @include be-toolbar-full-height;
    width: 100%;
}
</style>
