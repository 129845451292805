<template>
    <b-field v-show="!isDeleted" :type="hasError ? 'is-danger' : ''">
        <b-input v-model.trim="roiModel" expanded />
        <b-select v-model="roiType">
            <option
                v-for="type in roiTypes"
                :key="`opt${type.type}`"
                :value="type.type"
            >
                {{ type.label }}
            </option>
        </b-select>
        <div v-if="canRemove" class="control">
            <b-button icon-left="close" @click="isDeleted = true" />
        </div>
    </b-field>
</template>

<script>

import { chunk, flattenDeep, get, isInteger, trim } from 'lodash';
import { mapState } from 'vuex';

export default {
    props: {
        cameraId: {
            type: String,
            required: true,
        },
        roi: {
            type: Object,
            required: true,
        },
        canRemove: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            hasError: false,
            isDeleted: false,
            roiModel: this.convertValueToRoiModel(),
            roiType: this.roi.roi_type || 0,
        };
    },

    computed: {
        ...mapState('roi', ['roiTypes']),
    },

    watch: {
        roiModel: {
            handler(val) {
                this.$emit('update', val);
            },
            deep: true,
        },
    },

    mounted() {
        this.convertRoiModelToValue();
    },

    methods: {
        getSaveData() {
            return {
                id: get(this.roi, 'id', null),
                deleted: this.isDeleted,
                data: {
                    ...this.convertRoiModelToValue(),
                    roi_type: this.roiType,
                },
            };
        },

        getRoiData() {
            return {
                id: get(this.roi, 'id', null),
                deleted: this.isDeleted,
                ...this.convertRoiModelToValue(),
                roi_type: this.roiType,
            };
        },

        convertValueToRoiModel() {
            const numbers = flattenDeep(this.roi.roi).join(', ');
            const tags = this.roi.tags.map((t) => t.label).join(', ');

            return [numbers, tags].filter((v) => v).join(', ');
        },

        convertRoiModelToValue() {
            // we need to split tags and the numbers first
            const split = this.roiModel.split(',').map((v) => {
                const trimmed = trim(v);
                const isNumber = trimmed.match(/^\d+$/);
                return isNumber
                    ? parseInt(trimmed, 10)
                    : trimmed;
            });

            const chunks = chunk(split, 2);
            const numbers = chunks.filter((c) => c.length === 2);
            const labels = chunks.filter((c) => c.length === 1).map((l) => l[0]).filter((l) => l);

            return {
                roi: [numbers],
                tags: labels.map((label) => ({ label })),
            };
        },

        isValid() {
            this.hasError = false;
            if (this.isDeleted) {
                return true;
            }
            const value = this.convertRoiModelToValue();

            // at least 3 points
            if (value.roi[0].length < 3) {
                this.hasError = true;
                return false;
            }

            const allNumbers = value.roi[0].reduce((even, row) => (
                even && isInteger(row[0]) && isInteger(row[1])
            ), true);

            if (!allNumbers) {
                this.hasError = true;
            }

            return allNumbers;
        },
    },
};
</script>
