<template>
    <div class="field-webhook-headers">
        <b-field
            v-for="(row, index) in computedValue"
            :key="`field${index}`"
            grouped
        >
            <b-field :label="`Header ${index + 1}`" class="mb-0">
                <b-input v-model="row.header" />
            </b-field>
            <b-field :label="`Header ${index + 1} Value`" expanded class="mb-0">
                <b-input v-model="row.value" />
            </b-field>
            <b-field>
                <button type="button" class="remove button-link" @click="removeRow(row, index)">
                    <b-icon icon="close" size="is-small" />
                </button>
                <template v-slot:label>
                    <span>&#8203;</span>
                </template>
            </b-field>
        </b-field>
        <b-field
            :type="type"
            :message="message"
        >
            <b-button class="be-button px-6" type="is-primary" @click.prevent="addRow">
                Add Header Row
            </b-button>
        </b-field>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Array],
            default: () => [],
        },

        message: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            newValue: this.value,
        };
    },

    computed: {
        computedValue: {
            get() {
                return this.newValue;
            },
            set(value) {
                this.newValue = value;
                this.$emit('input', value);
            },
        },
    },

    watch: {
        value(value) {
            this.newValue = value;
        },
    },

    methods: {
        getNewRow({ header = '', value = '' }) {
            return {
                header,
                value,
            };
        },

        addRow() {
            this.computedValue = this.computedValue.concat([this.getNewRow({})]);
        },

        removeRow(row, index) {
            this.computedValue.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
    .field-webhook-headers {
        .remove {
            color: $danger;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: $control-height;
            transition: opacity $speed $easing;

            &:hover {
                opacity: .7;
            }
        }
    }
</style>
