<template>
    <div class="integration is-enterprise">
        <be-integration-header class="integration__header">
            <template v-slot:heading>
                Enterprise Integration Enquiry
            </template>
            <template v-slot:subheading>
                <p>
                    R/VISION is a flexible managed platform that can be adapted to any suitable enterprise need.
                    R/VISION can be integrated with ERP, Loyalty systems and advanced enterprise level CRM systems at
                    scale. This integration allows you to contact one of our Enterprise integration team to do a needs
                    analysis for your organisation.
                </p>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field
                            label="Please describe the most important use case you are trying to solve:"
                            :type="fieldType('message')"
                            :message="fieldMessage('message')"
                        >
                            <b-input
                                type="textarea"
                                placeholder="e.g. I would like to disproportionately reward customers who visit my store regularly by understanding how often they use our car parks."
                                v-model.trim="fields.message" />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field
                            :type="fieldType('case')"
                            :message="fieldMessage('case')"
                        >
                            <template v-slot:label>
                                Please select the option that best describes your camera infrastructure related to this
                                use case: <span class="has-text-primary">*</span>
                            </template>
                            <div>
                                <b-field
                                    v-for="(item, index) in cases"
                                    :key="`case${index}`"
                                >
                                    <b-radio
                                        v-model="fields.case"
                                        :native-value="item.value"
                                        :aria-checked="item.value === fields.case ? 'checked' : ''"
                                    >
                                        {{ item.label }}
                                    </b-radio>
                                </b-field>
                            </div>
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field
                            :type="fieldType('phoneNumber')"
                            :message="fieldMessage('phoneNumber')"
                        >
                            <template v-slot:label>
                                Your best contact phone number: <span class="has-text-primary">*</span>
                            </template>
                            <b-input v-model.trim="fields.phoneNumber" />
                        </b-field>
                    </div>

                    <div class="column is-12">
                        <b-button
                            @click="send"
                            :loading="isSending"
                            class="be-button is-wider is-primary"
                        >
                            Submit
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import noticesMixin from '@/mixins/noticesMixin';
import { getApiUrl } from '@/utils/api';

export default {
    mixins: [
        validationMixin,
        noticesMixin,
    ],

    validations() {
        return {
            fields: {
                message: {},
                phoneNumber: {
                    required,
                },
                case: {
                    required,
                },
            },
        };
    },

    data() {
        return {
            isSending: false,
            duration: 10000,
            fields: {
                message: '',
                phoneNumber: '',
                case: '',
            },
            validationMessages: {
                message: 'Please describe the use case',
                phoneNumber: 'Please enter your phone number',
                case: 'Please select your case',
            },
            cases: [
                {
                    label: 'We have some cameras we bought less than 5 years ago',
                    value: 'We have some cameras we bought less than 5 years ago',
                },
                {
                    label: 'We have some cameras < 5 years old but I think we will need more',
                    value: 'We have some cameras < 5 years old but I think we will need more',
                },
                {
                    label: 'We have new cameras',
                    value: 'We have new cameras',
                },
                {
                    label: 'We are planning to roll out new cameras',
                    value: 'We are planning to roll out new cameras',
                },
                {
                    label: 'I don\'t know',
                    value: 'I don\'t know',
                },
            ],
        };
    },

    methods: {
        reset() {
            this.fields = {
                message: '',
                phoneNumber: '',
                case: '',
            };
            this.$v.$reset();
        },

        send() {
            if (this.isSending) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isSending = true;
            this.closeNotices();

            this.sendMessage()
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Thanks for your enquiry, one of our experts will be in touch in the next day or sooner.',
                        duration: this.duration,
                    });
                    this.reset();
                })
                .catch(() => {
                    this.displayErrorNotice({
                        message: 'The message was not sent. Please reload the page and try again.',
                        duration: this.duration,
                    });
                })
                .finally(() => {
                    this.isSending = false;
                });
        },

        sendMessage() {
            return this.$http({
                url: getApiUrl({
                    path: 'contact_form',
                }),
                method: 'POST',
                data: { ...this.fields },
            });
        },

        fieldType(field) {
            return this.$v.fields[field].$error ? 'is-danger' : '';
        },

        fieldMessage(field) {
            return this.$v.fields[field] && this.$v.fields[field].$error && this.validationMessages[field]
                ? this.validationMessages[field]
                : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
