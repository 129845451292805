<template>
    <div v-if="hasData" class="observations-list">
        <div class="observations-list__colums columns is-multiline">
            <div
                v-for="(group, index) in formattedApiResults"
                :key="`item-${group.cluster[0].id}-${index}`"
                class="observations-list__column column is-4-tablet is-3-desktop"
            >
                <h-s-observation-group-item
                    :group="group"
                    @view="toggleGroupPopup"
                />
            </div>
        </div>

        <div v-if="hasMore" class="observations-list__more mt-5 has-text-centered">
            <b-button class="be-button is-secondary">
                Load more
            </b-button>
        </div>

        <h-s-view-observation-group-popup
            v-if="!!currentGroup"
            :active="!!currentGroup"
            :group="currentGroup"
            @close="toggleGroupPopup()"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HSObservationGroupItem from '@/components/local/hs/observations/partials/HSObservationGroupItem';
import HSViewObservationGroupPopup from '@/components/local/hs/observations/HSViewObservationGroupPopup';

export default {
    components: {
        HSObservationGroupItem,
        HSViewObservationGroupPopup,
    },

    data() {
        return {
            hasMore: false,
            currentGroup: null,
        };
    },

    computed: {
        ...mapGetters('hs/observations/explorer/detections', ['hasData', 'formattedApiResults']),
    },

    methods: {
        toggleGroupPopup(group = null) {
            this.currentGroup = group;
        },
    },
};
</script>

<style lang="scss" scoped>
.observations-list {
}
</style>
