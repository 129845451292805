<template>
    <b-modal
        :active="active"
        has-modal-card
        trap-focus
        scroll="keep"
        :width="420"
        :can-cancel="false"
        :destroy-on-hide="true"
    >
        <b-loading :active="isPreloading || isLoading" :is-full-page="false" :can-cancel="false" />
        <div class="modal-card is-narrow">
            <section class="modal-card-body">
                <div class="content" v-if="totalResults != null">
                    <p class="title is-5 mb-3">You are about to load {{ totalResults }} detections.</p>
                    <p>Are you sure you want to continue?</p>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button class="be-button is-outline" @click="close">Cancel</b-button>
                <b-button class="be-button is-primary" @click="loadNow">Ok</b-button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isPreloading: false,
            totalResults: null,
        };
    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    this.loadTotals();
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions({
            loadDataCount: 'maui/detections/loadDataCount',
        }),

        close() {
            this.$emit('close');
            this.$emit('update:active', false);
        },

        cancel() {
            this.$emit('cancel');
            this.$emit('update:is-loading', false);
            this.close();
        },

        loadNow() {
            this.$emit('load');
        },

        loadTotals() {
            this.$emit('load-totals');
            this.isPreloading = true;
            this.loadDataCount()
                .then((count) => {
                    this.totalResults = count;
                    this.isPreloading = false;
                });
        },
    },
};
</script>
