<template>
    <be-widget class="chart" :is-loading="isLoading">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:headingAfter>
            <images-detections-toggler
                :values="togglerValues"
                v-model="currentView"
            />
        </template>
        <template v-slot:content>
            <div class="chart">
                <bar-chart
                    class="chart__wrapper"
                    :chart-data="chartData"
                    :options="options"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BarChart from '@/utils/chartjs/BarChart';
import ImagesDetectionsToggler from '@/components/widgets/filters/ImagesDetectionsToggler';
import { DATE_FORMAT } from '@/utils/formatters/datetime';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import { COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({
    labels: [],
    datasets: [
        {
            label: '',
            backgroundColor: COLORS.purple,
            data: [],
        },
    ],
});

export default {
    components: {
        ImagesDetectionsToggler,
        BarChart,
    },

    mixins: [widgetDataMixin],

    data() {
        return {
            currentView: 'images',
            chartData: getChartDataDefaults(),
            togglerValues: [
                {
                    handle: 'images',
                    label: 'Images received',
                },
                {
                    handle: 'detections',
                    label: 'Detections per day',
                },
            ],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                },
            },
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('explorer/detections/unique/dailysummary', ['isLoading', 'formattedApiResults']),

        widgetTitle() {
            return this.currentView === 'images'
                ? 'Images received over time'
                : 'Detections over time';
        },

        isImagesView() {
            return this.currentView === 'images';
        },

        displayDates() {
            const startDate = this.$date(this.getDateRange.start);
            const endDate = this.$date(this.getDateRange.end);

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 0; i < diff; i++) {
                dateArray.push(startDate.add(i + 1, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },
    },

    watch: {
        currentView() {
            this.updateChartData();
        },

        formattedApiResults: {
            handler() {
                this.updateChartData();
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        ...mapActions('explorer/detections/unique/dailysummary', ['loadData']),

        canPopulateChart() {
            return this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        getLabelsArray() {
            const startDate = this.$date(this.getDateRange.start);
            const endDate = this.$date(this.getDateRange.end);

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 1; i <= diff; i++) {
                dateArray.push(startDate.add(i, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },

        getApiResultsData({ field = 'images' }) {
            if (!this.formattedApiResults) {
                return [];
            }

            const data = this.displayDates.map((date) => {
                if (this.formattedApiResults[date]) {
                    return this.formattedApiResults[date][field];
                }
                return null;
            });

            return data;
        },

        getApiResultsBg() {
            if (!this.formattedApiResults) {
                return [];
            }

            return this.displayDates.map((date) => {
                if (this.formattedApiResults[date]) {
                    return this.isImagesView ? '#AE83FB' : '#FFFA9C';
                }
                return '#ffffff';
            });
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            const label = this.isImagesView ? 'Images' : 'Detections';
            const backgroundColor = this.getApiResultsBg();
            const field = this.isImagesView ? 'images' : 'unique_plates';
            const data = this.getApiResultsData({ field });

            this.chartData = {
                labels: this.displayDates,
                datasets: [{
                    label,
                    backgroundColor,
                    data,
                }],
            };
        },

        resetChartData() {
            this.chartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    &__wrapper {
        position: relative;
        height: 520px;
        width: 100%;
    }
}
</style>
