<template>
    <component :is="pageComponent" :app="$route.params.app" />
</template>

<script>
import { kebabCase } from 'lodash';
import AppParkingManagement from '@/components/pages/dashboard/marketplace/settings/AppParkingManagement';
import AppStolenVehicles from '@/components/pages/dashboard/marketplace/settings/AppStolenVehicles';
import AppHubspot from '@/components/pages/dashboard/marketplace/settings/AppHubspot';
import AppComingSoon from '@/components/pages/dashboard/marketplace/settings/AppComingSoon';
import AppSalesforce from '@/components/pages/dashboard/marketplace/settings/AppSalesforce';
import AppHealthSafety from '@/components/pages/dashboard/marketplace/settings/AppHealthSafety';
import AppVehicleCrm from '@/components/pages/dashboard/marketplace/settings/AppVehicleCrm';
import AppPayWithZ from '@/components/pages/dashboard/marketplace/settings/AppPayWithZ';
import AppSwipedOn from '@/components/pages/dashboard/marketplace/settings/AppSwipedOn';
import AppPlexure from '@/components/pages/dashboard/marketplace/settings/AppPlexure';
import AppPowerBi from '@/components/pages/dashboard/marketplace/settings/AppPowerBi';

export default {
    components: {
        AppParkingManagement,
        AppStolenVehicles,
        AppSalesforce,
        AppComingSoon,
        AppHealthSafety,
        AppVehicleCrm,
        AppPayWithZ,
        AppHubspot,
        AppSwipedOn,
        AppPlexure,
        AppPowerBi,
    },

    created() {
        if (!this.pageComponent) {
            this.$router.replace('/dashboard/marketplace');
        }
    },

    computed: {
        pageComponent() {
            const componentName = `app-${this.$route.params.app}`;
            const componentNames = Object.keys(this.$options.components).map((c) => kebabCase(c));

            return componentNames.includes(componentName)
                ? componentName
                : 'app-coming-soon';
        },
    },
};
</script>
