<template>
    <div class="marketplace">
        <be-integration-header class="marketplace__header">
            <template v-slot:heading>
                R/VISION App Marketplace Settings
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="field">
                <div class="label">
                    Marketplace API Token
                </div>
                <be-copy :copy-text="token" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import BeCopy from '@/components/global/BeCopy';

export default {
    components: {
        BeCopy,
    },

    computed: {
        ...mapState('auth', ['token']),
    },
};
</script>
