<template>
    <div class="home-form py-7">
        <div class="home-form__inner home-container">
            <h1 class="title is-3 has-text-weight-bold has-text-centered mb-6">Sign in to your account</h1>
            <form @submit.prevent="login" novalidate>
                <b-field
                    :type="$v.email.$error ? 'is-danger' : ''"
                    :message="$v.email.$error ? validationMessages.email : ''"
                >
                    <b-input
                        v-model.trim="email"
                        type="email"
                        placeholder="Email"
                    />
                </b-field>
                <b-field
                    :type="$v.password.$error ? 'is-danger' : ''"
                    :message="$v.password.$error ? validationMessages.password : ''"
                >
                    <b-input
                        v-model="password"
                        placeholder="Password"
                        type="password"
                        password-reveal
                    />
                </b-field>

                <div v-if="apiError" class="my-5 has-text-centered has-text-danger">
                    <small>{{ apiError }}</small>
                </div>

                <div class="home-form__footer">
                    <div class="home-form__cta mt-5 has-text-centered">
                        <button
                            class="button be-button is-wide is-primary is-rounded"
                            type="submit"
                            :class="{ 'is-loading': isLoading }"
                            :disabled="isLoading"
                        >
                            <span v-if="!isLoading">Sign In</span>
                            <span v-else>Signing in&hellip;</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { first, get, isArray, isEmpty } from 'lodash';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
        },
    },

    data() {
        return {
            email: '',
            password: '',
            apiError: null,
            errors: {},
            validationMessages: {
                email: 'Please enter a valid email address',
                password: 'Please enter your password',
            },
        };
    },

    computed: {
        ...mapGetters('auth', ['isLoading']),
    },

    methods: {
        ...mapActions('auth', ['userLoginOrRegister']),

        login() {
            if (this.isLoading) {
                return false;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }

            this.userLoginOrRegister({
                type: 'login',
                auth: {
                    email: this.email,
                    password: this.password,
                },
            })
                .then(() => this.$router.replace('/dashboard'))
                .catch((err) => {
                    const data = get(err, 'response.data', {});
                    if (!isEmpty(data.detail)) {
                        this.apiError = data.detail;
                        return;
                    }
                    // fallback
                    if (!isEmpty(data.non_field_errors)) {
                        this.apiError = isArray(data.non_field_errors)
                            ? first(data.non_field_errors)
                            : data.non_field_errors;
                        return;
                    }
                    // fallback
                    this.apiError = 'There was a problem loggin in. Please try again.';
                });

            return true;
        },
    },
};
</script>
