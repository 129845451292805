<template>
    <div class="marketplace">
        <be-integration-header class="marketplace__header">
            <template v-slot:heading>
                R/VISION App Marketplace
            </template>
            <template v-slot:subheading>
                <div class="content">
                    <p>
                        Get more out of your cameras. Simply turn on any of these turn-key integrations to unlock
                        revenue building, safety and customer experience capabilities from your investment. Additional
                        monthly charges may apply, and will be itemised on your R/VISION subscription.
                    </p>
                    <p>
                        <strong class="has-text-primary">You can trial any App free for 90 days.</strong>
                    </p>
                </div>
            </template>
            <template v-slot:cta>
                <a
                    to="https://rush.co.nz/rvision"
                    class="marketplace__header__link"
                    target="_blank"
                    rel="nofollow noopener">
                    Become a Marketplace App
                </a>
            </template>
        </be-integration-header>
        <div class="app-container">
            <marketplace-apps />
        </div>
    </div>
</template>

<script>
import MarketplaceApps from '@/components/local/marketplace/MarketplaceApps';

export default {
    components: {
        MarketplaceApps,
    },
};
</script>

<style lang="scss" scoped>
.marketplace {
    &__header {
        background: $dark;

        &__link {
            color: $white;
            text-decoration: underline;
            font-size: 14px;
        }
    }
}
</style>
