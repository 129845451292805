import observations from './observations/index';
import objectClasses from './objectClasses';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    resetState({ dispatch }) {
        return dispatch('observations/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        observations,
        objectClasses,
    },
};
