const getDefaultState = () => ({
    bigeyesMenu: [
        {
            heading: 'Vehicles',
            handle: 'vehicles',
            slug: 'parking-management',
            items: [
                {
                    icon: 'view-dashboard',
                    to: '/dashboard',
                    label: 'Overview',
                },
                {
                    icon: 'database',
                    to: '/dashboard/vehicle-explorer',
                    label: 'Explorer',
                },
                {
                    icon: 'chart-box-outline',
                    to: '/dashboard/vehicle-observations-overview',
                    label: 'Vehicle Overview',
                },
                {
                    icon: 'view-dashboard',
                    to: '/dashboard/live-dashboard',
                    label: 'Live Dashboard',
                },
            ],
        },
        {
            heading: 'Health & Safety',
            handle: 'hs',
            slug: 'health-safety',
            items: [
                {
                    icon: 'view-dashboard',
                    to: '/dashboard/health-and-safety',
                    label: 'Overview',
                },
                {
                    icon: 'database',
                    to: '/dashboard/health-and-safety/explorer',
                    label: 'Explorer',
                },
                {
                    icon: 'chart-box-outline',
                    to: '/dashboard/health-and-safety/safety-observations-overview',
                    label: 'Safety Overview',
                },
                {
                    icon: 'magnify',
                    to: '/dashboard/health-and-safety/safety-observations-explorer',
                    label: 'Safety Observations',
                },
            ],
        },
        {
            heading: 'Setup',
            handle: 'setup',
            slug: 'setup',
            items: [
                {
                    icon: 'map-marker',
                    to: '/dashboard/sites',
                    label: 'Sites',
                    hasSub: true,
                },
                {
                    icon: 'account',
                    to: '/dashboard/user-management',
                    label: 'User Management',
                    hasSub: true,
                },
                {
                    icon: 'account',
                    to: '/dashboard/user-invites',
                    label: 'User Invites',
                    hasSub: true,
                },
            ],
        },

        {
            heading: 'Marketplace',
            handle: 'marketplace',
            slug: 'marketplace',
            items: [
                {
                    icon: 'shopping',
                    to: '/dashboard/marketplace/apps',
                    label: 'Get New Apps',
                    hasSub: true,
                },
                {
                    icon: 'cog',
                    to: '/dashboard/marketplace/settings',
                    label: 'Settings',
                    hasSub: true,
                },
            ],
        },
        {
            heading: 'Installed Apps',
            handle: 'apps',
            slug: 'installed-apps',
            items: [
                {
                    icon: 'bell',
                    to: '/dashboard/alerts',
                    label: 'R/Vision Alerts',
                    hasSub: true,
                },
                {
                    icon: 'webhook',
                    to: '/dashboard/webhooks',
                    label: 'Webhooks',
                    hasSub: true,
                },
                {
                    icon: 'database-refresh',
                    to: '/dashboard/live-explorer',
                    label: 'Live Explorer',
                    hasSub: true,
                },
            ],
        },
        {
            heading: 'Integrations',
            handle: 'integrations',
            slug: 'integrations',
            items: [
                {
                    icon: 'domain',
                    to: '/dashboard/enterprise',
                    label: 'Enterprise',
                    hasSub: true,
                },
            ],
        },
    ],

    maui63Menu: [
        {
            heading: 'Detections',
            items: [
                {
                    icon: 'view-dashboard',
                    to: '/dashboard',
                    label: 'Overview',
                },
                {
                    icon: 'map-marker',
                    to: '/dashboard/sites',
                    label: 'Sites',
                    hasSub: true,
                },
                {
                    icon: 'map',
                    to: '/dashboard/map-explorer',
                    label: 'Map Explorer',
                    hasSub: true,
                },
                {
                    icon: 'database',
                    to: '/dashboard/data-explorer',
                    label: 'Data Explorer',
                    hasSub: true,
                },
            ],
        },
        {
            heading: 'Integrations',
            items: [
                {
                    icon: 'bell',
                    to: '/dashboard/alerts',
                    label: 'R/Vision Alerts',
                    hasSub: true,
                },
                {
                    icon: 'webhook',
                    to: '/dashboard/webhooks',
                    label: 'Webhooks',
                    hasSub: true,
                },
                {
                    icon: 'hubspot',
                    to: '/dashboard/hubspot',
                    label: 'Hubspot',
                    hasSub: true,
                },
            ],
        },
    ],
});

const state = getDefaultState();

const getters = {
    menu(state, rootGetters) {
        if (rootGetters['user/isMaui63']) {
            return state.maui63Menu;
        }

        return state.bigeyesMenu;
    },
};

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    resetState({ commit }) {
        commit('resetState');
    },

    removeItem({ state }, { itemPath, slug }) {
        const foundSection = state.bigeyesMenu.find((obj) => obj.slug === slug);

        if (!foundSection) {
            return false;
        }

        if (!itemPath) {
            state.bigeyesMenu = state.bigeyesMenu.filter((obj) => obj.slug !== slug);
        } else {
            foundSection.items = foundSection.items.filter((obj) => obj.to !== itemPath);
        }

        return true;
    },

    restoreMenu({ state }) {
        state.bigeyesMenu = getDefaultState().bigeyesMenu;
        return true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
