<template>
    <div class="hs-overview">
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Health & Safety Observations Overview
                </template>
            </be-page-header>
            <h-s-observations-filters
                simple
                :summary="filtersSummary"
                :is-loading="isLoading"
                :is-multi-select="true"
                @filter="onFilter"
            />
            <div class="hs-overview__content">
                <b-loading
                    :active="isLoading"
                    :can-cancel="false"
                    :is-full-page="false"
                />
                <div
                    v-if="hasFiltersApplied"
                    class="hs-overview__cols columns is-multiline"
                >
                    <div class="column is-12">
                        <overall-metrics :can-load-data="false" />
                    </div>
                    <div class="column is-12">
                        <total-metrics :can-load-data="false" />
                    </div>
                    <div class="column is-12">
                        <speed-distribution :can-load-data="true" />
                    </div>
                    <!-- Temporarily hidden for confirmation -->
                    <!-- <div class="column is-12">
                        <traffic-volume :can-load-data="false" />
                    </div> -->
                </div>
                <div
                    v-else
                    class="hs-overview__empty content has-text-centered"
                >
                    <p>Apply filters to explore the results</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import HSObservationsFilters from '@/components/local/hs/observations/HSObservationsFilters';
// import SpeedDistribution from '@/components/widgets/hs/charts/SpeedDistribution';
import OverallMetrics from '@/components/widgets/hs/charts/OverallMetrics';
import TotalMetrics from '@/components/widgets/hs/charts/TotalMetrics';
// import TrafficVolume from '@/components/widgets/hs/charts/TrafficVolume';

export default {
    components: {
        // TrafficVolume,
        TotalMetrics,
        OverallMetrics,
        // SpeedDistribution,
        HSObservationsFilters,
    },

    mounted() {
        this.resetFilters();
    },

    computed: {
        ...mapState('hs/observations/summary', ['filtersApplied', 'data']),
        ...mapGetters('hs/observations/summary', [
            'isLoading',
            'hasFiltersApplied',
            'filtersSummary',
        ]),
    },

    watch: {
        filtersApplied(val) {
            if (val) {
                this.loadData();
            }
        },
    },

    methods: {
        ...mapActions('hs/observations', ['resetFilters']),
        ...mapActions('hs/observations/summary', ['filter', 'loadData']),
        onFilter({ filters }) {
            this.filter({ filters });
        },
    },
};
</script>

<style lang="scss" scoped>
.hs-overview {
    &__content {
        margin-top: 2em;
        position: relative;
    }

    &__empty {
        opacity: 0.6;
    }
}
</style>
