<template>
    <manage-popup
        :active="active"
        class="alert-popup"
        width="wide"
        :toggle-intercom="false"
        @close="$emit('close')"
    >
        <template #title>
            Send Alert
        </template>
        <template #body>
            <b-loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
            <div class="content">
                <p>Send SMS and email to Site Contact, STMS, account holder and escalation chain</p>
            </div>

            <div class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Email">
                        <b-taginput
                            :value="safetyEmails"
                            ellipsis
                            readonly
                            :allow-new="false"
                            :closable="false"
                            :allow-duplicates="false"
                        />
                    </b-field>
                    <b-field label="Name">
                        <b-input :value="safetyName" readonly />
                    </b-field>
                    <b-field label="Phone">
                        <b-taginput
                            :value="safetyPhones"
                            ellipsis
                            readonly
                            :allow-new="false"
                            :closable="false"
                            :allow-duplicates="false"
                        />
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Email">
                        <b-taginput
                            :value="stmsEmails"
                            ellipsis
                            readonly
                            :allow-new="false"
                            :closable="false"
                            :allow-duplicates="false"
                        />
                    </b-field>
                    <b-field label="Name">
                        <b-input :value="stmsName" readonly />
                    </b-field>
                    <b-field label="Phone">
                        <b-taginput
                            :value="stmsPhones"
                            ellipsis
                            readonly
                            :allow-new="false"
                            :closable="false"
                            :allow-duplicates="false"
                        />
                    </b-field>
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Email">
                        <b-input :value="additionalEmails[0]" readonly />
                    </b-field>
                    <b-field label="Name">
                        <b-input value="Barry Bignell" readonly />
                    </b-field>
                    <b-field label="Phone">
                        <b-input :value="additionalPhones[0]" readonly />
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Email">
                        <b-input :value="additionalEmails[1]" readonly />
                    </b-field>
                    <b-field label="Name">
                        <b-input value="Simon Straessle" readonly />
                    </b-field>
                    <b-field label="Phone">
                        <b-input :value="additionalPhones[1]" readonly />
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field
                        class="alert-popup__message"
                        expanded
                        :type="$v.message.$error ? 'is-danger' : ''"
                        :message="$v.message.$error ? validationMessages.message : ''"
                    >
                        <template #label>
                            Message Body
                            <button
                                v-if="!isLoading && message !== getDefaultMessage()"
                                type="button"
                                class="alert-popup__message-reset"
                                @click="setDefaultMessage()"
                            >
                                (Reset message)
                            </button>
                        </template>
                        <b-input v-model="message" type="textarea" expanded :maxlength="messageCharacterLimit" />
                    </b-field>
                </div>
            </div>
        </template>
        <template #footer>
            <b-button class="button be-button is-wider is-primary" :loading="isLoading" @click.prevent="send()">
                Send
            </b-button>
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { maxLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ManagePopup from '@/components/local/sites/ManagePopup';
import noticesMixin from '@/mixins/noticesMixin';
import observationNotificationMixin from '@/mixins/hs/observationNotificationMixin';
import { formatPhone } from '@/utils/phones';

export default {
    mixins: [noticesMixin, observationNotificationMixin, validationMixin],

    components: {
        ManagePopup,
    },

    validations() {
        return {
            message: {
                required,
                maxLength: maxLength(this.messageCharacterLimit),
            },
        };
    },

    computed: {
        safetyEmails() {
            return get(this.site, 'safety_manager.email', []);
        },
        safetyName() {
            return get(this.site, 'safety_manager.name', '');
        },
        safetyPhones() {
            return get(this.site, 'safety_manager.phone', []);
        },
        stmsEmails() {
            return get(this.site, 'stms.email', []);
        },
        stmsName() {
            return get(this.site, 'stms.name', '');
        },
        stmsPhones() {
            return get(this.site, 'stms.phone', []);
        },
        additionalEmails() {
            return ['eleanor+1@rush.co.nz', 'ruochen@rush.co.nz'];
            // return ['Barry.Bignell@downer.co.nz', 'Simon.Straessle@downer.co.nz'];
        },
        additionalPhones() {
            return ['+64273092123', '+64273092123'];
            // return ['+64272442531', '+6421957571'];
        },

    },

    watch: {
        active: {
            handler(val) {
                if (val) {
                    this.loadCamera()
                        .then(() => this.loadSite())
                        .then(() => this.setDefaultMessage());
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('hs/observations/explorer/detections', ['detectionSendNotification']),

        send() {
            if (this.isLoading) {
                return;
            }

            const phones = this.safetyPhones.concat(this.stmsPhones).concat(this.additionalPhones).map(formatPhone);
            const emails = this.safetyEmails.concat(this.stmsEmails).concat(this.additionalEmails);

            const payload = {
                id: this.detection.id,
                type: 'alert',
                data: {
                    phones,
                    emails,
                    message: this.message,
                },
            };

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            this.detectionSendNotification(payload)
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Alert successfully sent',
                    });
                    this.$emit('close');
                })
                .catch((err) => {
                    this.displayErrorNotice({
                        message: err.message || 'Cannot send alert. Please reload the page and try again',
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.alert-popup {

    &__message {
        position: relative;

        ::v-deep textarea {
            resize: none;
        }

        ::v-deep .counter {
            color: $grey-lighter;

            &.is-invisible {
                visibility: visible !important;
            }
        }

        &-reset {
            @include button-reset;
            text-decoration: underline;
            font-size: .75em;
            color: $grey-lighter;
            display: inline-flex;
            margin-left: 5px;
        }
    }
}
</style>
