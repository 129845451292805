<template>
    <be-widget :is-loading="isLoading">
        <template v-slot:heading>
            Detections over time for {{ plate }}
        </template>
        <template v-slot:content>
            <div class="chart">
                <div class="chart__controls">
                    <b-checkbox
                        class="be-checkbox"
                        v-model="isShowForecast"
                    >
                        Show forecast
                    </b-checkbox>
                </div>
                <scatter-chart
                    class="chart__wrapper"
                    :chart-data="chartData"
                    :options="chartOptions"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { DATE_FORMAT } from '@/utils/formatters/datetime';
import ScatterChart from '@/utils/chartjs/ScatterChartStyle';
import { CHART_COLORS } from '@/utils/constants';
import { getDatasetColorByIndex } from '@/utils/charts';
import { FORECAST_DAYS } from '@/store/explorer/plates/sitegraph';

const getChartDataDefaults = () => ({
    labels: [],
    datasets: [
        {
            label: '',
            backgroundColor: CHART_COLORS.purple,
            data: [],
        },
    ],
});

const getChartOptionsDefaults = () => ({
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'top',
        labels: {
            boxWidth: 10,
            padding: 10,
            usePointStyle: true,
        },
    },
    elements: {
        point: {
            pointStyle: 'rect',
        },
    },
});

const FAKE_YEAR = '1970-02-01';
const PROBABILITY_THRESHOLD = 0.25;

export default {
    components: {
        ScatterChart,
    },

    data() {
        return {
            isShowForecast: true,
            chartData: getChartDataDefaults(),
            chartOptions: getChartOptionsDefaults(),
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange', 'getFilterHash']),
        ...mapGetters('explorer/plates/sitegraph', ['isLoading', 'apiResults']),
        ...mapState('explorer/plates/sitegraph', ['plate']),

        displayDates() {
            const startDate = this.$date(this.getDateRange.start);
            let endDate = this.$date(this.getDateRange.end);

            if (this.isShowForecast) {
                endDate = this.$date().add(FORECAST_DAYS, 'day');
            }

            const dateArray = [startDate.format(DATE_FORMAT)];
            const diff = Math.floor(endDate.diff(startDate, 'day', true));
            for (let i = 0; i < diff; i++) {
                dateArray.push(startDate.add(i + 1, 'day').format(DATE_FORMAT));
            }
            // fix only one day bug
            if (dateArray.length === 1) {
                dateArray.unshift(startDate.add(-1, 'day').format(DATE_FORMAT));
                dateArray.push(startDate.add(1, 'day').format(DATE_FORMAT));
            }

            return dateArray;
        },

        apiResultsBySite() {
            const dataObj = {};
            if (!this.apiResults || !this.apiResults.actualData) {
                return dataObj;
            }

            const transformRow = (row) => {
                const date = this.$date(row.timestamp);
                const dayStart = date.startOf('day');
                const dateLabel = date.format(DATE_FORMAT);
                const secDiff = date.diff(dayStart, 'second');

                return {
                    ...row,
                    dateLabel,
                    y: this.$date(`${FAKE_YEAR} 00:00:00`).add(secDiff, 'second').valueOf(),
                };
            };

            this.apiResults.actualData.forEach((row) => {
                if (!dataObj[row.site_id]) {
                    dataObj[row.site_id] = {
                        site: row.site_name,
                        data: {},
                    };
                }

                const transformedRow = transformRow(row);
                const { dateLabel } = transformedRow;

                if (!dataObj[row.site_id].data[dateLabel]) {
                    dataObj[row.site_id].data[dateLabel] = [];
                }
                dataObj[row.site_id].data[dateLabel].push(transformedRow);
            });

            if (!this.apiResults.forecastData || !this.isShowForecast) {
                return dataObj;
            }

            this.apiResults.forecastData.forEach((row) => {
                if (!dataObj[row.site_id]) {
                    // we skip forecasts for sites that have no current data
                    return;
                }
                row.prediction
                    .filter((p) => p.probability >= PROBABILITY_THRESHOLD)
                    .forEach((p) => {
                        const transformedRow = transformRow(p);
                        const { dateLabel } = transformedRow;

                        if (!dataObj[row.site_id].data[dateLabel]) {
                            dataObj[row.site_id].data[dateLabel] = [];
                        }
                        dataObj[row.site_id].data[dateLabel].push({ ...transformedRow, isForecast: true });
                    });
            });

            return dataObj;
        },
    },

    watch: {
        apiResultsBySite: {
            handler() {
                this.updateChartData();
                this.$nextTick(() => {
                    if (this.$el && this.$el.scrollIntoView) {
                        this.$el.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                });
            },
            immediate: true,
            deep: true,
        },

        getFilterHash() {
            this.loadData({});
        },

        isShowForecast() {
            this.updateChartData();
        },
    },

    methods: {
        ...mapActions('explorer/plates/sitegraph', ['loadData']),

        getYAxisTicks() {
            const sod = this.$date(`${FAKE_YEAR} 00:00:00`);
            const eod = sod.endOf('day');

            const data = {
                min: sod.valueOf(),
                max: eod.valueOf(),
                stepSize: 10.8e+6,
                beginAtZero: false,
                callback: (value) => {
                    const date = this.$date(value);
                    if (date.diff(eod, 'minute') === 0) {
                        return null;
                    }
                    return date.format('h A');
                },
            };

            return data;
        },

        getXAxisTicks() {
            const dateIndexes = [0];
            if (this.displayDates.length > 1) {
                dateIndexes.push(this.displayDates.length - 1);
            }
            if (this.displayDates.length > 5) {
                const middle = Math.round((this.displayDates.length - 1) / 2);
                dateIndexes.push(middle);
            }

            const data = {
                min: 1,
                max: this.displayDates.length,
                beginAtZero: false,
                stepSize: 1,
                callback: (value, index) => {
                    const date = this.$date(this.displayDates[index]);
                    const dow = date.format('dd');
                    if (dateIndexes.includes(index)) {
                        return [dow, date.format('Do MMM')];
                    }
                    return dow;
                },
            };

            return data;
        },

        canPopulateChart() {
            return this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            this.chartOptions = this.getChartOptions();

            // updating chartData in two steps to mitigate the problem with deep updates
            this.chartData = {
                labels: [],
                datasets: [],
            };
            this.$nextTick(() => {
                this.chartData = {
                    labels: this.displayDates,
                    datasets: this.getDatasets(),
                };
            });
        },

        getDatasets() {
            return Object
                .keys(this.apiResultsBySite)
                .map((siteId, index) => this.getDatasetData({ index, siteData: this.apiResultsBySite[siteId] }));
        },

        getDatasetData({ index, siteData }) {
            const data = [];
            this.displayDates.forEach((date, index) => {
                if (siteData.data[date]) {
                    siteData.data[date].forEach((d) => {
                        data.push({
                            y: d.y,
                            x: (index + 1),
                            rotation: d.orientation?.vehicle_orientation === 'FRONT' ? 180 : 0,
                            pointStyle: d.isForecast ? 'circle' : 'triangle',
                            opacity: d.isForecast ? 0.3 : 1,
                        });
                    });
                }
            });

            const color = getDatasetColorByIndex({ index });

            return {
                label: siteData.site,
                borderColor: color,
                backgroundColor: color,
                pointBackgroundColor: color,
                pointRadius: 8,
                pointHoverRadius: 10,
                data,
            };
        },

        resetChartData() {
            this.chartData = getChartDataDefaults();
            this.chartOptions = getChartOptionsDefaults();
        },

        getChartOptions() {
            return {
                ...getChartOptionsDefaults(),
                scales: {
                    xAxes: [{
                        type: 'linear',
                        position: 'bottom',
                        ticks: this.getXAxisTicks(),
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            borderDash: [5, 5],
                            drawOnChartArea: true,
                        },
                        type: 'linear',
                        position: 'left',
                        ticks: this.getYAxisTicks(),
                    }],
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: (label) => {
                            const xLabel = this.$date(this.displayDates[label.xLabel - 1]).format('Do MMM');
                            const yLabel = this.$date(label.yLabel).format('h:mm A');

                            return `${yLabel} ${xLabel}`;
                        },
                    },
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    &__wrapper {
        position: relative;
        height: 400px;
        width: 100%;
    }
}
</style>
