import mostread from './mostread';
import sitegraph from './sitegraph';

const state = {};

const getters = {};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,

    modules: {
        mostread,
        sitegraph,
    },
};
