<template>
    <be-widget class="chart" :is-loading="isLoading">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:headingAfter>
            <images-detections-toggler v-model="currentView" :values="togglerValues" />
        </template>
        <template v-slot:content>
            <div class="chart">
                <bar-chart
                    v-if="isHistogramView"
                    class="chart__wrapper"
                    :chart-data="barChartData"
                    :options="barChartOptions"
                />
                <line-chart
                    v-if="!isHistogramView"
                    class="chart__wrapper"
                    :chart-data="lineChartData"
                    :options="lineChartOptions"
                />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { chain, meanBy } from 'lodash';
import dayjs from 'dayjs';
import BarChart from '@/utils/chartjs/BarChart';
import LineChart from '@/utils/chartjs/LineChart';
import ImagesDetectionsToggler from '@/components/widgets/filters/ImagesDetectionsToggler';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import { COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({ labels: [], datasets: [] });

export default {
    components: {
        ImagesDetectionsToggler,
        BarChart,
        LineChart,
    },

    mixins: [widgetDataMixin],

    data() {
        return {
            togglerValues: [
                { handle: 'histogram', label: 'Histogram' },
                { handle: 'timeline', label: 'By time' },
            ],
            currentView: 'histogram',
            barChartData: getChartDataDefaults(),
            lineChartData: getChartDataDefaults(),
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Speed distribution',
                        },
                        ticks: {
                            callback: (value) => `${value} km/h`,
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            stepSize: 1,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Number of events',
                        },
                    }],
                },
            },
            lineChartOptions: {
                showLines: true,
                spanGaps: true,
                responsive: true,
                maintainAspectRatio: false,
                legend: false,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            tooltipFormat: 'YYYY-MM-DD hA',
                            stepSize: 1,
                            unit: 'day',
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        bounds: 'ticks',
                        scaleLabel: {
                            display: true,
                            labelString: 'Date',
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            stepSize: 1,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Speed',
                        },
                    }],
                },
            },
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('explorer/speed', ['isLoading', 'apiResults']),

        widgetTitle() {
            return this.currentView === 'histogram'
                ? 'Speed distribution'
                : 'Speed readings over time';
        },

        isHistogramView() {
            return this.currentView === 'histogram';
        },

        getLineChartXAxisTicks() {
            return {
                min: this.getDateRange.start,
                max: this.getDateRange.end,
            };
        },
    },

    watch: {
        apiResults: {
            handler: 'updateChartData',
            immediate: true,
            deep: true,
        },
    },

    methods: {
        ...mapActions('explorer/speed', ['loadData']),

        canPopulateChart() {
            return this.apiResults && this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        getHistogramData() {
            if (!this.apiResults) {
                return {};
            }
            const bucketStep = 5;
            const maxSupportedSpeed = 100;
            const buckets = {};

            for (let i = 0; i <= maxSupportedSpeed; i += bucketStep) {
                buckets[i] = 0;
            }

            this.apiResults.forEach((row) => {
                const trimmedSpeed = Math.max(Math.min(row.speed, maxSupportedSpeed), 0);
                const bucketLabel = Math.trunc(trimmedSpeed / bucketStep) * bucketStep;
                buckets[bucketLabel] += 1;
            });

            return {
                labels: Object.keys(buckets),
                datasets: [{
                    label: '# of events',
                    data: Object.values(buckets),
                    backgroundColor: COLORS.blue,
                    borderWidth: 0,
                }],
            };
        },

        getOverTimeData() {
            const averagesByHour = chain(this.apiResults)
                .groupBy((row) => dayjs(row.camera_timestamp).startOf('hour').format('YYYY-MM-DD HH:mm'))
                .map((entries, day) => [day, Math.round(meanBy(entries, (entry) => entry.speed))])
                .fromPairs()
                .value();

            const data = Object.keys(averagesByHour)
                .map((key) => ({
                    x: key,
                    y: averagesByHour[key],
                }));

            return {
                datasets: [{
                    label: 'Average speed',
                    data,
                    pointBackgroundColor: COLORS.white,
                    borderColor: COLORS.blue,
                    borderWidth: 1,
                    lineTension: 0,
                    fill: false,
                    borderDash: [3, 6],
                }],
            };
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }
            this.lineChartOptions.scales.xAxes[0].ticks = this.getLineChartXAxisTicks;

            // to overcome the problem of non-reactive deep updates
            this.barChartData = getChartDataDefaults();
            this.lineChartData = getChartDataDefaults();
            this.$nextTick(() => {
                this.lineChartData = this.getOverTimeData();
                this.barChartData = this.getHistogramData();
            });
        },

        resetChartData() {
            this.lineChartData = getChartDataDefaults();
            this.barChartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
        margin-top: auto;
    }
}
</style>
