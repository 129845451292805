<template>
    <div class="field has-addons">
        <div
            class="control"
            v-for="(val, index) in values"
            :key="`control${index}`"
        >
            <b-button
                :active="false"
                :focused="false"
                class="be-button is-toggle"
                :class="[
                    computedValue === val.handle ? 'is-toggle-active' : 'is-toggle-inactive',
                    {
                        'is-small': isSmall,
                        [`is-${val.buttonType}`]: val.buttonType,
                    },
                ]"
                @click="toggle(val.handle)"
            >
                {{ val.label }}
            </b-button>
        </div>
    </div>
</template>

<script>
import modelMixin from '@/mixins/modelMixin';

export default {
    mixins: [modelMixin],

    props: {
        values: {
            type: Array,
            default: () => [
                {
                    handle: 'images',
                    label: 'Images received',
                },
                {
                    handle: 'detections',
                    label: 'Unique events',
                    buttonType: 'yellow',
                },
            ],
        },
        value: {
            type: String,
            required: true,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        toggle(value) {
            this.computedValue = value;
        },
    },
};
</script>
