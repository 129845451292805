import axios from 'axios';
import { STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';

const state = {
    status: '',
    data: null,
};

const getters = {
    isLoading: (state) => (state.status === STATUS_LOADING),
    isSuccess: (state) => (state.status === STATUS_SUCCESS),
    apiResults(state) {
        return (state.data || [])
            .filter((row) => row.date && row.speed);
    },
};

const mutations = {
    setData(state, data) {
        state.data = data;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
};
const actions = {
    loadData({ commit }) {
        commit('setStatus', { status: STATUS_LOADING });
        return axios('/hs/speed.json')
            .then((response) => {
                commit('setData', response.data);
                commit('setStatus', { status: STATUS_SUCCESS });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {},
};
