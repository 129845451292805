<template>
    <canvas
        class="media-image-canvas"
        :width="width * dpr"
        :height="height * dpr"
        :style="{
            opacity: isVisible ? 1 : 0,
        }"
    />
</template>

<script>
import { capitalize, get } from 'lodash';
import { convertToCanvasRect, drawBoundingBox } from '@/utils/canvas';
import {
    getMauiObjectColor,
    getMauiObjectLabel,
} from '@/utils/formatters/maui63/labels';
import { COLORS } from '@/utils/constants';
import { getConfidenceType } from '@/utils/confidence';

export default {
    props: {
        detections: {
            type: Object,
            required: true,
        },
        width: {
            type: Number,
            default: 1,
        },
        height: {
            type: Number,
            default: 1,
        },
        isVisible: {
            type: Boolean,
            default: true,
        },
        imageType: {
            type: String,
            default: 'vehicle',
        },
        scale: {
            type: Number,
            default: 1,
        },
        translate: {
            type: Array,
            default: () => [0, 0],
        },
    },

    provide() {
        return {
            provider: this.provider,
        };
    },

    mounted() {
        this.provider.context = this.$el.getContext('2d');
    },

    data() {
        return {
            dpr: 1, // window.devicePixelRatio || 1,
            provider: {
                context: null,
            },
        };
    },

    computed: {
        results() {
            return get(this.detections, 'results', []);
        },

        resultsGeneric() {
            return get(this.detections, 'results_generic', []);
        },

        widthHeight() {
            return `${this.width}x${this.height}`;
        },

        rescale() {
            return get(this.detections, 'img_width', 0) / this.width;
        },

        getSelectedObjectIds() {
            return this.$store.state.canvas.selectedObjectIds;
        },
    },

    watch: {
        detections: {
            handler() {
                this.drawNextTick();
            },
            deep: true,
            immediate: true,
        },
        widthHeight: {
            handler() {
                this.drawNextTick();
            },
            immediate: true,
        },
        getSelectedObjectIds: {
            handler(val) {
                this.highlightObjects(val);
            },
        },
    },

    methods: {
        drawNextTick() {
            this.$nextTick(() => this.draw());
        },

        draw() {
            if (!this.provider.context) {
                return;
            }
            const ctx = this.provider.context;

            // re-scale
            ctx.scale(this.dpr, this.dpr);
            // clear
            ctx.clearRect(0, 0, this.$el.width, this.$el.height);

            this.drawGeneric();
            switch (this.imageType) {
                case 'vehicle':
                    this.drawVehicles();
                    return;
                default:
                    this.drawDolphins();
            }
        },

        drawVehicles() {
            const ctx = this.provider.context;

            ctx.beginPath();

            this.drawPeople(ctx);
            this.drawBicycles(ctx);

            this.results.forEach((result) => {
                let confidenceColor = COLORS.green;
                let confidenceDash = [];
                const confidenceType = getConfidenceType({
                    confidence: result.confidence,
                });
                switch (confidenceType) {
                    case 'is-confidence-high':
                        // use var defaults
                        break;
                    case 'is-confidence-medium':
                        confidenceColor = COLORS.yellow;
                        confidenceDash = [5, 5];
                        break;
                    case 'is-confidence-low':
                    default:
                        confidenceColor = COLORS.red;
                        confidenceDash = [5, 5];
                        break;
                }
                this.drawBoundingBox(
                    `Vehicle (${result.type})`,
                    3,
                    confidenceColor,
                    result.vehicle_bb,
                    confidenceDash,
                );
                this.drawPlate(result, confidenceColor);
            });

            ctx.stroke();
        },

        drawDolphins() {
            const ctx = this.provider.context;
            ctx.beginPath();

            this.results.forEach((result) => {
                this.drawBoundingBox(
                    getMauiObjectLabel({ type: result.type }),
                    3,
                    getMauiObjectColor({ type: result.type }),
                    result.bb,
                );
            });
            ctx.stroke();
        },

        drawGeneric() {
            const ctx = this.provider.context;
            ctx.beginPath();

            this.resultsGeneric.forEach((result) => {
                this.drawBoundingBox(
                    capitalize(result.object_class || 'unknown'),
                    2,
                    COLORS.green,
                    result.bb,
                    [5, 5],
                );
                if (result.tags && result.tags.length) {
                    result.tags.forEach((tag) => {
                        this.drawBoundingBox(
                            tag.name,
                            3,
                            COLORS.yellow,
                            tag.bb,
                        );
                    });
                }
            });
            ctx.stroke();
        },

        drawBoundingBox(
            typeLabel,
            lineWidth,
            strokeStyle,
            resultBoundingBox,
            strokeLineDash = [],
        ) {
            drawBoundingBox({
                ctx: this.provider.context,
                typeLabel,
                lineWidth,
                strokeStyle,
                resultBoundingBox,
                scale: this.scale,
                strokeLineDash,
            });
        },

        drawPeople() {
            const people = get(this.detections, 'results_other.people', []);
            people.forEach((result) => {
                this.drawBoundingBox(
                    'Person',
                    3,
                    COLORS.purple,
                    result.person_bb,
                );
            });
        },

        drawBicycles() {
            const bicycles = get(this.detections, 'results_other.bicycles', []);
            bicycles.forEach((result) => {
                this.drawBoundingBox(
                    'Bicycle',
                    3,
                    COLORS.blue,
                    result.bicycle_bb,
                );
            });
        },

        drawPlate(result, confidenceColor) {
            const ctx = this.provider.context;

            const plate = get(result, 'plate_bb', null);

            ctx.lineWidth = 1;
            ctx.fillStyle = COLORS.black;
            ctx.strokeStyle = confidenceColor;

            const plateBb = convertToCanvasRect({
                str: [plate.l, plate.t, plate.r, plate.b],
                scale: this.scale,
            });
            ctx.strokeRect(plateBb[0], plateBb[1], plateBb[2], plateBb[3]);

            // render plate labels
            ctx.fillStyle = 'RGBA(255, 255, 255, 0.8)';
            ctx.fillRect(
                plateBb[0],
                plateBb[1] - 16,
                ctx.measureText(result.plate).width + 8,
                16,
            );

            ctx.font = 'normal 12px replica';
            ctx.fillStyle = COLORS.black;
            ctx.fillText(result.plate, plateBb[0] + 4, plateBb[1] - 4);
        },

        highlightObjects(objects) {
            objects.forEach((object) => {
                this.results.forEach((result) => {
                    if (result.id === object.id) {
                        switch (this.imageType) {
                            case 'vehicle':
                                this.drawBoundingBox(
                                    'Vehicle',
                                    3,
                                    COLORS.highlight,
                                    result.vehicle_bb,
                                );
                                break;

                            default:
                                break;
                        }
                    }
                });
            });
            if (objects.length === 0) {
                this.drawNextTick();
            }
        },
    },
};
</script>
