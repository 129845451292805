<template>
    <be-widget class="latest-detections" :is-loading="isLoading" :has-table="true">
        <template v-slot:heading>
            Latest {{ amount }} detections
        </template>
        <template v-slot:content>
            <b-table
                :data="formattedApiResults"
                :hoverable="true"
                :selected="lastSelectedRow"
            >
                <b-table-column label="Date" width="25%" v-slot="props">
                    {{ props.row.datestamp | dateHuman }}
                </b-table-column>
                <b-table-column label="Object Type" width="25%" v-slot="props">
                    <b-tag
                        class="has-background-info has-text-black-bis has-text-weight-bold"
                    >
                        {{ getObjectType(props.row) }}
                    </b-tag>
                </b-table-column>
                <b-table-column label="Confidence" width="25%" v-slot="props">
                    <be-confidence :confidence="props.row.confidence" />
                </b-table-column>
                <b-table-column label="Image" width="5%" header-class="has-text-right" v-slot="props">
                    <p class="has-text-right has-text-weight-bold">
                        <a href="#" @click.prevent="openPopup(props.row, props.index)">View</a>
                    </p>
                </b-table-column>
            </b-table>
            <media-image-popup
                :active="showMauiImagePopup"
                :image-with-detail="mauiImageWithDetail"
                image-type="dolphin"
                :display-arrows="formattedApiResults && formattedApiResults.length > 1"
                :loading="isLoadingFull"
                @prev="goto('prev')"
                @next="goto('next')"
                @close="closePopup"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import hasMauiImagePopupMixin from '@/mixins/hasMauiImagePopupMixin';
import formattersMixin from '@/mixins/formattersMixin';
import BeConfidence from '@/components/global/BeConfidence';
import { getMauiObjectLabel, getMauiSingleObjectClass } from '@/utils/formatters/maui63/labels';

export default {
    props: {
        amount: {
            type: Number,
            default: 10,
        },
    },

    components: {
        BeConfidence,
    },

    mixins: [hasMauiImagePopupMixin, hasPopupMixin, widgetDataMixin, formattersMixin],

    computed: {
        ...mapGetters('maui/detections', [
            'isLoading',
            'formattedApiResults',
        ]),
    },

    methods: {
        ...mapActions({ loadDetectionsData: 'maui/detections/loadData' }),

        loadData() {
            return this.loadDetectionsData({
                page: 1,
                perPage: this.amount,
            });
        },

        getObjectType(row) {
            return getMauiObjectLabel({
                objectClass: getMauiSingleObjectClass({ row }),
            });
        },
    },
};
</script>
