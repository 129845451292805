import { isString } from 'lodash';

export function formatNumber({ number, locale = 'en-NZ' }) {
    return new Intl.NumberFormat(locale).format(number);
}

export function formatCurrency({ number, locale = 'en-NZ', currency = 'NZD' }) {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(number);
}

export function formatPercentage({ number, decimal = false }) {
    if (isString(number) && number.includes('%')) {
        return number;
    }

    const num = Number(number);
    const percentage = decimal
        ? Math.round((num + Number.EPSILON) * 100) / 100
        : Math.round(num);

    return `${percentage}%`;
}
