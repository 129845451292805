import { mapActions, mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';

export default function integrationOAuthMixin(appId) {
    const mixin = {
        validations() {
            const integration = {};

            if (this.integration.enabled) {
                integration.clientId = { required };
                integration.username = { required };
            }

            return {
                integration,
            };
        },

        data() {
            const data = {
                isTestingConnection: false,
                integration: {
                    enabled: false,
                    username: '',
                    clientId: '',
                },
                validationMessages: {
                    username: 'Username is required',
                    clientId: 'Please provide OAuth Client Id',
                },
            };

            return data;
        },

        computed: {
            ...mapState('marketplace/user', ['allAppSettings']),
            ...mapGetters('marketplace/user', ['isSaving']),
        },

        created() {
            if (this.allAppSettings[appId]) {
                this.integration = {
                    ...this.allAppSettings[appId],
                };
            }
        },

        methods: {
            ...mapActions('marketplace/user', ['toggleApp', 'updateAppSettings']),

            async save() {
                if (this.isUpdating) {
                    return;
                }

                if (this.$v) {
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }
                }

                this.closeNotices();

                await this.updateAppSettings({ appId, data: this.integration });

                this.displaySuccessNotice({
                    message: 'Integration updated successfully',
                });
            },

            downloadCertificate() {
                axios({
                    url: getApiUrl({ path: 'integrations/oauth2/download_cert' }),
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'rush-cert.pem');
                    document.body.appendChild(link);
                    link.click();
                });
            },

            sendTestRequest() {
                this.isTestingConnection = true;
                axios
                    .get(getApiUrl({ path: `integrations/oauth2/test_connection/${appId}` }))
                    .then((response) => {
                        if (response.data.is_success) {
                            this.displaySuccessNotice({
                                message: 'Integration is working',
                            });
                        } else {
                            this.displayErrorNotice({
                                message: 'Integration is not working',
                            });
                        }
                    }).catch((err) => {
                        errorHandler(err);
                    }).finally(() => {
                        this.isTestingConnection = false;
                    });
            },

            fieldType(field) {
                if (this.$v.integration[field] !== undefined) {
                    return this.$v.integration[field].$error ? 'is-danger' : '';
                }
                return '';
            },

            fieldMessage(field) {
                if (this.$v.integration[field] !== undefined) {
                    return this.$v.integration[field].$error && this.validationMessages[field]
                        ? this.validationMessages[field]
                        : '';
                }
                return '';
            },
        },
    };
    return mixin;
}
