<template>
    <manage-popup v-if="active" class="manage-popup" :active="active" @close="maybeClose" :is-loading="isDataLoading || isRemoving">
        <template v-slot:title>
            {{ isEdit ? 'Edit Camera' : 'Add Camera' }}
        </template>
        <template v-slot:body>
            <be-request-error-message v-if="requestErrorMessage" @close="requestErrorMessage = null">
                {{ requestErrorMessage }}
            </be-request-error-message>

            <manage-camera-popup-step1
                ref="step1"
                v-show="step === 1"
                v-model="cameraModel"
                :site="site"
                :camera-id="isEdit ? camera.id : ''"
                :endpoint="endpoint"
                :frequency-list="frequencyList"
                :tags="cameraTags"
                :is-tags-loading="isTagsLoading"
                @update-tags="cameraTags = $event"
            />
            <manage-camera-popup-step2
                ref="step2"
                v-show="step === 2"
                v-model="cameraModel"
            />
        </template>
        <template v-slot:footer>
            <button
                v-if="isEdit"
                type="button"
                class="be-button-link is-danger"
                @click.prevent="remove"
                :disabled="isUpdating"
                :loading="isRemoving"
            >
                Remove Camera
            </button>
            <div class="footer-buttons">
                <b-button
                    v-if="step === 1"
                    class="be-button is-wider is-primary"
                    :disabled="isUpdating"
                    :loading="isRemoving"
                    @click.prevent="forward()"
                >
                    Continue
                </b-button>
                <template v-if="step === 2">
                    <b-button
                        class="be-button is-wider is-secondary"
                        :disabled="isUpdating"
                        :loading="isRemoving"
                        @click.prevent="back()"
                    >
                        Back
                    </b-button>
                    <b-button
                        class="be-button is-wider"
                        type="is-primary"
                        :disabled="isRemoving"
                        :loading="isUpdating"
                        @click.prevent="save"
                    >
                        {{ isEdit ? 'Save Camera' : 'Add Camera' }}
                    </b-button>
                </template>
            </div>
        </template>
    </manage-popup>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions, mapState } from 'vuex';
import BeRequestErrorMessage from '@/components/global/BeRequestErrorMessage';
import noticesMixin from '@/mixins/noticesMixin';
import { getCameraSaveData, getDefaultCameraTagsState } from '@/utils/sites/cameras';
import manageCameraMixin from '@/mixins/manageCameraMixin';
import ManagePopup from './ManagePopup';
import ManageCameraPopupStep1 from './partials/ManageCameraPopupStep1';
import ManageCameraPopupStep2 from './partials/ManageCameraPopupStep2';

export default {
    components: {
        ManageCameraPopupStep1,
        ManageCameraPopupStep2,
        BeRequestErrorMessage,
        ManagePopup,
    },

    mixins: [
        validationMixin,
        noticesMixin,
        manageCameraMixin,
    ],

    data() {
        return {
            isTagsLoading: false,
            isRemoving: false,
            isDataLoading: false,
            cameraTags: getDefaultCameraTagsState(this.camera),
            closeConfirmationMessage: 'Are you sure you want to exit camera creation without saving?',
        };
    },

    computed: {
        ...mapState('sites', ['frequencyList', 'camerasEndpoints']),

        isLoading() {
            return this.isUpdating || this.isRemoving;
        },

        endpoint() {
            return this.isEdit
                ? this.camerasEndpoints[this.camera.id]
                : null;
        },
    },

    mounted() {
        this.step = 1;
        const promises = [
            this.loadFrequencyList(),
        ];

        if (this.isEdit) {
            promises.push(
                this.loadCameraEndpoint({ cameraId: this.camera.id }),
            );
        }

        this.isDataLoading = true;
        Promise.all(promises).finally(() => {
            this.isDataLoading = false;
        });
    },

    methods: {
        ...mapActions({
            createSiteCamera: 'sites/createSiteCamera',
            updateSiteCamera: 'sites/updateSiteCamera',
            removeSiteCamera: 'sites/removeSiteCamera',
            updateCameraTags: 'sites/updateCameraTags',
            loadFrequencyList: 'sites/loadFrequencyList',
            loadCameraEndpoint: 'sites/loadCameraEndpoint',
        }),

        validateStep(step) {
            const ref = this.$refs[`step${step}`];
            if (ref) {
                ref.$v.$touch();
                if (ref.$v.$error) {
                    return false;
                }
            }
            return true;
        },

        forward(validate = true) {
            if (validate && !this.validateStep(this.step)) {
                return;
            }
            this.step += 1;
        },

        back() {
            this.step -= 1;
        },

        save() {
            if (this.isLoading) {
                return;
            }

            if (!this.validateStep(2)) {
                return;
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isUpdating = true;
            this[this.isNew ? 'create' : 'update']()
                .then(() => {
                    this.isUpdating = false;
                    this.displaySuccessNotice({
                        message: this.isNew
                            ? 'Site camera added successfully'
                            : 'Site camera updated successfully',
                    });
                    this.close();
                })
                .catch((error) => {
                    this.requestErrorMessage = error.message;
                    this.isUpdating = false;
                });
        },

        update() {
            return this.updateSiteCamera({
                cameraId: this.camera.id,
                siteId: this.site.id,
                data: getCameraSaveData(this.cameraModel),
            })
                .then(() => this.updateTags({}));
        },

        create() {
            return this.createSiteCamera({
                siteId: this.site.id,
                data: getCameraSaveData(this.cameraModel),
            })
                .then(({ camera }) => this.updateTags({ camera }));
        },

        updateTags({ camera = null }) {
            let tags = [...this.cameraTags];

            if (camera && camera.tags && camera.tags.length) {
                tags = tags.concat(camera.tags);
            }
            return this.updateCameraTags({
                siteId: this.site.id,
                cameraId: camera ? camera.id : this.camera.id,
                tags,
            });
        },

        remove() {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this camera?',
                type: 'is-danger',
                onConfirm: () => {
                    this.isRemoving = true;
                    this.removeSiteCamera({
                        siteId: this.site.id,
                        cameraId: this.camera.id,
                    })
                        .then(() => {
                            this.displaySuccessNotice({
                                message: 'Site camera removed successfully',
                            });
                            this.close();
                        })
                        .catch(() => {
                            this.isRemoving = false;
                        });
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-popup {
    ::v-deep .modal-card-foot {
        justify-content: space-between;
    }

    .footer-buttons {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
