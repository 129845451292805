import user from './user';

const getDefaultState = () => ({
    rvisionApps: [
        {
            slug: 'alerts',
            // priceLabel: 'Free',
            priceLabel: '',
            label: 'Alerts',
            icon: 'bell',
            url: '/dashboard/alerts',
            enabled: true, // enabled by deafult
            native: true,
        },
        {
            slug: 'webhooks',
            // priceLabel: 'Free',
            priceLabel: '',
            label: 'Webhooks',
            icon: 'webhook',
            url: '/dashboard/webhooks',
            enabled: true,
            native: true,
        },
        {
            slug: 'parking-management',
            // priceLabel: 'From $49.95/c/mo',
            // priceLabel: 'Free Trial',
            priceLabel: '',
            label: 'Vehicle Parking Management',
            icon: 'update',
            native: true,
        },
        {
            slug: 'stolen-vehicles',
            // priceLabel: '$2.99/c/mo',
            // priceLabel: 'Free Trial',
            priceLabel: '',
            label: 'Stolen Vehicles',
            icon: 'car-settings',
            native: true,
        },
        {
            slug: 'health-safety',
            // priceLabel: '$9.95/c/mo',
            priceLabel: '',
            label: 'Health & Safety',
            icon: 'safety-goggles',
            native: true,
        },
        {
            slug: 'salesforce',
            label: 'Salesforce',
            description: 'Modern CRM',
            logo: 'salesforce',
            // priceLabel: 'POA',
            priceLabel: '',
            bg: 'rgba(2, 161, 223, .1)',
        },
        {
            slug: 'azure-databricks',
            priceLabel: '',
            label: 'Azure Databricks',
            logo: 'azure-databricks',
            bg: 'rgba(255, 54, 33, .1)',
        },
        {
            slug: 'azure-log-analytics',
            priceLabel: '',
            label: 'Azure Log Analytics',
            logo: 'azure-log-analytics',
            bg: 'rgba(0, 121, 214, .1)',
        },
        {
            slug: 'inx',
            priceLabel: '',
            label: 'INX',
            logo: 'inx',
            bg: 'rgba(212, 17, 125, .1)',
        },
    ],

    availableApps: [

    ],
});

const state = getDefaultState();

const getters = {
    allApps(state) {
        return [
            ...state.rvisionApps,
            ...state.availableApps,
        ];
    },
};

const mutations = {};

const actions = {
    init({ dispatch }) {
        return Promise.all([
            dispatch('stolenPlates/load', null, { root: true }),
        ]);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        user,
    },
};
