<template>
    <div class="summary">
        <overview-filters />
        <div class="app-container">
            <be-page-header class="mb-3" :app-container="false">
                <template v-slot:default>
                    Overview
                </template>
                <template v-slot:subheading>
                    <be-detections-heading />
                </template>
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-12">
                    <system-totals :can-load-data="true" />
                </div>
                <div class="column summary__col is-6">
                    <detailed-usage-chart class="summary__col__widget" :can-load-data="true" />
                </div>
                <div class="column summary__col is-6">
                    <latest-detections class="summary__col__widget" :can-load-data="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import OverviewFilters from '@/components/global/OverviewFilters';
import BePageHeader from '@/components/global/BePageHeader';
import DetailedUsageChart from '@/components/widgets/maui63/DetailedUsageChart';
import SystemTotals from '@/components/widgets/maui63/SystemTotals';
import LatestDetections from '@/components/widgets/maui63/LatestDetections';

export default {
    components: {
        BeDetectionsHeading,
        LatestDetections,
        BePageHeader,
        DetailedUsageChart,
        OverviewFilters,
        SystemTotals,
    },
};
</script>

<style lang="scss" scoped>
    .summary {
        &__col {
            display: flex;
            &__widget {
                flex: 1;
            }
        }
    }
</style>
