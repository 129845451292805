<template>
    <b-field grouped>
        <b-field v-if="displayImagePreview">
            <b-checkbox
                class="be-checkbox"
                size="is-small"
                type="is-primary"
                v-model="imagesPreviewEnabled"
            >
                Display images preview
            </b-checkbox>
        </b-field>
        <b-field v-if="displayDates">
            <b-checkbox
                class="be-checkbox"
                size="is-small"
                type="is-primary"
                v-model="fullDatesEnabled"
            >
                Display full dates
            </b-checkbox>
        </b-field>
    </b-field>
</template>

<script>
import dataTableMixin from '@/mixins/dataTableMixin';

export default {
    mixins: [dataTableMixin],

    props: {
        displayDates: {
            type: Boolean,
            default: true,
        },
        displayImagePreview: {
            type: Boolean,
            default: true,
        },

    },
};
</script>
