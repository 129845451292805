export function getMauiObjectLabel({ objectClass, type }) {
    if (type) {
        return `${type}`.toUpperCase();
    }

    switch (Number(objectClass)) {
        case 0:
            return 'MAUI';
        case 1:
            return 'BOAT';
        default:
            return '';
    }
}

export function getMauiObjectColor({ objectClass, type }) {
    if (type) {
        switch (type) {
            case 'dolphin':
                return '#6100d0';
            default:
                return '#41ffd0';
        }
    }

    switch (Number(objectClass)) {
        case 0:
            return '#6100d0';
        default:
            return '#41ffd0';
    }
}

export function getMauiSingleObjectClass({ row }) {
    return row.image_data && row.image_data.length
        ? row.image_data[0].object_class
        : 0;
}
