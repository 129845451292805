import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default function integrationFakeSaveMixin(hasApiKey = false) {
    const mixin = {
        validations() {
            const integration = {};

            if (hasApiKey && this.integration.enabled) {
                integration.apiKey = { required };
            }

            return {
                integration,
            };
        },

        data() {
            const data = {
                isSaving: false,
                integration: {
                    enabled: false,
                },
                validationMessages: {
                    apiKey: 'Please provide a valid API key',
                },
            };

            if (hasApiKey) {
                data.integration.apiKey = '';
            }

            return data;
        },

        created() {
            this.integration.enabled = this.$store.state.marketplace.user.enabledApps.includes(this.app);
            if (hasApiKey && this.integration.enabled) {
                this.integration.apiKey = 'xxx-xxx-xxx';
            }
        },

        methods: {
            ...mapActions('marketplace/user', ['toggleApp']),

            save() {
                if (this.isSaving) {
                    return;
                }

                if (this.$v) {
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }
                }

                this.isSaving = true;
                this.closeNotices();

                setTimeout(() => {
                    this.isSaving = false;
                    this.toggleApp({
                        appId: this.app,
                        force: this.integration.enabled,
                    });
                    this.displaySuccessNotice({
                        message: 'Integration updated successfully',
                    });
                }, 1000);
            },

            fieldType(field) {
                return this.$v.integration[field].$error ? 'is-danger' : '';
            },

            fieldMessage(field) {
                return this.$v.integration[field].$error && this.validationMessages[field]
                    ? this.validationMessages[field]
                    : '';
            },
        },
    };

    return mixin;
}
