import { isArray, isEmpty, get } from 'lodash';
import { convertApiStringArr } from '@/utils/str';
import { transformImageDetectionsObject } from '@/utils/transformers/detections';

export function imageTransformerSingle(image) {
    return {
        ...image,
    };
}

export function imagesTransformerRow(row) {
    const { image } = row;

    const siteName = get(image, 'camera.site.name', '');
    const cameraName = get(image, 'camera.name', '');

    let plates = [];
    if (row.plate) {
        plates = row.plate;
    } else if (row.plates) {
        ({ plates } = row);
    }

    return {
        ...row,
        siteName,
        cameraName,
        detections: transformImageDetectionsObject({ image }),
        platesList: convertApiStringArr(plates),
        confidences: row.confidence ? convertApiStringArr(row.confidence) : [],
        video: get(image, 'video', null),
    };
}

export function imagesTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => imagesTransformerRow(item));
}
