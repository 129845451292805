import { get } from 'lodash';
import BeAddressAutocomplete from '@/components/global/BeAddressAutocomplete';
import BeTags from '@/components/global/BeTags';
import { getValidationMessage } from '@/utils/validations/validations';
import { getDefaultSiteState, getSiteValidationRules, SITE_VALIDATION_MESSAGES } from '@/utils/sites/sites';
import { SITE_TYPES } from '@/utils/sites/constants';

export default {
    components: {
        BeAddressAutocomplete,
        BeTags,
    },

    validations() {
        return {
            siteModel: getSiteValidationRules(this.siteType),
        };
    },

    props: {
        site: {
            type: Object,
            default: null,
        },
        siteType: {
            type: Number,
            default: SITE_TYPES.CARPARK,
        },
    },

    data() {
        return {
            isTagsLoading: false,
            siteModel: getDefaultSiteState(this.site, this.siteType),
            siteTags: this.site ? [...this.site.tags] : [],
            validationMessages: SITE_VALIDATION_MESSAGES,
            dropFile: null,
        };
    },

    computed: {
        isEdit() {
            return !!this.siteModel.id;
        },
        isNew() {
            return !this.isEdit;
        },
    },

    methods: {
        fieldHasError(field, parent = 'siteModel') {
            if (!this.$v) {
                return false;
            }

            const getter = [parent, field, '$error'].filter((g) => g);
            return get(this.$v, getter.join('.'), false);
        },

        getFieldType(field, parent = 'siteModel') {
            if (this.fieldHasError(field, parent)) {
                return 'is-danger';
            }

            return '';
        },

        getFieldMessage(field, parent = 'siteModel') {
            if (!this.fieldHasError(field, parent)) {
                return '';
            }

            return getValidationMessage(field, this.$v[parent], this.validationMessages);
        },

        addressSelected({ lat, lng }) {
            this.siteModel.lat = lat;
            this.siteModel.lng = lng;
        },

        deleteDropFile() {
            this.dropFile = null;
        },

        getSaveData() {
            return {
                site: { ...this.siteModel },
                tags: [...this.siteTags],
                file: this.dropFile,
            };
        },
    },
};
