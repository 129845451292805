<template>
    <div class="be-logo" :class="{ 'is-mini': displayMini }">
        <div class="be-logo__image">
            <img src="@/assets/logo-rvision.svg" alt="R/Vision Logo">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        displayMini: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.be-logo {
    &__image {
        display: block;
        width: 108px;
        overflow: hidden;
        img {
            display: block;
            width: 108px;
            height: auto;
            max-width: none;
        }
    }
    &.is-mini & {
        &__image {
            width: 31px;
            overflow: hidden;
        }
    }

    &.is-home & {
        &__image {
            @include desktop {
                width: 130px;
                img {
                    width: 130px;
                }
            }
        }
    }
}
</style>
