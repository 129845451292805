<template>
    <div
        v-if="scale"
        class="roi-canvas"
        @contextmenu.prevent="() => null"
        tabindex="0"
        @keydown.esc="onEscape"
        @keydown.delete="onDelete"
    >
        <div :style="canvasStyle" class="roi-canvas__inner">
            <v-stage
                ref="stage"
                @click="handleClick"
                :config="configKonva"
                @mousemove="handleMouseMove"
            >
                <roi-h-s-camera-image-layer
                    ref="layer"
                    v-for="roi in sortedRoisPoints"
                    :key="`roi-${roi.id}-${roi.active ? 1 : 0}-${roi.roiType}`"
                    :id="roi.id"
                    :active="roi.active"
                    :roi-points="roi.points"
                    :roi-type="roi.roiType"
                    :size="renderedSize"
                    :mouse-position="currentMousePosition"
                    :bounds="bounds"
                    :scale="realScale"
                    :label="label[roi.id]"
                    @change="handleRoiChange"
                    @deactivate="handleRoiDeactivate"
                />
            </v-stage>
        </div>
        <div class="roi-canvas__info mt-2">
            Regions of interest can contain a maximum of 40 points.
        </div>
        <div v-if="finishedRoisPoints.length" class="mt-4">
            <b-field v-for="(roi, index) in finishedRoisPoints" :key="roi.id">
                <b-input
                    v-model="label[roi.id]"
                    placeholder="ROI Label"
                    expanded
                    @focus="isFocusedInput = true"
                    @blur="isFocusedInput = false"
                />
                <div class="control">
                    <b-select v-model="roiTypesInternal[roi.id]">
                        <option
                            v-for="type in roiTypes"
                            :key="`opt-${type.type}-${roi.id}`"
                            :value="type.type"
                        >
                            {{ type.label }}
                        </option>
                    </b-select>
                </div>
                <div class="control">
                    <b-button icon-left="close" @click="removeRoiPoint(index, activeRoiIndex === index)" />
                </div>
            </b-field>
        </div>
    </div>
</template>

<script>
import { get, isEmpty, isEqual, omit, orderBy, uniqueId, uniqWith } from 'lodash';
import { mapState } from 'vuex';
import RoiHSCameraImageLayer from '@/components/local/sites/hs/partials/RoiHSCameraImageLayer';

export default {
    components: { RoiHSCameraImageLayer },
    props: {
        scale: {
            type: Number,
            required: true,
        },
        roiType: {
            type: Number,
            default: 0,
        },
        renderedSize: {
            type: Object,
            required: true,
        },
        rois: {
            type: Array,
            required: true,
        },
        canvasStyle: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isFocusedInput: false,
            activeRoiIndex: null,
            currentMousePosition: [0, 0],
            label: {},
            roisPoints: [],
            deletedRoisPoints: [],
            roiTypesInternal: {},
        };
    },

    computed: {
        ...mapState('roi', ['roiTypes']),

        bounds() {
            return {
                x: [0, this.renderedSize.width],
                y: [0, this.renderedSize.height],
            };
        },
        configKonva() {
            return {
                width: this.renderedSize.width,
                height: this.renderedSize.height,
            };
        },

        realScale() {
            return this.scale || 1;
        },

        finishedRoisPoints() {
            return this.roisPoints.filter((p) => p.isFinished);
        },

        sortedRoisPoints() {
            return orderBy(this.roisPoints, ['active']);
        },
    },

    mounted() {
        // wait till it renders first
        this.$nextTick(() => {
            const { roisPoints, label, roiTypes } = this.convertRoisPoints(this.rois);
            this.label = label;
            this.roisPoints = roisPoints;
            this.roiTypesInternal = roiTypes;
        });
    },

    watch: {
        finishedRoisPoints: {
            handler(val) {
                val.forEach((roi) => this.maybeGenerateLabelAndType(roi.id));
            },
            immediate: true,
            deep: true,
        },
        roiTypesInternal: {
            handler: 'updateRoiType',
            deep: true,
        },
    },

    methods: {
        generateId() {
            return uniqueId('layer-');
        },

        maybeGenerateLabelAndType(roiId) {
            this.maybeGenerateLabel(roiId);
            this.maybeGenerateRoiType(roiId);
        },

        maybeGenerateRoiType(roiId) {
            if (!isEmpty(this.roiTypesInternal[roiId])) {
                return;
            }

            const roi = this.getRoiById(roiId);
            this.$set(this.roiTypesInternal, roiId, roi ? roi.roiType : this.roiType);
        },

        maybeGenerateLabel(roiId) {
            if (!isEmpty(this.label[roiId])) {
                return;
            }

            // for the first roi we always set the label Default
            if (this.finishedRoisPoints.length === 1) {
                this.$set(this.label, roiId, 'Default');
                return;
            }

            const finishedPointsOfType = this.finishedRoisPoints.filter((roi) => roi.roiType === this.roiType);
            this.$set(this.label, roiId, this.getRoiTypeLabel(this.roiType, finishedPointsOfType.length));
        },
        hasActiveIndex() {
            return this.activeRoiIndex !== null;
        },
        getActiveLayer() {
            return this.hasActiveIndex()
                ? this.$refs.layer[this.activeRoiIndex]
                : null;
        },
        convertRoisPoints(rawObjects) {
            const label = {};
            const roiTypes = {};
            const roisPoints = [];

            rawObjects.forEach((roiObject) => {
                const rois = get(roiObject, 'roi', []);
                const tags = get(roiObject, 'tags', []);

                rois.forEach((rowPoints, index) => {
                    const points = uniqWith(this.convertPointsToScale(rowPoints), isEqual);
                    const id = this.generateId();

                    const roiType = roiObject.roi_type || 0;

                    roisPoints.push({
                        id,
                        points,
                        active: false,
                        roiId: roiObject.id,
                        roiType,
                    });

                    roiTypes[id] = roiType;

                    if (tags[index]) {
                        label[id] = tags[index].label;
                    }
                });
            });

            return { label, roisPoints, roiTypes };
        },

        generateDefaultRoiPoints() {
            const id = this.generateId();
            this.maybeGenerateLabelAndType(id);

            this.roisPoints = [{
                id,
                points: [
                    [0, 0],
                    [this.renderedSize.width, 0],
                    [this.renderedSize.width, this.renderedSize.height],
                    [0, this.renderedSize.height],
                ],
                active: false,
                roiType: this.roiType,
            }];
        },

        convertPointsToScale(points) {
            return points.map((roi) => ([
                roi[0] / this.realScale,
                roi[1] / this.realScale,
            ]));
        },

        convertPointsToOriginal(points) {
            return points.map((roi) => ([
                parseInt(roi[0] * this.realScale, 10),
                parseInt(roi[1] * this.realScale, 10),
            ]));
        },

        getMousePosition(stage) {
            return [stage.getPointerPosition().x, stage.getPointerPosition().y];
        },

        handleMouseMove(event) {
            this.currentMousePosition = this.getMousePosition(
                event.target.getStage(),
            );
        },

        handleClick(event) {
            if (event.evt.button > 0) {
                // we don't care about any of the other clicks
                this.handleRightClick(event);
                return;
            }
            if (this.hasActiveIndex()) {
                this.getActiveLayer().handleClick(event);
                return;
            }

            const layer = event.target.getLayer();
            if (layer) {
                this.activateRoiPoint(layer.attrs['data-id']);
                return;
            }

            this.addRoiPoint();
        },

        handleRightClick() {
            if (this.hasActiveIndex() && !this.getActiveLayer().isFinished) {
                this.removeRoiPoint(this.activeRoiIndex);
            }
            this.activateRoiPoint(null);
        },

        onDelete() {
            if (this.isFocusedInput) {
                return;
            }
            if (!this.hasActiveIndex()) {
                return;
            }
            this.removeRoiPoint(this.activeRoiIndex, true);
        },

        removeRoiPoint(index, deactivate = false) {
            // save the deleted point
            if (this.roisPoints[index] && this.roisPoints[index].roiId) {
                this.deletedRoisPoints.push(this.roisPoints[index].roiId);
            }
            this.roisPoints = this.roisPoints.filter((point, idx) => (
                idx !== index
            ));

            // remove the label
            this.label = omit(this.label, [this.activeRoiIndex]);
            this.roiTypesInternal = omit(this.roiTypesInternal, [this.activeRoiIndex]);

            if (deactivate) {
                this.activateRoiPoint(null);
            }
        },

        addRoiPoint() {
            this.roisPoints.push({
                id: this.generateId(),
                points: [this.currentMousePosition],
                active: true,
                roiType: this.roiType,
            });
            this.activeRoiIndex = this.roisPoints.length - 1;
        },

        activateRoiPoint(id) {
            let index = null;
            const points = [];
            this.roisPoints.forEach((point, idx) => {
                const active = point.id === id;
                points.push({ ...point, active });
                if (active) {
                    index = idx;
                }
            });
            this.activeRoiIndex = index;
            this.roisPoints = points;
        },

        updateRoiType() {
            this.roisPoints = this.roisPoints.map((roiPoints) => {
                const newPoints = { ...roiPoints };
                newPoints.roiType = this.roiTypesInternal[newPoints.id] || 0;
                return newPoints;
            });
        },

        updateRoiPoints(id, points, isFinished) {
            const len = points.length;
            this.roisPoints = this.roisPoints.map((roiPoints) => {
                if (roiPoints.id !== id) {
                    return roiPoints;
                }
                if (!len) {
                    return null;
                }
                return {
                    ...roiPoints,
                    points,
                    isFinished,
                };
            }).filter((r) => r);

            if (!len) {
                this.activateRoiPoint(null);
            }
        },

        onContext(event) {
            event.preventDefault();
        },

        onEscape() {
            this.$root.$emit('roi:camera:esc');
        },

        handleRoiChange({ points, id, isFinished }) {
            this.updateRoiPoints(id, points, isFinished);
        },

        handleRoiDeactivate() {
            this.activateRoiPoint(null);
        },

        validate() {
            // empty
            if (!this.$refs.layer) {
                return true;
            }
            return this.$refs.layer.reduce((curr, row) => (
                curr && row.isValid()
            ), true);
        },

        getSaveData() {
            // deletedRoisPoints
            const saveData = [];

            this.roisPoints.forEach((roi) => {
                saveData.push({
                    id: roi.roiId || null,
                    deleted: false,
                    data: {
                        roi: [this.convertPointsToOriginal(roi.points)],
                        tags: this.label[roi.id] ? [{ label: this.label[roi.id] }] : [],
                        roi_type: roi.roiType || 0,
                    },
                });
            });

            this.deletedRoisPoints.forEach((id) => {
                saveData.push({ id, deleted: true });
            });

            return saveData;
        },

        getRoiData() {
            const data = [];

            this.roisPoints.forEach((roi) => {
                data.push({
                    id: roi.roiId || null,
                    roi: [this.convertPointsToOriginal(roi.points)],
                    roi_type: roi.roiType || 0,
                    tags: this.label[roi.id] ? [{ label: this.label[roi.id] }] : [],
                });
            });

            this.deletedRoisPoints.forEach((id) => {
                data.push({ id, deleted: true });
            });

            return data;
        },

        getRoiTypeLabel(roiType, num) {
            if (roiType === 3) {
                return `LiveLane-${num}cm`;
            }
            if (roiType === 4) {
                return `PrivacyMask${num}`;
            }
            return this.roiTypes.reduce((curr, type) => (
                type.type === roiType ? `${type.label} #${num}` : curr
            ), '-');
        },

        getRoiById(roiId) {
            return this.roisPoints.reduce((curr, roi) => {
                if (curr) {
                    return curr;
                }
                return roi.id === roiId ? roi : curr;
            }, null);
        },
    },
};
</script>

<style lang="scss" scoped>
.roi-canvas {
    &,
    &__inner {
        &:focus {
            border: 0;
        }
    }

    &__info {
        opacity: .8;
        font-size: .8em;
        pointer-events: none;
    }
}

.error {
    color: $red;
}
</style>
