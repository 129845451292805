<template>
    <div class="observation-filters card" :class="{ 'is-opened': isFiltersVisible }">
        <b-loading :active="isLoading || initialLoading" :can-cancel="false" :is-full-page="false" />
        <b-collapse
            animation="fade"
            position="is-bottom"
            :open.sync="isFiltersVisible"
        >
            <div class="observation-filters__content card-content">
                <vehicle-observation-filter ref="observation" :simple="simple" :is-multi-select="isMultiSelect" />
                <div class="observation-filters__filter">
                    <div class="observation-filters__apply">
                        <b-button
                            class="be-button"
                            type="is-primary"
                            @click.prevent="apply"
                            :loading="isLoading"
                        >
                            Apply
                        </b-button>
                    </div>
                    <div class="observation-filters__reset">
                        <b-button
                            :disabled="isLoading"
                            class="be-button is-secondary"
                            @click.prevent="reset"
                        >
                            Reset
                        </b-button>
                    </div>
                </div>
            </div>
            <div
                slot="trigger"
                slot-scope="props"
                class="card-footer observation-filters__footer"
                role="button"
            >
                <div class="observation-filters__footer-item card-footer-item">
                    <div v-if="!isFiltersVisible && summary" class="observation-filters__summary" v-html="summary" />
                    <div class="observation-filters__cta">
                        <b-icon icon="filter" />
                        <span>{{ props.open ? 'Hide Filters' : 'Show Filters' }}</span>
                    </div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { cancelAllRequests } from '@/utils/api-tokens';
// import HSRiskFilter from '@/components/local/hs/observations/HSRiskFilter';
import VehicleObservationFilter from '@/components/local/vehicle/observations/VehicleObservationFilter';

export default {
    components: { VehicleObservationFilter },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        summary: {
            type: String,
            default: null,
        },
        isMultiSelect: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            initialLoading: false,
        };
    },

    computed: {
        isFiltersVisible: {
            set(val) {
                this.$store.commit('vehicle/observations/toggleFilters', val);
            },
            get() {
                return this.$store.state.vehicle.observations.filtersVisible;
            },
        },
    },

    mounted() {
        this.initialLoading = true;
        this.init()
            .finally(() => {
                this.initialLoading = false;
            });
    },

    methods: {
        ...mapActions('vehicle/observations', ['init']),

        cancelLoading() {
            cancelAllRequests({ refresh: true });
        },

        apply() {
            this.cancelLoading();
            if (this.$refs.risk && !this.$refs.risk.validate()) {
                return;
            }
            if (this.$refs.observation && !this.$refs.observation.validate()) {
                return;
            }

            const filters = {};
            if (this.$refs.risk) {
                filters.risk = this.$refs.risk.getFilters();
            }
            if (this.$refs.observation) {
                filters.observation = this.$refs.observation.getFilters();
            }

            this.$emit('filter', { filters });
            this.isFiltersVisible = false;
        },

        reset() {
            this.cancelLoading();
            if (this.$refs.risk) {
                this.$refs.risk.resetFilters();
            }
            if (this.$refs.observation) {
                this.$refs.observation.resetFilters();
            }
            this.apply();
        },
    },
};
</script>

<style lang="scss" scoped>
.observation-filters {
    position: relative;
    background: $grey-dark;
    border-radius: $radius;

    &__cta {
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0;
        cursor: pointer;
        display: block;
        width: 100%;
        color: $primary;
        font-size: 13px;
        display: inline-flex;
        align-items: center;

        .icon {
            margin-right: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__summary {
        flex: 0 0 100%;
        width: 100%;
        font-size: 13px;
        opacity: .6;
        text-align: center;
        padding-bottom: 1em;
        border-bottom: 1px solid $border;
        margin-bottom: 1em;
    }

    &__apply {
        text-align: center;
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-top:5px;
    }

    &__reset {
        text-align: center;
        display: flex;
        flex-direction: row;
        margin-top:5px;
    }

    &__footer {
        border-top-color: transparent;

        &-item {
            flex-wrap: wrap;
        }
    }

    &__filter {
        margin-top: 1em;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    &.is-opened & {
        &__footer {
            border-top-color: $border;
        }
    }
}
</style>
