<template>
    <div class="detections-table">
        <b-table
            :data="formattedApiResults"
            :hoverable="true"
            :selected="lastSelectedRow"
            :loading="isLoadingFull"
        >
            <b-table-column label="Date" width="120" v-slot="props">
                {{ props.row.datestamp | dateHuman }}
            </b-table-column>
            <b-table-column label="Object Type" width="120" v-slot="props">
                <b-tag
                    class="has-background-info has-text-black-bis has-text-weight-bold"
                >
                    {{ getObjectType(props.row) }}
                </b-tag>
            </b-table-column>
            <b-table-column label="Confidence" width="120" v-slot="props">
                <be-confidence :confidence="props.row.confidence" />
            </b-table-column>
            <b-table-column label="Coordinates" v-slot="props">
                <b-taglist class="has-text-weight-bold has-cursor-default">
                    <b-tag>
                        {{ getDmsLat(props.row) }}
                    </b-tag>
                    <b-tag>
                        {{ getDmsLng(props.row) }}
                    </b-tag>
                </b-taglist>
            </b-table-column>
            <b-table-column label="Image" width="80" v-slot="props" header-class="has-text-right">
                <p class="has-text-right has-text-weight-bold">
                    <a href="#" @click.prevent="openPopup(props.row, props.index)">View</a>
                </p>
            </b-table-column>
            <template slot="empty">
                <data-table-empty v-if="showZeroResults">
                    There are no results to display. Try changing your filter or search criteria.
                </data-table-empty>
            </template>
        </b-table>
        <be-pagination
            key="maui-detections"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :count="overallCount"
        />
        <media-image-popup
            :active.sync="showMauiImagePopup"
            :image-with-detail="mauiImageWithDetail"
            image-type="dolphin"
            :loading="isLoadingFull"
            :display-arrows="formattedApiResults && formattedApiResults.length > 1"
            @prev="goto('prev')"
            @next="goto('next')"
            @close="closePopup"
        />
    </div>
</template>

<script>
import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';
import hasPopupMixin from '@/mixins/hasPopupMixin';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import hasMauiImagePopupMixin from '@/mixins/hasMauiImagePopupMixin';
import BeConfidence from '@/components/global/BeConfidence';
import { getMauiObjectLabel, getMauiSingleObjectClass } from '@/utils/formatters/maui63/labels';
import { getDms } from '@/utils/map';
import mauiDetectionsMixin from '@/mixins/mauiDetectionsMixin';
import BePagination from '@/components/global/BePagination';
import formattersMixin from '@/mixins/formattersMixin';

export default {
    components: {
        BeConfidence,
        DataTableEmpty,
        BePagination,
    },

    mixins: [hasMauiImagePopupMixin, mauiDetectionsMixin, hasPopupMixin, widgetDataMixin, formattersMixin],

    methods: {
        getObjectType(row) {
            return getMauiObjectLabel({
                objectClass: getMauiSingleObjectClass({ row }),
            });
        },
        getDmsLat(row) {
            return getDms(row.lat, 'lat', true);
        },
        getDmsLng(row) {
            return getDms(row.lng, 'lng', true);
        },
    },
};
</script>

<style lang="scss" scoped>
.detections-table {
    position: relative;
}
</style>
