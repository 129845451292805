<template>
    <b-taglist
        class="detection-tag"
        :class="rootClasses"
        attached
        @click.native.prevent="$emit('click', detection)"
    >
        <b-tooltip
            v-if="hasPredictionConfirmation"
            type="is-dark"
            position="is-bottom"
            :triggers="['click']"
            :auto-close="['escape']"
            ref="tooltip1"
        >
            <template v-slot:content>
                <be-prediction-confirmation @click="onPredictionClick(1)" />
            </template>
            <b-tag
                class="detection-tag__confidence is-marginless has-cursor-pointer"
                :class="confidenceClasses"
                @click.native="onPredictionTooltipClick(1)"
            >
                <span class="detection-tag__confidence__type" :class="confidenceTypeClass" />
                <span
                    v-if="displayConfidenceLabel"
                    class="detection-tag__confidence__label"
                >{{ confidenceLabel }}</span>
            </b-tag>
        </b-tooltip>
        <b-tag
            v-else
            class="detection-tag__confidence is-marginless"
            :class="confidenceClasses"
        >
            <span class="detection-tag__confidence__type" :class="confidenceTypeClass" />
            <span
                v-if="displayConfidenceLabel"
                class="detection-tag__confidence__label"
            >{{ confidenceLabel }}</span>
        </b-tag>
        <div
            class="detection-tag__tags"
            @mouseover="highlightObjects"
            @mouseout="removeHighlight"
            @focusin="highlightObjects"
            @focusout="removeHighlight"
        >
            <b-tag v-if="isHS" b-tag class="detection-tag__count is-marginless" :class="detectionCount">
                <span class="row">{{ detectionCount }}</span>
            </b-tag>
            <b-tag class="detection-tag__detection is-marginless" :class="detectionClass">
                <span class="row">{{ detectionLabel }}</span>
                <span v-if="displayOrientation && orientation" class="row">{{ orientation }}</span>
            </b-tag>
            <b-tag
                v-if="displayModel && vehicleModel && vehicleModelParts.length"
                class="detection-tag__make is-marginless"
                :class="vehicleModelClass"
            >
                <span class="row">{{ vehicleModelParts[0] }}</span>
                <span class="row">{{ vehicleModelParts[1] }}</span>
            </b-tag>
        </div>
        <template v-if="displayTags && tags.length">
            <b-tag
                v-for="(tag, index) in tags"
                :key="`tag-${tag}-${index}`"
                class="detection-tag__tag is-marginless"
            >{{ tag }}
            </b-tag>
        </template>
        <template v-if="isHS && isMediaImagePopup && isViolation">
            <b-tag class="detection-tag__violation is-marginless is-dark">
                <b-tooltip type="is-danger" position="is-right">
                    <template v-slot:default>
                        <b-icon icon="alert" custom-size="mdi-18px" type="is-danger" />
                    </template>
                    <template v-slot:content>
                        Violation
                    </template>
                </b-tooltip>
            </b-tag>
        </template>
    </b-taglist>
</template>

<script>
import { get, isPlainObject, lowerCase } from 'lodash';
import { getConfidenceType } from '@/utils/confidence';
import { formatPercentage } from '@/utils/formatters/numbers';
import BePredictionConfirmation from '@/components/global/BePredictionConfirmation';
import { isViolation } from '@/utils/hs/helpers';

export default {
    components: {
        BePredictionConfirmation,
    },

    props: {
        detection: {
            type: Object,
            required: true,
        },
        rowType: {
            type: String,
            default: '',
        },
        decimal: {
            type: Boolean,
            default: false,
        },
        hasPredictionConfirmation: {
            type: Boolean,
            default: false,
        },
        displayOrientation: {
            type: Boolean,
            default: false,
        },
        displayModel: {
            type: Boolean,
            default: false,
        },
        displayConfidenceLabel: {
            type: Boolean,
            default: true,
        },
        displayTags: {
            type: Boolean,
            default: false,
        },
        isMediaImagePopup: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        rootClasses() {
            return {
                'has-extras': !!(this.vehicleModel || this.orientation),
            };
        },
        isHS() {
            return this.rowType === 'hs';
        },
        orientation() {
            return get(this.detection, 'orientation', '');
        },
        tags() {
            return get(this.detection, 'tags', [])
                .map((tag) => (isPlainObject(tag) ? (tag.name ?? tag.label) : tag));
        },
        confidence() {
            return get(this.detection, 'confidence', '');
        },
        vehicleModel() {
            return get(this.detection, 'vehicle_model', '');
        },
        vehicleModelConfidence() {
            return null;
            // return get(this.detection, 'vehicle_model_confidence', 0);
        },
        vehicleModelParts() {
            return this.vehicleModel.split('_');
        },
        vehicleModelClass() {
            return 'is-warning';
        },
        confidenceClasses() {
            return {
                'has-confidence-label': this.displayConfidenceLabel,
                'no-confidence-label': !this.displayConfidenceLabel,
                'has-decimal': this.decimal,
                'no-decimal': !this.decimal,
            };
        },
        detectionCount() {
            return get(this.detection, 'count', 0);
        },
        detectionLabel() {
            return get(this.detection, 'detection', '');
        },
        detectionClass() {
            const prefix = 'is-detection-';
            if (this.detection.type) {
                return `${prefix}${lowerCase(this.detection.type)}`;
            }
            // some detections do not return type
            // so we have an extra check for the plate parameter
            if (this.detection.is_plate) {
                return `${prefix}car`;
            }
            return `${prefix}null`;
        },

        confidenceTypeClass() {
            return getConfidenceType({
                confidence: this.confidence,
            });
        },

        confidenceLabel() {
            return formatPercentage({
                number: this.confidence,
                decimal: this.decimal,
            });
        },

        vehicleModelConfidenceLabel() {
            return formatPercentage({
                number: this.vehicleModelConfidence,
                decimal: this.decimal,
            });
        },

        vehicleModelconfidenceTypeClass() {
            return getConfidenceType({
                confidence: this.vehicleModelConfidence,
            });
        },

        isViolation() {
            return isViolation(this.detection);
        },
    },

    methods: {
        closeTooltip(tooltip) {
            if (this.$refs[`tooltip${tooltip}`]) {
                this.$refs[`tooltip${tooltip}`]
                    .keyPress({ key: 'Esc' });
            }
        },
        onPredictionClick(tooltip) {
            this.closeTooltip(tooltip);
            this.$emit('close-prediction');
        },
        onPredictionTooltipClick() {
            this.$emit('click-prediction', this.detection);
        },
        highlightObjects() {
            this.$store.commit('canvas/setSelectedObjectIds', { selectedObjectIds: this.detection.ids });
        },

        removeHighlight() {
            this.$store.commit('canvas/setSelectedObjectIds', { selectedObjectIds: [] });
        },
    },
};
</script>

<style lang="scss" scoped>
.detection-tag {
    white-space: nowrap;
    flex-wrap: nowrap;
    flex: 0 0 auto;

    &__tags {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
    }

    &.has-extras {
        .tag {
            height: 28px;
        }
    }

    &__count {
        color: $black-bis;
        background-color: $grey-light-counter;
    }

    &__detection,
    &__make {
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
        min-width: 70px;
        color: $black-bis;
        justify-content: center;
        font-weight: $weight-bold;
        text-transform: uppercase;

        .row {
            display: block;
            line-height: 1;
            font-size: .75em;

            &:nth-child(1) {
                font-size: 1em;
            }

            &:nth-child(2) {
                margin-top: 2px;
            }
        }
    }

    &__orientation {
        font-weight: $weight-bold;
        min-width: 60px;
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
        justify-content: center;
        color: $black-bis;
    }

    &__confidence {
        font-weight: $weight-bold;
        color: $white;
        background: rgba($black-bis, .8);

        &.no-confidence-label {
            width: 24px;
            text-align: center;
            justify-content: center;
            display: inline-flex;
        }

        &.has-confidence-label {
            width: 55px;
            padding-left: 8px;
            padding-right: 2px;
            text-align: left;
            justify-content: flex-start;
        }

        &.has-confidence-label.has-decimal {
            width: 65px;
        }

        /*::v-deep > */span {
            display: inline-flex;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 1;
        }

        &__type {
            width: 7px;
            height: 7px;
            border-radius: 7px;
            @include confidence-bg;
            display: block;
        }

        &.has-confidence-label &__type {
            margin-right: 4px;
        }
    }

    &__tag {
        text-transform: uppercase;
        font-weight: $weight-bold;
    }

    &__tag + &__tag {
        border-left: 1px solid $dark;
    }
}
</style>
