<template>
    <div class="integration is-hubspot">
        <be-integration-header class="integration__header" back-url="/dashboard/marketplace/apps">
            <template v-slot:logo>
                <img src="@/assets/integrations/logo-hubspot-white.png" alt="Hubspot Logo" style="max-width: 150px">
            </template>
            <template v-slot:subheading>
                <p>
                    The Hubspot App helps identify high value customers and provides useful insights that can be used to
                    easily rollout customer loyalty programmes.
                </p>
            </template>
            <template v-slot:cta>
                <div class="buttons">
                    <b-button
                        type="a"
                        href="https://hubspot.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="be-button is-wider is-primary"
                        ref="nofollow noopener"
                    >
                        Find out more
                    </b-button>
                    <b-button
                        type="a"
                        href="https://www.hubspot.com/products/get-started"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="be-button is-wider is-secondary"
                        ref="nofollow noopener"
                    >
                        Sign Up
                    </b-button>
                </div>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field horizontal>
                            <template v-slot:label>
                                <span class="is-block has-text-left has-text-weight-bold">
                                    Integration
                                </span>
                            </template>
                            <b-switch v-model="integration.enabled" />
                        </b-field>
                        <div class="content">
                            <p>
                                Enable this integration to send specific detection alerts and metadata to your Hubspot
                                account.
                            </p>
                        </div>
                    </div>
                    <div class="column is-12" v-if="integration.enabled">
                        <b-field
                            label="API Key"
                            :type="fieldType('apiKey')"
                            :message="fieldMessage('apiKey')"
                        >
                            <b-input v-model="integration.apiKey" />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-button
                            @click="save"
                            :loading="isSaving"
                            class="be-button is-wider is-primary"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import noticesMixin from '@/mixins/noticesMixin';
import marketplaceAppMixin from '@/mixins/marketplaceAppMixin';
import integrationFakeSaveMixin from '@/mixins/integrationFakeSaveMixin';

export default {
    mixins: [
        validationMixin,
        noticesMixin,
        marketplaceAppMixin,
        integrationFakeSaveMixin(true),
    ],
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
