<template>
    <div class="totals columns is-multiline">
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="check">
                <template v-slot:heading>Average Confidence</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ averageConfidence }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="glasses">
                <template v-slot:heading>Total PPE detections</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalPPEViolations }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="video-outline">
                <template v-slot:heading>Total PPE compliance</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalPPECompliance }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-3-desktop">
            <be-widget icon="video-outline">
                <template v-slot:heading>Total safety zone detections</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ totalSafetyZoneViolations }}</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatNumber, formatPercentage } from '@/utils/formatters/numbers';
import widgetDataMixin from '@/mixins/widgetDataMixin';

export default {
    mixins: [widgetDataMixin],

    computed: {
        ...mapGetters('hs/summary', ['summaryData', 'isLoading']),

        averageConfidence() {
            return this.summaryData.average_confidence
                ? formatPercentage({ number: this.summaryData.average_confidence, decimal: false })
                : '-';
        },

        totalPPEViolations() {
            return this.summaryData.total_ppe_violations
                ? formatNumber({ number: this.summaryData.total_ppe_violations })
                : '-';
        },

        totalSafetyZoneViolations() {
            return this.summaryData.total_safety_zone_violations
                ? formatNumber({ number: this.summaryData.total_safety_zone_violations })
                : '-';
        },

        totalPPECompliance() {
            return this.summaryData.total_ppe_compliance
                ? formatNumber({ number: this.summaryData.total_ppe_compliance })
                : '-';
        },
    },

    methods: {
        ...mapActions('hs/summary', ['loadData']),
    },
};
</script>

<style lang="scss" scoped>
.totals {
    justify-content: stretch;

    &__col {
        display: flex;
        justify-content: stretch;
    }
}
</style>
