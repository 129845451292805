import axios from 'axios';
import { getStatusGetters } from '@/utils/store-helpers';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';

const getDefaultState = () => ({
    stolenPlates: false,
    status: '',
});

const state = getDefaultState();

const getters = {
    ...getStatusGetters(),
};

const mutations = {
    setStolenPlates(state, { stolenPlates }) {
        state.stolenPlates = stolenPlates;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    resetState({ commit }) {
        commit('resetState');
        return true;
    },

    load({ commit }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({ url: getApiUrl({ path: 'billing/stolenPlates' }) })
            .then((response) => {
                commit('setStolenPlates', { stolenPlates: response.data.stolen_plates_enabled });
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
            });
    },

    update({ commit }, { stolenPlates }) {
        commit('setStatus', { status: STATUS_LOADING });

        return axios({
            url: getApiUrl({ path: 'billing/stolenPlates' }),
            data: {
                stolen_plates_enabled: stolenPlates,
            },
            method: 'PUT',
        })
            .then(() => {
                commit('setStolenPlates', { stolenPlates });
                commit('setStatus', { status: STATUS_SUCCESS });
            })
            .catch((err) => {
                commit('setStatus', { status: STATUS_ERROR });
                errorHandler(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
