<template>
    <div class="data-explorer">
        <overview-filters
            :can-export="true"
            :filter-confidence="true"
            :is-loading="isLoading"
            @update="clearPlates"
        />
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Vehicle Explorer
                </template>
                <template v-slot:subheading>
                    <be-detections-heading />
                </template>
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-flex is-6">
                    <search-plates ref="searchPlates" :is-loading="isLoading" />
                </div>
                <div class="column is-12">
                    <system-totals :can-load-data="true" />
                </div>
                <div v-if="plate" class="column is-12">
                    <detections-over-time-chart
                        ref="overTimeChart"
                        :can-load-data="true"
                    />
                </div>
                <div class="column is-12">
                    <data-explorer-table-widget
                        ref="tableWidget"
                        :can-load-data="true"
                        @view-changed="tableWidgetView = $event"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getApiUrl } from '@/utils/api';
import DataExplorerTableWidget from '@/components/widgets/dataTables/DataExplorerTableWidget';
import SystemTotals from '@/components/widgets/SystemTotals';
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import OverviewFilters from '@/components/global/OverviewFilters';
import SearchPlates from '@/components/widgets/SearchPlates';
import DetectionsOverTimeChart from '@/components/widgets/charts/DetectionsOverTimeChart';

export default {
    name: 'DataExplorer',

    components: {
        BeDetectionsHeading,
        OverviewFilters,
        DataExplorerTableWidget,
        SearchPlates,
        SystemTotals,
        DetectionsOverTimeChart,
    },

    data() {
        return {
            tableWidgetView: '',
        };
    },

    computed: {
        ...mapGetters({
            isLoadingUniqueDetections: 'explorer/detections/unique/isLoading',
            isLoadingAllDetections: 'explorer/detections/isLoading',
            isLoadingSummary: 'explorer/detections/unique/summary/isLoading',
        }),

        ...mapState('explorer/plates/sitegraph', ['plate']),

        isLoading() {
            return this.isLoadingUniqueDetections
                || this.isLoadingAllDetections
                || this.isLoadingSummary;
        },
    },

    mounted() {
        this.populateFilters();
    },

    methods: {
        ...mapMutations({
            resetDetectionsOverTime: 'explorer/plates/sitegraph/resetState',
        }),

        populateFilters() {
            if (!this.$route.query.tag) {
                return Promise.resolve(true);
            }

            const promises = this.$route.query.tag.split(',')
                .map((tagSlug) => (
                    this.$http(getApiUrl({ path: `tags/${tagSlug}` }))
                        .then(({ data }) => data)
                ));

            return Promise.all(promises).then((tags) => {
                this.$store.commit('dataFilters/setSelectedTags', { tags });
            });
        },

        clearPlates() {
            this.$refs.searchPlates.clear(false);
            this.resetDetectionsOverTime();
        },
    },
};
</script>
