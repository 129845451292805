import { find, get } from 'lodash';
import { mapState } from 'vuex';
import { getValidationMessage } from '@/utils/validations/validations';

export default {
    props: {
        site: {
            type: Object,
            required: true,
        },
        cameraId: {
            type: String, default: '',
        },
    },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        isNew() {
            return !this.cameraId;
        },

        isEdit() {
            return !!this.cameraId;
        },

        camera() {
            if (!this.cameraId) {
                return null;
            }

            return find(this.cameras, { id: this.cameraId });
        },

        cameras() {
            return get(find(this.sitesCameras, { id: this.site.id }), 'cameras', []);
        },

        ...mapState('sites', ['sitesCameras']),
    },

    watch: {
        isLoading: {
            handler(val) {
                this.$emit(val ? 'loading' : 'ready');
            },
        },
    },

    methods: {
        fieldHasError(field, parent = 'cameraModel') {
            if (!this.$v) {
                return false;
            }

            const getter = [parent, field, '$error'].filter((g) => g);

            return get(this.$v, getter.join('.'), false);
        },

        getFieldType(field, parent = 'cameraModel') {
            if (this.fieldHasError(field, parent)) {
                return 'is-danger';
            }

            return '';
        },

        getFieldMessage(field, parent = 'cameraModel') {
            if (!this.fieldHasError(field, parent)) {
                return '';
            }
            return getValidationMessage(field, this.$v[parent], this.validationMessages);
        },
    },
};
