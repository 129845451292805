<template>
    <div class="sites">
        <div class="sites__inner app-container" :class="{ 'is-map': isIndexMapView }">
            <b-loading :can-cancel="false" :is-full-page="false" :active="isLoading" />
            <be-page-header :app-container="false" :is-abs="isIndexMapView">
                <template v-slot>
                    Sites
                </template>
                <template v-slot:headingCta>
                    <b-button
                        type="is-primary"
                        rounded
                        class="be-button is-small"
                        @click="manageSiteActive = true"
                        icon-left="plus"
                    >
                        Add Site
                    </b-button>
                </template>
                <template v-slot:subheading>
                    Manage and add your sites
                </template>
                <template v-slot:cta>
                    <div class="field has-addons sites__cta">
                        <div class="control">
                            <b-button
                                class="be-button is-toggle sites__cta__control"
                                :class="{ 'is-wider': !isMobile }"
                                :type="isIndexListView ? 'is-toggle-active' : 'is-toggle-inactive'"
                                :icon-right="isMobile ? 'view-list' : null"
                                @click="setSitesIndexView('list')"
                            >
                                <span v-if="!isMobile">List View</span>
                            </b-button>
                        </div>
                        <div class="control">
                            <b-button
                                class="be-button is-toggle sites__cta__control"
                                :class="{ 'is-wider': !isMobile }"
                                :type="isIndexMapView ? 'is-toggle-active' : 'is-toggle-inactive'"
                                :icon-right="isMobile ? 'map' : null"
                                @click="setSitesIndexView('map')"
                            >
                                <span v-if="!isMobile">Map View</span>
                            </b-button>
                        </div>
                    </div>
                </template>
            </be-page-header>
            <div v-if="!isLoading">
                <template v-if="isIndexListView">
                    <div class="sites__columns columns is-multiline">
                        <div
                            v-for="(site, index) in sites"
                            :key="`site${index}`"
                            class="sites__column column is-6-tablet is-4-desktop is-3-widescreen"
                        >
                            <site-item
                                :key="`site-${index}-${site.id}`"
                                :item="site"
                            />
                        </div>
                    </div>
                    <div class="sites__pagination">
                        <be-pagination
                            v-if="displayPagination"
                            key="sites"
                            :per-page.sync="perPage"
                            :per-page-values="perPageValues"
                            :current-page.sync="currentPage"
                            :count="sitesCount"
                        />
                    </div>
                </template>
                <div v-if="isIndexMapView">
                    <sites-map />
                    <sites-map-list @add-site="manageSiteActive = true" />
                </div>

                <manage-site-popup :active="manageSiteActive" @close="manageSiteActive = false" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import SiteItem from '@/components/local/sites/SiteItem';
import SitesMap from '@/components/local/sites/SitesMap';
import SitesMapList from '@/components/local/sites/SitesMapList';
import BePagination from '@/components/global/BePagination';
import ManageSitePopup from '@/components/local/sites/ManageSitePopup';
import { DEFAULT_SITES_PAGINATION_PER_PAGE, SITES_PAGINATION_PER_PAGE } from '@/utils/sites/constants';

export default {
    components: {
        ManageSitePopup,
        BePagination,
        SiteItem,
        SitesMap,
        SitesMapList,
    },

    data() {
        return {
            manageSiteActive: false,
            perPageValues: [
                ...SITES_PAGINATION_PER_PAGE,
            ],
        };
    },

    computed: {
        ...mapState('sites', ['sites', 'sitesCount', 'sitesPerPage', 'sitesCurrentPage']),
        ...mapGetters({
            isLoading: 'sites/isLoading',
            isIndexMapView: 'dashboard/layout/isSitesIndexMapView',
            isIndexListView: 'dashboard/layout/isSitesIndexListView',
        }),

        displayPagination() {
            return !(
                this.sitesCurrentPage === 1
                && this.sitesPerPage === DEFAULT_SITES_PAGINATION_PER_PAGE
                && this.sitesCount < DEFAULT_SITES_PAGINATION_PER_PAGE
            );
        },

        isMobile() {
            return this.$bus.isMobile;
        },

        perPage: {
            get() {
                return this.sitesPerPage;
            },
            set(perPage) {
                return this.setSitesPerPage({ perPage });
            },
        },

        currentPage: {
            get() {
                return this.sitesCurrentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.setSitesPage({ page });
            },
        },
    },

    watch: {
        perPage(val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    per_page: val !== DEFAULT_SITES_PAGINATION_PER_PAGE ? val : undefined,
                },
            })
                .catch(() => ({}));
            this.getSites({ force: true });
        },
        currentPage(val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: val > 1 ? val : undefined,
                },
            })
                .catch(() => ({}));
            this.getSites({ force: true });
        },
    },

    methods: {
        ...mapActions('sites', ['getSites']),
        ...mapMutations('sites', ['setSitesPage', 'setSitesPerPage']),
        ...mapMutations('dashboard/layout', ['setSitesIndexView']),
    },

    mounted() {
        const page = Number(this.$route.query.page);
        const perPage = Number(this.$route.query.per_page);

        if (page && page > 1) {
            this.setSitesPage({ page });
        }
        if (perPage && perPage !== DEFAULT_SITES_PAGINATION_PER_PAGE) {
            this.setSitesPerPage({ perPage });
        }

        this.getSites({});
    },
};
</script>

<style lang="scss" scoped>
.sites {
    &__inner {
        position: relative;
        overflow: hidden;
    }

    &__cta {
        &__control {
            @include mobile {
                width: 54px;
                font-size: 24px;
                display: inline-flex;
                padding: 0;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
</style>
