<template>
    <b-modal
        :active="active"
        :destroy-on-hide="true"
        has-modal-card
        trap-focus
        scroll="keep"
        :width="600"
    >
        <div class="modal-card is-narrower">
            <header class="modal-card-head">
                <div class="modal-card-title">
                    Tags
                </div>
                <button type="button" class="modal-card-close" @click.prevent="close()">
                    <b-icon icon="close" />
                </button>
            </header>
            <div class="modal-card-body">
                <div class="tags">
                    <b-button
                        rounded
                        v-for="tag in tags"
                        :key="`tag-${tag.slug}`"
                        :type="getTagType(tag)"
                        @click="onTagClick(tag)"
                        :class="[
                            'be-button',
                            'is-small',
                            'is-tag',
                            getBorderStyle(tag.type),
                        ]"
                    >
                        {{ tag.label }}
                    </b-button>
                </div>
            </div>
        </div>
        <footer class="modal-card-foot">
            <b-button class="is-outline" @click.prevent="close()">
                Cancel
            </b-button>
            <b-button class="is-primary" @click.prevent="selectTags()">
                Select
            </b-button>
        </footer>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { findIndex } from 'lodash';

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        currentTags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedTags: [],
        };
    },

    computed: {
        ...mapState('tags', ['tags']),
    },

    watch: {
        active: {
            handler(val) {
                if (!val) {
                    return;
                }
                this.$nextTick(() => {
                    this.selectedTags = [...this.currentTags];
                });
            },
            immediate: true,
        },
    },

    methods: {
        selectTags() {
            this.$emit('select-tags', this.selectedTags);
            this.selectedTags = [];
            this.close();
        },

        close() {
            this.$emit('update:active', false);
        },

        onTagClick(tag) {
            const index = findIndex(this.selectedTags, { id: tag.slug });

            if (index === -1) {
                this.selectedTags.push(tag);
                return;
            }

            this.$delete(this.selectedTags, index);
        },

        getTagType(tag) {
            return this.isTagSelected(tag) ? 'is-primary' : '';
        },

        isTagSelected(tag) {
            return this.selectedTags.map((t) => t.slug).includes(tag.slug);
        },
        getBorderStyle(tagType) {
            if (tagType === 'CAM') {
                return 'border-red';
            } if (tagType === 'SITE') {
                return 'border-green';
            }
            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-card-body {
    .be-button {
        font-weight: 700;
        margin: 0 5px 5px 0;
    }
}

.modal-card-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tags .border-red {
    border: 1px solid red;
}

.tags .border-green {
    border: 1px solid green;
}
</style>
