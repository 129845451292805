import detections from './detections/index';
import images from './images';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    resetState({ commit }) {
        commit('detections/resetState');
        commit('detections/ppe/resetState');
        commit('detections/zones/resetState');
        commit('images/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        detections,
        images,
    },
};
