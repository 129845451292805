import Vue from 'vue';
import dayjs from 'dayjs';
import axios from 'axios';
import { get, truncate } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { CAMERA_APP_TYPES, SITE_TYPES } from '@/utils/sites/constants';
import { VEHICLE_RISK_METRICS } from '@/utils/sites/cameras';
import { DATETIME_FORMAT_HOUR } from '@/utils/formatters/datetime';

export const getDefaultObservationFilters = () => ({
    selectedSites: [],
    from: dayjs().subtract(7, 'day').toDate(),
    to: dayjs().toDate(),
});

const getDefaultState = () => ({
    currentFilters: getDefaultObservationFilters(),
    metricTypes: VEHICLE_RISK_METRICS,
    sites: [],
});

const state = getDefaultState();
const getters = {
    selectedSites(state) {
        if (!state.currentFilters.selectedSites) {
            return [];
        }
        return state.currentFilters.selectedSites;
    },
    getDateRange(state) {
        return {
            from: state.currentFilters.from ? dayjs(state.currentFilters.from).format(DATETIME_FORMAT_HOUR) : null,
            to: state.currentFilters.to ? dayjs(state.currentFilters.to).format(DATETIME_FORMAT_HOUR) : null,
        };
    },
};
const mutations = {
    setCurrentFilters(state, filters = {}) {
        state.currentFilters = {
            ...state.currentFilters,
            ...filters,
        };
    },
    setCurrentFilter(state, { filter, value }) {
        Vue.set(state.currentFilters, filter, value);
    },
    resetFilters() {
        state.currentFilters = getDefaultObservationFilters();
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setSites(state, { sites }) {
        state.sites = sites;
    },
    setSelectedSites(state, { selectedSites }) {
        state.selectedSites = selectedSites;
    },
};
const actions = {
    init({ dispatch }) {
        return dispatch('getSites');
    },
    getSites({ state, commit }) {
        if (state.sites.length) {
            return Promise.resolve(true);
        }
        return axios({ url: getApiUrl({ path: 'sites/summary' }) })
            .then((response) => {
                const results = get(response, 'data') || [];
                const sites = results
                    .filter((result) => result.site_type === SITE_TYPES.CARPARK && result.cameras.length)
                    .map((result) => ({
                        name: result.name,
                        shortName: truncate(result.name, { length: 20 }),
                        id: result.id,
                        cameras: result.cameras
                            .filter((camera) => camera.app_type === CAMERA_APP_TYPES.CARPARK)
                            .map((camera) => ({
                                ...camera,
                                shortName: truncate(camera.name, { length: 20 }),
                            })),
                    }));
                commit('setSites', { sites });
            })
            .catch((err) => {
                errorHandler(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
