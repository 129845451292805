import { isEmpty } from 'lodash';
import axios from 'axios';
import { STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { getApiUrl } from '@/utils/api';

const getDefaultState = () => ({
    data: null,
    status: '',
    dataHash: null,
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,
    hasData: (state) => !isEmpty(state.data),
    prevPage(state) {
        return state.data
            ? state.data.previous
            : null;
    },
    nextPage(state) {
        return state.data
            ? state.data.next
            : null;
    },
    overallCount(state) {
        return state.data
            ? (state.data.count || 0)
            : 0;
    },
    apiResults(state) {
        return state.data
            ? state.data.map((media) => ({ cluster: [media] }))
            : [];
    },
    formattedApiResults(state, getters) {
        return getters.apiResults;
    },
};

const mutations = {
    setData(state, { data }) {
        state.data = data;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
    setDataHash(state, { dataHash }) {
        state.dataHash = dataHash;
    },
    setPage(state, { page }) {
        state.currentPage = page;
    },
    setPerPage(state, { perPage }) {
        state.perPage = perPage;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    resetState({ commit }) {
        commit('resetState');
        return true;
    },

    resetSearch({ commit }) {
        commit('setPage', { page: 1 });
        commit('setData', { data: null });
        return Promise.resolve();
    },

    search({ commit, rootGetters }, { perPage = null, page = null }) {
        if (rootGetters['hs/observations/explorer/isLoading']) {
            return Promise.resolve();
        }

        commit('hs/observations/explorer/setStatus', { status: STATUS_LOADING }, { root: true });

        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        return axios({
            url: getApiUrl({
                path: `hsafety/observations/${rootGetters['hs/observations/explorer/detectionsRequestParameters']}`,
            }),
            params: rootGetters['hs/observations/explorer/summaryRequestParameters'],
        }).then((response) => {
            commit('setData', { data: response.data });
            commit('hs/observations/explorer/setStatus', { status: STATUS_SUCCESS }, { root: true });
        });
    },

    // temporary fix for incorrect amount of item quantity
    async getData({ rootGetters }, { date, time }) {
        return (
            (
                await axios({
                    url: getApiUrl({
                        path: `hsafety/observations/${date}T${time}:00:00`,
                    }),
                    params: rootGetters['hs/observations/explorer/summaryRequestParameters'],
                })
            )?.data || []
        );
    },

    /* eslint-disable-next-line */
    detectionUpdate(st, payload) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    },

    detectionSendNotification(st, payload) {
        return axios({
            method: 'POST',
            url: getApiUrl({ path: `hsafety/observations/${payload.id}/notification/` }),
            data: payload.data,
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
