<template>
    <router-view />
</template>

<script>
export default {
    created() { // intercept API calls and logout if we get a 401
        this.$http.interceptors.response.use(undefined, (err) => new Promise(function () {
            if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                this.$store.dispatch('auth/logout');
            }
            throw err;
        }));
    },

    watch: {
        $route: {
            immediate: true,
            handler(val) {
                if (val.path.includes('/dashboard')) {
                    document.documentElement.classList.remove('is-home');
                    document.documentElement.classList.add('is-dash');
                } else {
                    document.documentElement.classList.add('is-home');
                    document.documentElement.classList.remove('is-dash');
                }
            },
        },
    },
};
</script>
