import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';

Vue.use(vuescroll, {
    ops: {
        mode: 'native',
        detectResize: true,
        scrollPanel: {
            scrollingX: false,
            scrollingY: true,
        },
        bar: {
            background: '#59637C',
        },
    },
});
