<template>
    <div
        v-if="detections.length || time"
        class="detections-list has-cursor-default"
        :class="{ 'has-wrap': wrap }"
    >
        <be-detection-time
            v-if="time"
            :time="time"
            class="detections-list__item"
        />
        <be-detection
            ref="detectionItem"
            v-for="(detection, index) in displayDetections"
            :key="`displayDetection${index}`"
            class="detections-list__item"
            :class="{ 'has-cursor-pointer': clickable }"
            :detection="detection"
            :decimal="decimal"
            :has-prediction-confirmation="hasPredictionConfirmation"
            :row-type="rowType"
            :display-orientation="displayOrientation"
            :display-model="displayModel"
            :is-media-image-popup="isMediaImagePopup"
            :display-tags="displayTags"
            :display-tags-violation="displayTagsViolation"
            :display-confidence-label="displayConfidenceLabel"
            @click="onClick"
            @click-prediction="onPredictionClick"
            @close-prediction="onPredictionClose"
        />
        <b-tooltip
            class="detections-list__tooltip"
            v-if="hasExtraDetections"
            type="is-grey"
            :triggers="['click']"
            :auto-close="['outside', 'escape']"
            position="is-right"
        >
            <template v-slot:content>
                <div class="detections-list__extra">
                    <be-detection
                        v-for="(detection, index) in extraDetections"
                        :key="`displayDetection${index}`"
                        class="detections-list__item"
                        :class="{ 'has-cursor-pointer': clickable }"
                        :detection="detection"
                        :decimal="decimal"
                        :has-prediction-confirmation="hasPredictionConfirmation"
                        :row-type="rowType"
                        :display-orientation="displayOrientation"
                        :display-model="displayModel"
                        :is-media-image-popup="isMediaImagePopup"
                        :display-tags="displayTags"
                        :display-tags-violation="displayTagsViolation"
                        :display-confidence-label="displayConfidenceLabel"
                        @click="onClick"
                        @click-prediction="onPredictionClick"
                        @close-prediction="onPredictionClose"
                    />
                </div>
            </template>
            <button
                type="button"
                class="detections-list__cta"
            >
                &hellip;
            </button>
        </b-tooltip>
    </div>
</template>

<script>
import { get } from 'lodash';
import BeDetection from '@/components/global/BeDetection';
import BeDetectionTime from '@/components/global/BeDetectionTime';

export default {
    components: {
        BeDetectionTime,
        BeDetection,
    },

    props: {
        clickable: {
            type: Boolean,
            default: false,
        },
        wrap: {
            type: Boolean,
            default: false,
        },
        detections: {
            type: Array,
            default: () => [],
        },
        rowType: {
            type: String,
            default: '',
        },
        decimal: {
            type: Boolean,
            default: false,
        },
        time: {
            type: String,
            default: null,
        },
        extra: {
            type: Number,
            default: 2,
        },
        hasPredictionConfirmation: {
            type: Boolean,
            default: false,
        },
        displayOrientation: {
            type: Boolean,
            default: false,
        },
        displayModel: {
            type: Boolean,
            default: false,
        },
        displayTags: {
            type: Boolean,
            default: false,
        },
        displayTagsViolation: {
            type: Boolean,
            default: false,
        },
        displayConfidenceLabel: {
            type: Boolean,
            default: true,
        },
        isMediaImagePopup: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            showExtraDetections: false,
        };
    },

    computed: {
        displayDetections() {
            return this.extra > 0 ? this.uniqueAllDetections.slice(0, this.extra) : this.uniqueAllDetections;
        },

        extraDetections() {
            if (!this.hasExtraDetections) {
                return [];
            }
            return this.uniqueAllDetections.slice(this.displayDetections.length);
        },

        allDetections() {
            return this.detections;
        },

        uniqueAllDetections() {
            const uniqueAllDetections = this.detections.reduce((acc, curr) => {
                const tags = get(curr, 'tags', []);
                const key = `${curr.detection.toLowerCase()}-${tags.join('-').toLowerCase()}`;
                if (acc[key]) {
                    acc[key].count += 1;
                    // compute average confidence
                    // eslint-disable-next-line vue/max-len
                    acc[key].confidence = (acc[key].confidence * (acc[key].count - 1) + curr.confidence) / acc[key].count;
                } else {
                    acc[key] = { ...curr, count: 1 };
                }
                acc[key].ids = [...acc[key]?.ids || [], curr.id];
                return acc;
            }, {});
            return Object.values(uniqueAllDetections);
        },

        hasExtraDetections() {
            return this.extra > 0 && this.displayDetections.length < this.uniqueAllDetections.length;
        },
    },

    methods: {
        onClick(detection) {
            if (this.clickable) {
                this.$emit('click-detection', detection);
            }
        },

        onPredictionClick(detection) {
            // close other tooltips before firing the event
            if (this.$refs.detectionItem && this.$refs.detectionItem.length) {
                this.$refs.detectionItem.forEach((ref) => {
                    const refId = get(ref, 'detection.id', null);
                    if (refId !== detection.id) {
                        ref.closeTooltip();
                    }
                });
            }
            this.$emit('click-prediction', detection);
        },

        onPredictionClose() {
            this.$emit('close-prediction');
        },

        toggleExtraDetections(force = null) {
            this.showExtraDetections = force === null ? !this.showExtraDetections : force;
        },
    },
};
</script>

<style lang="scss" scoped>
.detections-list {
    display: flex;
    margin-left: -3px;
    margin-right: -3px;
    position: relative;

    &.has-wrap {
        flex-wrap: wrap;
    }

    &__item {
        margin: 3px;
    }

    &__cta {
        @include button-reset;
        margin: 3px;
        height: 24px;
        width: 30px;
        background: rgba($black-bis, .8);
        border-radius: $radius;
        color: $white;
        font-weight: $weight-bold;
        letter-spacing: 0.02em;
    }

    &__tooltip {
        ::v-deep .tooltip-content {
            padding: .75rem;

            &:before {
                display: none;
            }
        }
    }

    &__extra &__item {
        margin: 0 0 .5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    //&__extra {
    //    position: absolute;
    //    left: 100%;
    //    width: 200px;
    //    padding: 16px;
    //    border-radius: $radius-large;
    //    background: $grey;
    //    border: 1px solid $grey-darker;
    //}
}
</style>
