<template>
    <mgl-marker
        :coordinates="latlng"
        :draggable="false"
        :class="markerClasses"
    >
        <div slot="marker">
            <div
                class="map-marker"
                ref="marker"
                @mouseenter="onMarkerHover"
                @mouseleave="onMarkerLeave"
                @focusin="onMarkerHover"
                @focusout="onMarkerLeave"
            >
                <div class="map-marker__icon" />
                <div v-if="showMarkerInfo && !isPopupOpened" class="map-marker__info">
                    <div
                        v-for="(item, index) in items"
                        :key="`item${index}`"
                        :class="index ? 'mt-1' : ''"
                    >
                        <be-confidence
                            :count="item.count"
                            :object-type="getObjectType(item)"
                            :confidence="item.confidence"
                        />
                    </div>
                </div>
            </div>
        </div>
        <mgl-popup
            v-if="hasPopup"
            :showed="isPopupOpened"
            ref="popup"
            :close-button="false"
            @open="popupOpen"
            @close="popupClose"
            @keydown.enter="togglePopup"
            tabindex="0"
            :offset="[0, 0]"
        >
            <div class="map-marker__popup">
                <figure
                    class="map-marker__popup__fig mb-2 has-cursor-pointer"
                    @click="viewFull()"
                    @keydown.enter="viewFull()"
                    tabindex="0"
                >
                    <img v-if="currentFullItem" :src="currentFullItem.image_file" alt="" class="is-block" />
                    <b-loading v-else :active="true" :is-full-page="false" :can-cancel="false" />
                </figure>
                <b-button
                    class="be-button is-smaller is-primary"
                    expanded
                    @click="viewFull()"
                >
                    View full image
                </b-button>
                <div class="map-marker__popup__confidences mt-2">
                    <be-confidence
                        v-for="(item, index) in items"
                        :key="`item-popup${index}`"
                        :count="item.count"
                        :object-type="objectType"
                        :confidence="item.confidence"
                        :clickable="isMulti"
                        class="map-marker__popup__confidence"
                        :class="currentItem.id === item.id ? 'is-active' : ''"
                        @click="onConfidenceClick(item)"
                    />
                </div>
            </div>
        </mgl-popup>
    </mgl-marker>
</template>

<script>
import { MglMarker, MglPopup } from 'v-mapbox';
import { mapState } from 'vuex';
import BeConfidence from '@/components/global/BeConfidence';
import { getMauiObjectLabel, getMauiSingleObjectClass } from '@/utils/formatters/maui63/labels';

export default {
    components: {
        BeConfidence,
        MglMarker,
        MglPopup,
    },

    props: {
        marker: {
            type: Object,
            required: true,
        },
        hasPopup: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            currentItem: this.marker.properties.item,
            showMarkerInfo: false,
            isPopupOpened: false,
            markerClasses: [],
        };
    },

    watch: {
        isPopupOpened(val) {
            if (val) {
                this.currentItem = this.item;
                this.$emit('popup-opened', this.currentItem);
                this.$nextTick(() => {
                    this.$refs.popup.popup.setMaxWidth('320px');
                });
            } else {
                this.currentItem = {};
                this.$emit('popup-closed');
            }
        },
    },

    computed: {
        ...mapState('media/images', ['data']),

        isMulti() {
            return this.items.length > 1;
        },

        currentFullItem() {
            return this.data[this.currentItem.image];
        },

        latlng() {
            return [
                this.item.lng,
                this.item.lat,
            ];
        },

        items() {
            return this.marker.properties.items;
        },

        item() {
            return this.items[0];
        },

        objectType() {
            return getMauiObjectLabel({
                objectClass: getMauiSingleObjectClass({ row: this.item }),
            });
        },
    },

    methods: {
        popupOpen() {
            this.isPopupOpened = true;
        },

        popupClose() {
            this.isPopupOpened = false;
        },

        togglePopup() {
            this.isPopupOpened = !this.isPopupOpened;
        },

        viewFull() {
            this.popupClose();
            this.$emit('click', this.currentItem);
        },

        getObjectType(row) {
            return getMauiObjectLabel({
                objectClass: getMauiSingleObjectClass({ row }),
            });
        },

        onMarkerHover() {
            this.$nextTick(() => {
                if (this.$refs.marker && this.$refs.marker.parentNode) {
                    this.$refs.marker.parentNode.style['z-index'] = 1000;
                }
                this.showMarkerInfo = true;
            });
            this.$emit('enter', this.item);
        },

        onMarkerLeave() {
            this.$nextTick(() => {
                if (this.$refs.marker && this.$refs.marker.parentNode) {
                    this.$refs.marker.parentNode.style['z-index'] = 1;
                }
                this.showMarkerInfo = false;
            });
            this.$emit('leave', this.item);
        },

        onConfidenceClick(item) {
            this.currentItem = item;
            this.$emit('confidence-click', this.currentItem);
        },
    },
};
</script>

<style lang="scss" scoped>
.map-marker {
    display: block;
    position: relative;
    cursor: pointer;

    &__popup {
        width: 300px;

        &__fig {
            position: relative;
            min-height: 120px;
        }

        &__confidences {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__confidence {
            margin: 0 3px 3px 0;
            opacity: .5;
            @include transition-button;
            &.is-active,
            &:hover {
                opacity: 1;
            }
        }
    }

    &__icon {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: $primary;
        cursor: pointer;
    }

    &__info {
        cursor: pointer;
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 5px;
        text-align: left;
        background-color: $grey-darker;
        color: $white;
        padding: 5px;
        border-radius: $radius;
    }
}
</style>
