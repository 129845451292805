<template>
    <div class="codeview">
        <div class="codeview__preview">
            <code class="codeview__preview__code">{{ codePreview }}</code>
            <b-button
                v-if="hasPopup"
                class="be-button is-grey codeview__showcode"
                @click="isOpened = true"
                size="is-small"
                icon-left="code-json"
            />
        </div>
        <b-modal
            v-if="isOpened && hasPopup"
            :active.sync="isOpened"
            :destroy-on-hide="true"
            has-modal-card
            trap-focus
            scroll="keep"
            :width="600"
        >
            <div class="modal-card is-narrower">
                <div class="modal-card-body">
                    <template v-if="isJsonString">
                        <div v-html="json" />
                    </template>
                    <template v-else>
                        <div v-html="json" />
                    </template>
                </div>
            </div>
            <footer class="modal-card-foot">
                <b-button class="is-outline" @click="isOpened = false">
                    Close
                </b-button>
            </footer>
        </b-modal>
    </div>
</template>

<script>
import { isString, truncate } from 'lodash';

export default {
    props: {
        json: {
            type: [String, Object],
            required: true,
        },
    },

    data() {
        return {
            isOpened: false,
        };
    },

    computed: {
        hasPopup() {
            if (this.isShort) {
                return false;
            }
            return true;
        },

        isJsonString() {
            return isString(this.json);
        },

        isShort() {
            return this.isJsonString && this.json.trim().length < 20;
        },

        codePreview() {
            if (this.isShort) {
                return this.json;
            }

            if (this.isJsonString) {
                return truncate(this.json, { length: 20 });
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.codeview {
    &__preview {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__code {
            margin-right: 10px;
        }
    }
}
</style>
