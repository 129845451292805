import observationFilters from './observationFilters';
import riskFilters from './riskFilters';
import explorer from './explorer';
import summary from './summary';

const state = {
    filtersVisible: true,
};

const getters = {};

const mutations = {
    toggleFilters(state, force = null) {
        state.filtersVisible = force === null ? !state.filtersVisible : force;
    },
};
const actions = {
    resetState({ commit }) {
        commit('riskFilters/resetState');
        commit('observationFilters/resetState');
        commit('explorer/resetState');
        commit('toggleFilters', false);
    },

    resetFilters({ commit }) {
        commit('riskFilters/resetFilters');
        commit('observationFilters/resetFilters');
    },

    resetFiltersState({ commit }) {
        commit('riskFilters/resetState');
        commit('observationFilters/resetState');
    },

    init({ dispatch }) {
        return Promise.all([
            dispatch('observationFilters/init'),
            dispatch('riskFilters/init'),
        ]);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        observationFilters,
        riskFilters,
        explorer,
        summary,
    },
};
