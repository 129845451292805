import { isString } from 'lodash';
import { isError, isErrorWithProblemDetails } from './is';
import { BeNoticeProgrammatic as BeNotice } from '../plugins/be-notice';

export function errorHandler(...args) {
    const error = args[0];
    let message = '';

    if (isString(error)) {
        message = error;
    } else if (isErrorWithProblemDetails(error)) {
        message = error.response.data.title;
    } else if (isError(error)) {
        ({ message } = error);
    }

    BeNotice.open({
        message,
        type: 'is-danger',
        position: 'is-top-right',
        duration: 5000,
    });

    // hook the error logger here if needed
    if (process.env.NODE_ENV === 'development') {
        console.error('[BIGEYES ERROR]', ...args);
    }
}
