import qs from 'qs';

export const API_URL = process.env.VUE_APP_ALPR_API_URL;

export const API_VERSION = {
    v1: 'api/v1',
    v2: 'api/v2',
};

export function cleanUrlPath({ path }) {
    return `/${path.replace(/^\/|\/$/g, '')}/`;
}

export function getApiUrl({ path, query = '', apiVersion = API_VERSION.v1 }) {
    // ensure we have slashes
    // SAFARI WANTS THE LINK TO END WITH /
    const suffix = cleanUrlPath({ path });

    let url = `${API_URL}/${apiVersion}${suffix}`;

    if (query) {
        url += `?${qs.stringify(query)}`;
    }

    return url;
}

export function downloadFileFromApiResponse({ response, filename }) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}
