import { isArray, isEmpty } from 'lodash';
import { convertApiStringArr } from '@/utils/str';

export function mauiDetectionsTransformerRow(row) {
    return {
        ...row,
        confidences: convertApiStringArr(row.confidence),
    };
}

export function mauiDetectionsTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => mauiDetectionsTransformerRow(item));
}

export function mauiMapDetectionsTransformerRow(row) {
    return {
        ...row,
        confidences: convertApiStringArr(row.confidence),
    };
}

export function mauiMapDetectionsTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => mauiMapDetectionsTransformerRow(item));
}

export function mauiMapDetectionsCsvTransformerRow(row) {
    return {
        ID: row.id,
        Date: row.datestamp,
        Count: row.count || 1,
        Confidence: row.confidence,
        Lat: row.lat,
        Lng: row.lng,
        Image: row.image,
    };
}

export function mauiMapDetectionsCsvTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => mauiMapDetectionsCsvTransformerRow(item));
}
