<template>
    <div class="webhooks">
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot>
                    Webhooks
                </template>
                <template v-slot:subheading>
                    Manage and add webhooks
                </template>
                <template v-slot:headingCta>
                    <b-button
                        tag="router-link"
                        to="webhooks/new"
                        type="is-primary"
                        class="be-button is-small"
                        rounded
                        icon-left="plus"
                    >
                        Add New
                    </b-button>
                </template>
            </be-page-header>
            <enterprise-banner v-if="$store.getters['user/displayEnterpriseBanner']" />
            <be-widget :has-padding="true" :is-loading="isLoading" :has-table="true">
                <template v-slot:content>
                    <b-table
                        v-if="tableData.length"
                        :data="tableData"
                        :hoverable="true"
                        class="is-seamless"
                    >
                        <b-table-column label="Name" v-slot="props">
                            {{ props.row.name || props.row.url }}
                        </b-table-column>
                        <b-table-column label="Last Updated" width="200" v-slot="props">
                            {{ props.row.last_updated | dateTimeHuman }}
                        </b-table-column>
                        <b-table-column label=" " width="120" header-class="has-text-right" v-slot="props">
                            <p class="has-text-right has-text-weight-bold">
                                <router-link :to="`/dashboard/webhooks/${props.row.id}`">
                                    Edit
                                </router-link>
                            </p>
                        </b-table-column>
                    </b-table>
                    <be-pagination
                        key="webhooks"
                        :current-page.sync="currentPage"
                        :per-page.sync="perPage"
                        :count="webhooksCount"
                    />
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BePagination from '@/components/global/BePagination';
import formattersMixin from '@/mixins/formattersMixin';
import EnterpriseBanner from '@/components/local/informational/EnterpriseBanner';

export default {
    components: {
        EnterpriseBanner,
        BePagination,
    },

    mixins: [formattersMixin],

    computed: {
        ...mapState('webhooks', ['webhooks', 'webhooksCount']),
        ...mapGetters('webhooks', ['isLoading']),

        tableData() {
            return this.webhooks || [];
        },

        perPage: {
            get() {
                return this.$store.state.webhooks.perPage;
            },
            set(perPage) {
                return this.setPerPage({ perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.webhooks.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.setPage({ page });
            },
        },
    },

    watch: {
        currentPage() {
            this.getWebhooks({});
        },
        perPage() {
            this.getWebhooks({});
        },
    },

    methods: {
        ...mapActions('webhooks', ['getWebhooks', 'updateWebhook']),
        ...mapMutations('webhooks', ['setPerPage', 'setPage']),
    },

    mounted() {
        this.getWebhooks({});
    },
};
</script>
