import { mapState } from 'vuex';
import Mapbox from 'mapbox-gl';
import { MglMap } from 'v-mapbox';

export default {
    components: {
        MglMap,
    },

    computed: {
        ...mapState('dashboard/layout', ['navOpened']),
    },

    watch: {
        navOpened() {
            this.resizeMap();
        },
    },

    methods: {
        resizeMap() {
            this.$nextTick(() => {
                if (this.$refs.map && this.$refs.map.map) {
                    this.$refs.map.map.resize();
                }
            });
        },
    },

    created() {
        this.mapbox = Mapbox;
    },
};
