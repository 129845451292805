<template>
    <div class="data-explorer">
        <overview-filters
            :filter-confidence="true"
            :is-loading="isLoading"
        />
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Data Explorer
                </template>
                <template v-slot:subheading>
                    <be-detections-heading />
                </template>
                <template v-slot:cta>
                    <b-button
                        type="is-primary"
                        rounded
                        expanded
                        class="be-button is-small"
                        @click.prevent="loadAndExportData()"
                        icon-left="export"
                        :loading="isLoadingForExport"
                        :disabled="!hasResults"
                    >
                        Export
                    </b-button>
                </template>
            </be-page-header>
            <div class="columns is-multiline">
                <div class="column is-12">
                    <system-totals :can-load-data="true" />
                </div>
                <div class="column is-12">
                    <data-explorer-table-widget
                        ref="tableWidget"
                        :can-load-data="canLoadData"
                        :load-data-page="null"
                        :load-data-per-page="null"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getApiUrl } from '@/utils/api';
import DataExplorerTableWidget from '@/components/widgets/maui63/dataTables/DataExplorerTableWidget';
import SystemTotals from '@/components/widgets/maui63/SystemTotals';
import BeDetectionsHeading from '@/components/global/BeDetectionsHeading';
import OverviewFilters from '@/components/global/OverviewFilters';

export default {
    name: 'DataExplorer',

    components: {
        BeDetectionsHeading,
        OverviewFilters,
        DataExplorerTableWidget,
        SystemTotals,
    },

    data() {
        return {
            canLoadData: true,
        };
    },

    computed: {
        ...mapGetters({
            hasResults: 'maui/detections/hasResults',
            isLoadingForExport: 'maui/detections/map/isLoading',
            isLoadingDetections: 'maui/detections/isLoading',
            isLoadingSummary: 'explorer/detections/unique/summary/isLoading',
        }),

        isLoading() {
            return this.isLoadingDetections
                || this.isLoadingSummary;
        },
    },

    mounted() {
        this.populateFilters();
    },

    methods: {
        ...mapActions('maui/detections/map', ['exportData', 'loadData']),

        populateFilters() {
            if (!this.$route.query.tag) {
                return Promise.resolve(true);
            }

            const promises = this.$route.query.tag.split(',')
                .map((tagSlug) => this.$http(getApiUrl({ path: `tags/${tagSlug}` }))
                    .then(({ data }) => data));

            return Promise.all(promises).then((tags) => {
                this.$store.commit('dataFilters/setSelectedTags', { tags });
            });
        },

        loadAndExportData() {
            return this.loadData({})
                .then(() => this.exportData())
                .catch(() => {
                });
        },
    },
};
</script>
