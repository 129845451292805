export default {
    computed: {
        imagesPreviewEnabled: {
            get() {
                return this.$store.state.dashboard.layout.dataExplorerImagesPreviewEnabled;
            },

            set(value) {
                this.$store.commit('dashboard/layout/toggleDataExplorerImagesPreviewEnabled', value);
            },
        },
        fullDatesEnabled: {
            get() {
                return this.$store.state.dashboard.layout.dataExplorerFullDatesEnabled;
            },

            set(value) {
                this.$store.commit('dashboard/layout/toggleDataExplorerFullDatesEnabled', value);
            },
        },

    },
};
