<template>
    <div class="request-error-message message is-danger has-text-danger">
        <p class="request-error-message__content px-4 py-2">
            <slot />
        </p>
        <b-button
            v-if="closable"
            class="request-error-message__close"
            icon-right="close"
            @click="$emit('close')"
        />
    </div>
</template>

<script>
export default {
    props: {
        closable: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.request-error-message {
    display: flex;
    align-items: center;

    &__content {
        flex: 1;
        font-size: 14px;
    }

    &__close {
        margin-left: 20px;
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        display: inline-flex;
        background-color: rgba($white, .5);
        color: $danger;
        align-items: center;
        justify-content: center;
        border-color: transparent;
    }
}
</style>
