<template>
    <div class="map-info">
        <div class="map-info__cta">
            <b-button
                :icon-left="isOpened ? 'close' : 'information-variant'"
                @click="toggle()"
                size="is-small"
                rounded
            />
        </div>
        <be-widget v-if="isOpened" :is-loading="isLoading">
            <template v-slot:content>
                <div class="map-info__row">
                    <div class="map-info__row__value">
                        <template v-if="confidence">
                            <be-confidence-single :confidence="confidence" />
                        </template>
                        <template v-else>&ndash;</template>
                    </div>
                    <div class="map-info__row__label label-table">
                        Confidence
                    </div>
                </div>
                <div class="map-info__row">
                    <div class="map-info__row__value">
                        <b-tag v-if="detections" class="map-info__row__tag has-cursor-default">
                            {{ detections }}
                        </b-tag>
                        <template v-else>&ndash;</template>
                    </div>
                    <div class="map-info__row__label label-table">
                        Detections
                    </div>
                </div>
                <div class="map-info__row">
                    <div class="map-info__row__value">
                        <b-tag v-if="images" class="map-info__row__tag has-cursor-default">
                            {{ images }}
                        </b-tag>
                        <template v-else>&ndash;</template>
                    </div>
                    <div class="map-info__row__label label-table">
                        Images
                    </div>
                </div>

                <template v-if="allTime">
                    <div class="map-info__row">
                        <div class="map-info__row__value">
                            <b-tag class="map-info__row__tag has-cursor-default">
                                All Time
                            </b-tag>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="map-info__row">
                        <div class="map-info__row__value">
                            <b-tag v-if="dateFrom" class="map-info__row__tag has-cursor-default">
                                {{ dateFrom }}
                            </b-tag>
                            <template v-else>&ndash;</template>
                        </div>
                        <div class="map-info__row__label label-table">
                            Date From
                        </div>
                    </div>
                    <div class="map-info__row">
                        <div class="map-info__row__value">
                            <b-tag v-if="dateTo" class="map-info__row__tag has-cursor-default">
                                {{ dateTo }}
                            </b-tag>
                            <template v-else>&ndash;</template>
                        </div>
                        <div class="map-info__row__label label-table">
                            Date Through
                        </div>
                    </div>
                </template>
                <div class="mt-4">
                    <b-button
                        type="is-primary"
                        :disabled="!canExportCsv"
                        rounded
                        expanded
                        class="be-button is-small"
                        @click.prevent="$emit('export')"
                        icon-left="export"
                    >
                        Export Data
                    </b-button>
                </div>
            </template>
        </be-widget>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { uniqBy } from 'lodash';
import BeConfidenceSingle from '@/components/global/BeConfidenceSingle';

export default {
    components: { BeConfidenceSingle },
    data() {
        return {
            isOpened: true,
        };
    },

    computed: {
        ...mapGetters('maui/detections/map', ['formattedApiResults', 'isLoading', 'canExportCsv']),
        ...mapGetters('dataFilters', ['getDateRange', 'getPeriodLabel']),
        ...mapState('dataFilters', ['allTime']),

        startDate() {
            return this.$date(this.getDateRange.start);
        },

        endDate() {
            return this.$date(this.getDateRange.end);
        },

        confidence() {
            const totalConfidence = this.formattedApiResults.reduce((count, row) => {
                const confidence = Number(row.confidence);
                return count + (confidence || 0);
            }, 0);

            const number = totalConfidence / this.formattedApiResults.length;
            return number;
            // ? new Intl.NumberFormat().format(
            //     Math.floor(number * 100) / 100,
            // ) : 0;
        },

        detections() {
            const number = this.formattedApiResults.length;
            return number
                ? new Intl.NumberFormat().format(number)
                : 0;
        },

        images() {
            const number = uniqBy(this.formattedApiResults, 'image').length;
            return number
                ? new Intl.NumberFormat().format(number)
                : 0;
        },

        dateFrom() {
            return this.startDate.format('MMM, D, YYYY');
        },

        dateTo() {
            return this.endDate.format('MMM, D, YYYY');
        },
    },

    methods: {
        toggle() {
            this.isOpened = !this.isOpened;
        },
    },
};
</script>

<style lang="scss" scoped>
.map-info {
    position: absolute;
    @include z-index(map-list, 1);
    right: 30px;
    top: 30px;
    width: 250px;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__value {
            font-weight: $weight-normal;
            font-size: $size-7;
            color: $text;
            letter-spacing: 0.01em;
        }

        &__tag {
            font-weight: $weight-bold;
            color: $white;
            background: rgba($black-bis, .8);
            min-width: 54px;
        }
    }

    &__row + &__row {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid rgba($border, .4);
    }

    &__cta {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate3d(50%, -50%, 0);
        @include z-index(above);

        button {
            padding: 0;
            width: 30px;
            height: 30px;
            box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);
        }
    }
}
</style>
