<template>
    <div>
        <roi-camera-row
            v-for="(roi, index) in currentRois"
            :key="`roi-${roi.id}-${index}`"
            ref="rows"
            :roi="roi"
            :camera-id="camera.id"
        />
        <div>
            <b-button @click.prevent="addRoi">
                Add ROI
            </b-button>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import noticesMixin from '@/mixins/noticesMixin';
import RoiCameraRow from '@/components/local/sites/partials/RoiCameraRow';

export default {
    components: { RoiCameraRow },
    mixins: [
        validationMixin,
        noticesMixin,
    ],

    props: {
        camera: {
            type: Object,
            required: true,
        },
        rois: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            currentRois: [...this.rois],
        };
    },

    methods: {
        isDirty() {
            return false;
        },

        addRoi() {
            this.currentRois.push({
                roi: [],
                tags: [],
                roi_type: 0,
            });
        },

        validate() {
            return this.$refs.rows.reduce((curr, row) => (
                curr && row.isValid()
            ), true);
        },

        getSaveData() {
            return this.$refs.rows.map((row) => row.getSaveData());
        },
    },
};
</script>
