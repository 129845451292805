<template>
    <be-widget class="detailed-usage" :has-table="true" :is-loading="isLoading">
        <template v-slot:content>
            <detections-table
                :can-load-data="canLoadData"
                :load-data-page="loadDataPage"
                :load-data-per-page="loadDataPerPage"
            />
        </template>
    </be-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import DetectionsTable from '@/components/widgets/maui63/dataTables/DetectionsTable';

export default {
    name: 'DataExplorerTableWidget',

    props: {
        canLoadData: {
            type: Boolean,
            default: false,
        },
        loadDataPage: {
            type: Number,
            default: 1,
        },
        loadDataPerPage: {
            type: Number,
            default: null,
        },
    },

    components: {
        DetectionsTable,
    },

    computed: {
        ...mapGetters('maui/detections', ['isLoading']),
    },
};
</script>
