<template>
    <mgl-marker
        :coordinates="coordinates"
        :draggable="false"
    >
        <div slot="marker">
            <div class="map-marker">
                <div class="map-marker__label" v-if="label" v-text="label" />
            </div>
        </div>
    </mgl-marker>
</template>

<script>
import { MglMarker } from 'v-mapbox';

export default {
    components: {
        MglMarker,
    },

    props: {
        marker: {
            type: Object,
            required: true,
        },
    },

    computed: {
        coordinates() {
            return this.marker.geometry.coordinates;
        },

        label() {
            return this.marker.properties.point_count_abbreviated;
        },
    },
};
</script>

<style lang="scss" scoped>
.map-marker {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 4px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;

    &__label {
        text-align: center;
        line-height: 1;
        color: $grey;
        font-size: 12px;
        font-weight: $weight-bold;
    }
}
</style>
