let beNotices = [];

export default {
    methods: {
        displayWarningNotice({ message, ...args }) {
            this.displayNotice({
                message,
                icon: 'alert-outline',
                type: 'is-warning',
                position: 'is-top-right',
                queue: false,
                ...args,
            });
        },

        displaySuccessNotice({ message, ...args }) {
            this.displayNotice({
                message,
                icon: 'check-circle-outline',
                type: 'is-success',
                position: 'is-top-right',
                queue: false,
                ...args,
            });
        },
        displayErrorNotice({ message, ...args }) {
            this.displayNotice({
                message,
                icon: 'alert-circle-outline',
                type: 'is-danger',
                position: 'is-top-right',
                queue: false,
                ...args,
            });
        },

        closeNotices() {
            if (beNotices && beNotices.length) {
                beNotices.forEach((notice) => notice.close());
            }
            beNotices = [];
        },

        displayNotice({ message, closeOthers = false, ...args }) {
            if (closeOthers) {
                this.closeNotices();
            }

            const notice = this.$buefy.beNotice.open({
                message,
                ...args,
            });

            beNotices.push(notice);
        },
    },
};
