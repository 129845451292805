import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateAsUtcUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import summary from './summary';

const state = getDefaultDataState({ hasPagination: false });

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    apiResults(state) {
        return state.data;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
};

const actions = {
    loadData({ state, commit, rootState, rootGetters }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
        });

        const path = buildStartEndDateAsUtcUrl({
            path: 'explorer/speed',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        return axios({ url: getApiUrl({ path }), params })
            .then((response) => {
                commit('setStatus', { status: 'success' });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        summary,
    },
};
