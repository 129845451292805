<template>
    <div class="integration is-hubspot">
        <be-integration-header class="integration__header">
            <template v-slot:heading>
                {{ heading }}
            </template>
            <template v-slot:back>
                <b-button
                    tag="router-link"
                    type="is-light"
                    to="/dashboard/marketplace/apps"
                    class="be-button is-back"
                    icon-left="chevron-left"
                />
            </template>
        </be-integration-header>

        <div class="app-container">
            <div class="content">
                <p>Integration coming soon</p>
            </div>
        </div>
    </div>
</template>

<script>

import { find } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    props: {
        app: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters('marketplace', ['allApps']),
        heading() {
            const app = find(this.allApps, { slug: this.app });

            if (!app) {
                return 'App Settings';
            }

            return `${app.label} Settings`;
        },
    },
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
