export function getDetectionsByTagUrl({ path = '/dashboard/vehicle-explorer', tags = [] }) {
    let tag = null;
    if (tags.length) {
        tag = tags.reduce((slug, tag) => {
            if (tag.editable) {
                return slug;
            }
            return tag.slug;
        }, null);

        if (!tag) {
            tag = tags[0].slug;
        }
    }

    return { path, query: { tag } };
}
