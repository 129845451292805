<template>
    <b-tag class="has-cursor-default">
        <template v-if="list && list.built_in">
            <i>{{ description }} (Built-in)</i>
        </template>
        <template v-else>
            {{ description }}
        </template>
    </b-tag>
</template>

<script>
import { find } from 'lodash';
import { mapState } from 'vuex';

export default {
    props: {
        alert: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState('alerts/lists', ['allLists']),

        list() {
            return find(this.allLists, { id: this.alert.alert_list });
        },

        description() {
            return this.list ? this.list.description : '';
        },
    },
};
</script>
