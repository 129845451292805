<template>
    <manage-popup
        class="manage-popup"
        :active="active"
        width="wide"
        @close="maybeClose"
    >
        <template v-slot:title>
            {{ popupTitle }}
        </template>
        <template v-slot:body>
            <be-request-error-message v-if="requestErrorMessage" @close="requestErrorMessage = null">
                {{ requestErrorMessage }}
            </be-request-error-message>
            <component
                ref="site"
                v-if="siteType != null"
                :is="manageComponent"
                :site="site"
                :site-type="siteType"
                @request-error="requestErrorMessage = $event"
            />
            <manage-site-popup-selector v-else v-model="siteTypeModel" />
        </template>
        <template v-slot:footer>
            <template v-if="siteType != null">
                <button
                    v-if="isEdit"
                    type="button"
                    class="be-button-link is-danger"
                    @click.prevent="remove"
                    :disabled="isUpdating"
                    :loading="isRemoving"
                >
                    Remove Site
                </button>
                <b-button
                    class="be-button is-wider is-primary"
                    @click.prevent="save"
                    :disabled="isRemoving"
                    :loading="isUpdating"
                >
                    Save Site
                </b-button>
            </template>
            <template v-else>
                <b-button
                    class="be-button is-wider is-primary"
                    @click.prevent="setSiteType()"
                    :disabled="siteTypeModel == null"
                >
                    Continue
                </b-button>
            </template>
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import BeRequestErrorMessage from '@/components/global/BeRequestErrorMessage';
import ManagePopup from '@/components/local/sites/ManagePopup';
import ManageSitePopupSelector from '@/components/local/sites/partials/ManageSitePopupSelector';
import ManageSitePopupCarpark from '@/components/local/sites/partials/ManageSitePopupCarpark';
import ManageSitePopupHealthSafety from '@/components/local/sites/partials/ManageSitePopupHealthSafety';
import noticesMixin from '@/mixins/noticesMixin';
import { SITE_VALIDATION_MESSAGES } from '@/utils/sites/sites';
import { SITE_TYPES } from '@/utils/sites/constants';

export default {
    components: {
        ManageSitePopupSelector,
        ManageSitePopupCarpark,
        ManageSitePopupHealthSafety,
        ManagePopup,
        BeRequestErrorMessage,
    },

    mixins: [
        noticesMixin,
        validationMixin,
    ],

    props: {
        active: {
            type: Boolean,
            required: true,
        },

        site: {
            type: Object,
            default: null,
        },
    },

    data() {
        let siteType = null;
        if (this.site) {
            siteType = this.site.site_type;
        }

        return {
            siteType,
            siteTypeModel: siteType,
            isRemoving: false,
            isUpdating: false,
            validationMessages: SITE_VALIDATION_MESSAGES,
            requestErrorMessage: null,
        };
    },

    computed: {
        isEdit() {
            return this.site && this.site.id;
        },
        isNew() {
            return !this.isEdit;
        },
        isLoading() {
            return this.isUpdating || this.isRemoving;
        },

        manageComponent() {
            return `manage-site-popup-${this.siteType === SITE_TYPES.HS ? 'health-safety' : 'carpark'}`;
        },

        popupTitle() {
            let prefix = this.isEdit ? 'Edit ' : 'Create ';
            switch (this.siteType) {
                case SITE_TYPES.CARPARK:
                    prefix += 'Carpark ';
                    break;
                case SITE_TYPES.HS:
                    prefix += 'Health & Safety ';
                    break;
                default:
                    break;
            }
            return `${prefix} Site`;
        },
    },

    methods: {
        setSiteType() {
            this.siteType = this.siteTypeModel;
        },

        ...mapActions('sites', ['createSite', 'updateSite', 'removeSite', 'updateSiteTags', 'uploadSiteFile']),

        save() {
            if (this.isLoading || !this.validate()) {
                return;
            }
            const saveData = this.getSaveData();
            if (!saveData) {
                return;
            }

            saveData.site.name = this.isNew ? `SITE: ${saveData.site.name}` : `${saveData.site.name}`;
            this.isUpdating = true;
            this[this.isNew ? 'create' : 'update'](saveData)
                .then(() => {
                    this.displaySuccessNotice({
                        message: this.isNew
                            ? 'Site added successfully'
                            : 'Site updated successfully',
                    });
                    this.close();
                })
                .catch((error) => {
                    this.requestErrorMessage = error.message;
                })
                .finally(() => {
                    this.isUpdating = false;
                });
        },

        validate() {
            if (!this.$refs.site) {
                this.requestErrorMessage = 'Cannot save site. Please reload the page and try again.';
                return false;
            }
            this.$refs.site.$v.$touch();
            if (this.$refs.site.$v.$invalid) {
                return false;
            }
            return true;
        },

        create(payload) {
            return this.createSite({
                data: payload.site,
            })
                .then(({ site }) => {
                    const promises = [this.updateTags({ site, tags: payload.tags })];
                    if (payload.file) {
                        promises.push(this.uploadSiteFile({ siteId: site.id, file: payload.file }));
                    }
                    return Promise.all(promises);
                });
        },

        update(payload) {
            return this.updateSite({
                siteId: this.site.id,
                data: payload.site,
            })
                .then(() => {
                    const promises = [this.updateTags({ tags: payload.tags })];
                    if (payload.file) {
                        promises.push(this.uploadSiteFile({ siteId: this.site.id, file: payload.file }));
                    }
                    return Promise.all(promises);
                });
        },

        updateTags({ site = null, tags = [] }) {
            let updateTags = [...tags];
            if (site && site.tags && site.tags.length) {
                updateTags = updateTags.concat(site.tags);
            }
            return this.updateSiteTags({
                siteId: site ? site.id : this.site.id,
                tags: updateTags,
            });
        },

        remove() {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this site?',
                type: 'is-danger',
                onConfirm: () => {
                    this.isRemoving = true;
                    this.removeSite({
                        siteId: this.site.id,
                    })
                        .then(() => {
                            this.removed();
                        })
                        .finally(() => {
                            this.isRemoving = false;
                        });
                },
            });
        },

        maybeClose() {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to exit site creation without saving?',
                onConfirm: () => this.close(),
            });
        },

        close() {
            this.resetData();
            this.resetValidation();
            this.$emit('close');
        },

        removed() {
            this.displaySuccessNotice({ message: 'Site removed successfully' });
            this.resetData();
            this.$emit('close');
            // redirect
            this.$router.push('/dashboard/sites');
        },

        getSaveData() {
            return this.$refs.site
                ? this.$refs.site.getSaveData()
                : null;
        },

        resetValidation() {
            if (this.$refs.site && this.$refs.site.$v) {
                this.$refs.site.$v.$reset();
            }
        },

        resetData() {
            let siteType = null;
            if (this.site && this.site.site_type) {
                siteType = this.site.site_type;
            }
            this.siteType = siteType;
            this.siteTypeModel = siteType;
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-popup {
    ::v-deep .modal-card-foot {
        justify-content: space-between;

        .be-button {
            margin-left: auto;
        }
    }
}
</style>
