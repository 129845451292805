import { isFinite } from 'lodash';

export function convertDwellTimeToObject({ time }) {
    let days = 0;
    let hours = 0;
    let minutes = 0;

    // the number is always in minutes
    let delta = Number(time);

    if (!isFinite(delta) || delta <= 0) {
        return { days, hours, minutes };
    }

    // calculate whole days
    days = Math.floor(delta / (24 * 60));
    delta -= days * (24 * 60);

    // calculate whole hours
    hours = Math.floor(delta / 60) % 24;
    delta -= hours * 60;

    // calculate whole minutes
    minutes = Math.floor(delta) % 60;
    delta -= minutes;

    return { days, hours, minutes };
}

export function pad(value) {
    return (value < 10 ? '0' : '') + value;
}

export function formatNumber(value, prependZero) {
    return prependZero
        ? pad(value)
        : value;
}

export function formatDwellTime({ time, inMinutes = true }) {
    const minutes = inMinutes ? time : time / 60;

    const object = convertDwellTimeToObject({ time: minutes });
    return Object.values(object).map((value) => (
        formatNumber(value, true)
    )).join(':');
}

export function convertDwellTimeObjectToMinutes({ dwell }) {
    if (!dwell) {
        return 0;
    }
    return dwell.days * 24 * 60
        + dwell.hours * 60
        + dwell.minutes;
}
