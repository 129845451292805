<template>
    <div>
        <b-loading active is-full-page />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    created() {
        const { id } = this.$route.params;
        this.loadDataRow({ id })
            .then((video) => {
                window.location.href = video.display_video_file;
            })
            .catch(() => {
                this.$router.push('/dashboard');
            });
    },

    methods: {
        ...mapActions('media/videos', ['loadDataRow']),
    },
};
</script>
