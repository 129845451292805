import { get } from 'lodash';

export const formatHSData = (data, { rootState }) => data.map((row) => [
    row?.confidence || null,
    get(rootState.media.images.data, `${row.image.id}.image_file`, null),
    row?.image?.camera?.site?.id || null,
    row?.image?.camera?.id || null,
    row?.image?.id || null,
    JSON.stringify(row ?? {}).replace(/,/g, ''),
]);

export const formatHSImageData = (data, { rootState }) => data.map((row) => [
    row?.detections[0]?.confidence || null,
    get(rootState.media.images.data, `${row.id}.image_file`, null),
    row?.camera?.site?.id || null,
    row?.camera?.id || null,
    row?.id || null,
    JSON.stringify(row ?? {}).replace(/,/g, ''),
]);

export const formatVehicleData = (data, { rootState }) => data.flatMap((row) => {
    const detections = row?.detections || [];
    const imageId = row?.image?.id || null;
    const confidence = row?.confidence || null;
    const imageFile = get(rootState.media.images.data, `${imageId}.image_file`, null);
    const siteId = row?.image?.camera?.site?.id || null;
    const cameraId = row?.image?.camera?.id || null;
    const date = row?.image?.time_created ? new Date(row?.image?.time_created).toISOString().split('T')[0] : null;
    const time = row?.image?.time_created ? new Date(row?.image?.time_created).toISOString().split('T')[1] : null;
    const siteName = row?.image?.camera?.site?.name || null;
    const cameraName = row?.image?.camera?.name || null;
    const direction = row?.detections?.orientation || null;
    const rowString = JSON.stringify(row ?? '').replace(/,/g, '');

    if (detections.length === 0) {
        return [[
            null,
            confidence,
            imageFile,
            siteId,
            cameraId,
            imageId,
            date,
            time,
            siteName,
            cameraName,
            direction,
            rowString,
        ]];
    }

    return detections.map((detection) => [
        detection?.type || 'UNKNOWN',
        detection?.detection || 'UNKNOWN',
        detection?.confidence || null,
        imageFile,
        siteId,
        cameraId,
        imageId,
        date,
        time,
        siteName,
        cameraName,
        detection?.is_plate ? detection?.orientation : null,
        rowString,
    ]);
});

export default {
    formatHSData,
    formatHSImageData,
    formatVehicleData,
};
