import { mapActions, mapState } from 'vuex';
import { find } from 'lodash';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isSiteLoading: false,
            showMore: false,
        };
    },

    computed: {
        ...mapState('sites', ['sitesFull', 'sitesCameras']),

        site() {
            return find(this.sitesFull, { id: this.item.id });
        },

        status() {
            if (!this.cameras.length) {
                return 'pending';
            }

            const cameras = this.cameras.reduce((arr, camera) => {
                const frequency = camera.event_frequency && camera.event_frequency.frequency
                    ? camera.event_frequency.frequency
                    : '';
                const lastSeen = camera.last_seen;
                if (!frequency || !lastSeen) {
                    return arr.concat([false]);
                }

                const date = this.$date(lastSeen);
                const matches = frequency.match(/^\d+/);
                const days = Number(matches[0]) || 1;

                if (date.add(days, 'day').isAfter(this.$date())) {
                    return arr.concat([true]);
                }

                return arr.concat([false]);
            }, []);

            const onlineCameras = cameras.filter((c) => c);
            if (onlineCameras.length === cameras.length) {
                return 'online';
            }

            return 'warning';
        },

        cameras() {
            if (!this.site || !this.site.id) {
                return [];
            }

            const cameras = find(this.sitesCameras, { id: this.site.id });

            return cameras && cameras.cameras ? cameras.cameras : [];
        },

        tags() {
            return this.site
                ? this.site.tags
                : [];
        },

        lastDetectionCamera() {
            if (!this.cameras.length) {
                return null;
            }

            if (this.cameras.length === 1) {
                return this.cameras[0];
            }

            // calculate here
            return this.cameras[0];
        },

        lastDetection() {
            return this.lastDetectionCamera && this.lastDetectionCamera.last_seen
                ? this.$date(this.lastDetectionCamera.last_seen)
                : null;
        },

        confidence() {
            const cameras = this.cameras.filter((c) => c.average_confidence !== null);
            if (!cameras.length) {
                return 0;
            }
            return cameras.reduce((a, camera) => (a + (camera.average_confidence || 0)), 0) / cameras.length || 0;
        },
    },

    methods: {
        ...mapActions('sites', ['getSite']),

        loadSite() {
            this.isSiteLoading = true;
            this.getSite({ siteId: this.item.id })
                .finally(() => {
                    this.isSiteLoading = false;
                });
        },

        toggleMore() {
            this.showMore = !this.showMore;
        },
    },
};
