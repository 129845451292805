<template>
    <div class="observations-date-times">
        <div v-if="formattedDates && formattedDates.length" class="observations-date-times__dates">
            <div
                v-for="date in formattedDates"
                :key="date.date"
                class="observations-date-times__date"
            >
                <div class="observations-date-times__date">
                    <button
                        type="button"
                        :class="{ 'is-active': selectedDate === date.date }"
                        @click="selectedDate = date.date"
                    >
                        <span>{{ date.date }}</span>
                    </button>
                </div>
            </div>
        </div>
        <p v-else class="observations-date-times__empty">No data available</p>

        <div v-if="formattedTimes" class="observations-date-times__times">
            <div
                v-for="time in formattedTimes"
                :key="`${selectedDate}-${time.time}`"
                class="observations-date-times__time"
            >
                <button
                    type="button"
                    :class="{ 'is-active': selectedTime === time.time }"
                    @click="selectedTime = time.time"
                >
                    <span>{{ time.displayTime }}</span>
                    <small v-if="time.count">{{ time.count }}</small>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('hs/observations/explorer', ['formattedDates', 'formattedTimes']),
        selectedDate: {
            get() {
                return this.$store.state.hs.observations.explorer.selectedDate;
            },
            set(date) {
                this.$store.commit('hs/observations/explorer/setSelectedDate', { date });
            },
        },
        selectedTime: {
            get() {
                return this.$store.state.hs.observations.explorer.selectedTime;
            },
            set(time) {
                this.$store.commit('hs/observations/explorer/setSelectedTime', { time });
            },
        },
    },
    watch: {
        formattedDates() {
            if (this.selectedDate === null) {
                this.selectedDate = this.formattedDates[this.formattedDates.length - 1].date;
            }
        },
        async selectedDate() {
            this.selectedTime = null;

            const results = await Promise.all(this.formattedTimes.map(
                (time) => this.getData({ date: this.selectedDate, time: time.time }),
            ));

            results.forEach((result, i) => {
                this.formattedTimes[i].count = result.length;
            });
        },
        selectedTime(val) {
            if (val) {
                this.search({ page: 1 });
            } else {
                this.resetSearch();
            }
        },
    },

    methods: {
        ...mapActions('hs/observations/explorer/detections', ['search', 'resetSearch', 'getData']),
    },
};
</script>

<style lang="scss" scoped>
.observations-date-times {
    &__empty {
        padding-top: 8px;
        padding-bottom: 8px;
        background: $grey-dark;
        color: $white;
        text-align: center;
        opacity: .7;
        font-size: 13px;
    }

    &__dates,
    &__times {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: $grey-dark;
        color: $white;
        width: 100%;
        overflow-x: scroll;
        border-radius: $radius;
        @include hide-scrollbar;
    }

    &__times {
        margin-top: 1.5rem;
    }

    &__date + &__date {
        margin-left: 5px;
    }

    &__time + &__time {
        margin-left: 5px;
    }

    button {
        @include button-reset;
        display: inline-flex;
        position: relative;
        color: $white;
        font-size: 14px;
        font-weight: $weight-bold;
        padding: 10px;
        border: 2px solid transparent;
        border-radius: $radius;

        &.is-active {
            background: rgba($primary, .7);
            border-color: $primary;
        }

        small {
            position: absolute;
            right: -5px;
            top: -5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            font-size: 12px;
            border-radius: 10px;
            background: $red;
            color: $white;
            pointer-events: none;
        }
    }
}
</style>
