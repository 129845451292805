import Vue from 'vue';
import { merge } from 'buefy/src/utils/helpers';
import { registerComponentProgrammatic } from 'buefy/src/utils/plugins';
import BeNotice from '@/components/global/BeNotice';

const BeNoticeProgrammatic = {
    open(options) {
        const params = typeof options === 'string'
            ? { message: options }
            : options;

        const defaultParam = {
            type: 'is-success',
            position: 'is-top-right',
        };

        const propsData = merge(defaultParam, params);

        const NoticeComponent = Vue.extend(BeNotice);
        return new NoticeComponent({
            el: document.createElement('div'),
            propsData,
        });
    },
};

const BeNoticePlugin = {
    install(Vue) {
        registerComponentProgrammatic(Vue, 'beNotice', BeNoticeProgrammatic);
    },
};

Vue.use(BeNoticePlugin);

export default BeNoticePlugin;

export { BeNoticeProgrammatic };
