/* eslint-disable no-param-reassign */

import Vue from 'vue';
import WindowSize from '../utils/window-size';
import { BREAKPOINTS } from '../utils/constants';

const eventBus = {
    install(Vue) {
        const windowSize = new WindowSize().init();

        Vue.prototype.$bus = new Vue({
            data() {
                return {
                    windowSize,
                    vh: window.innerHeight * 0.01,
                    breakpoints: {
                        ...BREAKPOINTS,
                    },
                };
            },

            computed: {
                isWidescreen() {
                    return this.windowSize.width >= this.breakpoints.widescreen;
                },
                isDesktop() {
                    return this.windowSize.width >= this.breakpoints.desktop;
                },
                isTouch() {
                    return this.windowSize.width < this.breakpoints.desktop;
                },
                isTablet() {
                    return this.windowSize.width >= this.breakpoints.tablet;
                },
                isMobile() {
                    return this.windowSize.width < this.breakpoints.tablet;
                },
                isMobileDrop() {
                    return this.isTouch;
                },
            },

            watch: {
                vh: {
                    immediate: true,
                    handler(val) {
                        document.documentElement.style.setProperty('--vh', `${val}px`);
                    },
                },
                windowSize: {
                    deep: true,
                    immediate: false,
                    handler() {
                        this.vh = window.innerHeight * 0.01;
                        this.$emit('resize');
                    },
                },
            },
        });
    },
};

Vue.use(eventBus);
