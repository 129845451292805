import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import MediaImagePopup from '@/components/local/data/MediaImagePopup';

const getImageWithDetailDefaults = () => ({
    image: '',
});

export default {
    components: {
        MediaImagePopup,
    },

    data() {
        return {
            lastSelectedRow: null,
            lastSelectedRowIndex: null,
            mauiImageWithDetail: getImageWithDetailDefaults(),
            showMauiImagePopup: false,
        };
    },

    computed: {
        ...mapGetters({
            isLoadingFull: 'media/images/isLoading',
        }),
    },

    methods: {
        ...mapActions({
            loadDataRow: 'media/images/loadDataRow',
        }),

        viewDetails(row, index, openImmediately = false) {
            if (openImmediately) {
                this.showMauiImagePopup = true;
            }
            this.loadDataRow({ id: row.image })
                .then((fullRow) => {
                    if (!fullRow.image_file) {
                        return;
                    }
                    this.mauiImageWithDetail = {
                        image: fullRow.image_file,
                        imageId: fullRow.id,
                        detections: fullRow.detected_objects,
                        timeCreated: get(fullRow, 'time_created', null),
                    };
                    this.showMauiImagePopup = true;
                    this.lastSelectedRow = row;
                    this.lastSelectedRowIndex = index;
                });
        },

        closePopup() {
            this.mauiImageWithDetail = getImageWithDetailDefaults();
            this.showMauiImagePopup = false;
        },
    },
};
