import { Scatter, mixins } from 'vue-chartjs';
import { convertHexToRGBA } from '@/utils/colors';

const { reactiveProp } = mixins;
/* eslint-disable no-param-reassign */
export default {
    extends: Scatter,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        this.addPlugin({
            id: 'plugin-for-element-style',
            afterUpdate: (chart) => {
                if (chart.data.datasets === undefined || !Array.isArray(chart.data.datasets)) return;

                chart.data.datasets.forEach((dataset, index) => {
                    chart.getDatasetMeta(index).data.forEach((d, i) => {
                        const { opacity } = dataset.data[i];
                        if (opacity || opacity === 0) {
                            const modifiedColor = convertHexToRGBA(dataset.backgroundColor, opacity);
                            d._model.backgroundColor = modifiedColor;
                            d._model.borderColor = modifiedColor;
                        }
                        d._model.rotation = dataset.data[i].rotation ?? d._model.pointStyle;
                        d._model.pointStyle = dataset.data[i].pointStyle ?? d._model.pointStyle;
                    });
                });
            },
        });
        // Overwriting base render method with actual data.
        this.renderChart(this.chartData, this.options);
    },
};
