<template>
    <div class="site-hs columns-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <b-field
                    label="Name"
                    :type="getFieldType('name')"
                    :message="getFieldMessage('name')"
                >
                    <b-input v-model.trim="siteModel.name" />
                </b-field>
            </div>
            <div class="column is-6">
                <b-field
                    label="Enter address"
                    :type="getFieldType('address')"
                    :message="getFieldMessage('address')"
                >
                    <be-address-autocomplete
                        v-model.trim="siteModel.address"
                        :field-props="{
                            type: getFieldType('address'),
                            class: 'mb-0',
                        }"
                        @selected="addressSelected"
                    />
                </b-field>
            </div>
            <div class="column is-3">
                <b-field
                    label="Latitude"
                    :type="getFieldType('lat')"
                    :message="getFieldMessage('lat')"
                >
                    <b-input v-model.trim="siteModel.lat" />
                </b-field>
            </div>
            <div class="column is-3">
                <b-field
                    label="Longitude"
                    :type="getFieldType('lng')"
                    :message="getFieldMessage('lng')"
                >
                    <b-input v-model.trim="siteModel.lng" />
                </b-field>
            </div>
            <div class="column is-12">
                <div class="field">
                    <div class="label">Tags</div>
                    <be-tags
                        prefix="SITE"
                        dropdown-position="top"
                        v-model="siteTags"
                        :is-loading="isTagsLoading"
                    />
                </div>
            </div>

            <div class="column is-6">
                <div class="field">
                    <div class="label">Site Safety Manager</div>
                    <b-field
                        horizontal
                        :type="getFieldType('safety_manager.email')"
                        :message="getFieldMessage('safety_manager.email')"
                    >
                        <template #label>
                            <small>Email</small>
                        </template>
                        <b-taginput
                            v-model="siteModel.safety_manager.email"
                            rounded
                            :before-adding="beforeAddEmail"
                        />
                    </b-field>
                    <b-field
                        horizontal
                        :type="getFieldType('safety_manager.name')"
                        :message="getFieldMessage('safety_manager.name')"
                    >
                        <template #label>
                            <small>Name</small>
                        </template>
                        <b-input v-model="siteModel.safety_manager.name" />
                    </b-field>
                    <b-field
                        horizontal
                        :type="getFieldType('safety_manager.phone')"
                        :message="getFieldMessage('safety_manager.phone')"
                    >
                        <template #label>
                            <small>Phone</small>
                        </template>
                        <b-taginput
                            v-model="siteModel.safety_manager.phone"
                            rounded
                            :before-adding="beforeAddPhone"
                        />
                    </b-field>
                </div>
            </div>
            <div class="column is-6">
                <div class="field">
                    <div class="label">
                        Site STMS (<small
                        >Site Traffic Management Supervisor</small
                        >)
                    </div>
                    <b-field
                        horizontal
                        :type="getFieldType('stms.email')"
                        :message="getFieldMessage('stms.email')"
                    >
                        <template #label>
                            <small>Email</small>
                        </template>
                        <b-taginput
                            v-model="siteModel.stms.email"
                            rounded
                            :before-adding="beforeAddEmail"
                        />
                    </b-field>
                    <b-field
                        horizontal
                        :type="getFieldType('stms.name')"
                        :message="getFieldMessage('stms.name')"
                    >
                        <template #label>
                            <small>Name</small>
                        </template>
                        <b-input v-model="siteModel.stms.name" />
                    </b-field>
                    <b-field
                        horizontal
                        :type="getFieldType('stms.phone')"
                        :message="getFieldMessage('stms.phone')"
                    >
                        <template #label>
                            <small>Phone</small>
                        </template>
                        <b-taginput
                            v-model="siteModel.stms.phone"
                            rounded
                            :before-adding="beforeAddPhone"
                        />
                    </b-field>
                </div>
            </div>
            <div class="column is-12">
                <b-field>
                    <template #label>
                        Document Attachments (<small
                        >Traffic Plan, Floor Map, etc. PDF file only.</small
                        >)
                    </template>

                    <div v-if="document" class="site-hs__document">
                        <b-button
                            tag="a"
                            class="be-button is-smaller"
                            type="is-primary"
                            rounded
                            :href="document.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            icon-left="download"
                        >
                            {{ document.name }}
                        </b-button>
                        <button
                            type="button"
                            class="button-link is-danger mt-1"
                            @click="maybeDeleteFile()"
                        >
                            Delete file
                        </button>
                    </div>
                    <template v-else>
                        <b-upload
                            v-show="!dropFile"
                            v-model="dropFile"
                            drag-drop
                            expanded
                        >
                            <section class="section py-3">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="cloud-upload"
                                            custom-size="mdi-18px"
                                        />
                                    </p>
                                    <p>
                                        <small
                                        >Drop your file here or click to
                                            upload</small
                                        >
                                    </p>
                                </div>
                            </section>
                        </b-upload>
                        <div v-if="dropFile" class="tags">
                            <span class="tag is-primary is-medium">
                                {{ dropFile.name }}
                                <button
                                    class="delete is-small"
                                    type="button"
                                    @click="deleteDropFile()"
                                />
                            </span>
                        </div>
                    </template>
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
import { truncate } from 'lodash';
import { validationMixin } from 'vuelidate';
import { email, numeric } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import manageSiteMixin from '@/mixins/manageSiteMixin';

export default {
    mixins: [validationMixin, manageSiteMixin],

    computed: {
        document() {
            if (!this.site || !this.site.document) {
                return null;
            }

            const name = truncate(
                this.site.document.split('/').pop().split('#')[0].split('?')[0],
                {
                    length: 50,
                },
            );

            return {
                name,
                url: this.site.document,
            };
        },
    },

    methods: {
        ...mapActions('sites', ['deleteSiteFile']),
        beforeAddEmail(tag) {
            return email(tag);
        },

        beforeAddPhone(tag) {
            return numeric(tag);
        },

        maybeDeleteFile() {
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this file?',
                type: 'is-danger',
                onConfirm: () => {
                    this.deleteSiteFile({ siteId: this.site.id });
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.site-hs {
    ::v-deep .field.is-horizontal .field-label {
        text-align: left;
    }

    .content {
        p:not(:last-child) {
            margin-bottom: 0.25em;
        }
    }

    &__document {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button:last-child {
            margin-left: 1em;
        }
    }
}
</style>
