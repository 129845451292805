export const SITE_NAME_MAXLENGTH = 64;

export const SITE_CAMERA_NAME_MAXLENGTH = 64;

export const SITE_TYPES = {
    CARPARK: 0,
    HS: 1,
};

export const CAMERA_APP_TYPES = {
    CARPARK: 1,
    MAUI: 2,
    HS: 3,
};

export const CAMERA_COUNTRIES = [
    {
        slug: 'NZ',
        label: 'NZ',
    },
    {
        slug: 'AU',
        label: 'AU',
    },
];

export const CAMERA_LOCATIONS = {
    auto: 'AUTO',
    entry: 'ENT',
    exit: 'EXT',
    none: '',
};

export const CAMERA_FUNCTION_TYPES = {
    NONE: 0,
    ENTRY: 1,
    EXIT: 2,
    AUTO_ENTRY_FRONT: 3,
    AUTO_ENTRY_REAR: 4,
};

export const CAMERA_ORIENTATIONS = {
    front_enter: 'FRONT_ENT',
    front_exit: 'FRONT_EXT',
};

export const SITES_PAGINATION_PER_PAGE = [12, 24, 36, 48, 60, 100, 150, 200];

export const DEFAULT_SITES_PAGINATION_PER_PAGE = SITES_PAGINATION_PER_PAGE[1];
