<template>
    <manage-popup
        :active="active"
        class="alert-popup"
        :toggle-intercom="false"
        @close="$emit('close')"
    >
        <template #title>
            Edit Notes
        </template>
        <template #body>
            <b-field>
                <b-input
                    type="textarea"
                    placeholder="Detection notes"
                    v-model.trim="notes"
                />
            </b-field>
        </template>
        <template #footer>
            <b-button class="button be-button is-wider is-primary" :loading="isLoading" @click.prevent="send()">
                Send
            </b-button>
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import ManagePopup from '@/components/local/sites/ManagePopup';
import noticesMixin from '@/mixins/noticesMixin';

export default {
    props: {
        detection: {
            type: Object,
            default: null,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },

    mixins: [noticesMixin],

    components: {
        ManagePopup,
    },

    data() {
        return {
            notes: this.detection.notes,
            isLoading: false,
        };
    },

    methods: {
        ...mapActions('hs/observations/explorer/detections', ['detectionUpdate']),

        send() {
            if (this.isLoading) {
                return;
            }

            const payload = {
                id: this.detection.id,
                notes: this.notes,
            };

            this.isLoading = true;
            this.detectionUpdate(payload)
                .then(() => {
                    this.displaySuccessNotice({
                        message: 'Note successfully saved',
                    });
                    this.$emit('close');
                })
                .catch((err) => {
                    this.displayErrorNotice({
                        message: err.message || 'Cannot save notes. Please reload the page and try again',
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
