<template>
    <div class="field">
        <div class="control" @click.stop.prevent="download">
            <div class="download">
                {{ downloadLabel }}
            </div>
        </div>
        <input ref="input" type="hidden" :value="image" />
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';

export default {
    mixins: [noticesMixin],

    props: {
        image: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'Download',
        },
        labelCopied: {
            type: String,
            default: 'Downloaded!',
        },
    },

    data() {
        return {
            downloadLabel: this.label,
        };
    },

    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },

    methods: {
        updateLabel() {
            this.downloadLabel = this.labelCopied;
            this.timeout = setTimeout(() => {
                this.downloadLabel = this.label;
            }, 2000);
        },

        download() {
            const { input } = this.$refs;
            const imageUrl = input.value;

            try {
                // Fetch the image data
                fetch(imageUrl)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Failed to fetch the image');
                        }
                        return response.blob();
                    })
                    .then((blob) => {
                        // Create a blob URL for the image
                        const blobUrl = window.URL.createObjectURL(blob);

                        // Create a temporary link element for downloading
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.download = `${imageUrl.split('/').pop()}.jpg`;

                        // Trigger the download
                        link.click();

                        // Clean up the blob URL
                        window.URL.revokeObjectURL(blobUrl);

                        this.displaySuccessNotice({ message: 'Downloaded image' });
                    })
                    .catch((err) => {
                        this.displayErrorNotice({ message: err.message });
                    });
            } catch (err) {
                this.displayErrorNotice({ message: err.message });
            }

            // Clear the input field
            input.value = '';

            // Update label and handle other UI updates if needed
            this.updateLabel();
        },

    },
};
</script>

<style lang="scss" scoped>
    .download {
        display: flex;
        align-items: stretch;
        cursor: pointer;
        transition: all $speed $easing;
        padding: .5rem 1rem;
        margin: 0 10px 0 10px;
        border: 1px solid $border;
        border-radius: $radius;
        background: $grey-darker;
        justify-content: center;
        text-align: center;
        color: $primary;
        font-weight: $weight-bold;
        flex: 0 0 100px;
        @include mobile {
            flex: 0 0 80px;
            padding: .5rem .75rem;
        }

        &:hover & {
            border-color: darken($border, 10);
            color: darken($primary, 10);
        }
    }
</style>
