<template>
    <div class="columns-wrapper site-type-selector">
        <div class="site-type-selector__cols columns">
            <div
                v-for="(option, index) in options"
                :key="`opt${index}`"
                class="site-type-selector__col column is-6"
            >
                <div class="site-type-selector__card card is-rounded">
                    <button
                        type="button"
                        class="site-type-selector__card-cta"
                        :class="{ 'is-selected': option.value === computedValue }"
                        @click="computedValue = option.value"
                    >
                        <div class="site-type-selector__card-icon">
                            <b-icon :icon="option.icon" size="is-large" />
                        </div>
                        <p class="site-type-selector__card-label" v-html="option.label" />
                        <div v-if="option.value === computedValue" class="site-type-selector__card-installed">
                            <b-icon icon="check-circle" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modelMixin from '@/mixins/modelMixin';
import { SITE_TYPES } from '@/utils/sites/constants';

export default {
    mixins: [modelMixin],

    data() {
        return {
            options: [
                {
                    label: 'Carpark',
                    value: SITE_TYPES.CARPARK,
                    icon: 'car',
                },
                {
                    label: 'Health & Safety',
                    value: SITE_TYPES.HS,
                    icon: 'safety-goggles',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.site-type-selector {
    &__card {
        overflow: hidden;
        position: relative;
        flex: 1;
        display: flex;

        &-cta {
            @include button-reset;
            flex: 1;
            min-height: 240px;
            padding: 2rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            color: $text;
            background: lighten($dark, 3);
            font-size: 16px;
            border: 3px solid transparent;
            @include transition-button;

            &.is-selected {
                border-color: $green;
                background: lighten($dark, 5);
            }

            &:hover {
                background: lighten($dark, 5);
            }
        }

        &-icon {
            flex: 0 0 auto;
            margin-bottom: 1em;
        }

        &-installed {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $green;
        }

        &-label {
            flex: 0 0 auto;
        }
    }
}
</style>
