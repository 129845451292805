import dayjs from 'dayjs';
import Vue from 'vue';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_SIMPLE_FORMATTED_FORMAT = 'DD MMM';

export const DATE_FORMATTED_FORMAT = 'DD MMM YYYY';

export const TIME_FORMAT = 'HH:mm';

export const TIME_FORMAT_SEC = 'HH:mm:ss';

export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const DATETIME_FORMAT_SEC = `${DATE_FORMAT} ${TIME_FORMAT_SEC}`;

export const DATETIME_FORMAT_HOUR = `${DATE_FORMAT} HH`;

export const DATETIME_SIMPLE_FORMATTED_FORMAT = `${DATE_SIMPLE_FORMATTED_FORMAT} HH:ss`;

export const DATETIME_FORMATTED_FORMAT = `${DATE_FORMATTED_FORMAT} ${TIME_FORMAT}`;

export const DATETIME_FORMATTED_FORMAT_SEC = `${DATE_FORMATTED_FORMAT} ${TIME_FORMAT_SEC}`;

export const WEEK_IN_SECONDS = 60 * 60 * 24 * 7;

export const YEAR_IN_SECONDS = 60 * 60 * 24 * 365;

export const HUMANIZE_IN_SECONDS = YEAR_IN_SECONDS;

export function dateFormatter(date) {
    return (
        dayjs.isDayjs(date) ? date : dayjs(date)
    ).format(DATE_FORMAT);
}

export function timeFormatter(time) {
    return (
        dayjs.isDayjs(time) ? time : dayjs(time)
    ).format(TIME_FORMAT);
}

export function datetimeFormatter(time, seconds = false) {
    return (
        dayjs.isDayjs(time) ? time : dayjs(time)
    ).format(seconds ? DATETIME_FORMAT_SEC : DATETIME_FORMAT);
}

export function datetimeFormattedFormatter(time, seconds = false) {
    return (
        dayjs.isDayjs(time) ? time : dayjs(time)
    ).format(seconds ? DATETIME_FORMATTED_FORMAT_SEC : DATETIME_FORMATTED_FORMAT);
}

export function datetimeApiFormatter(time) {
    return (
        dayjs.isDayjs(time) ? time : dayjs(time)
    ).format('YYYY-MM-DD[T]HH:mm:ss');
}

export function datetimeAsUtcApiFormatter(time) {
    const dateTime = dayjs.isDayjs(time) ? time : dayjs(time);
    const dateTimeAsUtc = dateTime.utc();
    return dateTimeAsUtc.format('YYYY-MM-DD[T]HH:mm:ss');
}

export function datetimeHumanFormatter(time) {
    const obj = dayjs.isDayjs(time) ? time : dayjs(time);

    if ((Vue.prototype.$now.unix() - obj.unix()) > HUMANIZE_IN_SECONDS) {
        return datetimeFormatter(obj);
    }

    // a bug with the backend - the time is off and we have to check if the update has happened in the "future"
    if (obj.isAfter(Vue.prototype.$now)) {
        return obj.toNow(false);
    }
    return obj.fromNow(false);
}

export function dateHumanFormatter(time) {
    const obj = dayjs.isDayjs(time) ? time : dayjs(time);

    if ((Vue.prototype.$now.unix() - obj.unix()) > HUMANIZE_IN_SECONDS) {
        return dateFormatter(obj);
    }
    // a bug with the backend - the time is off and we have to check if the update has happened in the "future"
    if (obj.isAfter(Vue.prototype.$now)) {
        return obj.toNow(false);
    }
    return obj.fromNow(false);
}
