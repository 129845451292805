/* eslint-disable no-param-reassign, guard-for-in, no-restricted-syntax */

import { COLORS } from '@/utils/constants';

export function convertToCanvasRect({ str, scale, translate }) {
    // [l, t, r, b]
    if (str == null) {
        return [0, 0, 0, 0];
    }
    if (!translate) {
        translate = [0, 0];
    }

    const arr = str;
    let i;
    for (i in arr) {
        arr[i] = parseInt(parseInt(arr[i], 10) / scale, 10) + translate[i % 2];
    }

    // convert to width
    arr[2] -= arr[0];

    // convert to height
    arr[3] -= arr[1];

    return arr;
}

export function convertToCanvasLine({ str, scale, translate }) {
    if (str == null) {
        return [];
    }
    if (!translate) {
        translate = [0, 0];
    }

    const arr = str;
    let i;
    for (i in arr) {
        arr[i] = parseInt(parseInt(arr[i], 10) / scale, 10) + translate[i % 2];
    }

    return arr;
}

export function drawBoundingBox(
    {
        ctx,
        typeLabel = '',
        lineWidth = 3,
        strokeStyle,
        resultBoundingBox,
        scale,
        translate,
        strokeLineDash = [],
    },
) {
    if (!resultBoundingBox) {
        return;
    }

    // render bounding box
    const boundingBox = convertToCanvasRect({
        str: [
            resultBoundingBox.l,
            resultBoundingBox.t,
            resultBoundingBox.r,
            resultBoundingBox.b,
        ],
        scale,
        translate,
    });

    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = strokeStyle;
    ctx.setLineDash(strokeLineDash);
    ctx.strokeRect(boundingBox[0], boundingBox[1], boundingBox[2], boundingBox[3]);

    ctx.fillStyle = 'RGBA(255, 255, 200, 0.2)';
    ctx.fillRect(
        boundingBox[0] + 1.5,
        boundingBox[1] + 1.5,
        boundingBox[2] - 1.5,
        boundingBox[3] - 1.5,
    );

    // render label
    if (typeLabel) {
        const labelBoundingBox = [
            // rect
            boundingBox[0] - 1.5,
            boundingBox[1] - 16,
            // text
            boundingBox[0] + 2,
            boundingBox[1] - 4,
        ];

        // place underneath
        if (labelBoundingBox[1] < 0 || labelBoundingBox[3] < 0) {
            labelBoundingBox[1] = boundingBox[1] + boundingBox[3];
            labelBoundingBox[3] = boundingBox[1] + boundingBox[3] + 12;
        }

        ctx.font = 'normal 12px replica';
        ctx.fillStyle = 'RGBA(255, 255, 255, 0.8)';
        ctx.fillRect(
            labelBoundingBox[0],
            labelBoundingBox[1],
            ctx.measureText(typeLabel).width + 6,
            14,
        );
        ctx.fillStyle = COLORS.black;
        ctx.fillText(typeLabel, labelBoundingBox[2], labelBoundingBox[3]);
    }
}

export function getTansformationConfig({ clientWidth, clientHeight, mediaWidth, mediaHeight }) {
    const aspectRatio = mediaWidth / mediaHeight;
    const isWide = clientWidth / clientHeight > aspectRatio;
    const visibleMediaHeight = isWide ? clientHeight : clientWidth / aspectRatio;
    const visibleMediaWidth = isWide ? clientHeight * aspectRatio : clientWidth;
    const translateX = (clientWidth - visibleMediaWidth) / 2;
    const translateY = (clientHeight - visibleMediaHeight) / 2;
    return {
        translate: [translateX, translateY],
        scale: mediaWidth / visibleMediaWidth,
    };
}
