<template>
    <tr
        class="camera-metrics-row"
        :class="{
            'is-off': isOff,
            'is-day': isDay,
            'is-hour': isHour,
        }"
    >
        <th class="camera-metrics-row__label">
            {{ measurement.label }}
        </th>
        <td class="camera-metrics-row__input is-text" :class="{ 'is-error': $v.computedValue.medium_risk_threshold.$error }">
            <span v-if="isOff" class="camera-metrics-row__off">Off</span>
            <b-input v-else v-model.number="computedValue.medium_risk_threshold" class="is-marginless" />
        </td>
        <td class="camera-metrics-row__input is-text" :class="{ 'is-error': $v.computedValue.high_risk_threshold.$error }">
            <span v-if="isOff" class="camera-metrics-row__off">Off</span>
            <b-input v-else v-model.number="computedValue.high_risk_threshold" class="is-marginless" />
        </td>
        <td class="camera-metrics-row__input is-text" :class="{ 'is-error': $v.computedValue.alert_threshold.$error }">
            <span v-if="isOff" class="camera-metrics-row__off">Off</span>
            <b-input v-else v-model.number="computedValue.alert_threshold" class="is-marginless" />
        </td>
        <td class="camera-metrics-row__input is-select">
            <b-select expanded v-model="computedValue.threshold_period">
                <option
                    v-for="period in thresholdPeriods"
                    :key="`period-${period.id}`"
                    :value="period.id"
                >
                    {{ period.label }}
                </option>
            </b-select>
        </td>
    </tr>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { helpers, required } from 'vuelidate/lib/validators';
import modelMixin from '@/mixins/modelMixin';
import { HS_CAMERA_THRESHOLD_PERIODS } from '@/utils/sites/cameras';

const requiredIfPeriod = (value, model) => {
    if (model.threshold_period === 1) {
        return true;
    }
    return helpers.req(value) && value > 0;
};

export default {
    mixins: [
        validationMixin,
        modelMixin,
    ],

    props: {
        measurement: {
            type: Object,
            required: true,
        },
    },

    validations: {
        computedValue: {
            medium_risk_threshold: {
                required: requiredIfPeriod,
            },
            high_risk_threshold: {
                required: requiredIfPeriod,
            },
            alert_threshold: {
                required: requiredIfPeriod,
            },
            threshold_period: { required },
        },
    },

    data() {
        return {
            thresholdPeriods: HS_CAMERA_THRESHOLD_PERIODS,
        };
    },

    computed: {
        isOff() {
            return this.computedValue.threshold_period === 1;
        },
        isDay() {
            return this.computedValue.threshold_period === 3;
        },
        isHour() {
            return this.computedValue.threshold_period === 2;
        },
    },

    watch: {
        isOff(val) {
            if (val) {
                this.computedValue.medium_risk_threshold = 0;
                this.computedValue.high_risk_threshold = 0;
                this.computedValue.alert_threshold = 0;
            }
        },
    },

    methods: {
        validate() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.camera-metrics-row {

    $day-color: $light-blue;
    $hour-color: $blue;
    $off-color: $red;
    $text-color: $black;

    &.is-off {
        & ::v-deep {
            input,
            select {
                background: $off-color;
                border-color: transparent;
                color: $text-color;
            }

            .select:after {
                border-color: $text-color;
            }
        }
    }

    &.is-off & {
        &__input {
            background: $off-color;
        }

        &__off {
            color: $text-color;
        }
    }

    &.is-day & {
        &__input.is-select {
            background: $day-color;

            & ::v-deep {
                select {
                    background: $day-color;
                    border-color: transparent;
                    color: $text-color;
                }

                .select:after {
                    border-color: $text-color;
                }
            }
        }
    }

    &.is-hour & {
        &__input.is-select {
            background: $hour-color;

            & ::v-deep {
                select {
                    background: $hour-color;
                    border-color: transparent;
                    color: $text-color;
                }

                .select:after {
                    border-color: $text-color;
                }
            }
        }
    }

    &__label,
    &__input {
        vertical-align: middle;
    }

    &__label {
        font-size: 13px;
        line-height: 1.1;
    }

    &__off {
        font-size: 13px;
        line-height: 1.1;
        text-align: center;
        display: block;
        pointer-events: none;
        user-select: none;
    }

    &__input {
        width: 15%;
        min-width: 115px;

        & ::v-deep {
            input,
            select {
                text-align: center;
            }

            .control {
                &:after {
                    display: none;
                }
            }
        }

        &.is-error ::v-deep {
            input {
                border-color: $red;
            }
        }
    }
}
</style>
