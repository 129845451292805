import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';

const getDefaultState = () => ({
    card: null,
    status: '',
});

const state = getDefaultState();

const getters = {
    cardDetails: (state) => state.card,
    cardStatus: (state) => state.status,
};

const mutations = {
    cardLoading(state) {
        state.status = STATUS_LOADING;
    },
    cardSuccess(state, cardObject) {
        state.status = STATUS_SUCCESS;
        state.card = cardObject;
    },
    cardError(state) {
        state.status = STATUS_ERROR;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    addCard({ commit, rootGetters }, payload) {
        const url = getApiUrl({ path: 'billing/addCard' });

        return new Promise((resolve, reject) => {
            commit('user/userRequest', null, { root: true });
            axios({ url, data: payload, method: 'POST' })
                .then((response) => {
                    const userData = rootGetters['user/userDetails'];
                    userData.payment = response.data.id;
                    commit('user/userSuccess', userData, { root: true });
                    resolve(response);
                })
                .catch((err) => {
                    commit('user/userError', null, { root: true });
                    reject(err);
                });
        });
    },
    removeCard({ commit }, payload) {
        const url = getApiUrl({
            path: 'billing/deleteCard',
            query: { card_id: payload.userObject.payment },
        });

        return new Promise((resolve, reject) => {
            commit('user/userRequest', null, { root: true });
            axios({ url, data: { card_id: payload.userObject.payment }, method: 'POST' })
                .then((response) => {
                    commit('user/userSuccess', {
                        ...payload.userObject,
                        payment: '',
                    }, { root: true });
                    commit('cardSuccess', null);
                    resolve(response);
                })
                .catch((err) => {
                    commit('user/userError', null, { root: true });
                    reject(err);
                });
        });
    },
    getCardDetails({ commit }, cardId) {
        const url = getApiUrl({ path: 'billing/viewCard' });

        return new Promise((resolve, reject) => {
            commit('cardLoading');
            axios({ url, params: { card_id: cardId }, method: 'GET' })
                .then((response) => {
                    commit('cardSuccess', response.data);
                    resolve(response);
                })
                .catch((err) => {
                    commit('cardError');
                    reject(err);
                });
        });
    },
    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
