import dayjs from 'dayjs';
import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { createDataHash } from '@/utils/store-helpers';

const getDefaultState = () => ({
    status: '',
    data: {
        uptime: null,
        avg_confidence: null,
    },
    dataHash: null,
});

const state = getDefaultState();

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setData(state, { data }) {
        state.data = data;
    },
    setDataHash(state, { dataHash }) {
        state.dataHash = dataHash;
    },
    setStatus(state, { status }) {
        state.status = status;
    },
};
const actions = {
    loadSummaryData({ commit, state, rootState }, { tags = [] }) {
        const data = {};
        if (tags.length) {
            // maybe we will have the tags with labels then we need
            data.tags = tags.join(',');
        }

        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        let path = 'summary';
        if (startDate && endDate) {
            path += `/${dayjs(startDate).format('YYYY-MM-DD')}/to/${dayjs(endDate).format('YYYY-MM-DD')}`;
        }

        const dataHash = createDataHash(path, data);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        return axios({ url: getApiUrl({ path }), data })
            .then((response) => {
                commit('setData', { data: response.data });
                commit('setStatus', { status: 'success' });
                commit('setDataHash', { dataHash });
            })
            .catch((err) => errorHandler(err))
            .finally(() => {
                commit('setStatus', { status: 'success' });
            });
    },

    resetState({ commit }) {
        commit('resetState');
    },
};

const getters = {
    summaryData: (state) => state.data,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
