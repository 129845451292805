import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';

// const mockedErrorResponse = (message = '', code = 400) => [code, { message }];

const mock = new MockAdapter(axios);

/**
 * Get all sites information
 */
// mock
//     .onGet(/sites\/?$/)
//     .reply(200, sitesGet());

/**
 * Get single site information for the account
 */
// mock
//     .onGet(/sites\/([\d\w\-]+)\/?$/)
//     .reply((config) => {
//
//         const regex = new RegExp('\/([\\d\\w\\-]+)/?$', 'gi');
//         const matches = regex.exec(config.url);
//
//         if (!matches[1]) {
//             return mockedErrorResponse('Site not found');
//         }
//
//         const site = siteGet({ siteId: matches[1] });
//         if (!site) {
//             return mockedErrorResponse('Site not found');
//         }
//
//         return [200, site];
//     });

/**
 * Get all cameras for the site
 */
// mock
//     .onGet(/sites\/([\d\w\-]+)\/cameras\/?$/)
//     .reply((config) => {
//
//         const regex = new RegExp('\/([\\d\\w\\-]+)/cameras/?$', 'gi');
//         const matches = regex.exec(config.url);
//         if (!matches[1]) {
//             return mockedErrorResponse('Site not found');
//         }
//
//         return [200, camerasGet({ siteId: matches[1] })];
//     });

/**
 * Update tags for the site or camera
 */
// mock
//     .onPost(/tags\/(site|camera)\/([\d\w\-]+)\/?$/)
//     .reply((config) => {
//         const regex = new RegExp('\/([\\d\\w\\-]+)/?$', 'gi');
//
//         const matches = regex.exec(config.url);
//         if (!matches[1]) {
//             return mockedErrorResponse('Site not found');
//         }
//
//         let dataJson = JSON.parse(config.data);
//         return [200, tagsEntryGet({ id: matches[1], returnTags: dataJson.tags || [] })];
//     });

/**
 * Add site
 */
// mock
//     .onPost(/sites\/?$/)
//     .reply((config) => {
//         return [200, siteCreate({ data: JSON.parse(config.data) })];
//     });

/**
 * Update site
 */
// mock
//     .onPut(/sites\/([\d\w\-]+)\/?$/)
//     .reply((config) => {
//         const regex = new RegExp('\/([\\d\\w\\-]+)/?$', 'gi');
//
//         const matches = regex.exec(config.url);
//         if (!matches[1]) {
//             return mockedErrorResponse('Site not found');
//         }
//
//         let additionalData = JSON.parse(config.data);
//         return [200, siteGet({ siteId: matches[1], additionalData })];
//     });

/**
 * Remove site
 */
// mock
//     .onDelete(/sites\/([\d\w\-]+)\/?$/)
//     .reply(204);

/**
 * Update site camera data
 */
// mock
//     .onPut(/sites\/([\d\w\-]+)\/cameras\/([\d\w\-]+)\/?$/)
//     .reply((config) => {
//         const regex = new RegExp('\/([\\d\\w\\-]+)/?$', 'gi');
//
//         const matches = regex.exec(config.url);
//         if (!matches[1]) {
//             return mockedErrorResponse('Site not found');
//         }
//
//         let additionalData = JSON.parse(config.data);
//         return [200, cameraGet({ cameraId: matches[1], additionalData })];
//     });

/**
 * Create site camera
 */
// mock
//     .onPost(/sites\/([\d\w\-]+)\/cameras\/?$/)
//     .reply((config) => {
//         const regex = new RegExp('\/([\\d\\w\\-]+)/cameras/?$', 'gi');
//         const matches = regex.exec(config.url);
//         if (!matches[1]) {
//             return mockedErrorResponse('Site not found');
//         }
//         return [200, cameraCreate({ data: JSON.parse(config.data) })];
//     });

/**
 * Remove site camera
 */
// mock
//     .onDelete(/sites\/([\d\w\-]+)\/cameras\/([\d\w\-]+)\/?$/)
//     .reply(204);

/**
 * Get Summary data
 */
// mock
//     .onGet(/summary/)
//     .reply(200, summaryGet());

/**
 * Get all tags
 */
// mock
//     .onGet(/tags\/?/)
//     .reply((config) => {
//         const url = config.url.split('?');
//         const qstring = qs.parse(url[1]);
//         const filter = qstring.filter || '';
//
//         return [200, tagsGet({ filter })];
//     });
//
// /**
//  * Add new tag
//  */
// mock
//     .onPost(/tags\/?/)
//     .reply((config) => {
//         return [200, tagAdd({ tag: JSON.parse(config.data) })];
//     });

/**
 * Get all webhooks
 */
// mock
//     .onGet(/webhooks\/?$/)
//     .reply(() => {
//         return [200, webhooksGet()];
//     });

/**
 * Get single webhook
 */
// mock
//     .onGet(/webhooks\/([\d\w\-]+)\/?$/)
//     .reply((config) => {
//         const regex = new RegExp('\/([\\d\\w\\-]+)/?$', 'gi');
//         const matches = regex.exec(config.url);
//
//         if (!matches[1]) {
//             return mockedErrorResponse('Webhook not found');
//         }
//
//         const webhook = webhookGet({ webhookId: matches[1] });
//         if (!webhook) {
//             return mockedErrorResponse('Webhook not found');
//         }
//
//         return [200, webhook];
//     });

/**
 * Add webhook
 */
// mock
//     .onPost(/webhooks\/?$/)
//     .reply((config) => {
//         return [200, webhookCreate({ data: JSON.parse(config.data) })];
//     });

/**
 * Update webhook
 */
// mock
//     .onPut(/webhooks\/([\d\w\-]+)\/?$/)
//     .reply((config) => {
//         const regex = new RegExp('\/([\\d\\w\\-]+)/?$', 'gi');
//
//         const matches = regex.exec(config.url);
//         if (!matches[1]) {
//             return mockedErrorResponse('Webhook not found');
//         }
//
//         let additionalData = JSON.parse(config.data);
//         return [200, webhookGet({ webhookId: matches[1], additionalData })];
//     });

/**
 * Remove webhook
 */
// mock
//     .onDelete(/webhooks\/([\d\w\-]+)\/?$/)
//     .reply(204);

/**
 * Get unique detections
 */
// mock.onGet(/detections\/unique/)
//     .reply(() => {
//         return [200, detectionsGet()];
//     });
//

// mock.onGet(/hsafety\/detections\/graph(.*)/)
//     .reply(() => (
//         [200, hsOverviewGet()]
//     ));

// mock.onGet(/hsafety\/summary(.*)/)
//     .reply(() => (
//         [200, hsSummaryGet()]
//     ));

// other requests should work as expected
mock.onAny().passThrough();
