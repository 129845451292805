const getDefaultState = () => ({
    selectedObjectIds: [],
    cameraData: null,
    zoneRois: [],
});

const state = getDefaultState();

const getters = {
    selectedObjectIds: (state) => state.selectedObjectIds,
    cameraData: (state) => state.cameraData,
    ppeSettings: (state) => ({
        detectVis: state.cameraData?.hsafety_setting?.hivis_ppe_criteria || false,
        detectHat: state.cameraData?.hsafety_setting?.hardhat_ppe_criteria || false,
        temporarySpeedLimit: state.cameraData?.hsafety_setting?.temporary_speed_limit || 0,
    }),
    zoneRois:
        (state) => (roiType = null) => (roiType ? state.zoneRois.find((x) => x.roi_type === roiType) : state.zoneRois),
};

const mutations = {
    setSelectedObjectIds(state, { selectedObjectIds }) {
        state.selectedObjectIds = selectedObjectIds;
    },

    setCameraData(state, { cameraData }) {
        state.cameraData = cameraData;
    },

    setZoneRois(state, { zoneRois }) {
        state.zoneRois = zoneRois;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
