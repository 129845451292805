<template>
    <div class="site-map">
        <mgl-map
            ref="map"
            class="site-map__map"
            :style="{ height }"
            v-bind="mapboxOptions"
        >
            <site-map-marker
                :item="site"
                :has-popup="false"
                :has-label="true"
            />
        </mgl-map>
    </div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import 'v-mapbox/dist/v-mapbox.css';
import { MAPBOX_OPTIONS } from '@/utils/map';
import mapMixin from '@/mixins/mapMixin';
import SiteMapMarker from './SiteMapMarker';

export default {
    mixins: [mapMixin],

    components: {
        SiteMapMarker,
    },

    props: {
        site: {
            type: Object,
            required: true,
        },
        height: {
            type: String,
            default: '200px',
        },
    },

    data() {
        return {
            mapboxOptions: {
                ...MAPBOX_OPTIONS,
                interactive: false,
                center: [
                    this.site.lng,
                    this.site.lat,
                ],
                zoom: 17,
                attributionControl: false,
                maxZoom: 18,
            },
        };
    },
};
</script>

<style lang="scss">
@import url('https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css');

.site-map {
    &__map {
        width: 100%;
    }
}
</style>
