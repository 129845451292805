<template>
    <component :is="summaryComponent" />
</template>

<script>
import { mapGetters } from 'vuex';
import Maui63Summary from '@/components/pages/dashboard/maui63/Summary';
import VehicleSummary from '@/components/pages/dashboard/vehicle/VehicleSummary';

export default {
    components: {
        Maui63Summary,
        VehicleSummary,
    },

    computed: {
        ...mapGetters('user', ['isMaui63']),

        summaryComponent() {
            return this.isMaui63
                ? 'maui63-summary'
                : 'vehicle-summary';
        },
    },
};
</script>
