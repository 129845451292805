<template>
    <div>
        <!-- <data-table-options /> -->
        <b-table
            :data="formattedApiResults"
            :hoverable="true"
        >
            <b-table-column label="First Name" v-slot="props">
                {{ props.row.name.split(' ')[0] }}
            </b-table-column>
            <b-table-column label="Last Name" v-slot="props">
                {{ props.row.name.substring(props.row.name.split(' ')[0].length + 1) }}
            </b-table-column>
            <b-table-column label="Role" v-slot="props">
                {{ props.row.role }}
            </b-table-column>
            <b-table-column label="Sites" v-slot="props">
                <template v-for="site in props.row.sites">
                    <span :key="site.id">{{ site.name }}<br></span>
                </template>
            </b-table-column>
            <b-table-column label="Email" v-slot="props">
                {{ props.row.email }}
            </b-table-column>
            <b-table-column label="Action" v-slot="props">
                <div style="display: flex;">
                    <!-- <div @click="goToPage(props.row.id)" style="cursor: pointer;">
                        <b-icon icon="pencil" style="cursor: pointer; margin-right: 0.5em;" />
                    </div> -->
                    <div
                        @click="remove(props.row.id)"
                        @keydown.enter="remove(props.row.id)"
                        tabindex="0"
                        style="cursor: pointer;"
                    >
                        <b-icon icon="delete" />
                    </div>
                </div>
            </b-table-column>
            <template slot="empty">
                <data-table-empty v-if="showZeroResults">
                    There are no results to display.
                </data-table-empty>
            </template>
        </b-table>
        <!-- <be-pagination
            key="detections"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :count="overallCount"
        /> -->
    </div>
</template>

<script>
// import BePagination from '@/components/global/BePagination';
// import BeDetectionsList from '@/components/global/BeDetectionsList';
// import DataTableOptions from '@/components/local/dataTables/DataTableOptions';
// import BeButtonViewImage from '@/components/global/BeButtonViewImage';
// import DataTableEmpty from '@/components/widgets/dataTables/DataTableEmpty';
import { getApiUrl } from '@/utils/api';

export default {
    components: {
        // BeButtonViewImage,
        // DataTableOptions,
        // BePagination,
        // DataTableEmpty,
        // BeDetectionsList,
    },

    mounted() {
        this.loadData({ refresh: true });
    },

    watch: {
        isLoading(val) {
            if (val) {
                this.$emit('loading');
            } else {
                this.$emit('ready');
            }
        },
    },

    computed: {
        storePrefix() {
            return 'users/invites/';
        },

        overallCount() {
            return this.$store.getters[`${this.storePrefix}formattedApiResults`].length;
        },

        isLoading() {
            return this.$store.getters[`${this.storePrefix}isLoading`];
        },

        formattedApiResults() {
            return this.$store.getters[`${this.storePrefix}formattedApiResults`];
        },

        perPage: {
            get() {
                return this.$store.state.users.invites.perPage;
            },
            set(perPage) {
                return this.$store.commit(`${this.storePrefix}setPerPage`, { perPage });
            },
        },

        currentPage: {
            get() {
                return this.$store.state.users.invites.currentPage;
            },
            set(currPage) {
                const page = currPage && currPage > 0 ? currPage : 1;
                return this.$store.commit(`${this.storePrefix}setPage`, { page });
            },
        },

        showZeroResults() {
            return !this.isLoading
                && this.overallCount === 0;
        },
    },

    methods: {
        loadData(payload = null) {
            return this.$store.dispatch(`${this.storePrefix}loadData`, payload);
        },
        goToPage(id) {
            this.$router.push(`/dashboard/user-invites/${id}`);
        },
        async deleteRow(id) {
            return this.$http.delete(getApiUrl({ path: `organisation/invites/${id}` }));
        },
        remove(id) {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure want to remove this invite?',
                type: 'is-danger',
                onConfirm: () => {
                    this.deleteRow(id)
                        .then(() => (
                            this.loadData({ refresh: true })
                        ));
                },
            });
        },
    },
};
</script>
