import axios from 'axios';
import { getApiUrl } from '@/utils/api';
import { ACCOUNT_TYPE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';

const getDefaultState = () => ({
    details: null,
    status: '',
    dashboardType: null,
    currentPlan: 'standard', // to display a banner
});

const state = getDefaultState();

const getters = {
    userStatus: (state) => state.status,
    userDetails: (state) => state.details,
    userFullName: (state) => (state.details ? [state.details.first_name, state.details.last_name].join(' ') : ''),
    userInitials: (state) => (state.details ? state.details.first_name.charAt(0) : ''),
    isMaui63: (state) => state.dashboardType === ACCOUNT_TYPE.maui63,
    isBigeyes: (state) => state.dashboardType === ACCOUNT_TYPE.bigeyes,
    displayEnterpriseBanner: (state, getters) => getters.isBigeyes && state.currentPlan === 'standard',
};

const mutations = {
    userRequest(state) {
        state.status = STATUS_LOADING;
    },
    userSuccess(state, userObject) {
        state.status = STATUS_SUCCESS;
        if (userObject) {
            state.details = userObject;
        }
    },
    setDashboardType(state, { dashboard_type }) { // eslint-disable-line camelcase
        state.dashboardType = dashboard_type; // eslint-disable-line camelcase
    },
    userError(state) {
        state.status = STATUS_ERROR;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    getUserDetails({ commit, dispatch }) {
        commit('userRequest');
        return Promise.all([
            // axios({ url: getApiUrl({ path: 'auth/user' }) }),
            axios({ url: getApiUrl({ path: 'profile' }) }),
        ])
            .then((responses) => {
                commit('userSuccess', { ...responses[0].data });

                if (responses[0]?.data?.role !== 'ADMIN') {
                    dispatch('removeUnauthorizedAccessItems');
                }

                commit('setDashboardType', responses[0].data);
            })
            .catch((err) => {
                commit('userError');
                dispatch('removeUnauthorizedAccessItems');
                throw new err(); // eslint-disable-line new-cap
            });
    },

    removeUnauthorizedAccessItems({ dispatch }) {
        dispatch(
            'nav/removeItem',
            { itemPath: '/dashboard/user-management', slug: 'setup' },
            { root: true },
        );
        dispatch(
            'nav/removeItem',
            { itemPath: '/dashboard/user-invites', slug: 'setup' },
            { root: true },
        );
        dispatch(
            'nav/removeItem',
            { itemPath: null, slug: 'marketplace' },
            { root: true },
        );
    },

    updateAppOptions({ dispatch, rootGetters }) {
        dispatch(
            'nav/restoreMenu',
            '',
            { root: true },
        );

        const enabledAppsSlugs = rootGetters['marketplace/user/allEnabledApps'];
        const allApps = rootGetters['marketplace/allApps'];
        const disabledApps = allApps.filter((app) => !enabledAppsSlugs.includes(app.slug));

        disabledApps
            .forEach((app) => {
                if (app.tabs) {
                    app.tabs.forEach((tabs) => {
                        dispatch(
                            'nav/removeItem',
                            { itemPath: tabs, slug: app.slug },
                            { root: true },
                        );
                    });
                } else {
                    dispatch(
                        'nav/removeItem',
                        { slug: app.slug },
                        { root: true },
                    );
                }
            });
    },

    updatePlan({ commit, getters }, plan) {
        commit('userRequest');
        return new Promise((resolve, reject) => {
            axios({
                url: getApiUrl({ path: 'billing/updatePlan' }),
                params: { new_plan_id: plan },
                method: 'GET',
            })
                .then((response) => {
                    const userDeets = getters.userDetails;
                    userDeets.plan = response.data.plan.id;
                    commit('userSuccess', userDeets);
                    resolve(response);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err);
                });
        });
    },

    changePassword({ commit }, passwords) {
        const url = getApiUrl({ path: 'auth/password/change' });

        commit('userRequest');
        return new Promise((resolve, reject) => {
            axios({ url, data: passwords, method: 'POST' })
                .then((response) => {
                    commit('userSuccess');
                    resolve(response);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err);
                });
        });
    },
    resetState({ commit }) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
