import Vue from 'vue';
import { indexOf, keyBy } from 'lodash';
import axios from 'axios';
import { errorHandler } from '@/utils/errors';
import { API_VERSION, getApiUrl } from '@/utils/api';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';

const getDefaultState = () => ({
    enabledApps: [],
    allAppSettings: {},
    loadStatus: '',
    updateStatus: '',
});

const state = getDefaultState();

const getters = {
    allEnabledApps(state, getters, rootState) {
        const apps = state.enabledApps;
        if (rootState.stolenPlates.stolenPlates && apps.indexOf('stolen-vehicles') === -1) {
            apps.push('stolen-vehicles');
        }
        return apps;
    },
    isSaving: (state) => state.updateStatus === STATUS_LOADING,
    isLoading: (state) => state.loadStatus === STATUS_LOADING,
};

const mutations = {
    toggleApp(state, { appId, force = null }) {
        const appIndex = indexOf(state.enabledApps, appId);
        const isEnabled = appIndex > -1;

        const enable = force === null ? !isEnabled : force;
        if (enable) {
            if (appIndex < 0) {
                state.enabledApps.push(appId);
            }
            return;
        }
        if (appIndex > -1) {
            Vue.delete(state.enabledApps, appIndex);
        }
    },

    loadAllAppSettings(state, { data }) {
        const integratedApps = ['salesforce'];
        const dataByAppId = keyBy(data, 'appId');

        integratedApps.forEach((appId) => {
            const settings = dataByAppId[appId] || null;
            state.allAppSettings[appId] = settings;

            const enable = settings && settings.enabled;
            const appIndex = indexOf(state.enabledApps, appId);

            if (enable && appIndex < 0) {
                state.enabledApps.push(appId);
                return;
            }
            if (!enable && appIndex > -1) {
                Vue.delete(state.enabledApps, appIndex);
            }
        });
    },

    loadAppSettings(state, { appId, data }) {
        state.allAppSettings[appId] = data;
    },

    setLoadStatus(state, { status }) {
        state.loadStatus = status;
    },

    setUpdateStatus(state, { status }) {
        state.updateStatus = status;
    },
};

const _mapAppSettingsItem = function (apiDataItem) {
    if (apiDataItem == null) {
        return null;
    }
    return {
        enabled: apiDataItem.is_enabled,
        username: apiDataItem.username,
        clientId: apiDataItem.oauth_client_id,
        appId: apiDataItem.integration_partner_id,
    };
};

const _mapToApiAppSettingsItem = function (integration) {
    if (integration == null) {
        return null;
    }
    return {
        is_enabled: integration.enabled,
        username: integration.username,
        oauth_client_id: integration.clientId,
    };
};

const actions = {
    toggleApp({ commit }, { appId, force = null }) {
        commit('toggleApp', { appId, force });
    },
    async loadAllAppSettings({ commit }) {
        commit('setLoadStatus', { status: STATUS_LOADING });
        try {
            const response = await axios
                .get(getApiUrl({ path: 'integrations/', apiVersion: API_VERSION.v1 }));
            commit('setLoadStatus', { status: STATUS_SUCCESS });
            commit('loadAllAppSettings', {
                data: response.data.map(_mapAppSettingsItem),
            });
        } catch (err) {
            commit('setLoadStatus', { status: STATUS_ERROR });
            errorHandler(err);
            throw new Error('Could not load all app settings');
        }
    },
    async loadAppSettings({ commit }, { appId }) {
        commit('setLoadStatus', { status: STATUS_LOADING });
        try {
            const response = await axios
                .get(getApiUrl({ path: `integrations/${appId}` }));
            commit('setLoadStatus', { status: STATUS_SUCCESS });
            const mappedData = _mapAppSettingsItem(response.data);
            commit('loadAppSettings', { appId, data: mappedData });
            if (mappedData !== null) {
                commit('toggleApp', { appId, force: mappedData.enabled });
            }
        } catch (err) {
            if ('response' in err && err.response.status !== 404) {
                commit('setLoadStatus', { status: STATUS_ERROR });
                errorHandler(err);
                throw new Error(`Failed to retrieve app settings for appId: ${appId}`);
            }
            commit('setLoadStatus', { status: STATUS_SUCCESS });
            commit('loadAppSettings', { appId, data: null });
            commit('toggleApp', { appId, force: false });
        }
    },
    async updateAppSettings({ commit }, { appId, data }) {
        commit('setUpdateStatus', { status: STATUS_LOADING });
        try {
            const response = await axios({
                method: 'PUT',
                url: getApiUrl({ path: `integrations/${appId}`, apiVersion: API_VERSION.v1 }),
                data: _mapToApiAppSettingsItem(data),
            });
            commit('setUpdateStatus', { status: STATUS_SUCCESS });
            const mappedData = _mapAppSettingsItem(response.data);
            commit('loadAppSettings', { appId, data: mappedData });
            commit('toggleApp', { appId, force: mappedData.enabled });
        } catch (err) {
            commit('setUpdateStatus', { status: STATUS_ERROR });
            errorHandler(err);
            throw new Error('Could not update integration');
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
