<template>
    <div class="integration is-hubspot">
        <be-integration-header class="integration__header" back-url="/dashboard/marketplace/apps">
            <template v-slot:heading>
                Parking Management Settings
            </template>
            <template v-slot:subheading>
                <p>
                    The Parking Management App allows easy parking management and enforcement through understanding the vehicles
                    entering and exiting the space. This features allows you to track how much time each vehicle has
                    spent in the parking space, frequency of visits as well as parking patterns across multiple sites.
                </p>
            </template>
        </be-integration-header>
        <div class="app-container">
            <div class="integration__form">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field horizontal>
                            <template v-slot:label>
                                <span class="is-block has-text-left has-text-weight-bold">
                                    Integration
                                </span>
                            </template>
                            <b-switch v-model="integration.enabled" />
                        </b-field>
                        <div class="content">
                            <p>
                                This feature enables the Live Explorer view to provide insights about your parking
                                space.
                            </p>
                        </div>
                    </div>
                    <div class="column is-12">
                        <b-button
                            @click="save"
                            :loading="isSaving"
                            class="be-button is-wider is-primary"
                        >
                            Save
                        </b-button>
                    </div>
                    <div class="column is-12">
                        <div class="card is-rounded integration__info mt-5">
                            <div class="card-content">
                                <div class="content">
                                    <h4>Using this integration</h4>
                                    <p>
                                        To use this integration, set up a camera within a site as either an entry/exit
                                        camera or use our AI based tracker to determine the direction of travel.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import noticesMixin from '@/mixins/noticesMixin';
import marketplaceAppMixin from '@/mixins/marketplaceAppMixin';
import integrationFakeSaveMixin from '@/mixins/integrationFakeSaveMixin';

export default {
    mixins: [
        noticesMixin,
        marketplaceAppMixin,
        integrationFakeSaveMixin(false),
    ],
};
</script>

<style lang="scss" scoped>
.integration {
    &__form {
        max-width: 600px;
    }
}
</style>
