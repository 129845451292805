import { isArray, isEmpty, get } from 'lodash';
import { convertApiStringArr } from '@/utils/str';
import { transformImageDetectionsObject } from '@/utils/transformers/detections';

export function hsImageTransformerSingle(image) {
    return {
        ...image,
    };
}

export function hsImagesTransformerRow(image) {
    const siteName = get(image, 'camera.site.name', '');
    const cameraName = get(image, 'camera.name', '');

    return {
        ...image,
        siteName,
        cameraName,
        isImage: true,
        rowType: 'hs',
        detections: transformImageDetectionsObject({ image }),
        confidences: image.confidence ? convertApiStringArr(image.confidence) : [],
        video: get(image, 'video', null),
    };
}

export function hsImagesTransformer(rawData) {
    if (!isArray(rawData) || isEmpty(rawData)) {
        return [];
    }

    return rawData.map((item) => hsImagesTransformerRow(item));
}
